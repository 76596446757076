<div class="wrapper" *ngIf="project.show" (click)="toggleExpanded()">
  <div class="main-container">
    <div class="left-container">
      <div class="picture-frame">
        <app-project-picture
          class="project-picture"
          [project]="project"
          width="60px"
          height="60px"
        ></app-project-picture>
      </div>
      <div class="content">
        <div class="name">{{ project.name }}</div>
        <div id="header">
          <div id="framesCount" *ngIf="projectFrameCount">
            {{ projectFrameCount.toLocaleString() }} frames
          </div>
          <div id="squareFootage" *ngIf="project.squareFootage">
            {{ project.squareFootage | squareFootage }} ft<sup>2</sup>
          </div>
        </div>
        <app-lines-bar
          *ngIf="hasData"
          [stats]="project.stats"
          [styles]="{ margin: '4px', padding: '4px' }"
        ></app-lines-bar>
      </div>
    </div>
    <div class="right-container">
      <app-round-button
        *ngIf="!expanded"
        (click)="toggleExpanded(); $event.stopPropagation()"
        class="expand-btn btn-mr"
        icon="fas fa-chevron-down"
        shadow="none"
        backgroundColor="transparent"
        iconColor="rgba(81, 81, 81, 1)"
        [sizepx]="48"
        fontSize="18px"
      ></app-round-button>

      <app-round-button
        *ngIf="expanded"
        (click)="toggleExpanded(); $event.stopPropagation()"
        class="expand-btn btn-mr"
        icon="fas fa-chevron-up"
        shadow="none"
        backgroundColor="transparent"
        iconColor="rgba(81, 81, 81, 1)"
        [sizepx]="48"
        fontSize="18px"
      ></app-round-button>

      <app-round-button
        (click)="onSelectProject(); $event.stopPropagation()"
        class="btn-mr"
        id="selectButton"
        icon="fas fa-eye"
        fontSize="20px"
        [sizepx]="48"
      ></app-round-button>
    </div>
  </div>
  <div *ngIf="expanded" class="secondary-info">
    <p class="info">General Contractor: {{ project.contractor }}</p>
    <p class="info">Manager: {{ project.manager }}</p>
    <p class="info">Status: {{ project.status }}</p>
  </div>
</div>
