import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from './services/dashboard.service';
import { OutsideClickService } from '../../services/outside-click.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LotStats, SimplifiedLotStats } from './interfaces/lot-stat';
import { StatFormatterService } from './services/stat-formatter.service';

@Component({
  selector: 'app-dashboard-charts',
  templateUrl: './dashboard-charts.component.html',
  styleUrls: ['./dashboard-charts.component.scss']
})
export class DashboardChartsComponent implements OnInit, OnDestroy {
  @Input() projectId: number;

  overallData: LotStats;
  formattedMountainStats: any;

  overallDisplayedData: SimplifiedLotStats;
  designDisplayedData: SimplifiedLotStats;
  squareFootage: number;

  selectedLotName = '';
  selectedDescription = '';
  firstRendered = true;

  mappedStats = new Map<
    string,
    {
      name: string;
      description: string;
      squareFootage: number;
      overall: SimplifiedLotStats;
      design: SimplifiedLotStats;
    }
  >();

  selectedLot: BehaviorSubject<string>;
  showSearchBar: BehaviorSubject<boolean>;

  subscriptions: Subscription[] = [];

  constructor(
    private dashboardService: DashboardService,
    private statsFormatterService: StatFormatterService,
    private outsideClickService: OutsideClickService
  ) {}

  private processData(overall: any, design: any) {
    const installedStats = overall.stats.splice(4, 1);
    overall.stats.push(installedStats[0]);
    this.mappedStats.set(overall.id, {
      name: overall.formattedName,
      description: overall.description,
      squareFootage: overall.squareFootage,
      overall: { id: overall.id, count: overall.count, stats: overall.stats },
      design: {
        id: design.id,
        count: design.count,
        stats: design.stats
      }
    });

    overall = overall.children;
    design = design.children;

    overall.forEach((lot, i) => {
      this.mappedStats.set(lot.id, {
        name: lot.formattedName,
        description: lot.description,
        squareFootage: lot.squareFootage,
        overall: { id: lot.id, count: lot.count, stats: lot.stats },
        design: {
          id: design[i].id,
          count: design[i].count,
          stats: design[i].stats
        }
      });

      lot.children.forEach((sublot, j) => {
        this.mappedStats.set(sublot.id, {
          name: sublot.formattedName,
          description: sublot.description,
          squareFootage: sublot.squareFootage,
          overall: { id: sublot.id, count: sublot.count, stats: sublot.stats },
          design: {
            id: design[i].children[j]?.id,
            count: design[i].children[j]?.count,
            stats: design[i].children[j]?.stats
          }
        });

        sublot.children.forEach((subsublot, k) => {
          this.mappedStats.set(subsublot.id, {
            name: subsublot.formattedName,
            description: subsublot.description,
            squareFootage: subsublot.squareFootage,
            overall: {
              id: subsublot.id,
              count: subsublot.count,
              stats: subsublot.stats
            },
            design: {
              id: design[i].children[j]?.children[k]?.id,
              count: design[i].children[j]?.children[k]?.count,
              stats: design[i].children[j]?.children[k]?.stats
            }
          });
        });
      });
    });
  }

  private filterData(filterValue: string, data: LotStats[]) {
    data.forEach(lot => {
      if (lot.children) {
        this.filterData(filterValue, lot.children);
      }
      lot.show = lot.formattedName.search(filterValue) > -1 ? true : false;
      if (lot.children && lot.children.some(l => l.show)) {
        lot.show = true;
        if (filterValue !== '') {
          lot.expanded = true;
        } else {
          lot.expanded = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.selectedLot = this.dashboardService.selectedLot;
    this.showSearchBar = this.dashboardService.showSearchBar;

    this.subscriptions.push(
      this.outsideClickService.outsideClickEvent.subscribe(() => {
        this.dashboardService.showSearchBar.next(false);
      })
    );

    this.subscriptions.push(
      this.dashboardService.selectedLot.subscribe(lotName => {
        if (lotName && lotName.startsWith(this.projectId.toString())) {
          this.subscriptions.push(
            this.dashboardService
              .getMountainGraphsStats(this.projectId, lotName)
              .subscribe(data => {
                if (data) {
                  this.formattedMountainStats = this.statsFormatterService.extractDatasets(
                    data
                  );
                }
              })
          );
        }
      })
    );

    this.subscriptions.push(
      this.dashboardService.getStatistics(this.projectId).subscribe(
        data => {
          if (data) {
            this.overallData = data.overall;
            this.processData(data.overall, data.design);
            this.dashboardService.selectedLot.next(this.overallData?.id);
            this.dashboardService.selectedLotFormattedName.next(
              this.overallData?.formattedName as string
            );
            this.subscriptions.push(
              this.dashboardService.selectedLot.subscribe(id => {
                if (id && this.firstRendered) {
                  const stats = this.mappedStats.get(id);
                  this.selectedLotName = stats.name;
                  this.selectedDescription = '';
                  this.overallDisplayedData = stats.overall;
                  this.designDisplayedData = stats.design;
                  this.squareFootage = stats.squareFootage;
                  this.firstRendered = false;
                }
              })
            );
          }
        },
        err => {
          console.log(err);
        }
      )
    );

    this.subscriptions.push(
      this.dashboardService.selectedLot.subscribe(id => {
        if (id && !this.firstRendered) {
          const stats = this.mappedStats.get(id);
          this.selectedLotName = stats.name;
          this.selectedDescription = stats.description;
          this.overallDisplayedData = stats.overall;
          this.designDisplayedData = stats.design;
          this.squareFootage = stats.squareFootage;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSearch(value: string) {
    this.selectedDescription = '';
    this.filterData(value, [this.overallData]);
    this.dashboardService.showSearchBar.next(true);
  }

  handleShowSearchBar() {
    if (this.dashboardService.showSearchBar.value) {
      this.dashboardService.showSearchBar.next(false);
    } else {
      this.dashboardService.showSearchBar.next(true);
    }
  }

  emitClick() {
    this.outsideClickService.emitClic();
  }
}
