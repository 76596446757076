import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuOpenedService } from '../../modules/menu/services/menu-opened.service';

@Injectable({
  providedIn: 'root'
})
export class ToggleMenuServiceService {
  public showMenu = new BehaviorSubject<boolean>(false);
  public isOpen = false;

  constructor (private menuOpenedService: MenuOpenedService) {}

  toggle() {
    this.isOpen = !this.isOpen;
    this.menuOpenedService.isOpen.next(true);
    this.showMenu.next(this.isOpen);
  }
}
