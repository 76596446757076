<div [ngStyle]="styles">
  <div class="stat-container">
    <div
      *ngFor="let statGroup of statGroups; let i = index"
      class="bar"
      [ngStyle]="{
        gridRow: i + 1 + ' / ' + (i + 3)
      }"
      [style.z-index]="statGroups.length - i"
    >
      <div
        *ngFor="let stat of statGroup; let first = first; let last = last"
        class="stat"
        [class.last-stat]="last"
        [class.first-stat]="first"
        [ngStyle]="{
          height: '8px',
          width: stat.percent + '%',
          backgroundColor: stat.color
        }"
      ></div>
    </div>
  </div>
</div>
