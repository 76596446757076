<div class="container">
    <div class="header">
        <i class="fa fa-list"></i>
        <p>ALL TEMPLATES</p>
    </div>
    
    <ng-container *ngFor="let item of checklists; let i = index">
        <app-checklist-info
            [id]='item.id'
            [name]='item.name'
            [createdAt]='item.createdAt'
            [createdBy]='item.createdBy'
            [updatedAt]='item.updatedAt'
            (editPressed)='editPressed(item)'
            (clonePressed)='clonePressed($event)'
            (removePressed)='removePressed($event)'
            (titleChange)='titleChange($event, i)'
        >
        </app-checklist-info>
    </ng-container>

    <div class="header-row hover" (click)="addChecklist()">
        <div class="left-container">
          <div class="new-btn">
            <div class="circle large">
              <i class="fa fa-clipboard-check"></i>
            </div>
            <div class="circle small">
              <i class="fa fa-plus"></i>
            </div>
          </div>
    
          <p>Add a new line...</p>
        </div>
      </div>
</div>