import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MachineTimersWebsocketService extends Socket {

  constructor(private readonly tokenStorageService: TokenStorageService) {
    super({
      url: `https://${environment.configuration.backend_url}/machiningTimes`,
      options: {
        query: `x-access-token=${tokenStorageService.get()}`
      }
    });
  }
}
