import { Component, Input, OnChanges } from '@angular/core';

import { SmrProgressFloorShared } from '../../interfaces/smr-progress-data.interface';

@Component({
  selector: 'app-smr-floors-header',
  templateUrl: './smr-floors-header.component.html',
  styleUrls: ['./smr-floors-header.component.scss']
})
export class SmrFloorsHeaderComponent implements OnChanges {
  @Input() displayRange: number;
  @Input() floors: SmrProgressFloorShared;
  @Input() startDate: Date;
  @Input() projectAndLot: string;
  @Input() isOpen: boolean;

  project: string;
  lots: string;

  ngOnChanges() {
    const firstSeparator = this.projectAndLot.indexOf('-');
    this.project = firstSeparator !== -1 ? this.projectAndLot.slice(0, firstSeparator) : this.projectAndLot;
    this.lots = firstSeparator !== -1 ? this.projectAndLot.slice(firstSeparator + 1) : '';
  }
}
