import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BarcodeReader } from '../models/barcode-reader';
import { SearchResult } from '../models/search-result.model';
import { InputMethodEnum } from '../enums/input-method.enum';
import { InputTypeService } from './input-type.service';

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {
  scannedString: Subject<SearchResult>;
  maxScannedTimer = 2000; // Max between scan in ms before resetting the string
  savedString: string;
  charForEnter = '$'; // When detecting an enter, replace with this char

  private lastScanTime: number;

  constructor(private inputTypeService: InputTypeService) {
    this.savedString = '';
    this.scannedString = new Subject();
    this.lastScanTime = 0;
  }

  newKeyboardInput(event: any): void {
    this.addToSavedString(this.returnChar(event)); // We save the string for next input
    const scanner = new BarcodeReader(this.savedString);
    if (scanner.isFromBarcodeScanner()) {
      // If we recognize the beginning scanner sequence
      if (scanner.isFromBarcodeScannerAndComplete()) {
        // If we have the complete sequence
        this.emitString(scanner.cleanedScan); // We emit on the subject
      }
    } else {
      this.savedString = '';
    }
  }

  returnChar(event: any): string {
    return event.key === 'Enter' ? this.charForEnter : event.key;
  }

  emitString(str: string) {
    this.scannedString.next({
      fullString: str,
      inputMethod: InputMethodEnum.GUN,
      inputType: this.inputTypeService.getInputType(str)
    }); // Emit
    this.savedString = ''; // Clean the saved string
  }

  addToSavedString(str: string) {
    const d = new Date();
    const newTime = d.getTime();

    // If its first scan
    if (
      this.savedString.length === 0 ||
      newTime - this.lastScanTime < this.maxScannedTimer
    ) {
      this.savedString = `${this.savedString}${str}`; // We save the string for next input
      this.lastScanTime = newTime;
    } else {
      this.savedString = str; // if to long time, we clear the string and start over
    }
  }
}
