import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent {
  @Input() size: number;
  @Input() color: string;
  @Input() selected: boolean;
  @Output() checkboxSelected = new EventEmitter();

  handleClick() {
    this.checkboxSelected.emit();
  }

  getColor(defaultValue: string): string {
    return this.selected
      ? this.color
        ? this.color
        : 'var(--fabbrica)'
      : defaultValue;
  }
}
