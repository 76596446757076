import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GroupCounts } from '../interfaces/group-counts.interface';

import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialGroupsService {
  constructor(private backend: BackendService) {}

  get(documentId): Observable<GroupCounts> {
    const matGroupsUri = this.getUri(documentId);
    return this.backend.get(matGroupsUri).pipe(mergeMap(data => of(data.counts)));
  }

  private getUri(documentId: number): string {
    return `material-groups/${documentId}`;
  }
}
