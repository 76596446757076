<app-modal
  [header]="header"
  [headerIcon]="headerIcon"
  (cancelEmitter)="onCancel()"
  (confirmEmitter)="onConfirm()"
>
  <div class="buttons" *ngIf="!twoDataTypes">
    <button
      *ngFor="let item of elements; let i = index"
      [class.activated]="
        multipleSelection
          ? i === findInSelectedButtons(i)
          : i === selectedButton
      "
      (click)="onClick(i)"
    >
      <i
        [class]="item.flag ? alternativeIcon + ' icon' : optionIcon + ' icon'"
      ></i>
      {{ item.label }}
    </button>
  </div>

  <ng-container *ngIf="twoDataTypes">
    <div class="section-header">{{ sectionHeaders[0] }}:</div>
    <div class="buttons">
      <button
        *ngFor="let item of elements[0]; let i = index"
        [class.activated]="i === selectedButtonDataType1"
        (click)="onClick({ index: i, dataSetId: 'ds1' })"
      >
        <i
          [class]="item.flag ? alternativeIcon + ' icon' : optionIcon + ' icon'"
        ></i>
        {{ item.label }}
      </button>
    </div>
    <hr class="h-divider" />
    <div class="section-header">{{ sectionHeaders[1] }}:</div>
    <div class="buttons">
      <button
        *ngFor="let item of elements[1]; let i = index"
        [class.activated]="i === selectedButtonDataType2"
        (click)="onClick({ index: i, dataSetId: 'ds2' })"
      >
        <i
          [class]="item.flag ? alternativeIcon + ' icon' : optionIcon + ' icon'"
        ></i>
        {{ item.label }}
      </button>
    </div>
  </ng-container>
</app-modal>
