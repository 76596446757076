<div class="rfid-input">
  <div class="text">
    {{ value ? value : 'Scan the RFID' }}
  </div>
  <label>
    RFID
  </label>
</div>

<app-round-button
  *ngIf="!disabled"
  class="input-icon"
  [icon]="'fa fa-qrcode'"
  [sizepx]="35"
  fontSize="20px"
  backgroundColor="var(--fabbrica"
  iconColor="var(--menuItem-background1)"
  shadow="none"
  (click)="toggleQrScanner(true)"
></app-round-button>

<div class="backdrop" *ngIf="qrScannerOpen">
  <div class="scanner-container" (click)="toggleQrScanner(false)">
    <app-qrscanner
      (scanSuccessEvent)="handleQrScanSuccess($event)"
      [mute]="false"
      [alwaysOn]="true"
      [autoClose]="true"
    ></app-qrscanner>
  </div>
</div>
