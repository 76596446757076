import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ElementRef,
  ViewChild
} from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-lot-search-bar',
  templateUrl: './lot-search-bar.component.html',
  styleUrls: ['./lot-search-bar.component.scss']
})
export class LotSearchBarComponent implements OnInit {
  @Output() searchEvent = new EventEmitter<string>();
  @Input() textValue = '';
  @Input() description = '';
  @ViewChild('search') searchElement: ElementRef;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.description = 'Overall project';
  }

  onChange() {
    this.searchEvent.emit(this.textValue);
  }

  onFocus() {
    this.searchElement.nativeElement.focus();
    this.dashboardService.showSearchBar.next(true);
  }
}
