import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceService } from '../../../../services/device.service';

@Component({
  selector: 'app-new-input-dropdown',
  templateUrl: './new-input-dropdown.component.html',
  styleUrls: ['./new-input-dropdown.component.scss']
})
export class NewInputDropdownComponent {
  expanded = false;
  options = [
    'Multichoice input',
    'Numerical input',
    'Text input',
    'Weight input',
    'RFID input',
    'Photo input'
  ];
  @Output() buttonSelected = new EventEmitter<number>();

  constructor(public deviceService: DeviceService) {}

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  onButtonSelect(e: number) {
    this.buttonSelected.emit(e);
    this.toggleExpand();
  }
}
