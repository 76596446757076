<app-round-button
  id="backward-button"
  icon="fas fa-chevron-left"
  sizepx="42"
  fontSize="21px"
  (click)="moveBackward()"
></app-round-button>
<app-round-button
  id="forward-button"
  icon="fas fa-chevron-right"
  sizepx="42"
  fontSize="21px"
  (click)="moveForward()"
></app-round-button>
