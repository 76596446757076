<div
  [ngStyle]="{
    width: size,
    height: size,
    border: border,
    'border-radius': '50%',
    'font-size': fontSize,
    'background-color': backgroundColor,
    color: iconColor,
    'box-shadow': shadow
  }"
  [ngClass]="{ active: isActive }"
>
  <div *ngIf="text">
    <p>{{ text }}</p>
  </div>

  <i *ngIf="icon && !isSvg" id="icon" [class]="icon"></i>
  <svg-icon
    *ngIf="icon && isSvg"
    id="icon"
    [src]="icon"
    [stretch]="true"
    [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
  >
  </svg-icon>
</div>
