import { Injectable } from '@angular/core';
import { StorageKey } from '../enums/storage-key.enum';

import { StorageService } from './storage.service';
import { jwtDecode } from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private key = StorageKey.TOKEN;

  constructor(private readonly storageService: StorageService) {}

  get(): string {
    const token = this.storageService.get(this.key);
    if (token && this.isTokenExpired(token)) {
      this.clear();
      return null;
    }
    return token;
  }

  /**
   * fab-1263: We want to logout from the app if last login was before today.
   *
   * We use local time and the user will logout
   * if the token was issued before today at 00:00:00 local time.
   *
   * @param token
   * @returns
   */
  isTokenExpired(token: any): boolean {
    const decoded = jwtDecode(token);
    const issuedAtDate = new Date(decoded.iat * 1000);
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    if (issuedAtDate < startOfToday) {
      return true;
    }
    return false;
  }

  set(token: string): void {
    this.storageService.set(this.key, token);
  }

  clear(): void {
    this.storageService.clear(this.key);
  }
}
