import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { SmrProgressService } from '../services/smr-progress.service';

@Directive({
  selector: '[appDateHover]'
})
export class DateHoverDirective implements OnInit, OnDestroy {
  listenerFn: (e: MouseEvent) => void;
  constructor(private el: ElementRef, private readonly smrProgressService: SmrProgressService) {
    this.listenerFn = (event: MouseEvent) => {
      this.smrProgressService.updateHoveredDate(event.clientX);
    };
  }

  ngOnInit() {
    this.el.nativeElement.addEventListener('mousemove', this.listenerFn.bind(this), { capture: true });
  }

  ngOnDestroy() {
    this.el.nativeElement.removeEventListener('mousemove', this.listenerFn.bind(this), { capture: true });
  }
}
