import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectStatusEnum } from './enums/project-status.enum';
import { ProjectData } from './interfaces/project-data';
import { ProjectService } from './services/project.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnDestroy {
  @Output() idSelected: EventEmitter<any> = new EventEmitter<any>();

  projects: ProjectData[];
  filterByStatus = false;
  filterValue = '';

  subscriptions: Subscription[] = [];

  constructor(private projectsService: ProjectService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.projectsService.getAll().subscribe(data => {
        if (data) {
          this.projects = data;
          this.filterProjects('');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  filterProjects(result: string, fromSwitchFlag = false) {
    if (!fromSwitchFlag) {
      this.filterValue = result.toLowerCase();
    }
    this.projects.forEach(p => {
      if (this.filterByStatus) {
        p.show = p.name.toLowerCase().search(this.filterValue) > -1;
      } else {
        p.show =
          p.name.toLowerCase().search(this.filterValue) > -1 &&
          p.status === ProjectStatusEnum.AWARDED;
      }
    });
  }

  filterFromSwitch() {
    this.filterByStatus = !this.filterByStatus;
    this.filterProjects('', true);
  }

  seeProjectDetails(id: string) {
    this.idSelected.emit(id);
  }
}
