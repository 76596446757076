<div id="menu-container" [ngClass]="menuState" (click)="closeMenu()">
  <div id="top" class="header-tab" (click)="toggleMenu()">
    <app-header-tab [state]="menuState"></app-header-tab>
  </div>

  <div id="middle" class="tabList">
    <app-menu-tab
      *ngFor="let tab of midTabs"
      [menuTab]="tab"
      class="tab"
    ></app-menu-tab>
  </div>

  <div id="submenu" class="tabList">
    <app-menu-tab
      *ngFor="let tab of subMenuTabs"
      [menuTab]="tab"
      class="tab"
    ></app-menu-tab>
  </div>

  <div id="bottom" class="tabList">
    <!-- <div class="divider"></div> -->
    <app-station-tab
      (click)="$event.stopPropagation()"
      id="sidemenu.station.tab"
      *ngIf="permissionsService.isVisible(user, 'sidemenu.station.tab')"
      class="tab bottom-tab"
    ></app-station-tab>
    <!-- <div class="divider"></div> -->
    <app-user-tab class="tab bottom-tab"></app-user-tab>
  </div>
</div>
