<div id="main" (click)="$event.stopPropagation()">
  <div id="when-closed" (click)="toggleScan()">
    <div id="top">
      <div id="icon-container">
        <i class="fas fa-barcode"></i>
        <i class="fas fa-qrcode"></i>
      </div>
      <div id="text-container">
        <p class="bold">Scan</p>
        <p>Barcode /</p>
        <p>QR Code</p>
      </div>
    </div>
    <div id="bottom">
      <div id="button-centering">
        <app-round-button
          icon="fas fa-camera"
          sizepx="100"
          fontSize="35px"
        ></app-round-button>
      </div>
      <div id="rectangle" *ngIf="!scanActive"></div>
    </div>
  </div>

  <div id="when-active" *ngIf="scanActive">
    <ul id="scanner-buttons">
      <!-- <li (click)="toggleScan()">
        <span id="icon-fawe">
          <i class="fas fa-times"></i>
        </span>
      </li> -->
      <li>
        <span (click)="toggleTorch()" id="icon-fawe">
          <i class="fas fa-bolt"></i>
        </span>
      </li>
    </ul>

    <zxing-scanner
      [torch]="torchActive"
      [formats]="['QR_CODE', 'CODE_39', 'DATA_MATRIX']"
      (scanSuccess)="scanSuccess($event)"
    ></zxing-scanner>
  </div>
</div>
