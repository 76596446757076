<div id="range-presets-select" *ngIf="enablePresets">
  <div  class="select" (click)="toggleSelect(); $event.stopPropagation()">
  </div>
  <span id="arrow-button-container">
    <app-round-button
      *ngIf="isSelectOpen"
      class="button"
      icon="fas fa-chevron-up"
      iconColor="white"
      shadow="none"
      [sizepx]="36"
      fontSize="18px"
      (click)="toggleSelect()"
    ></app-round-button>
    <app-round-button
      *ngIf="!isSelectOpen"
      class="button"
      icon="fas fa-chevron-down"
      iconColor="white"
      shadow="none"
      [sizepx]="36"
      fontSize="18px"
      (click)="toggleSelect(); $event.stopPropagation()"
    ></app-round-button>
  </span>
 
</div>
<div class="calendar-select-container">
  <span>
    {{ initialDate |  date:'yyyy-MM-dd' }}{{ endDate ? ' | ' : '' }}{{ endDate |  date:'yyyy-MM-dd' }}
  </span>
  <div class="picker-container">
    <app-round-button
      sizepx="36"
      fontSize="21px"
      shadow="none"
      icon="far fa-calendar-alt"
      class="picker"
    ></app-round-button>
  </div>
</div>
