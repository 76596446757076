import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditionEnablingService {
  public editing = new Subject<boolean>();

  constructor() {
    this.editing.next(false);
  }
}
