<div class="header">
  <div class="btn" (click)="goTo()" style="margin-left: 15px;">
    <i class="fas fa-arrow-left icon"></i>
    <div class="p">Back</div>
  </div>
  <div class="btn" (click)="onSave()" style="margin-right: 15px;">
    <i class="fas fa-save icon"></i>
    <div class="p">Save</div>
  </div>
</div>
