import { EventEmitter, Component, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-type-input',
  templateUrl: './generic-type-input.component.html',
  styleUrls: ['./generic-type-input.component.scss']
})
export class GenericTypeInputComponent implements OnInit {
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() value: string | number;
  @Output() valueEmitter = new EventEmitter<string | number>();

  placeholder = '';
  label = '';
  isWeightInput = false;

  ngOnInit() {
    switch (this.type) {
      case 'text':
        this.placeholder = 'user text input';
        this.label = 'abc';
        break;
      case 'number':
        this.placeholder = 'user numbers input';
        this.label = '123';
        break;
      case 'weight':
        this.placeholder = 'user weight input';
        this.isWeightInput = true;
        break;
    }
  }

  onValueChange(e: any) {
    this.value = e.target.value;
    this.type === 'text'
      ? this.valueEmitter.emit(this.value)
      : this.valueEmitter.emit(parseFloat(this.value as string));
  }
}
