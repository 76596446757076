import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { PwaService } from '../../services/pwa.service';

@Component({
  selector: 'app-update-prompt',
  templateUrl: './update-prompt.component.html',
  styleUrls: ['./update-prompt.component.scss']
})
export class UpdatePromptComponent {
  isAvailable = false;

  constructor(private pwaService: PwaService, private updates: SwUpdate) {
    this.updates.available.subscribe(() => (this.isAvailable = true));
  }

  updateApp() {
    this.pwaService.updateApp().subscribe(() => (this.isAvailable = false));
  }
}
