import { Injectable } from '@angular/core';
import { StorageKey } from '../enums/storage-key.enum';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SubMenuStorageService {
  private key = StorageKey.SUBMENU;

  constructor(private readonly storageService: StorageService) {}

  get(): any {
    return this.storageService.get(this.key);
  }

  set(data: any): void {
    this.storageService.set(this.key, data);
  }

  clear(): void {
    this.storageService.clear(this.key);
  }
}
