import { Component, Input, OnChanges, ElementRef } from '@angular/core';
import { SmrProgressService } from '../../services/smr-progress.service';
import { addDays, startOfDay, endOfDay } from 'date-fns';

@Component({
  selector: 'app-smr-date-marker',
  templateUrl: './smr-date-marker.component.html',
  styleUrls: ['./smr-date-marker.component.scss']
})
export class SmrDateMarkerComponent implements OnChanges {
  @Input() currentDate: boolean;
  @Input() date: Date;
  @Input() displayRange: number;
  @Input() startDate: Date;

  constructor(private el: ElementRef, private readonly smrProgressService: SmrProgressService) {}

  ngOnChanges() {
    if (this.currentDate) {
      this.date = new Date();
    }
    if (this.date) {
      const position = this.getHackedPosition();
      this.el.nativeElement.style.setProperty('--marker-position', position);
    }
  }

  getHackedPosition(): string {
    const position = this.smrProgressService.computeDatePosition({
      min: startOfDay(this.startDate),
      date: this.date,
      max: endOfDay(addDays(this.startDate, this.displayRange - 1))
    });
    return this.positionIsNegative(position) ? '-9999%' : position;
  }

  positionIsNegative(stringPosition: string): boolean {
    return stringPosition.substr(0, 1) === '-';
  }
}
