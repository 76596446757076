import { Component, Input, OnInit } from '@angular/core';
import { Stat } from '../../interfaces/lot-stat';

@Component({
  selector: 'app-partitioned-bar',
  templateUrl: './partitioned-bar.component.html',
  styleUrls: ['./partitioned-bar.component.scss']
})
export class PartitionedBarComponent implements OnInit {
  @Input() stats: Stat[];
  @Input() styles = {};
  empty = false;

  ngOnInit(): void {
    this.empty = !this.stats.some(s => s.percent !== 0);
  }
}
