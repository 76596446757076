import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BackendService } from './backend.service';
import { TokenStorageService } from './token-storage.service';
import { UserService } from './user.service';

@Injectable()
export class AuthService {
  private static readonly AUTHENTICATE_URL = 'users/login';

  constructor(
    private readonly backendService: BackendService,
    private readonly tokenStorageService: TokenStorageService,
    private readonly userService: UserService
  ) {}

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const body = this.encodeURIComponentWithPlusManagmentForPasswords(
      `username=${username}&password=${password}`
    );

    return this.backendService
      .post(AuthService.AUTHENTICATE_URL, body, { headers })
      .pipe(
        tap(data => {
          if (data && data.token) {
            this.tokenStorageService.set(data.token);
            this.userService.setUser({
              name: username,
              functionalities: data.functionalities
            });
          }
        })
      );
  }

  encodeURIComponentWithPlusManagmentForPasswords(str) {
    const encodeFromURI = encodeURI(str);
    return encodeFromURI.replace(/[+]/g, function(c) {
      return '%2B';
    });
  }

  logout() {
    this.userService.reset();
    this.tokenStorageService.clear();
  }
}
