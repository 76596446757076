import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {
  public headerTitle = new BehaviorSubject<string>('Stations');
  public subheaderTitle = new BehaviorSubject<string>(null);

  setHeader(text: string) {
    this.headerTitle.next(text);
    this.subheaderTitle.next(null);
  }

  setSubheader(text: string) {
    this.subheaderTitle.next(text.toUpperCase());
  }

  setHeaderAndSubheader(text: string) {
    const splittedText = text.split('$');
    this.headerTitle.next(`${splittedText[0]}:`);
    this.subheaderTitle.next(` ${splittedText[1].toUpperCase()}`);
  }
}
