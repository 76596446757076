<div id="wrapper">
  <div id="sticky-wrapper" [class.opened]="!!opened" [class.activable]="hasSmrs" (click)="toggle()">
    <div id="arrow" [class.opened]="!!opened || isLoading">
      <app-round-button
        *ngIf="hasSmrs"
        sizepx="36"
        fontSize="18px"
        icon="fas fa-chevron-right"
        backgroundColor="#aaa"
      ></app-round-button>
    </div>
    <div id="name">
      <span>{{ category?.name }}</span>
    </div>
    <div id="dates" appDateHover [class.opened]="!!opened">
      <ng-container *ngFor="let item of plannedProcessedDates | keyvalue">
        <span
          class="processed date"
          appDatePosition
          [attr.data-id]="item.value.ids"
          [date]="item.value.date"
          [minDate]="startDate"
          [displayRange]="displayRange"
        >
        </span>
      </ng-container>
      <ng-container *ngFor="let item of plannedOnSiteDates | keyvalue">
        <span
          class="onsite date"
          appDatePosition
          [attr.data-id]="item.value.ids"
          [date]="item.value.date"
          [minDate]="startDate"
          [displayRange]="displayRange"
        >
        </span>
      </ng-container>
    </div>
  </div>
  <div id="floors" *ngIf="opened">
    <ng-container *ngFor="let floor of category.floors; let odd = odd">
      <app-smr-floor
        *ngIf="floor?.smrs?.length"
        [floor]="floor"
        [startDate]="startDate"
        [displayRange]="displayRange"
        [class.odd]="odd"
      ></app-smr-floor>
    </ng-container>
  </div>
  <div id="loading-section" *ngIf="isLoading" [style]="'height: ' + (smrLineHeightInPx * totalNumberOfSmrs + 1) + 'px'">
    <app-loading-cog></app-loading-cog>
  </div>
</div>
