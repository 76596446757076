import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { UserService } from 'src/app/shared/services/user.service';

@Directive({
  selector: '[appPermissions]'
})
export class PermissionsDirective implements OnInit {
  @Input() appPermissions: string;

  constructor(
    private el: ElementRef,
    private readonly permissionsService: PermissionsService,
    private readonly userService: UserService
  ) {}

  ngOnInit() {
    const user = this.userService.getUser();
    if (!this.permissionsService.isVisible(user, this.appPermissions)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
