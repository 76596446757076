import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { SmrProgressInProjectLot } from '../../interfaces/smr-progress-data.interface';
import { Week } from '../../interfaces/smr-progress-week.interface';
import { SmrDateRange } from '../smr-range-selector/classes/smr-date-range';

@Component({
  selector: 'app-smr-project',
  templateUrl: './smr-project.component.html',
  styleUrls: ['./smr-project.component.scss']
})
export class SmrProjectComponent implements OnInit {
  @Input() currentRange: SmrDateRange;
  @Input() weeks: Week[];
  @Input() dataInProject: SmrProgressInProjectLot;
  @Input() isOpen: boolean;
  @ViewChild('stickyWrapper') stickyWrapper: ElementRef<HTMLElement>;

  isSticky = false;
  constructor() {}

  ngOnInit(): void {
    this.isOpen = !this.dataInProject.data ? false : this.isOpen;
  }

  toggleOpening() {
    if (this.dataInProject.data) {
      this.isOpen = !this.isOpen;
    }
  }
}
