import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RecursiveItem } from '../../../../../shared/components/recursive-linker/interfaces/recursive-item';
import { BackendService } from '../../../../../shared/services/backend.service';
import { SelectValue } from '../../../../../shared/components/modal-select/interfaces/select-value';
import { ProjectStatusEnum } from 'src/app/shared/components/project-list/enums/project-status.enum';

@Injectable({
  providedIn: 'root'
})
export class LinksBackendService {
  private projectsURI = 'projects';
  private linksURI = 'birth-certificate/line-configuration-links';

  private innerProjects: SelectValue[] = [];
  projects = new BehaviorSubject<SelectValue[]>(null);

  constructor(private backend: BackendService) {
    this.getAllProjects();
  }

  private updateProjects() {
    this.projects.next(this.innerProjects);
  }

  private getAllProjects() {
    this.innerProjects = [];
    this.backend
      .get(this.projectsURI)
      .pipe(
        map(data =>
          data.projects.map(project => {
            if (project.projectStatus === ProjectStatusEnum.AWARDED) {
              return { id: project.number, label: project.numberName };
            }
          })
        )
      )
      .subscribe(data => {
        if (data) {
          data.map(value => {
            if (value) {
              this.innerProjects.push(value);
            }
          });
          this.updateProjects();
        }
      });
  }

  getLinks(id: number | string): Observable<any> {
    return this.backend.get(this.linksURI + '/' + id);
  }

  linkProject(id: number | string, links: any[]): Observable<any> {
    return this.backend.post(this.linksURI + '/' + id, links);
  }

  transformLinks(links: any[]) {
    const project = {
      name: links[0].name,
      lineConfig: links[0].lineConfiguration
        ? links[0].lineConfiguration
        : links[0].noTemplate
        ? 'no-template'
        : 'inherited',
      parent: null,
      children: [],
      manuallyAssignedValue:
        links[0].lineConfiguration || links[0].noTemplate ? true : false,
      isExpanded: false,
      longId: links[0].longId,
      nodeType: links[0].nodeType
    } as RecursiveItem;

    let lotPivot = null;
    let sublotPivot = null;
    let subsublotPivot = null;

    links.forEach(link => {
      switch (link.nodeType) {
        case 'Lot':
          const lot = this.processLink(link, project);
          lotPivot = lot;
          break;

        case 'SubLot':
          const sublot = this.processLink(link, lotPivot);
          sublotPivot = sublot;
          break;

        case 'SubSubLot':
          const subsublot = this.processLink(link, sublotPivot);
          subsublotPivot = subsublot;
          break;

        case 'Frame':
          this.processLink(link, subsublotPivot);
          break;
      }
    });

    return project;
  }

  private processLink(link: any, pivot: RecursiveItem): RecursiveItem {
    const node = {
      name: link.name,
      lineConfig: link.lineConfiguration
        ? link.lineConfiguration
        : link.noTemplate
        ? 'no-template'
        : pivot.lineConfig,
      parent: pivot,
      children: [],
      manuallyAssignedValue:
        link.lineConfiguration || link.noTemplate ? true : false,
      isExpanded: false,
      longId: link.longId,
      nodeType: link.nodeType,
      description: link.description
    } as RecursiveItem;
    pivot.children.push(node);
    return node;
  }

  getValidLinks(item: RecursiveItem, list: any[]) {
    if (item.manuallyAssignedValue) {
      list.push(this.itemTransform(item));
    }
    item.children.forEach(element => {
      this.getValidLinks(element, list);
    });
  }

  itemTransform(item: RecursiveItem) {
    const splitItem = item.longId.split(',');
    const response = {
      Project: parseInt(splitItem[0], 10),
      Lot: null,
      SubLot: null,
      SubSubLot: null,
      Frame: null,
      LineConfiguration:
        item.lineConfig !== 'no-template' ? item.lineConfig : null,
      LongId: item.longId,
      manuallyAssignedValue: item.manuallyAssignedValue
    };
    switch (splitItem.length) {
      case 1:
        break;

      case 2:
        response.Lot = parseInt(splitItem[1], 10);
        break;

      case 3:
        response.Lot = parseInt(splitItem[1], 10);
        response.SubLot = parseInt(splitItem[2], 10);
        break;

      case 4:
        response.Lot = parseInt(splitItem[1], 10);
        response.SubLot = parseInt(splitItem[2], 10);
        response.SubSubLot = parseInt(splitItem[3], 10);
        break;

      case 5:
        response.Lot = parseInt(splitItem[1], 10);
        response.SubLot = parseInt(splitItem[2], 10);
        response.SubSubLot = parseInt(splitItem[3], 10);
        response.Frame = splitItem[4];
        break;
    }

    return response;
  }
}
