import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackendService } from '../../../services/backend.service';
import { MountainGraphStat } from '../interfaces/lot-stat';
import { StatFormatterService } from './stat-formatter.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  selectedLot = new BehaviorSubject<string>(null);
  selectedLotFormattedName = new BehaviorSubject<string>('');
  showSearchBar = new BehaviorSubject<boolean>(false);

  constructor(
    private backendService: BackendService,
    private statFormatterService: StatFormatterService
  ) {}

  getMountainGraphsStats(
    projectId: string | number,
    lot: string
  ): Observable<MountainGraphStat> {
    return this.backendService.get(`projects/${projectId}/graphs/${lot}`).pipe(
      map(data => {
        if (data) {
          if (data.statsOverall.length > 0) {
            const lastStat = data.statsOverall[data.statsOverall.length - 1];
            const tempDate = new Date();
            tempDate.setDate(tempDate.getDate() + 1);
            const today = tempDate.toISOString();
            lastStat.to = today;
          }

          return {
            id: data.name,
            description: data.description,
            specialDates: data.specialDates,
            statsOverall: data.statsOverall
          };
        }
      })
    );
  }

  getStatistics(projectId: string | number) {
    return this.backendService.get(`projects/${projectId}/app-all-stats`).pipe(
      map(data => {
        const formattedOverallData = this.transformDataNodeToLot(
          data,
          'overall'
        );
        const formattedDesignData = this.transformDataNodeToLot(data, 'design');
        return { overall: formattedOverallData, design: formattedDesignData };
      })
    );
  }

  private transformDataNodeToLot(
    data: any,
    dataType: string,
    depth: number = 0
  ) {
    const stats =
      dataType === 'overall'
        ? {
            toRelease: data.statsOverall.toRelease ?? 0,
            manufactured: data.statsOverall.manufactured ?? 0,
            installed: data.statsOverall.installed ?? 0,
            onHold: data.statsOverall.onHold ?? 0,
            released: data.statsOverall.released ?? 0,
            fPRSentToProd: data.statsOverall.fPRSentToProd ?? 0,
            count: data.statsOverall.count ?? 0
          }
        : {
            notStarted: data.statsDesign.notStarted ?? 0,
            finished: data.statsDesign.finished ?? 0,
            inProgress: data.statsDesign.inProgress ?? 0,
            revision: data.statsDesign.revision ?? 0,
            count: data.statsDesign.count ?? 0
          };

    let children = [];
    if (data.children.length > 0) {
      children = data.children.map(c =>
        this.transformDataNodeToLot(c, dataType, depth + 1)
      );
    }

    const expanded = data.nodeType === 'project';

    const lot = {
      stat: stats,
      name: data.name,
      description: data.description
    };
    const formattedLot =
      dataType === 'overall'
        ? this.statFormatterService.formatOverallLot(lot)
        : this.statFormatterService.formatDesignLot(lot);
    formattedLot.squareFootage = Math.round(data.squareFootage * 10000) / 10000;
    formattedLot.id = data.name;
    formattedLot.children = children;
    formattedLot.depth = depth;
    formattedLot.expanded = expanded;
    formattedLot.show = true;

    let formattedName = '';
    switch (depth) {
      case 0:
        formattedName = formattedLot.name.split('L')[0];
        break;
      case 1:
        const dirty1 = formattedLot.name.split('L')[1];
        formattedName = `${dirty1[0]}${dirty1[1]}`;
        break;
      case 2:
        const dirty2 = formattedLot.name.split('L')[1];
        formattedName = `${dirty2[0]}${dirty2[1]}-${dirty2[2]}${dirty2[3]}`;
        break;
      default:
        const dirty3 = formattedLot.name.split('L')[1];
        formattedName = `${dirty3[0]}${dirty3[1]}-${dirty3[2]}${dirty3[3]}-${dirty3[4]}${dirty3[5]}`;
        break;
    }
    formattedLot.formattedName = formattedName;

    return formattedLot;
  }
}
