import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IframeStatusService {
  constructor() {}

  isInIFrame(window: Window | any): boolean {
    return window.location.ancestorOrigins.length > 0;
  }
}
