import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
  WorkInstructionsStationDto,
  StationConfigurationFrontendDto
} from '../../interfaces/station.interface';
import { StationsBackendService } from '../../services/checklist-station-backend.service';
import { EditionEnablingService } from '../../services/edition-enabling.service';
import { ExpandService } from '../../services/expand.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { DeviceService } from '../../../../../../shared/services/device.service';

@Component({
  selector: 'app-work-instructions-station',
  templateUrl: './work-instructions-station.component.html',
  styleUrls: ['./work-instructions-station.component.scss']
})
export class WorkInstructionsStationComponent implements OnInit, OnDestroy {
  @Input() station: WorkInstructionsStationDto;
  @Output() stationNameChange = new EventEmitter<WorkInstructionsStationDto>();
  @Output() confirmDeleteStation = new EventEmitter<
    WorkInstructionsStationDto
  >();

  editMode = false;
  editionMode = false;
  editing = false;
  showDeleteModal = false;
  expanded = false;
  newName = '';
  showCreateConfigModal = false;
  configName = '';

  configurations: any;
  configurations2 = [];
  steps: any[] = [];
  isMobile = false;

  subscriptions: Subscription[] = [];

  constructor(
    private editionEnablingService: EditionEnablingService,
    private expandService: ExpandService,
    private stationsService: StationsBackendService,
    private toastrService: ToastService,
    public deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.editionEnablingService.editing.subscribe(
        editing => (this.editing = editing)
      )
    );

    this.subscriptions.push(
      this.deviceService.isMobile.subscribe(
        newValue => (this.isMobile = newValue)
      )
    );

    this.subscriptions.push(
      this.expandService.stationExpanded.subscribe(id => {
        if (id) {
          this.expanded = this.station.id === id;
        }
      })
    );

    this.subscriptions.push(
      this.stationsService
        .getConfigurations(this.station.id)
        .subscribe(data => {
          if (data) {
            this.configurations = data.configurations.map(c => {
              return {
                id: c.id,
                name: c.name,
                version: c.version,
                steps: [],
                expanded: false
              };
            });
            this.configurations.forEach(config => {
              this.subscriptions.push(
                this.stationsService
                  .getConfiguration(config.id, config.version)
                  .subscribe(data2 => {
                    if (data2) {
                      config.steps = [...data2.steps];
                    }
                  })
              );
            });
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.expandService.stationExpanded.next(null);
  }

  addNewConfig(configurationName: string) {
    this.stationsService
      .createNewConfiguration(this.station.id, configurationName)
      .subscribe(data => {
        this.toggleCreateConfigModal();
        this.subscriptions.push(
          this.stationsService.getConfiguration(data.id, 0).subscribe(steps => {
            if (steps) {
              data.steps = [...steps.steps];
            }
          })
        );
        this.toastrService.success('Configuration successfully created.');
        this.configurations.push(data);
      });
  }

  onNameChange(e: any) {
    this.newName = e.target.value;
  }

  onConfigNameChange(e: any) {
    this.configName = e.target.value;
  }

  getContent(name: string) {
    return `Do you really want to delete the station "${name}" from the list? All the configurations will be deleted as well.`;
  }

  setEditionMode(val: boolean) {
    this.setExpanded(false);
    if (this.editionMode) {
      if (this.newName) {
        this.station.name = this.newName;
        this.stationNameChange.emit(this.station);
      }
      this.editionEnablingService.editing.next(false);
      this.editionMode = val;
    } else {
      if (!this.editing) {
        this.editionEnablingService.editing.next(true);
        this.editionMode = val;
      }
    }
  }

  setExpanded(val: boolean) {
    if (!this.editing) {
      this.stationsService.configurations.next(null);
      this.expandService.stationExpanded.next(this.station.id);
      this.expanded = val;
    }
  }

  onDelete() {
    if (!this.editing) {
      this.showDeleteModal = true;
    }
  }

  editName() {
    this.toggleEditionModal();
    this.station.name = this.newName;
    this.stationNameChange.emit(this.station);
  }

  toggleEditionModal() {
    this.editMode = !this.editMode;
  }

  toggleCreateConfigModal() {
    this.showCreateConfigModal = !this.showCreateConfigModal;
  }

  onCancelDelete() {
    this.showDeleteModal = false;
  }

  onConfirmDelete() {
    this.confirmDeleteStation.emit(this.station);
  }
}
