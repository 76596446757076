import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges
} from '@angular/core';

import { AudioService } from '../../services/audio.service';
import { environment } from '../../../../environments/environment';
import { SearchResult } from '../../models/search-result.model';
import { InputMethodEnum } from '../../enums/input-method.enum';
import { InputTypeService } from '../../services/input-type.service';

const ISANALISINGSTRING = false;

@Component({
  selector: 'app-qrscanner',
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.scss']
})
export class QrscannerComponent implements OnInit {
  @Input() mute: boolean;
  @Input() autoClose: boolean;
  @Input() alwaysOn: boolean;
  @Input() delay: number;
  @Output() scanSuccessEvent = new EventEmitter<SearchResult>();

  scanActive: boolean;
  torchActive: boolean;
  private scanDelayActive: boolean;
  private lastScans: Array<string>;
  private lastScansMaxCount: number;

  constructor(
    private audioService: AudioService,
    private inputTypeService: InputTypeService
  ) {
    this.lastScansMaxCount = 5;
    this.lastScans = [];
    this.scanActive = false;
    this.torchActive = false;
    if (!this.mute) {
      this.mute = false;
    }
    if (!this.autoClose) {
      this.autoClose = false;
    }
  }

  ngOnInit() {
    this.delay = this.delay ? this.delay : 3000;
    this.scanDelayActive = false;
    this.alwaysOn = this.alwaysOn ? this.alwaysOn : false;
    this.scanActive = this.alwaysOn; // Start with scanner ON if always ON
  }

  toggleScan() {
    this.scanActive = !this.scanActive || this.alwaysOn;
  }

  toggleTorch() {
    this.torchActive = !this.torchActive;
  }

  private notScannedYet(newScan: string): boolean {
    return this.lastScans.findIndex(oldScan => oldScan === newScan) === -1
      ? true
      : false;
  }

  scanSuccess(scannedString: string) {
    if (!this.scanDelayActive || this.notScannedYet(scannedString)) {
      if (this.autoClose) {
        this.toggleScan();
      }
      this.playScanSound();
      this.scanSuccessEvent.emit({
        fullString: scannedString.toUpperCase(),
        inputMethod: InputMethodEnum.GUN,
        inputType: this.inputTypeService.getInputType(scannedString)
      });
      this.addToScannedList(scannedString);
      this.scanDelayActive = true;
      setTimeout(() => (this.scanDelayActive = false), this.delay);
    }
  }

  private addToScannedList(newScan: string) {
    const posi = this.lastScans.findIndex(oldScan => oldScan === newScan);
    if (posi > -1) {
      // Remove the old one and add the new at the beginning of the list
      this.lastScans.splice(posi, 1);
      this.lastScans.unshift(newScan);
    } else {
      if (this.lastScans.unshift(newScan) > this.lastScansMaxCount) {
        // We add the new to the beginning of the list and check for the length of the list
        this.lastScans.splice(
          this.lastScansMaxCount + 1,
          this.lastScans.length - this.lastScansMaxCount
        );
      }
    }
  }

  private playScanSound() {
    if (!this.mute) {
      this.audioService.playScan();
    }
  }
}
