<div class="cam-container">
  <webcam
    class="camera"
    [switchCamera]="nextWebcam"
    [trigger]="triggerObservable()"
    [imageQuality]="1"
    (imageCapture)="handleImage($event)"
    [height]="1280"
    [width]="1024"
    [videoOptions]="videoOptions"
  >
  </webcam>
</div>
<div class="footer">
  <app-round-button
    backgroundColor="var(--red)"
    iconColor="white"
    icon="fas fa-times"
    shadow="none"
    [sizepx]="45"
    fontSize="24px"
    (click)="onCancel()"
  ></app-round-button>
  <app-round-button
    backgroundColor="var(--fabbrica)"
    iconColor="white"
    icon="fas fa-camera"
    shadow="none"
    [sizepx]="45"
    fontSize="24px"
    (click)="triggerSnapshot()"
  ></app-round-button>
  <app-round-button
    backgroundColor="var(--menuItem-text1)"
    iconColor="white"
    icon="fas fa-share"
    shadow="none"
    [sizepx]="45"
    fontSize="24px"
    (click)="switchCamera()"
  ></app-round-button>
</div>
