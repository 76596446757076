<div class="switch-container">
  <label class="switch">
    <input
      [disabled]="disabled"
      [(ngModel)]="value"
      (ngModelChange)="onChange()"
      type="checkbox"
    />
    <span class="slider round"></span>
  </label>
  <div class="switch-label">{{ label }}</div>
</div>
