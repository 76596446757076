<ng-container *ngIf="!loadingProjects">
  <div class="header">
    <p>Project Number-Name</p>
  </div>

  <div
    class="header-row"
    *ngFor="let project of possibleProjects"
  >
    <div class="left-container">
      <p>{{ project.label }}</p>
    </div>
    <div class="right-container">
      <app-round-button
        (click)="editionRequired(project.id)"
        class="icon-btn"
        data-cy="toggle"
        [icon]="'fa fa-edit'"
        [sizepx]="48"
        fontSize="26px"
        backgroundColor="var(--fabbrica)"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
      ></app-round-button>
    </div>
  </div>
</ng-container>

<div class="container" *ngIf="loadingProjects">
  <app-loading-cog></app-loading-cog>
</div>
