<div *ngIf="!empty" class="container" [ngStyle]="styles">
  <div
    *ngFor="let stat of stats"
    [ngStyle]="{
      height: '8px',
      width: stat.percent + '%',
      'background-color': stat.color
    }"
  ></div>
</div>
<div *ngIf="empty" class="container">
  <div
    [ngStyle]="{ height: '8px', width: '100%', 'background-color': 'white' }"
  ></div>
</div>
