import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageKey } from '../../../enums/storage-key.enum';
import { StorageService } from '../../../services/storage.service';
import { SelectValue } from '../../modal-select/interfaces/select-value';

export const NO_LINE: SelectValue = {
  id: '',
  label: 'no current line'
};

@Injectable({
  providedIn: 'root'
})
export class LineSelectorService {
  subject: BehaviorSubject<SelectValue>;

  constructor(private storageService: StorageService) {
    const line = this.storageService.get(StorageKey.USERLINE);
    this.subject = new BehaviorSubject<SelectValue>(
      this.getSanitizedLine(line)
    );
  }

  set(line: SelectValue) {
    const sanitizedLine = this.getSanitizedLine(line);
    this.storageService.set(StorageKey.USERLINE, sanitizedLine);
    this.subject.next(sanitizedLine);
  }

  remove() {
    this.storageService.clear(StorageKey.USERLINE);
    this.subject.next(NO_LINE);
  }

  private getSanitizedLine(station: SelectValue): SelectValue {
    return station || NO_LINE;
  }
}
