<app-data-container [gridTemplate]="gridTemplate" [dataList]="dataList"></app-data-container>
<div class="button-container" *ngIf="'isPdmLinkVisible()'">
  <app-round-button    
    [icon]="'fas fa-cube'"
    [backgroundColor]="'var(--fabbrica)'"
    [sizepx]="32"
    fontSize="17px"
    title="Open Assembly in PDM"
    (click)="OpenPDMLink()"
  ></app-round-button>
</div>