import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { User } from '../models/user';
import { UserStorageService } from './user-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<User>;

  constructor(private userStorageService: UserStorageService) {
    const localUser = this.userStorageService.get();
    this.user = new BehaviorSubject(localUser || this.getEmptyUser());
  }

  setUser({
    name,
    functionalities
  }: {
    name: string;
    functionalities: string[];
  }): void {
    const user = new User({ name, functionalities });
    this.userStorageService.set(user);
    this.user.next(user);
  }

  getUser(): User {
    return this.user.value;
  }

  getUserInitals(): string {
    const user = this.getUser();
    return user ? user.initials : '';
  }

  reset() {
    this.userStorageService.clear();
    this.user.next(this.getEmptyUser());
  }

  getEmptyUser(): User {
    return new User({ name: 'Unknown', functionalities: [] });
  }
}
