<a href="https://ingeniusolutions.com/" target="_blank" rel="noreferrer noopener">
  Made with
  <img style="height:18px; vertical-align:middle;" draggable="false" alt="❤" src="assets/icon/heart.svg" />
  by
  <img
    id="ingeniu-logo"
    style="height:24px; vertical-align:middle;"
    draggable="false"
    class="ingeniu-footer"
    alt="Ingeniu Logo"
    src="assets/icon/logo-ingeniu.png"
  />
</a>
