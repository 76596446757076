import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-dots',
  templateUrl: './loading-dots.component.html',
  styleUrls: ['./loading-dots.component.scss']
})
export class LoadingDotsComponent {
  @Input() loading: boolean;

  constructor() {
    this.loading = true;
  }
}
