import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WorkInstructionsInputTypesEnum } from '../../../../../../shared/components/work-instructions/enums/work-instructions-input-types.enum';
import { WorkInstructionsStepConformity } from '../../../../../../shared/components/work-instructions/enums/work-instructions-step-conformity.enum';
import { WorkInstructionsLanguageTextData } from '../../../../../../shared/components/work-instructions/interfaces/work-instructions-multilingual-text-data.interface';
import { StepCollapseService } from '../../../../../../shared/components/work-instructions/services/step-collapse.service';
import { DeviceService } from '../../../../../../shared/services/device.service';
import { ModalLockService } from '../../../../../../shared/services/modal-lock.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { EditionService } from '../../../../services/edition.service';
import { HeaderTitleService } from '../../../../services/header-title.service';
import { StationsBackendService } from '../../../work-instructions-stations/services/checklist-station-backend.service';
import { BackendStepDto } from '../../interfaces/station-configuration.interface';

@Component({
  selector: 'app-station-configuration-body',
  templateUrl: './station-configuration-body.component.html',
  styleUrls: ['./station-configuration-body.component.scss']
})
export class StationConfigurationBodyComponent implements OnInit, OnDestroy {
  unsavedChanges = false;
  title = '';
  editing = false;
  stepsModified = false;
  newName = '';
  currentName = '';
  id = '';

  steps = [];
  qcStep = null;

  subscriptions: Subscription[] = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private stationsService: StationsBackendService,
    private toastrService: ToastService,
    private headerService: HeaderTitleService,
    public editionService: EditionService,
    public deviceService: DeviceService,
    public modalLockService: ModalLockService
  ) {}

  private formatFrontendStep(step: any): BackendStepDto {
    return {
      Description: { En: step.description.en, Es: step.description.es },
      IsCritical: step.isCritical,
      IsFinalQC: step.isFinalQC,
      Inputs: step.inputs.map(input => {
        return {
          InputType: input.inputType,
          InputData:
            input.inputType === 'multipleOptionButton'
              ? input.inputData.map(opt => ({ En: opt.en, Es: opt.es }))
              : null
        } as any;
      })
    } as any;
  }


  ngOnInit(): void {
    const id = this.activeRoute.snapshot.params.id;
    const version = this.activeRoute.snapshot.queryParams.version;
    this.subscriptions.push(
      this.stationsService.getConfiguration(id, version).subscribe(data => {
        this.editionService.showEditionButton.next(true);
        this.headerService.setHeaderAndSubheader(`STATIONS$${data.name}`);
        this.title = `Editing configuration "${data.name}"`;
        this.newName = data.name;
        this.currentName = data.name;
        this.steps = data.steps;
        this.steps.forEach((step, i) => {
          step.index = i;
          step.conformity = WorkInstructionsStepConformity.UNKNOWN;
        });

        const index = this.steps.findIndex(s => s.isFinalQC);
        if (index >= 0) {
          this.qcStep = this.steps[index];
          this.steps.splice(index, 1);
        }
        this.id = data.id;
      })
    );
  }

  ngOnDestroy() {
    this.editionService.showEditionButton.next(false);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setEditing(val: boolean) {
    this.editing = val;
  }

  changeName(e: any) {
    this.newName = e.target.value;
  }

  closeEditionModal() {
    this.newName = this.currentName;
    this.editionService.showEditionModal.next(false);
  }

  renameConfiguration() {
    this.unsavedChanges = true;
    this.currentName = this.newName;
    this.headerService.setHeaderAndSubheader('STATION CONFIGURATION$'+ this.currentName);
    this.editionService.showEditionModal.next(false);
  }

  onSave() {
    this.unsavedChanges = false;
    const body: { Name?: string; Steps?: any[] } = {
      Name: this.currentName
    };

    if (this.stepsModified) {
      const formattedSteps = this.steps.map(step =>
        this.formatFrontendStep(step)
      );
      body.Steps = formattedSteps;
      if (this.qcStep) {
        body.Steps.push(this.formatFrontendStep(this.qcStep));
      }
    }

    this.stationsService
      .updateConfiguration(this.id, body)
      .subscribe(success => {
        if (success) {
          this.toastrService.success('Station successfully updated');
        }
      });
  }

  addStep() {
    this.unsavedChanges = true;
    const step = {
      description: { en: '', es: '' } as WorkInstructionsLanguageTextData,
      isCritical: false,
      inputs: [],
      index: this.steps.length,
      conformity: WorkInstructionsStepConformity.UNKNOWN,
    };
    this.steps.push(step);
    this.stepsModified = true;
  }

  removeStep(step: any) {
    this.unsavedChanges = true;
    this.steps.splice(step.index, 1);
    this.reassignIndexes();
  }

  setCriticality(criticality: boolean, step: any) {
    this.unsavedChanges = true;
    step.isCritical = criticality;
    this.stepsModified = true;
  }

  setInputs(inputs: any, step: any) {
    this.unsavedChanges = true;
    step.inputs = inputs;
    this.stepsModified = true;
  }

  drop(event: CdkDragDrop<string[]>) {
    this.unsavedChanges = true;
    moveItemInArray(this.steps, event.previousIndex, event.currentIndex);
    this.reassignIndexes();
  }

  private reassignIndexes() {
    this.unsavedChanges = true;
    this.steps.forEach((step, i) => (step.index = i));
    this.stepsModified = true;
  }
}
