<div
  class="container"
  [ngStyle]="{
    'padding-bottom':
      (deviceService.isMobile | async) &&
      formattedDatasets.specialDates.length > 0
        ? '50px'
        : ''
  }"
>
  <canvas id="mountain-graph"></canvas>
  <p
    *ngIf="
      (deviceService.isMobile | async) &&
      formattedDatasets.specialDates.length > 0
    "
  >
    Vertical lines denote the planned start and end dates according to the
    legend.
  </p>
</div>
