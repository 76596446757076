import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClickOnScreenService {
  clickOnScreenEvent: EventEmitter<boolean> = new EventEmitter();

  emitClick() {
    this.clickOnScreenEvent.emit(true);
  }
}
