import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { BomMode } from '../../../../enums/bom-mode.enum';

@Component({
  selector: 'app-bom-toggle-button',
  templateUrl: './bom-toggle-button.component.html',
  styleUrls: ['./bom-toggle-button.component.scss']
})
export class BomToggleButtonComponent implements OnInit {
  @Input() defaultState: BomMode;
  @Output() actualMode = new EventEmitter();
  BomModeEnum = BomMode;
  private bomMode: BomMode;

  ngOnInit() {
    this.bomMode = this.defaultState;
  }

  testForActivatedSide(button: BomMode) {
    if (button === this.bomMode) {
      return 'activated';
    } else {
      return '';
    }
  }

  leftButtonClick() {
    this.bomMode = BomMode.LIST;
    this.emitEvent();
  }
  rightButtonClick() {
    this.bomMode = BomMode.TREE;
    this.emitEvent();
  }

  emitEvent() {
    this.actualMode.emit(this.bomMode);
  }
}
