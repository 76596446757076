import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BackendService } from '../../../../shared/services/backend.service';
import {
  ProjectData,
  BackendProjectsResponse,
  BackendProject
} from '../interfaces/project-data';
import { StatFormatterService } from '../../dashboard-charts/services/stat-formatter.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(
    private backend: BackendService,
    private statFormatterService: StatFormatterService
  ) {}

  getAll(): Observable<ProjectData[]> {
    return this.backend.get('projects?stat=1').pipe(
      map(data => {
        const backendResponse = data as BackendProjectsResponse;
        return backendResponse.projects.map(p => this.formatProject(p));
      })
    );
  }

  formatProject(project: BackendProject): ProjectData {
    return {
      name: project.numberName,
      contractor: project.generalContractor,
      manager: project.projectManager,
      status: project.projectStatus,
      stats: this.statFormatterService.formatProjectStats(project.stat),
      show: true,
      squareFootage: project.squareFootage
    };
  }

  getPhoto(projectNumber: string): Observable<Blob> {
    return this.backend
      .get(
        `projects/${projectNumber}/photo`,
        { responseType: 'blob' as 'json' },
        false
      )
      .pipe(
        map(data => (data && data.type === 'application/json' ? null : data))
      );
  }
}
