<div>
  <input
    [disabled]="disabled"
    value="{{ value }}"
    [type]="type"
    (change)="onValueChange($event)"
    [placeholder]="placeholder"
  />
  <label *ngIf="!isWeightInput">{{ label }}</label>
  <i *ngIf="isWeightInput" class="fa fa-balance-scale icon"></i>
</div>
