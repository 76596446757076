import {
  Component,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Input
} from '@angular/core';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DeviceService } from '../../../../services/device.service';
import 'chartjs-adapter-date-fns';

Chart.register(annotationPlugin);
@Component({
  selector: 'app-mountain-chart',
  templateUrl: './mountain-chart.component.html',
  styleUrls: ['./mountain-chart.component.scss']
})
export class MountainChartComponent implements AfterViewInit, OnChanges {
  @Input() formattedDatasets: any;

  ctx: any;
  chart: Chart;
  mobileCtx: any;
  mobileChart: Chart;

  constructor(public deviceService: DeviceService) {}

  private getDate(formattedDate: string): string {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const splittedString = formattedDate.split(' ');
    const monthIndex = months.findIndex(m => m === splittedString[0]) + 1;
    const month = monthIndex < 10 ? `0${monthIndex}` : monthIndex.toString();
    const dayString = splittedString[1].split(',')[0];
    const day = parseInt(dayString, 10) < 10 ? `0${dayString}` : dayString;
    const year = splittedString[2].split(',')[0];
    return `${year}-${month}-${day}`;
  }

  private annotationAdjustX(index: number, lastIndex: number): number {
    switch (index) {
      case 0:
        return -15;
      case lastIndex:
        return 15;
      default:
        return 0;
    }
  }

  private getNameFromColor(color: string): string[] {
    switch (color) {
      case 'rgba(0, 137, 255, 1)':
        return ['Released (FAR)', 'released'];
      case 'rgba(128, 0, 128, 1)':
        return ['FPR Sent To Production', 'fprSentToProd'];
      case 'rgba(255, 21, 204, 1)':
        return ['On Hold', 'onHold'];
      case 'rgba(37, 185, 0, 1)':
        return ['Installed', 'installed'];
      default:
        return ['Manufactured', 'manufactured'];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formattedDatasets.currentValue) {
      this.ngAfterViewInit();
    }
  }

  getOrCreateTooltip = chart => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  externalTooltipHandler = context => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = this.getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const tableHead = document.createElement('thead');
      tableHead.style.fontSize = '14px';

      titleLines.forEach(title => {
        const tr = document.createElement('tr') as any;
        tr.style.borderWidth = 0;

        const th = document.createElement('th') as any;
        th.style.borderWidth = 0;
        const displayTitle = title.split(',', 2).join(',');
        const text = document.createTextNode(displayTitle);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const index = this.formattedDatasets.dates.findIndex(
        date => date === this.getDate(tooltip.title[0])
      );

      const tableBody = document.createElement('tbody');
      tableBody.style.fontSize = '12px';

      tooltip.labelColors.forEach(color => {
        const [name, privateName] = this.getNameFromColor(
          color.backgroundColor
        );
        let displayName = `${name}: ${
          this.formattedDatasets.datasets.get(privateName)?.stats[index]
        }`;
        if (name === 'On Hold') {
          displayName = `${name}: ${
            this.formattedDatasets.datasets.get(privateName)?.realStats[index]
          }`;
        }

        const span = document.createElement('span');
        span.style.background = color.backgroundColor;
        span.style.borderColor = color.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';

        const tr = document.createElement('tr') as any;
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = 0;

        const td = document.createElement('td') as any;
        td.style.borderWidth = 0;

        const text = document.createTextNode(displayName) as any;

        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  generateData(formattedDatasets) {
    return {
      labels: formattedDatasets.dates,
      datasets: [
        {
          label: 'Installed',
          data: formattedDatasets.datasets.get('installed').stats,
          borderColor: formattedDatasets.datasets.get('installed').color,
          backgroundColor: formattedDatasets.datasets.get('installed').color,
          fill: true
        },
        {
          label: 'Manufactured',
          data: formattedDatasets.datasets.get('manufactured').stats,
          borderColor: formattedDatasets.datasets.get('manufactured').color,
          backgroundColor: formattedDatasets.datasets.get('manufactured').color,
          fill: true
        },
        {
          label: 'On Hold',
          data: formattedDatasets.datasets.get('onHold').stats,
          borderColor: formattedDatasets.datasets.get('onHold').color,
          backgroundColor: formattedDatasets.datasets.get('onHold').color,
          fill: true
        },
        {
          label: 'Released (FAR)',
          data: formattedDatasets.datasets.get('released').stats,
          borderColor: formattedDatasets.datasets.get('released').color,
          backgroundColor: formattedDatasets.datasets.get('released').color,
          fill: true
        },
        {
          label: 'FPR Sent To Production',
          data: formattedDatasets.datasets.get('fprSentToProd').stats,
          borderColor: formattedDatasets.datasets.get('fprSentToProd').color,
          backgroundColor: formattedDatasets.datasets.get('fprSentToProd')
            .color,
          fill: true
        }
      ]
    };
  }

  getColor(displayName: string) {
    const name = displayName.split(' ')[0];
    switch (name) {
      case 'Installed':
        return 'rgba(37, 185, 0, 0.9)';
      case 'FPR':
        return 'rgba(128, 0, 128, 0.9)';
      case 'Manufactured':
        return 'rgba(255, 165, 0, 0.9)';
      case 'Released (FAR)':
        return 'rgba(0, 137, 255, 0.9)';
      default:
        break;
    }
  }

  generateSpecialDates(dates) {
    return dates.specialDates.map((d, i) => {
      return {
        borderColor: this.getColor(d.name),
        borderWidth: 2,
        display: ctx => ctx.chart.isDatasetVisible(1),
        label: {
          color: this.getColor(d.name),
          backgroundColor: 'transparent',
          enabled: true,
          content: this.deviceService.isMobile.value
            ? ''
            : `${d.name.split(' ')[1]}`,
          position: 'start',
          yAdjust: -24,
          xAdjust: this.annotationAdjustX(i, dates.specialDates.length - 1)
        },
        scaleID: 'x',
        value: d.date
      };
    });
  }

  ngAfterViewInit() {
    this.ctx = (document.getElementById('mountain-graph') as any).getContext(
      '2d'
    );
    if (this.chart) {
      this.chart.destroy();
    }

    if (this.formattedDatasets) {
      const info = this.generateData(this.formattedDatasets);
      const specialDates = this.generateSpecialDates(this.formattedDatasets);

      const numberOfMonths = this.getAmountOfMonths();
      this.chart = new Chart(this.ctx, {
        type: 'line',
        data: info,
        options: {
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: numberOfMonths <= 3 ? 'day' : 'month'
              }
            },
            y: {
              min: 0,
              max: this.formattedDatasets.frameCount
            }
          },
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
              reverse: true
            },
            annotation: {
              annotations: specialDates
            },
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: this.externalTooltipHandler
            }
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          }
        }
      });
    }
  }

  private getAmountOfMonths(): number {
    let count: number = 0;
    if (this.formattedDatasets) {
      let currentMonth = '';
      this.formattedDatasets.dates.forEach((date: string) => {
        const splittedDate = date.split('-');
        if (splittedDate[1] != currentMonth) {
          count++;
          currentMonth = splittedDate[1];
        }
      });
    }
    return count;
  }
}
