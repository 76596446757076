import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-oval-button',
  templateUrl: './oval-button.component.html',
  styleUrls: ['./oval-button.component.scss']
})
export class OvalButtonComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;

  ngOnInit() {
    if (!this.icon) {
      this.icon = '';
    }
  }
}
