<div
  *ngIf="menuTab?.isHeader"
  id="box"
  class="header"
  [ngStyle]="{
    'flex-direction':
      (deviceService.isMobile | async)
        ? 'row-reverse'
        : (menuOpenedService.isOpen | async)
        ? 'row-reverse'
        : 'row'
  }"
>
  <div
    id="content"
    [ngStyle]="{
      'font-weight': menuTab?.fontWeight,
      'font-size': menuTab?.fontSize
    }"
  >
    <div>{{ menuTab?.title }}</div>
  </div>

  <div class="icon-container">
    <div id="circle" [ngClass]="circleColor()">
      <i
        *ngIf="!customSvg"
        [class]="menuTab?.icon"
        id="icon-svg"
        [ngStyle]="{ color: menuTab?.iconColor }"
      ></i>
      <div *ngIf="customSvg" style="margin-top: 4px;">
        <svg-icon
          *ngIf="customSvg"
          id="custom-svg"
          [src]="menuTab?.icon"
          [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"
        >
        </svg-icon>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!menuTab?.isHeader"
  id="box"
  [ngStyle]="{
    background: menuTab?.backgroundColor,
    'border-radius': menuTab?.borderRadius,
    'flex-direction':
      (deviceService.isMobile | async)
        ? 'row-reverse'
        : (menuOpenedService.isOpen | async)
        ? 'row-reverse'
        : 'row'
  }"
  [routerLink]="menuTab?.link"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{ exact: false }"
>
  <div
    id="content"
    [ngStyle]="{
      'font-weight': menuTab?.fontWeight,
      'font-size': menuTab?.fontSize
    }"
  >
    <div class="par">{{ menuTab?.title }}</div>
  </div>

  <div class="icon-container">
    <div id="circle" [ngClass]="circleColor()">
      <i
        *ngIf="!customSvg"
        [class]="menuTab?.icon"
        id="icon-svg"
        [ngStyle]="{ color: menuTab?.iconColor }"
      ></i>
      <div *ngIf="customSvg">
        <svg-icon
          *ngIf="customSvg"
          id="custom-svg"
          [src]="menuTab?.icon"
          [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"
        >
        </svg-icon>
      </div>
    </div>
  </div>
</div>
