import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BomIndenterState } from './models/bom-indenter-state.model';
import { BomLineDataService } from '../../../../../services/bom-line-data.service';
import { PdmLinkService } from 'src/app/shared/services/pdm-link.service';

@Component({
  selector: 'app-bom-tree-item-section',
  templateUrl: './bom-tree-item-section.component.html',
  styleUrls: ['./bom-tree-item-section.component.scss']
})
export class BomTreeItemSectionComponent implements OnInit {
  @Input() document: any;
  @Output() isExpandedEvent = new EventEmitter();
  indenterState: BomIndenterState;
  dataList;
  gridTemplate: string;
  private gridTemplateSmall: string;
  constructor(private bomLineDataService: BomLineDataService, private pdmLinkService: PdmLinkService) {}

  ngOnInit() {
    this.gridTemplate = '8% 35% 1fr';
    this.gridTemplateSmall = '8% 1fr';

    if (this.document) {
      this.dataList = this.bomLineDataService.getDataList(this.document);
      this.indenterState = {
        isLast: this.document._isLast,
        hasChildren: this.document.hasOwnProperty('_children'),
        treeDepth: this.document.treeDepth
      };
    }
  }

  isExpandedChanged(isExpanded: boolean) {
    this.isExpandedEvent.emit(isExpanded);
  }

  OpenPDMLink(){
       //get link
       var link = this.pdmLinkService.GetPdmLink(this.document.fileName, this.document.path);

       //open link
       window.open(link, '_blank');
  }

  isPdmLinkVisible(): boolean {
    return this.document?.fileName?.trim() !== '' && this.document?.path?.trim() !== '';
  }
}
