import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-machine-timers-indicator',
  templateUrl: './machine-timers-indicator.component.html',
  styleUrls: ['./machine-timers-indicator.component.scss']
})
export class MachineTimersIndicatorComponent {
  @Input() name: string;
  @Input() time: string;
  @Input() color: string;

}
