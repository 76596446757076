import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-search-bar',
  templateUrl: './generic-search-bar.component.html',
  styleUrls: ['./generic-search-bar.component.scss']
})
export class GenericSearchBarComponent {
  @Input() textValue = '';
  @Input() icon = 'fas fa-search';
  @Input() placeholder = 'Search...';
  @Input() size = 36;
  @Input() fontSize = 24;
  @Input() autocomplete = false;
  @Output() valueChangeEmitter = new EventEmitter<string>();
  @Output() searchEmitter = new EventEmitter<string>();

  search() {
    this.valueChangeEmitter.emit(this.textValue);
  }
}
