import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { BomLineDataService } from '../../../../../services/bom-line-data.service';
import { PdmLinkService } from 'src/app/shared/services/pdm-link.service';

@Component({
  selector: 'app-bom-list-part-item',
  templateUrl: './bom-list-part-item.component.html',
  styleUrls: ['./bom-list-part-item.component.scss']
})
export class BomListPartItemComponent implements OnInit, OnChanges {
  @Input() part: any;
  gridTemplate: string;

  dataList: any;
  constructor(private bomLineDataService: BomLineDataService, private pdmLinkService: PdmLinkService) {}

  ngOnInit() {
    this.gridTemplate = '10% 40% auto';
  }

  ngOnChanges() {
    this.dataList = this.bomLineDataService.getDataList(this.part);
  }

  OpenPDMLink()
  {
     //get link
     var link = this.pdmLinkService.GetPdmLink(this.part.fileName, this.part.path);

     //open link
     window.open(link, '_blank');
  }

  isPdmLinkVisible(): boolean {
    return this.part?.fileName?.trim() !== '' && this.part?.path?.trim() !== '';
  }
}
