<!--<ingeniu-bom-section (load)="onLoad()" [loaded]="isLoaded" [documents]="bomParts"></ingeniu-bom-section>-->

<app-bom-button-section
  [defaultState]="defaultState"
  (buttonStateUpdate)="manageButtonsActions($event)"
></app-bom-button-section>

<app-bom-list-section
  *ngIf="buttonState.mode === bomModeEnum.LIST && isLoaded"
  [documents]="boms.list"
></app-bom-list-section>
<app-bom-tree-section
  *ngIf="buttonState.mode === bomModeEnum.TREE && isLoaded"
  [documents]="boms.tree"
></app-bom-tree-section>
