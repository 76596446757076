import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { BomService } from '../../shared/services/bom.service';
import { ComponentListService } from './component-list.service';
import { ComponentService } from '../../shared/services/component.service';
import { FullScreenService } from '../../shared/services/full-screen.service';
import { PdfService } from '../../shared/services/pdf.service';
import { SubmenuService } from '../../shared/services/submenu.service';
import { LanguageSelectorService } from '../components/work-instructions/services/language-selector.service';
import { StationSelectorService } from '../components/work-instructions/services/station-selector.service';

import { ToastService } from '../../shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ClearStorageService {
  constructor(
    private authService: AuthService,
    private bomService: BomService,
    private componentListService: ComponentListService,
    private componentService: ComponentService,
    private fullScreenService: FullScreenService,
    private pdfService: PdfService,
    private submenuService: SubmenuService,
    private languageSelectorService: LanguageSelectorService,
    private stationSelectorService: StationSelectorService
  ) {}

  clearOnLogout(): void {
    this.authService.logout();
    this.bomService.removeBom();
    this.componentListService.deleteComponentList();
    this.componentService.removeActiveComponent();
    this.fullScreenService.remove();
    this.pdfService.removePdfUrl();
    this.submenuService.removeMenu();
    this.languageSelectorService.remove();
    this.stationSelectorService.remove();
  }

  clearOnUpdate(): void {
    localStorage.clear();
  }
}
