import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-user-tag',
  templateUrl: './user-tag.component.html',
  styleUrls: ['./user-tag.component.scss']
})
export class UserTagComponent {
  @Input() user: any;
  @Output() removeEvent = new EventEmitter<any>();
}
