import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackendService } from '../../../../../shared/services/backend.service';
import { SelectValue } from '../../../../../shared/components/modal-select/interfaces/select-value';

@Injectable({
  providedIn: 'root'
})
export class LineStationUserService {
  private usersURI = 'birth-certificate/assembly-line-users';
  public users = new BehaviorSubject<any[]>(null);
  public groups = new BehaviorSubject<any[]>(null);
  public qcUsers = new BehaviorSubject<any[]>(null);
  public qcGroups = new BehaviorSubject<any[]>(null);
  private innerUsers = [];

  constructor(private backend: BackendService) {
    this.getAll();
    this.getQC();
  }

  private emitValues() {
    this.users.next(this.innerUsers);
  }

  private getAllUsers(): Observable<any> {
    return this.backend.get(this.usersURI + '?role=app-assembly').pipe(
      map(data => {
        return {
          users: data.users
            .map(u => this.convertUserToStation(u))
            .sort((a, b) =>
              a.label === b.label ? 0 : a.label < b.label ? -1 : 1
            ),
          groups: data.groups
        };
      })
    );
  }

  private getQCUsers(): Observable<any> {
    return this.backend.get(this.usersURI + '?role=app-qc').pipe(
      map(data => {
        return {
          users: data.users
            .map(u => this.convertUserToStation(u))
            .sort((a, b) =>
              a.label === b.label ? 0 : a.label < b.label ? -1 : 1
            ),
          groups: data.groups
        };
      })
    );
  }

  getAll() {
    this.getAllUsers().subscribe(data => {
      this.innerUsers = data.users;
      this.groups.next(data.groups);
      this.emitValues();
    });
  }

  getQC() {
    this.getQCUsers().subscribe(data => {
      this.qcUsers.next(data.users);
      this.qcGroups.next(data.groups);
    });
  }

  convertUserToStation(user: any): SelectValue {
    return { id: user.id, label: user.name };
  }
}
