import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() title: string;
  @Input() width: string;
  @Input() height: string;
  @Input() noIcon: boolean;
  @Input() icon: string;

  @Input() backgroundColor: string;
  @Input() fontColor: string;
  @Input() fontWeight: string;
  @Input() size: string;
  @Input() border: string;
  @Input() iconSize: string = '32px';
  @Input() iconBackgroundColor: string;
  @Input() iconBorder: string;
  @Input() iconColor: string;

  @Input() shadow: string;
  @Input() disable = false;
  @Input() inactive = false;
  @Input() svgIcon = false;

  calculateWidth(title: string =''): string {
    const baseWidth = 75; 
    const charWidth = 8; 
    return `${baseWidth + title.length * charWidth}px`;
  }
}
