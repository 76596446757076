import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { addDays, subDays } from 'date-fns';
import { FileSaverService } from 'ngx-filesaver';
import { Subject, Subscription } from 'rxjs';

import { IframeStatusService } from '../../services/iframe-status.service';
import { Month } from './interfaces/smr-progress-month.interface';
import { SmrDateRange } from './components/smr-range-selector/classes/smr-date-range';
import { SmrExcelExportConfig } from './interfaces/smr-progress-excel-config';
import { SmrExcelExportService } from './services/smr-progress-excel-export.service';
import { SmrProgressInProjectLot } from './interfaces/smr-progress-data.interface';
import { SmrProgressService } from './services/smr-progress.service';
import { SmrRangeInputPossibilities } from './components/smr-range-selector/enum/smr-range-input-possibilities.enum';
import { ToastService } from '../../services/toast.service';
import { Week } from './interfaces/smr-progress-week.interface';
import { SmrProgressProjectLotTuple } from './interfaces/smr-progress-project-lot-tuple';
import { SmrProgressBackendService } from './services/smr-progress-backend.service';
@Component({
  selector: 'app-smr-progress',
  templateUrl: './smr-progress.component.html',
  styleUrls: ['./smr-progress.component.scss'],
  providers: [SmrProgressService, SmrProgressBackendService]
})
export class SmrProgressComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input() projectsId: Array<number>;
  isInIframe: boolean;
  @ViewChild('stickyWrapper') stickyWrapper: ElementRef<HTMLElement>;
  currentRange: SmrDateRange;

  hoveredDate: Date;
  dataArray: Array<SmrProgressInProjectLot> = [];
  subscriptions: Subscription[];
  hasReceivedDataForEveryProject = false;

  isSticky = false;

  // Calendar
  months: Month[];
  weeks: Week[];

  private dataUpdate = new Subject();

  constructor(
    private readonly smrProgressService: SmrProgressService,
    private readonly smrProgressBackendService: SmrProgressBackendService,
    private readonly smrExcelService: SmrExcelExportService,
    private readonly toastService: ToastService,
    private readonly fileSaverService: FileSaverService,
    private readonly iframeStatusService: IframeStatusService
  ) {
    this.subscriptions = [];
    this.isInIframe = this.iframeStatusService.isInIFrame(window);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.smrProgressService.dateHover.subscribe((date: Date) => {
        this.hoveredDate = date;
      })
    );
    this.subscriptions.push(
      this.dataUpdate.subscribe(() => {
        this.updateFromSmrProgressService();
      })
    );
  }

  updateFromSmrProgressService() {
    this.smrProgressService.startDate = this.currentRange.startDate;
    this.smrProgressService.displayRange = this.currentRange.dayRange;
    this.months = this.smrProgressService.computeMonths();
    this.weeks = this.smrProgressService.computeWeeks();
  }

  ngAfterViewInit() {
    const observer = new IntersectionObserver(([e]) => (this.isSticky = e.intersectionRatio < 1), { threshold: [1] });
    observer.observe(this.stickyWrapper.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const chng = changes[propName];
        const cur = chng.currentValue;
        if (propName === 'projectsId' && !!cur && cur !== '') {
          this.hasReceivedDataForEveryProject = false;
          this.subscriptions.push(
            this.smrProgressBackendService
              .getDataForMultipleProjects(this.projectsId)
              .subscribe(dataInProjectLots => this.manageNewDataForSmr(dataInProjectLots))
          );
        }
      }
    }
  }

  manageNewDataForSmr(dataInProjectLots: SmrProgressInProjectLot[]) {
    dataInProjectLots.forEach(dataInProjectLot => {
      const alreadyExistingData = this.getAlreadyExistingDataForThisTuple(dataInProjectLot.projectLotsTuple);
      if (alreadyExistingData) {
        alreadyExistingData.data = Object.assign({}, dataInProjectLot.data);
      } else {
        this.dataArray.push(dataInProjectLot);
      }
    });
    this.sortSmrData(this.dataArray);
    this.hasReceivedDataForEveryProject = this.checkIfAllProjectsReceivedData();
    this.dataUpdate.next();
  }

  checkIfAllProjectsReceivedData(): boolean {
    const projectsIdReceived = this.dataArray.map(proj => proj.projectLotsTuple.projectId);
    const projectsfound = this.projectsId.filter(proj => projectsIdReceived.includes(proj));
    return projectsfound.length === this.projectsId.length;
  }

  sortSmrData(dataArray: SmrProgressInProjectLot[]) {
    dataArray.sort((a, b) => {
      if (!a.data && b.data) {
        return 1;
      }
      if (!b.data && a.data) {
        return -1;
      }
      if (a.projectLotsTuple.projectId < b.projectLotsTuple.projectId) {
        return -1;
      } else if (a.projectLotsTuple.projectId > b.projectLotsTuple.projectId) {
        return 1;
      }

      return 0;
    });
  }

  getAlreadyExistingDataForThisTuple(tupleProjectLot: SmrProgressProjectLotTuple): SmrProgressInProjectLot {
    if (!this.dataArray || !this.dataArray.find) {
      return null;
    }
    return this.dataArray.find((projectData: SmrProgressInProjectLot) => {
      return (
        projectData.projectLotsTuple.projectId === tupleProjectLot.projectId &&
        this.lotsAreSame(tupleProjectLot.lots, projectData.projectLotsTuple.lots)
      );
    });
  }

  lotsAreSame(lots1: string[], lots2: string[]): boolean {
    return lots1.join() === lots2.join();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  rangeChanged(smrRange: SmrDateRange) {
    this.currentRange = smrRange;
    this.dataUpdate.next();
  }

  stepForward() {
    if (this.currentRange) {
      const newStartDate = addDays(this.currentRange.startDate, this.currentRange.step);
      const newRange = new SmrDateRange(newStartDate, this.currentRange.dayRange);
      newRange.lastChangedBy = SmrRangeInputPossibilities.Input;
      this.rangeChanged(newRange);
    }
  }

  stepBackward() {
    if (this.currentRange) {
      const newStartDate = subDays(this.currentRange.startDate, this.currentRange.step);
      const newRange = new SmrDateRange(newStartDate, this.currentRange.dayRange);
      newRange.lastChangedBy = SmrRangeInputPossibilities.Input;
      this.rangeChanged(newRange);
    }
  }

  getExcelReport() {
    if (!this.hasReceivedDataForEveryProject) {
      return;
    }
    const excelConfig: SmrExcelExportConfig = {
      startDate: this.currentRange.startDate,
      endDate: this.currentRange.endDate,
      projectsNumber: this.projectsId
    };
    if (this.isExcelExportConfigValid(excelConfig)) {
      this.toastService.info(`SMR Report for Project ${this.projectsId}: waiting for download`);
      this.smrExcelService.askForExcelAndDownload(excelConfig).subscribe(
        data => {
          const fullFileName = this.getSMRExportFileName(excelConfig);
          this.fileSaverService.save(data, fullFileName);
          this.toastService.success(`SMR Report for Project ${this.projectsId}: successfully downloaded!`);
        },
        error => {
          this.toastService.error(`An error occured while generating report for project ${this.projectsId}`);
        }
      );
    }
  }

  isExcelExportConfigValid(config: SmrExcelExportConfig): boolean {
    return config.projectsNumber.length > 0 && config.projectsNumber[0] > 10000;
  }

  getSMRExportFileName(excelConfig: SmrExcelExportConfig): string {
    const fileExt = '.xlsx';
    const projects = excelConfig.projectsNumber.join('_');
    return `SMR Report-${projects}${fileExt}`;
  }
}
