import { Component, OnInit, Input } from '@angular/core';

import { ScreenSizeService } from '../../../shared/services/screen-size.service';
import { ScreenType } from '../../../shared/enums/screen-type.enum';

@Component({
  selector: 'app-table-title-line',
  templateUrl: './table-title-line.component.html',
  styleUrls: ['./table-title-line.component.scss']
})
export class TableTitleLineComponent implements OnInit {
  @Input() titleList: Array<string>;
  @Input() gridTemplate: string;

  screenType: ScreenType;
  ScreenTypeEnum = ScreenType;
  qty: string;

  constructor(private screenSizeService: ScreenSizeService) {}

  ngOnInit() {
    this.screenSizeService.screenTypeSubject.subscribe(newScreenSize => {
      this.screenType = newScreenSize;
    });

    let index: number;
    index = this.titleList && this.titleList.findIndex(data => data === 'Qty');

    if (index >= 0) {
      this.qty = this.titleList[index];
      this.titleList.splice(index, 1);
    }
  }

  nameClass(title: string) {
    return title === 'Name' ? 'name' : '';
  }
}
