<div *ngIf="!loading">
  <app-edition-header
    (saved)="onSave()"
    [backRoute]="'app/work-instructions/lines'"
  ></app-edition-header>

  <div class="header">
    <p>Line Configuration Name</p>
  </div>

  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div
      class="drag-container"
      cdkDrag
      [cdkDragDisabled]="modalLockService.lockScreen | async"
      *ngFor="let station of stationsSelected"
    >
      <div class="drag-line" cdkDragHandle></div>
      <app-line-configuration-body
        class="config-body"
        [station]="station"
        (deleteStation)="removeStation($event)"
        (madeChanges)="detectChanges()"
      >
      </app-line-configuration-body>
    </div>
  </div>

  <app-line-configuration-body
    *ngIf="qcStation"
    [station]="qcStation"
    (deleteStation)="removeStation($event)"
    (madeChanges)="detectChanges()"
  >
  </app-line-configuration-body>

  <div class="add-btn-container">
    <app-button
      [title]="'Add a new configured station'"
      [icon]="'fa fa-plus'"
      [backgroundColor]="'var(--fabbrica)'"
      [fontColor]="'white'"
      [iconBackgroundColor]="'var(--fabbrica-xdark)'"
      (click)="setStationSelectModal(true)"
    >
    </app-button>
  </div>
</div>

<div *ngIf="loading" style="margin-top: 25%;">
  <app-loading-cog></app-loading-cog>
</div>

<app-modal-select
  *ngIf="stationSelectModal"
  [header]="'Select a station'"
  [headerIcon]="'fa fa-wrench'"
  [optionIcon]="'fa fa-wrench'"
  [elements]="possibleStations"
  [selectedElement]="possibleStations[0]"
  (cancelEmitter)="setStationSelectModal(false)"
  (confirmEmitter)="addStation($event)"
></app-modal-select>

<app-modal
  *ngIf="editionService.showEditionModal | async"
  header="Name the line configuration"
  headerIcon="fas fa-list"
  (cancelEmitter)="closeEditionModal()"
  (confirmEmitter)="renameLine()"
>
  <input
    class="input-modal"
    type="text"
    placeholder="Enter a new name"
    [value]="newName"
    (change)="changeName($event)"
  />
</app-modal>
