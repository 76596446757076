import { Component, Input } from '@angular/core';
import { Stat } from '../../interfaces/lot-stat';
import { FrameStatus } from '../../../../enums/frame-status';

@Component({
  selector: 'app-lines-bar',
  templateUrl: './lines-bar.component.html',
  styleUrls: ['./lines-bar.component.scss']
})
export class LinesBarComponent {
  @Input() styles;
  @Input()
  get stats(): Stat[] {
    return this._stats;
  }

  set stats(stats: Stat[]) {
    this._stats = stats;
    const adapted = this.adaptStats(stats);
    this._statGroups = adapted.map(statGroup =>
      statGroup.filter(s => s && s.percent > 0)
    );
  }

  // tslint:disable-next-line:variable-name
  private _stats: Stat[];

  get statGroups(): Stat[][] {
    return this._statGroups;
  }

  // tslint:disable-next-line:variable-name
  private _statGroups: Stat[][];

  adaptStats(stats: Stat[]): Array<Array<Stat>> {
    const statDict = stats.reduce(
      (a, c) => ({
        ...a,
        [c.id]: c
      }),
      {}
    ) as { [key: string]: Stat };
    return this.adaptFrameStatus(statDict);
  }

  adaptFrameStatus(statDict: { [key: string]: Stat }) {
    return [
      [statDict[FrameStatus.INSTALLED]],
      [statDict[FrameStatus.MANUFACTURED], statDict[FrameStatus.ON_HOLD]],
      [statDict[FrameStatus.RELEASED]],
      [statDict[FrameStatus.FPR]]
    ];
  }
}
