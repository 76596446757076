import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '../../../../shared/services/toast.service';
import { HeaderTitleService } from '../../services/header-title.service';
import { UploadService } from './services/upload.service';

@Component({
  selector: 'app-work-instructions-frame-import',
  templateUrl: './work-instructions-frame-import.component.html',
  styleUrls: ['./work-instructions-frame-import.component.scss']
})
export class WorkInstructionsFrameImportComponent implements OnDestroy {
  uploadedFiles: File[] = [];
  uploading = false;

  subscriptions: Subscription[] = [];

  constructor(
    private headerService: HeaderTitleService,
    private uploadService: UploadService,
    private toastrService: ToastService
  ) {
    headerService.setHeader('CHECKLIST MANAGER / SAP FRAME IMPORT');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  fileUploaded(files: any[]) {
    for (const file of files) {
      if (
        file.name.toLowerCase().endsWith('.xlsx') &&
        !this.uploadedFiles.some(f => f.name === file.name)
      ) {
        this.uploadedFiles.push(file);
      }
    }
  }

  upload() {
    this.uploading = true;
    this.uploadedFiles.forEach(f => {
      this.subscriptions.push(
        this.uploadService.upload(f).subscribe(resp => {
          const title = `${f.name} - ${resp.error}`;
          if (resp.success) {
            const successMessage = `frames created: ${resp.result.newFrames} | frames updated: ${resp.result.updatedFrames}`;
            this.toastrService.success(successMessage, title);
          } else {
            this.toastrService.error(title);
          }
          const i = this.uploadedFiles.findIndex(file => file.name === f.name);
          this.removeUploadedFile(i);
          this.uploading = false;
        })
      );
    });
  }

  removeUploadedFile(index: number) {
    this.uploadedFiles.splice(index, 1);
  }
}
