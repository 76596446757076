import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectValue } from '../../../../shared/components/modal-select/interfaces/select-value';
import {
  LineSelectorService,
  NO_LINE
} from '../../../../shared/components/work-instructions/services/line-selector.service';
// tslint:disable-next-line
import { StationSelectorService } from '../../../../shared/components/work-instructions/services/station-selector.service';
// tslint:disable-next-line
import { Station } from '../../../../shared/interfaces/station.interface';
import { DeviceService } from '../../../../shared/services/device.service';
import { UserService } from '../../../../shared/services/user.service';
import { KpiManagerService } from '../../../work-instructions-manager/components/kpi-target/services/kpi-manager.service';
import { StationsBackendService } from '../../../work-instructions-manager/components/work-instructions-stations/services/checklist-station-backend.service';
import { MenuOpenedService } from '../../services/menu-opened.service';

@Component({
  selector: 'app-station-tab',
  templateUrl: './station-tab.component.html',
  styleUrls: ['./station-tab.component.scss']
})
export class StationTabComponent implements OnInit, OnDestroy {
  modal = false;
  currentStation: Station;
  currentLine: SelectValue;
  stations: Station[] = [];
  lines: SelectValue[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private stationSelectorService: StationSelectorService,
    private lineSelectorService: LineSelectorService,
    private userService: UserService,
    private stationsService: StationsBackendService,
    private kpiManagerService: KpiManagerService,
    public menuOpenedService: MenuOpenedService,
    public deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    const userId = this.userService.user.value.name;

    this.subscriptions.push(
      this.kpiManagerService.getAllLines().subscribe(data => {
        if (data) {
          this.lines = [
            ...data.map(l => ({
              id: l.lineNumber,
              label: l.description
            })),
            NO_LINE
          ];
        }
      })
    );

    this.subscriptions.push(
      this.stationsService.getUserStations(userId).subscribe(data => {
        if (data) {
          this.stations = data;
        }
      })
    );

    this.currentStation = this.stationSelectorService.subject.value;
    this.currentLine = this.lineSelectorService.subject.value;
  }

  openModal() {
    this.modal = true;
  }

  onCancel() {
    this.modal = false;
  }

  onConfirm(values: any[]) {
    if (values[0]) {
      this.currentStation = values[0];
      this.stationSelectorService.set(this.currentStation);
    }
    if (values[1]) {
      this.currentLine = values[1];
      this.lineSelectorService.set(this.currentLine);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
