import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-flat-icon',
  templateUrl: './flat-icon.component.html',
  styleUrls: ['./flat-icon.component.scss']
})
export class FlatIconComponent{
  @Input() icon: string;
  @Input() iconSize: string;
  @Input() color: string;
  @Input() border: string;
  @Input() size: string;
}
