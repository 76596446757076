import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BackendService } from './backend.service';
import { CheckPart } from '../models/check-part';
import {
  PostUnitsCheckPartsData,
  GetUnitsCheckParts,
  SynchronizedPartsData
} from '../interfaces/check-parts.interface';
import { UnitsSocketService } from './units-socket.service';

@Injectable()
export class UnitService {
  constructor(
    private backendService: BackendService,
    private socket: UnitsSocketService
  ) {}

  getCheckedParts(qrScanString: string): Observable<GetUnitsCheckParts[]> {
    const uri = this.buildURI(qrScanString);
    return this.backendService.get(uri);
  }

  setCheckedParts(
    qrScanString: string,
    ...checkedParts: CheckPart[]
  ): Observable<PostUnitsCheckPartsData> {
    const uri = this.buildURI(qrScanString);
    const body = checkedParts.map(c => {
      const checkPartObj = {
        Part: {
          Name: c.document.fileName,
          Serial: c.sn,
          Variables: {}
        },
        Qty: c.confirmed
      };

      const variables: LooseObject = {};
      if (c.expirationDate) {
        variables.ExpirationDate = c.expirationDate;
      }
      if (c.pailNo) {
        variables.PailNo = c.pailNo;
      }
      checkPartObj.Part.Variables = variables;

      return checkPartObj;
    });
    return this.backendService.post(uri, body);
  }

  synchronizedCheckParts(): Observable<SynchronizedPartsData> {
    return this.socket.fromEvent<SynchronizedPartsData>('new-check-parts');
  }

  private buildURI(qrScanString: string): string {
    return `units/${qrScanString}/check-parts`;
  }
}

interface LooseObject {
  [key: string]: any;
}
