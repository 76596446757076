<div *ngIf="!configurationsLoading" class="border-top">
  <div class="header" *ngIf="configurations.length > 0">
    <p>Configurations</p>
  </div>

  <div
    *ngFor="let config of configurations"
    [ngClass]="
      isFinalQC
        ? 'container border-bottom qcStation'
        : 'container border-bottom'
    "
    (click)="toggleExpanded(config.id)"
  >
    <div class="header-row hover">
      <div class="left-container">
        <p>{{ config.name }}</p>
      </div>
      <div class="right-container">
        <app-round-button
          *ngIf="!config.expanded"
          class="mr10px expand-btn"
          data-cy="toggle"
          [icon]="'fas fa-chevron-down'"
          [sizepx]="32"
          [fontSize]="isMobile ? '14px' : '17px'"
          backgroundColor="transparent"
          iconColor="var(--menuItem-text1)"
          shadow="none"
        ></app-round-button>
        <app-round-button
          *ngIf="config.expanded"
          class="mr10px expand-btn"
          data-cy="toggle"
          [icon]="'fas fa-chevron-up'"
          [sizepx]="32"
          [fontSize]="isMobile ? '14px' : '17px'"
          backgroundColor="transparent"
          iconColor="var(--menuItem-text1)"
          shadow="none"
        ></app-round-button>

        <app-round-button
          (click)="
            editConfig(config.id, config.version); $event.stopPropagation()
          "
          class="mr5px mv5px"
          [icon]="'fas fa-edit'"
          [sizepx]="isMobile ? 35 : 48"
          [fontSize]="isMobile ? '18px' : '26px'"
          backgroundColor="var(--fabbrica)"
          iconColor="var(--menuItem-background1)"
          shadow="var(--shadow1)"
        ></app-round-button>
      </div>
    </div>

    <div
      style="display: flex; justify-content: space-between;"
      *ngIf="config.expanded"
    >
      <div class="steps-container">
        <div
          *ngFor="let step of config.steps; let i = index"
          class="text-container"
        >
          <div class="step-row">
            <p>
              <strong>Step {{ i + 1 }}:</strong>
              {{ lang === 'En' ? step.description.en : step.description.es }}
            </p>
            <p *ngIf="step.isFinalQC" id="final-inspection-step">
              Final inspection
            </p>
          </div>
        </div>
      </div>

      <div class="btn-container">
        <app-round-button
          *ngIf="!showDeleteModal"
          [icon]="'fas fa-trash'"
          style="margin-bottom: 5px; margin-right: 5px;"
          [sizepx]="isMobile ? 35 : 48"
          [fontSize]="isMobile ? '18px' : '26px'"
          backgroundColor="var(--red)"
          iconColor="var(--menuItem-background1)"
          shadow="var(--shadow1)"
          (click)="
            setShowDeleteModal(true, config.id); $event.stopPropagation()
          "
        ></app-round-button>

        <div
          class="confirm-delete"
          *ngIf="showDeleteModal"
          (click)="deleteConfig()"
        >
          <div>Confirm</div>
          <div
            class="icon"
            (click)="
              setShowDeleteModal(false, config.id); $event.stopPropagation()
            "
          >
            <i class="fa fa-trash"></i>
          </div>
        </div>

        <app-round-button
          [icon]="'fas fa-copy'"
          style="margin-bottom: 5px; margin-right: 5px;"
          [sizepx]="isMobile ? 35 : 48"
          [fontSize]="isMobile ? '18px' : '26px'"
          backgroundColor="var(--fab-unit)"
          iconColor="var(--menuItem-background1)"
          shadow="var(--shadow1)"
          (click)="copyConfig(config.id); $event.stopPropagation()"
        ></app-round-button>
      </div>
    </div>
  </div>
</div>

<div style="margin: 10px;" *ngIf="configurationsLoading">
  <app-loading-cog></app-loading-cog>
</div>
