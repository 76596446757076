import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { MenuTab } from '../models/menu-tab.model';

import { SubMenuStorageService } from './sub-menu-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SubmenuService {
  subMenuListSubject: BehaviorSubject<Array<MenuTab>>;

  constructor(private subMenuStorageService: SubMenuStorageService) {
    this.subMenuListSubject = new BehaviorSubject([]);
  }

  addMenuItem(menuItem: MenuTab) {
    const menu: Array<MenuTab> = this.getSubMenu();
    let itemMenuAlreadyExists = false;

    // We check if this new menuItem already Exists:
    menu.forEach(existingItem => {
      if (menuItem.link === existingItem.link) {
        itemMenuAlreadyExists = true;
      }
    });

    // We add the new menuItem at the correct place:
    if (!itemMenuAlreadyExists) {
      if (menuItem.rank >= 0) {
        menu.splice(menuItem.rank, 0, menuItem);
      } else {
        menu.push(menuItem);
      }
      this.setSubMenu(menu);
    }
  }

  private getSubMenu(): Array<MenuTab> {
    const subMenu = this.subMenuStorageService.get();
    return subMenu || [];
  }

  private setSubMenu(menu: Array<MenuTab>) {
    // We emit a new menu through subMenu Subject:
    setTimeout(() => this.subMenuListSubject.next(menu));
    this.subMenuStorageService.set(menu);
  }

  removeMenu() {
    this.setSubMenu([]);
    this.subMenuStorageService.clear();
  }
}
