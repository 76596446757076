<div class="container" (click)='setExpanded()'>
    <div class="row">
        <div class="left-container">
            <div class="left-container">
                <app-round-button
                  class="icon-btn"
                  data-cy="toggle"
                  [icon]="'fa fa-clipboard-check'"
                  [sizepx]="59"
                  fontSize="30px"
                  backgroundColor="var(--red)"
                  iconColor="var(--menuItem-background1)"
                  shadow="var(--shadow1)"
                ></app-round-button>
                <p>{{name}}</p>
            </div>
        </div>
        
        <div class="icon-button-container">
            <app-round-button class="h-margin" [sizepx]="59" fontSize="28px" data-cy="view" (click)="editId($event)" [icon]="'fas fa-edit'"></app-round-button>
            <app-round-button class="h-margin" [sizepx]="59" fontSize="28px" data-cy="view" (click)="cloneId($event)" [icon]="'fas fa-clone'"></app-round-button>
            <app-round-button class="h-margin" [sizepx]="59" fontSize="28px" data-cy="view" (click)="remove($event)" backgroundColor="var(--red)" [icon]="'fas fa-times'"></app-round-button>
        </div>
    </div>


    <div *ngIf="expanded" class="info-container">
        <div class="info">
            <div class="key">Creation:</div><div class="value">{{createdAt | date}}</div>
        </div>
        <div class="info">
            <div class="key">Last modification:</div><div class="value">{{updatedAt | date}}</div>
        </div>
        <div class="info">
            <div class="key">Created by</div><div class="value">{{createdBy}}</div>
        </div>
    </div>
</div>