import { Component, Input, EventEmitter, Output } from '@angular/core';
import { WorkInstructionsInputTypesEnum } from '../../enums/work-instructions-input-types.enum';
import { WorkInstructionsLanguageTextData } from '../../interfaces/work-instructions-multilingual-text-data.interface';

export interface InputValue {
  value: WorkInstructionsLanguageTextData | string | number;
  type: WorkInstructionsInputTypesEnum;
}

@Component({
  selector: 'app-standard-input',
  templateUrl: './standard-input.component.html',
  styleUrls: ['./standard-input.component.scss']
})
export class StandardInputComponent {
  @Input() input: any;
  @Input() pictures: any[];
  @Input() comment: string;
  @Input() index: number;
  @Input() currentLanguage: string;
  @Input() editModeActivated: boolean;
  @Input() disabled: boolean;
  @Output() resultEmitter = new EventEmitter<InputValue>();
  @Output() removedInput = new EventEmitter<number>();
  @Output() textChanged = new EventEmitter<any>();

  @Output() pictureAdd = new EventEmitter<string>();
  @Output() pictureDelete = new EventEmitter<string>();
  @Output() picturesSaved = new EventEmitter<any>();
  @Output() commentSaved = new EventEmitter<string>();

  removeInput(index: number) {
    this.removedInput.emit(index);
  }

  onTextChange() {
    this.textChanged.emit();
  }

  handleOutput(e: any, type: WorkInstructionsInputTypesEnum) {
    const inputVal: InputValue = {
      value: e,
      type: type
    };
    this.resultEmitter.emit(inputVal);
  }

  public get workInstructionsInputTypesEnum(): typeof WorkInstructionsInputTypesEnum {
    return WorkInstructionsInputTypesEnum;
  }
}
