import { Component, Input } from '@angular/core';

import { Month } from '../../interfaces/smr-progress-month.interface';
import { Week } from '../../interfaces/smr-progress-week.interface';

@Component({
  selector: 'app-smr-calendar',
  templateUrl: './smr-calendar.component.html',
  styleUrls: ['./smr-calendar.component.scss']
})
export class SmrCalendarComponent {
  @Input() data: Week[] | Month[];
  @Input() displayRange: number;
  @Input() showLabels = false;
  @Input() noContent = false;
  @Input() startDate: Date;
  @Input() unit: 'week' | 'month' = 'week';
}
