<ng-container *ngIf="!loading">
  <div class="header">
    <p>Station Name</p>
  </div>

  <app-work-instructions-station
    *ngFor="let station of stations"
    [station]="station"
    (stationNameChange)="updateStation($event)"
    (confirmDeleteStation)="deleteStation($event)"
  >
  </app-work-instructions-station>

    <div class="add-btn-container">
      <app-button
        [title]='"Add a new station"'
        [icon]='"fa fa-plus"'
        [backgroundColor]='"var(--fabbrica)"'
        [fontColor]='"white"'
        [iconBackgroundColor]='"var(--fabbrica-xdark)"'
        (click)='toggleShowModal()'
        >
      </app-button>
    </div>
</ng-container>


<app-modal *ngIf="newStationModal"
  header='Name the station'
  headerIcon='fas fa-wrench'
  (cancelEmitter)='toggleShowModal()'
  (confirmEmitter)='addStation()'
  >
  <input class="input-modal"
    type="text"
    placeholder="Enter a new name"
    [value]='newStationName'
    (change)='onNewStationNameChange($event)'
  />
</app-modal>

<div *ngIf="loading" style="margin-top: 25%;">
  <app-loading-cog></app-loading-cog>
</div>
