// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { DeviceType } from '../app/shared/enums/device-type.enum';

export const environment = {
  production: false,
  configuration: {
    backend_scheme: 'https://',
    backend_url: 'webserv-dev.fabbricausa.com',
    backend_port: 443,
    backend_version: 'v4',
    version: require('../../package.json').version,
    bypassDevice: false,
    bypassDevicetype: DeviceType.MOBILE,
    isVerbose: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
