import { Component, Input, OnChanges } from '@angular/core';

import { FileType } from '../../enums/file-type.enum';

@Component({
  selector: 'app-file-indicator',
  templateUrl: './file-indicator.component.html',
  styleUrls: ['./file-indicator.component.scss']
})
export class FileIndicatorComponent implements OnChanges {
  @Input() fileType: FileType;
  cssColor: string;

  constructor() {
    this.cssColor = '';
  }

  ngOnChanges() {
    this.cssColor = this.getStyleFromType(this.fileType);
  }

  getStyleFromType(type: FileType): string {
    switch (type) {
      case FileType.COMPONENT:
        return '#F2A143';
      case FileType.DOCUMENT:
        return '#C94848';
      case FileType.UNIT:
        return '#1497E0';
      case FileType.UNKNOWN:
        return '';
    }
  }
}
