import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-dragdata';

@Component({
  selector: 'app-draggable-chart',
  templateUrl: './draggable-chart.component.html',
  styleUrls: ['./draggable-chart.component.scss']
})
export class DraggableChartComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() goals: any[];
  @Input() processedUnits: number[];
  @Input() labels: string[];

  @Output() goalChanged: EventEmitter<any> = new EventEmitter();

  maxValue = 35;

  canvas: any;
  ctx: any;
  chart: Chart;

  constructor(private router: Router) {}

  compareDatesAsString(date1: string, date2: string): boolean {
    const splittedDate1 = date1.split('-');
    const splittedDate2 = date2.split('-');

    if (parseInt(splittedDate1[0]) > parseInt(splittedDate2[0])) {
      return true;
    } else if (parseInt(splittedDate1[0]) < parseInt(splittedDate2[0])) {
      return false;
    } else {
      if (parseInt(splittedDate1[1]) > parseInt(splittedDate2[1])) {
        return true;
      } else if (parseInt(splittedDate1[1]) < parseInt(splittedDate2[1])) {
        return false;
      } else {
        if (parseInt(splittedDate1[2]) >= parseInt(splittedDate2[2])) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  goToTVScreen() {
    const lineNumber = this.title.split('#')[1];
    this.router.navigateByUrl(`/app/tv-screen/${lineNumber}`);
  }

  ngOnInit(): void {
    this.ngAfterViewInit();
  }

  ngAfterViewInit(): void {
    this.canvas = document.getElementById('chart' + this.title.split('#')[1]);
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d');
    }
    if (this.chart) {
      this.chart.destroy();
    }

    if (this.ctx) {
      this.chart = new Chart(this.ctx, {
        data: {
          labels: this.labels,
          datasets: [
            {
              type: 'line',
              dragData: false,
              label: 'Frames Processed',
              backgroundColor: '#95CBF0',
              borderColor: '#95CBF0',
              data: this.processedUnits
            },
            {
              type: 'bar',
              label: 'Goal Value',
              backgroundColor: this.labels.map(d => {
                const today = new Date().toISOString().split('T')[0];
                return this.compareDatesAsString(d, today)
                  ? '#FFE0E6'
                  : '#ACE1AF';
              }),
              minBarLength: 5,
              data: this.goals
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          onHover: function(e) {
            const point = (e as any).chart.getElementsAtEventForMode(
              e,
              'nearest',
              { intersect: true },
              false
            );
            if (point.length) (e as any).native.target.style.cursor = 'grab';
            else (e as any).native.target.style.cursor = 'default';
          },
          scales: {
            y: {
              max: this.maxValue,
              min: 0
            }
          },
          plugins: {
            legend: {
              onClick: () => {},
              labels: {
                boxWidth: 15,
                boxHeight: 15,
                generateLabels: () => {
                  return [
                    {
                      fillStyle: '#95CBF0',
                      strokeStyle: '#95CBF0',
                      text: 'Frames Processed'
                    },
                    {
                      fillStyle: '#FFE0E6',
                      strokeStyle: '#FFE0E6',
                      text: 'Editable Goal Values'
                    },
                    {
                      fillStyle: '#ACE1AF',
                      strokeStyle: '#ACE1AF',
                      text: 'Non-Editable Goal Values'
                    }
                  ];
                }
              },
              align: 'end'
            },
            dragData: {
              round: 0,
              showTooltip: true,
              onDragStart: (e, datasetIndex, index) => {
                const date = this.labels[index];
                const today = new Date().toISOString().split('T')[0];
                e.target.style.cursor = 'grabbing';
                return this.compareDatesAsString(date, today);
              },
              onDragEnd: (e, datasetIndex, index, value) => {
                this.goalChanged.emit({
                  value: value,
                  date: this.labels[index]
                });
                e.target.style.cursor = 'default';
              }
            }
          }
        }
      } as any);
    }
  }
}
