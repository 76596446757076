import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { IframeStatusService } from 'src/app/shared/services/iframe-status.service';
import { IntranetSmrLinkService } from './services/intranet-smr-link.service';
import { MaterialGroupsService } from 'src/app/shared/services/material-groups.service';
import { SmrProgressSmr } from '../../interfaces/smr-progress-data.interface';
import { SmrSelectionService } from '../../services/smr-selection.service';
import { Subscription } from 'rxjs';
import { GroupCounts } from 'src/app/shared/interfaces/group-counts.interface';

@Component({
  selector: 'app-smr',
  templateUrl: './smr.component.html',
  styleUrls: ['./smr.component.scss']
})
export class SmrComponent implements OnInit, OnDestroy {
  @Input() displayRange: number;
  @Input() opened = false;
  @Input() smr: SmrProgressSmr;
  @Input() startDate: Date;

  counts: GroupCounts;
  hasCount = false;

  isInIframe: boolean;
  isSelected: boolean;

  sub: Subscription;

  constructor(
    public readonly window: Window,
    private readonly intranetSmrLinkService: IntranetSmrLinkService,
    private readonly materialGroupService: MaterialGroupsService,
    private readonly iframeStatusService: IframeStatusService,
    private readonly smrSelectionService: SmrSelectionService
  ) {}

  ngOnInit() {
    this.isInIframe = this.iframeStatusService.isInIFrame(window);
    this.updateIsSelectedFromSelectionService();
    this.sub = this.smrSelectionService.selectionListSubject.subscribe(() => {
      this.updateIsSelectedFromSelectionService();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateIsSelectedFromSelectionService() {
    this.isSelected = this.smrSelectionService.isSmrSelected(this.smr?.data?.documentID);
  }

  toggle() {
    this.opened = !this.opened;
    if (!this.hasCount) {
      this.getCounts(this.smr.data.documentID);
    }
  }

  getCounts(documentId: number) {
    this.materialGroupService.get(documentId).subscribe(data => {
      this.counts = data;
      this.hasCount = true;
    });
  }

  viewSmr(e: Event, smrId: number) {
    e.stopPropagation();
    this.intranetSmrLinkService.viewSmrOnIntranet(smrId);
  }
}
