import { Injectable } from '@angular/core';
import { StorageKey } from '../enums/storage-key.enum';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageVersionStorageService {
  private key = StorageKey.STORAGEVERSION;

  constructor(private readonly storageService: StorageService) {}

  get(): string {
    return this.storageService.get(this.key);
  }

  set(version: string): void {
    this.storageService.set(this.key, version);
  }

  clear(): void {
    this.storageService.clear(this.key);
  }
}
