import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { User } from '../models/user';
import { StorageKey } from '../enums/storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {
  private key = StorageKey.USER;

  constructor(private readonly storageService: StorageService) {}

  get(): User {
    const userObj = { ...this.storageService.get(this.key) };
    return userObj.name ? new User(userObj) : null;
  }

  set(user: User): void {
    this.storageService.set(this.key, user);
  }

  clear(): void {
    this.storageService.clear(this.key);
  }
}
