import { Injectable } from '@angular/core';
import { RecursiveItem } from '../interfaces/recursive-item';

@Injectable({
  providedIn: 'root'
})
export class RecursiveLinkerService {
  rootItem: RecursiveItem;

  constructor() {}

  itemChanged(updatedItem: RecursiveItem): void {
    if (updatedItem.lineConfig === 'inherited') {
      updatedItem.manuallyAssignedValue = false;
      if (updatedItem.parent) {
        this.changeDependingOnParents(updatedItem, updatedItem.parent);
      }
    }
    if (updatedItem.children.length > 0) {
      this.changeRecursivelyAllChildren(updatedItem);
    }
  }

  changeRecursivelyAllChildren(item: RecursiveItem): void {
    item.children.forEach(childrenItem => {
      if (!childrenItem.manuallyAssignedValue) {
        childrenItem.lineConfig = item.lineConfig;
        this.itemChanged(childrenItem);
      }
    });
  }

  changeDependingOnParents(
    childItem: RecursiveItem,
    parentItem: RecursiveItem
  ) {
    if (parentItem.manuallyAssignedValue) {
      childItem.lineConfig = parentItem.lineConfig;
    } else {
      if (parentItem.parent) {
        this.changeDependingOnParents(childItem, parentItem.parent);
      } else {
        childItem.lineConfig = 'inherited';
      }
    }
  }

  collapseChildren(item: RecursiveItem) {
    if (item.children.length === 0) {
      return;
    }
    item.isExpanded = false;
    item.children.forEach(c => this.collapseChildren(c));
  }

  collapseBrothers(item: RecursiveItem) {
    if (item.parent) {
      item.parent.children.forEach(c => {
        if (c.name !== item.name) {
          this.collapseChildren(c);
        }
      });
    }
  }
}
