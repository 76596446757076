import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StepInputValidatedService {
  stepInputsAreValidated;

  init(length: number) {
    this.stepInputsAreValidated = new Array(length).fill(false);
  }

  getValue(index: number) {
    return this.stepInputsAreValidated[index];
  }

  setValue(index: number, value: boolean) {
    this.stepInputsAreValidated[index] = value;
  }
}
