import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { InputTypeEnum } from 'src/app/shared/enums/input-type.enum';
import { InputTypeService } from 'src/app/shared/services/input-type.service';
import { BarcodeScannerService } from '../../../../services/barcode-scanner.service';
import { ToastService } from '../../../../services/toast.service';
import { BackendService } from '../../../../services/backend.service';
import { Subscription } from 'rxjs';
import { RfidKeyboardScannerService } from 'src/app/shared/services/rfid-keyboard-scanner.service';

@Component({
  selector: 'app-rfid-input',
  templateUrl: './rfid-input.component.html',
  styleUrls: ['./rfid-input.component.scss']
})
export class RfidInputComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() value: string;
  @Output() rfidScanned = new EventEmitter<string>();

  qrScannerOpen = false;
  subscriptions: Subscription[] = [];

  constructor(
    private scannerService: BarcodeScannerService,
    private rfidKeybardScannerService: RfidKeyboardScannerService,
    private inputTypeService: InputTypeService,
    private toastService: ToastService,
    private backend: BackendService
  ) {}

  private validateScannedRfidCode(code: string) {
    return this.inputTypeService.getInputType(code) === InputTypeEnum.RFIDLABEL;
  }

  private processScannedString(scannedString: string) {
    if (this.validateScannedRfidCode(scannedString)) {
      this.subscriptions.push(
        this.backend.get(`units/check-rfid/${scannedString}`).subscribe(
          data => {
            if (!data.rfidExists) {
              this.toastService.success(
                `RFID ${scannedString} successfully read`
              );
              this.value = scannedString;
              this.rfidScanned.emit(this.value);
            } else {
              this.toastService.warning(
                `This RFID ${scannedString} is already used in another unit!`
              );
            }
          },
          err => this.toastService.error(`Problem with backend: ${err.message}`)
        )
      );
    } else {
      this.toastService.error(
        `RFID scanned not valid: ${scannedString}</br>RFID must contains 24 characters`
      );
    }
  }

  ngOnInit(): void {
    if (!this.disabled) {
      this.subscriptions.push(
        this.scannerService.scannedString.subscribe(data => {
          this.processScannedString(data.fullString);
        })
      );
      this.subscriptions.push(
        this.rfidKeybardScannerService.scannedString.subscribe(data => {
          this.processScannedString(data.fullString);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleQrScanner(val: boolean) {
    this.qrScannerOpen = val;
  }

  handleQrScanSuccess(e: any) {
    this.processScannedString(e.fullString);
    this.toggleQrScanner(false);
  }
}
