import { Injectable } from '@angular/core';
import { StorageService } from '../../../services/storage.service';
import { StorageKey } from '../../../enums/storage-key.enum';
import { Station } from 'src/app/shared/interfaces/station.interface';
import { BehaviorSubject } from 'rxjs';

export const NO_STATION: Station = {
  id: '',
  label: 'no current station',
  isFinalQC: false
};
@Injectable({
  providedIn: 'root'
})
export class StationSelectorService {
  /*
   * You can subscribe to observe in real time
   * the station changes
   */
  subject: BehaviorSubject<Station>;

  constructor(private storageService: StorageService) {
    const station = this.storageService.get(StorageKey.USERSTATION);
    this.subject = new BehaviorSubject<Station>(
      this.getSanitizedStation(station)
    );
  }

  set(station: Station) {
    const sanitizedStation = this.getSanitizedStation(station);
    this.storageService.set(StorageKey.USERSTATION, sanitizedStation);
    this.subject.next(sanitizedStation);
  }

  remove() {
    this.storageService.clear(StorageKey.USERSTATION);
    this.subject.next(NO_STATION);
  }

  private getSanitizedStation(station: Station): Station {
    return station || NO_STATION;
  }
}
