import { Injectable } from '@angular/core';

import { Socket } from 'ngx-socket-io';

import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UnitsSocketService extends Socket {
  constructor(private readonly tokenStorageService: TokenStorageService) {
    super({
      url: `https://${environment.configuration.backend_url}/units`,
      options: {
        query: `x-access-token=${tokenStorageService.get()}`
      }
    });
  }
}
