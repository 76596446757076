<div id="wrapper">
  <div id="calendar">
    <div
      class="item"
      *ngFor="let datum of data"
      [class.even]="datum.num % 2 === 0"
      [class.odd]="datum.num % 2 === 1"
      [class.centered]="unit === 'month'"
      [style.flexGrow]="datum.size"
    >
      <ng-container *ngIf="showLabels">{{ datum.label }}</ng-container>
    </div>
  </div>
  <div id="content">
    <ng-content></ng-content>
  </div>
</div>
