import { Component, OnInit, Input } from '@angular/core';
import { FullScreenService } from '../../services/full-screen.service';

@Component({
  selector: 'app-full-screen-container',
  templateUrl: './full-screen-container.component.html',
  styleUrls: ['./full-screen-container.component.scss']
})
export class FullScreenContainerComponent implements OnInit {
  @Input() overflowAuto: boolean;
  overflowStyle: string;

  constructor(private fullScreenService: FullScreenService) {}

  ngOnInit() {
    if (this.overflowAuto) {
      this.overflowStyle = 'auto';
    } else {
      this.overflowStyle = 'hidden';
    }
  }

  getFullScreenClass(): string {
    const fullScreenState = this.fullScreenService.get();
    if (fullScreenState) {
      return 'full-screen-on';
    } else {
      return 'full-screen-off';
    }
  }
}
