import { addDays } from 'date-fns';
import { SmrRangeInputPossibilities } from '../enum/smr-range-input-possibilities.enum';

export class SmrDateRange {
  readonly startDate: Date;
  readonly endDate: Date;
  readonly dayRange: number;
  readonly step: number;
  readonly flatPickrString: string;
  lastChangedBy: SmrRangeInputPossibilities;

  constructor(startDate: Date, dayRange: number, lastChangedBy?: SmrRangeInputPossibilities) {
    this.lastChangedBy = lastChangedBy;
    this.startDate = startDate;
    this.dayRange = dayRange;
    this.endDate = this.getEndDate(this.startDate, this.dayRange);
    this.step = this.getStepFromDisplayRange(this.dayRange);
    this.flatPickrString = this.getFlatPickrString(this.startDate, this.endDate);
  }

  private getEndDate(startDate: Date, dayRange: number): Date {
    return addDays(startDate, dayRange);
  }

  private getStepFromDisplayRange(displayRange: number): number {
    return Math.round(displayRange / 3);
  }

  private getFlatPickrString(startDate: Date, endDate: Date): string {
    return `${startDate.toISOString()} to ${endDate.toISOString()}`;
  }
}
