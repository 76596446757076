<div
  id="box"
  (click)="openModal()"
  [ngStyle]="{
    'flex-direction':
      (deviceService.isMobile | async)
        ? 'row-reverse'
        : (menuOpenedService.isOpen | async)
        ? 'row-reverse'
        : 'row'
  }"
>
  <div id="content">
    <div class="par">{{ currentStation.label }}/{{ currentLine.label }}</div>
  </div>
  <div class="icon">
    <i class="fa fa-toolbox"></i>
  </div>
</div>

<app-modal-select
  *ngIf="modal"
  [header]="'Select a station'"
  [headerIcon]="'fa fa-wrench'"
  [optionIcon]="'fa fa-wrench'"
  [twoDataTypes]="true"
  [sectionHeaders]="['Stations', 'Lines']"
  [elements]="[stations, lines]"
  [selectedElement]="[currentStation, currentLine]"
  (cancelEmitter)="onCancel()"
  (confirmEmitter)="onConfirm($event)"
></app-modal-select>
