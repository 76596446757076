<div id="placeholder">
  <input 
    [(ngModel)]="textValue" 
    (keyup)="search()" 
    id="input-field" 
    type="text" 
    name="fname" 
    [placeholder]="placeholder" 
    [autocomplete]="autocomplete ? 'on' : 'off'" />
  <div class="floating">
    <app-round-button
      data-cy="search-button"
      id="button"
      [icon]="icon"
      [sizepx]="size"
      [fontSize]="fontSize"
      [shadow]="'none'"
      (click)='search()'
    ></app-round-button>
  </div>
</div>
    