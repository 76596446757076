import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkInstructionsLanguagesEnum } from '../../enums/work-instructions-languages.enum';
import { WorkInstructionsCustomizableInput } from '../../interfaces/work-instructions-customizable-input.interface';
import { WorkInstructionsLanguageTextData } from '../../interfaces/work-instructions-multilingual-text-data.interface';

@Component({
  selector: 'app-bilingual-editable-text',
  templateUrl: './bilingual-editable-text.component.html',
  styleUrls: ['./bilingual-editable-text.component.scss']
})
export class BilingualEditableTextComponent implements WorkInstructionsCustomizableInput {
  @Input() currentLanguage: WorkInstructionsLanguagesEnum;
  @Input() defaultValue: WorkInstructionsLanguageTextData;
  @Input() editModeActivated = false;
  @Input() whiteText = false;
  @Output() textChange = new EventEmitter<any>();

  constructor() {
    this.currentLanguage = WorkInstructionsLanguagesEnum.ENGLISH;
  }

  public get birthCertificateLanguagesEnum(): typeof WorkInstructionsLanguagesEnum {
    return WorkInstructionsLanguagesEnum;
  }

  onTextChange() {
    this.textChange.emit();
  }

}
