import { Injectable } from '@angular/core';
import { SearchResult } from '../models/search-result.model';
import { Subject } from 'rxjs';
import { InputTypeService } from './input-type.service';
import { InputMethodEnum } from '../enums/input-method.enum';
import { InputTypeEnum } from '../enums/input-type.enum';

@Injectable({
  providedIn: 'root'
})
export class RfidKeyboardScannerService {
  scannedString: Subject<SearchResult> = new Subject();
  maxScannedTimer = 200; // Max between scan in ms before resetting the string
  rfidStringLength = 24;
  savedString: string = '';
  private lastScanTime: number = 0;

  constructor(private inputTypeService: InputTypeService) {}

  newKeyboardInput(event: KeyboardEvent): void {
    const char = event.key;
    this.addToSavedString(char);
    if (
      this.inputTypeService.getInputType(this.savedString) ===
      InputTypeEnum.RFIDLABEL
    ) {
      this.emitString();
    } else if (this.savedString.length > this.rfidStringLength) {
      this.resetString();
    }
  }

  resetString() {
    this.savedString = '';
  }

  emitString() {
    this.scannedString.next({
      fullString: this.savedString,
      inputMethod: InputMethodEnum.GUN,
      inputType: this.inputTypeService.getInputType(this.savedString)
    });
    this.savedString = '';
  }

  addToSavedString(str: string) {
    const actualTime = new Date().getTime();
    if (this.resetStringIsNeeded(actualTime)) {
      this.resetString();
    }
    this.savedString = `${this.savedString}${str}`;
    this.lastScanTime = actualTime;
  }

  resetStringIsNeeded(actualTime: number): boolean {
    return (
      this.savedString.length > 0 &&
      actualTime - this.lastScanTime >= this.maxScannedTimer
    );
  }
}
