<div *ngIf="editMode" class="photo-input">
  <div class="text">
    Take a Picture
  </div>
  <i class="fa fa-camera icon"></i>
</div>

<ng-container *ngIf="!editMode">
  <div
    #pictureSection
    class="frame-pictures-container"
    (click)="$event.stopPropagation()"
  >
    <app-frame-pictures
      [disabled]="disabled"
      [pictures]="pictures"
      (pictureTaken)="handleNewPicture($event)"
      (pictureDeleted)="handlePictureDeletion($event)"
    ></app-frame-pictures>
    <div
      class="save-btn-container"
      *ngIf="pictures.length > 0"
      style="width: 160px;"
    >
      <app-button
        [disable]="disabled"
        title="Save pictures"
        width="140px"
        height="40px"
        [noIcon]="true"
        backgroundColor="var(--fabbrica)"
        (click)="picturesSaved.emit()"
        style="font-weight: 500;"
      ></app-button>
    </div>
  </div>

  <div #commentSection (click)="$event.stopPropagation()">
    <app-comment-section
      [(comment)]="comment"
      [disabled]="editMode"
    ></app-comment-section>
    <div class="save-btn-container" style="width: 180px;">
      <app-button
        [disable]="disabled"
        title="Save comment"
        width="160px"
        height="40px"
        [noIcon]="true"
        backgroundColor="var(--fabbrica)"
        (click)="commentSaved.emit(comment)"
        style="font-weight: 500;"
      ></app-button>
    </div>
  </div>
</ng-container>
