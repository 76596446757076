<div class="super-container" [ngClass]="station.isFinalQC ? 'qcStation' : ''">
  <div class="container" (click)="setExpanded(!expanded)">
    <div class="left-container">
      <app-flat-icon
        *ngIf="!station.isFinalQC"
        [icon]="'fa fa-wrench'"
        size="59px"
        [iconSize]="isMobile ? '25px' : '30px'"
        color="var(--menuItem-text1)"
      >
      </app-flat-icon>
      <svg-icon
        *ngIf="station.isFinalQC"
        id="ribbon-svg"
        [src]="'assets/icon/ribbon.svg'"
        [stretch]="true"
        [svgStyle]="{
          'width.px': isMobile ? 25 : 30,
          'height.px': isMobile ? 30 : 35
        }"
      >
      </svg-icon>

      <div class="par">{{ station.name }}</div>

      <div *ngIf="station.isFinalQC" class="final-inspection-container">
        <div class="par" id="p-tag">Final inspection</div>
      </div>

      <app-round-button
        *ngIf="isMobile === false && !station.isFinalQC"
        (click)="toggleEditionModal(); $event.stopPropagation()"
        style="margin-left: 10px;"
        [icon]="'fas fa-edit'"
        [sizepx]="30"
        fontSize="16px"
        backgroundColor="transparent"
        iconColor="var(--menuItem-text1)"
        border="2px solid var(--menuItem-text1)"
        shadow="none"
      ></app-round-button>
    </div>

    <div class="right-container">
      <app-round-button
        *ngIf="isMobile && !station.isFinalQC"
        (click)="toggleEditionModal(); $event.stopPropagation()"
        style="margin-left: 10px;"
        [icon]="'fas fa-edit'"
        [sizepx]="30"
        fontSize="16px"
        backgroundColor="transparent"
        iconColor="var(--menuItem-text1)"
        border="2px solid var(--menuItem-text1)"
        shadow="none"
      ></app-round-button>
      <app-round-button
        *ngIf="!expanded"
        class="mr10px expand-btn"
        data-cy="toggle"
        [icon]="'fas fa-chevron-down'"
        [sizepx]="32"
        fontSize="17px"
        backgroundColor="transparent"
        iconColor="var(--menuItem-text1)"
        shadow="none"
      ></app-round-button>
      <app-round-button
        *ngIf="expanded"
        class="mr10px expand-btn"
        data-cy="toggle"
        [icon]="'fas fa-chevron-up'"
        [sizepx]="32"
        fontSize="17px"
        backgroundColor="transparent"
        iconColor="var(--menuItem-text1)"
        shadow="none"
      ></app-round-button>
    </div>
  </div>

  <div class="config-container" *ngIf="expanded">
    <app-work-instructions-station-configuration
      [stationId]="station.id"
      [isFinalQC]="station.isFinalQC"
      [configurations]="configurations"
    ></app-work-instructions-station-configuration>

    <div class="bottom-button-container">
      <div class="add-btn-container">
        <app-button
          [title]="'Add a new configuration'"
          [icon]="'fa fa-wrench'"
          [backgroundColor]="'var(--fab-unit)'"
          [fontColor]="'white'"
          [iconBackgroundColor]="'var(--fab-unit-dark)'"
          (click)="toggleCreateConfigModal()"
        >
        </app-button>
      </div>

      <div class="add-btn-container">
        <app-button
          *ngIf="!station.isFinalQC"
          [title]="'Delete station'"
          [icon]="'fa fa-trash'"
          [backgroundColor]="'var(--red)'"
          [fontColor]="'white'"
          [iconBackgroundColor]="'var(--red-dark)'"
          (click)="onDelete()"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>

<app-modal
  *ngIf="showDeleteModal"
  [confirmDelete]="true"
  header="Delete the station"
  headerIcon="fas fa-exclamation"
  (cancelEmitter)="onCancelDelete()"
  (confirmEmitter)="onConfirmDelete()"
>
  <div class="par" class="modal-content">
    {{ getContent(station.name) }}
  </div>
</app-modal>

<app-modal
  *ngIf="editMode"
  header="Name the station"
  headerIcon="fas fa-wrench"
  (cancelEmitter)="toggleEditionModal()"
  (confirmEmitter)="editName()"
>
  <input
    class="input-modal"
    type="text"
    placeholder="Enter a new name"
    [value]="station.name"
    (change)="onNameChange($event)"
  />
</app-modal>

<app-modal
  *ngIf="showCreateConfigModal"
  header="Name the station configuration"
  headerIcon="fas fa-clipboard-check"
  (cancelEmitter)="toggleCreateConfigModal()"
  (confirmEmitter)="addNewConfig(configName)"
>
  <input
    class="input-modal"
    type="text"
    placeholder="Enter a name"
    [value]="configName"
    (change)="onConfigNameChange($event)"
  />
</app-modal>
