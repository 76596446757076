<div id="wrapper" *ngIf="isAvailable">
  <div>
    <div id="title">A new version of the App is available</div>
    <div id="description">
      Updating the app will reload the page. Unsaved data will be lost.
    </div>
  </div>
  <app-oval-button
    (click)="updateApp()"
    text="Update the App"
  ></app-oval-button>
</div>
