import { Injectable } from '@angular/core';
import { StorageVersionStorageService } from './storage-version-storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageVersionService {
  runningVersion = '0.4';

  constructor(
    private storageVersionStorageService: StorageVersionStorageService
  ) {}

  isValid(): boolean {
    const storedVersion = this.storageVersionStorageService.get();
    return this.runningVersion === storedVersion;
  }

  set(): void {
    this.storageVersionStorageService.set(this.runningVersion);
  }
}
