import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepCollapseService {
  public expandedIndex = new Subject<number>();

  setExpandedIndex(index: number) {
    this.expandedIndex.next(index)
  }
}