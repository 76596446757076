<div id="box" [ngClass]="rotationActive()">
  <div id="content">
    <img id="logo-fabbrica-big" src="assets/fabbrica-logo-t-ret.png" alt="Fabbrica Logo" />
  </div>
  <div *ngIf="deviceType === 'mobile'" id="icon">
    <i class="fas fa-angle-right"></i>
  </div>
  <div *ngIf="deviceType !== 'mobile'" id="icon">
    <i class="fas fa-angle-left"></i>
  </div>
</div>
