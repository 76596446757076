import { Input, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StationsBackendService } from '../work-instructions-stations/services/checklist-station-backend.service';
import { StationConfigurationFrontendDto } from '../work-instructions-stations/interfaces/station.interface';
import { ToastService } from '../../../../shared/services/toast.service';
import { LanguageSelectorService } from '../../../../shared/components/work-instructions/services/language-selector.service';
import { DeviceService } from '../../../../shared/services/device.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-work-instructions-station-configuration',
  templateUrl: './work-instructions-station-configuration.component.html',
  styleUrls: ['./work-instructions-station-configuration.component.scss']
})
export class WorkInstructionsStationConfigurationComponent
  implements OnInit, OnDestroy {
  @Input() stationId: string;
  @Input() isFinalQC: boolean;
  @Input() configurations: any[] = [];
  showDeleteModal = false;
  selectedConfigId = '';
  configurationsLoading = false;
  subscriptions: Subscription[] = [];
  lang: string;
  isMobile = false;

  constructor(
    private router: Router,
    private stationsService: StationsBackendService,
    private toastrService: ToastService,
    private language: LanguageSelectorService,
    private deviceService: DeviceService
  ) {
    this.lang = language.get();
  }
  ngOnInit(): void {
    this.subscriptions.push(
      this.deviceService.isMobile.subscribe(newValue => {
        this.isMobile = newValue;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setShowDeleteModal(val: boolean, id: string = '') {
    this.showDeleteModal = val;
    if (id) {
      this.selectedConfigId = id;
    }
  }

  toggleExpanded(id: string) {
    this.showDeleteModal = false;
    this.configurations.forEach(config => {
      if (config.id === id) {
        config.expanded = !config.expanded;
      } else {
        config.expanded = false;
      }
    });
  }

  getContent() {
    const name = this.configurations.find(c => c.id === this.selectedConfigId)
      .name;
    return `Do you really want to delete the config "${name}"?`;
  }

  copyConfig(id: string) {
    this.showDeleteModal = false;
    this.configurationsLoading = true;
    this.subscriptions.push(
      this.stationsService.createConfigurationCopy(id).subscribe(data => {
        this.toastrService.success('Configuration successfully copied.');
        this.subscriptions.push(
          this.stationsService
            .getConfiguration(data.id, data.version)
            .subscribe(copy => {
              this.configurations.push({
                id: copy.id,
                name: copy.name,
                version: 0,
                expanded: false,
                steps: [...copy.steps]
              });
              this.configurationsLoading = false;
            })
        );
      })
    );
  }

  editConfig(id: string, version: number) {
    this.showDeleteModal = false;
    this.router.navigateByUrl(
      `/app/work-instructions/stations/station-configuration/${id}?version=${version}`
    );
  }

  deleteConfig() {
    this.showDeleteModal = false;
    this.configurationsLoading = true;
    this.subscriptions.push(
      this.stationsService
        .deleteConfiguration(this.selectedConfigId)
        .subscribe(success => {
          if (success) {
            this.toastrService.success('Configuration successfully deleted.');
            const ind = this.configurations.findIndex(
              c => c.id === this.selectedConfigId
            );
            this.configurations.splice(ind, 1);
            this.stationsService.configurations.next(this.configurations);
            this.configurationsLoading = false;
          }
        })
    );
  }
}
