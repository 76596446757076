import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StatFormatterService } from '../../../../shared/components/dashboard-charts/services/stat-formatter.service';
import { HeaderTitleService } from '../../services/header-title.service';
import { KpiManagerService } from './services/kpi-manager.service';

@Component({
  selector: 'app-kpi-target',
  templateUrl: './kpi-target.component.html',
  styleUrls: ['./kpi-target.component.scss']
})
export class KpiTargetComponent implements OnInit, OnDestroy {
  initDate: Date;
  endDate: Date;
  linesData: any[] = [];

  subscriptions: Subscription[] = [];

  getSimpleDate(date: Date | string) {
    if (typeof date === 'string') {
      return date.split('T')[0];
    }
    return date.toISOString().split('T')[0];
  }

  constructor(
    private headerService: HeaderTitleService,
    private kpiManagerService: KpiManagerService,
    private statFormatterService: StatFormatterService
  ) {
    headerService.setHeader('CHECKLIST MANAGER / KPI TARGET');
  }

  ngOnInit(): void {
    let today = new Date();
    this.initDate = new Date(today.setDate(today.getDate() - 7));
    this.endDate = new Date(today.setDate(today.getDate() + 14));
    const from = this.getSimpleDate(this.initDate);
    const to = this.getSimpleDate(this.endDate);
    const dates = this.statFormatterService.getDatesInRange(
      this.initDate,
      this.endDate
    );

    this.subscriptions.push(
      this.kpiManagerService.getAllLines(true, from, to).subscribe(data => {
        const newLineData: any[] = [];
        if (data) {
          data.forEach(line => {
            const goals = this.setGoalsForLine(line, dates);
            const processedUnits = this.setProcessedUnitsForLine(line, dates);

            newLineData.push({
              lineNumber: line.lineNumber,
              name: line.description,
              goals: goals,
              processedUnits: processedUnits,
              labels: dates.map(d => this.getSimpleDate(d))
            });
          });
        }

        this.linesData = newLineData;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setDates(dates: Date[]) {
    this.initDate = dates[0];
    this.endDate = dates[1];

    if (this.initDate && this.endDate) {
      const from = this.getSimpleDate(this.initDate);
      const to = this.getSimpleDate(this.endDate);
      const dates = this.statFormatterService.getDatesInRange(
        this.initDate,
        this.endDate
      );

      this.subscriptions.push(
        this.kpiManagerService.getAllLines(true, from, to).subscribe(data => {
          const newLineData: any[] = [];
          if (data) {
            data.forEach(line => {
              const goals = this.setGoalsForLine(line, dates);
              const processedUnits = this.setProcessedUnitsForLine(line, dates);

              newLineData.push({
                lineNumber: line.lineNumber,
                name: line.description,
                goals: goals,
                processedUnits: processedUnits,
                labels: dates.map(d => this.getSimpleDate(d))
              });
            });
          }

          this.linesData = [];
          setTimeout(() => (this.linesData = newLineData), 0);
        })
      );
    }
  }

  setGoalsForLine(line: any, dates: any[]): number[] {
    const goals = new Array(dates.length).fill(0);
    line.goals.forEach(goal => {
      const i = dates.findIndex(
        d => this.getSimpleDate(d) === this.getSimpleDate(goal.date)
      );
      goals[i] = goal.goalValue;
    });
    return goals;
  }

  setProcessedUnitsForLine(line: any, dates: any[]): number[] {
    const processedUnits = new Array(dates.length).fill(NaN);
    line.processedUnits.forEach(unit => {
      if (unit) {
        const i = dates.findIndex(
          d => this.getSimpleDate(d) === this.getSimpleDate(unit)
        );
        if (isNaN(processedUnits[i])) {
          processedUnits[i] = 1;
        } else {
          processedUnits[i] += 1;
        }
      }
    });
    return processedUnits;
  }

  onGoalChanged(e: any, lineNumber: number) {
    const goalValue = e.value;
    const goalDate = e.date;

    this.subscriptions.push(
      this.kpiManagerService
        .createGoal(lineNumber, goalValue, goalDate)
        .subscribe()
    );
  }
}
