<div id="top-buttons">
  <div id="left-buttons">
    <app-round-button
      id="excel-report-button"
      [isActive]="hasReceivedDataForEveryProject"
      data-cy="smr-export-button"
      sizepx="42"
      fontSize="21px"
      [icon]="'far fa-file-excel'"
      (click)="getExcelReport()"
      [backgroundColor]="hasReceivedDataForEveryProject ? '' : 'var(--menuItem-border1)'"
    ></app-round-button>
    <app-smr-intranet-viewer-button *ngIf="isInIframe"></app-smr-intranet-viewer-button>
  </div>
  <app-loading-cog *ngIf="!hasReceivedDataForEveryProject"></app-loading-cog>
  <app-smr-range-selector
    [currentRange]="currentRange"
    (currentRangeChange)="rangeChanged($event)"
  ></app-smr-range-selector>
</div>

<br />

<div id="sticky-wrapper" #stickyWrapper>
  <app-smr-calendar-header
    [startDate]="currentRange?.startDate"
    [displayRange]="currentRange?.dayRange"
    style="box-shadow: var(--box-shadow);"
  >
    <app-smr-calendar
      class="no-content"
      unit="month"
      show-labels
      [showLabels]="true"
      [startDate]="currentRange?.startDate"
      [displayRange]="currentRange?.dayRange"
      [data]="months"
      appDateHover
    ></app-smr-calendar>
    <app-smr-calendar
      class="no-content"
      [showLabels]="true"
      [startDate]="currentRange?.startDate"
      [displayRange]="currentRange?.dayRange"
      [data]="weeks"
      appDateHover
    ></app-smr-calendar>
  </app-smr-calendar-header>
  <app-smr-calendar-controls (stepBackward)="stepBackward()" (stepForward)="stepForward()"></app-smr-calendar-controls>
</div>

<div class="spacing"></div>
<div *ngFor="let dataInProject of dataArray">
  <app-smr-project
    [isOpen]="dataArray.length === 1"
    [currentRange]="currentRange"
    [weeks]="weeks"
    [dataInProject]="dataInProject"
  ></app-smr-project>
  <div class="spacing"></div>
</div>

<app-smr-date-marker
  [startDate]="currentRange?.startDate"
  [displayRange]="currentRange?.dayRange"
  [currentDate]="true"
  [class.sticky]="isSticky"
  appDateHoveredArea
></app-smr-date-marker>
<app-smr-date-marker
  [date]="hoveredDate"
  [startDate]="currentRange?.startDate"
  [displayRange]="currentRange?.dayRange"
  [class.sticky]="isSticky"
></app-smr-date-marker>
