import {
  Component,
  ContentChild,
  OnInit,
  TemplateRef,
  Input,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ScreenType } from '../../enums/screen-type.enum';
import { DeviceService } from '../../services/device.service';
import { ScreenSizeService } from '../../services/screen-size.service';
import { ToggleMenuServiceService } from '../../services/toggle-menu-service.service';

@Component({
  selector: 'app-doc-head',
  templateUrl: './doc-head.component.html',
  styleUrls: ['./doc-head.component.scss']
})
export class DocHeadComponent implements OnInit, OnDestroy {
  @Input() name = '';
  @Input() description = '';
  @Input() size: 'small' | 'medium' = 'medium';

  isMobile = false;
  screenType: ScreenType;
  ScreenTypeEnum = ScreenType;
  subscriptions: Subscription[] = [];

  @ContentChild('rightButtonTemplateRef', { static: false })
  rightButtonTemplateRef!: TemplateRef<any>;

  constructor(
    private screenSizeService: ScreenSizeService,
    private toggleMenuService: ToggleMenuServiceService,
    private deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.screenSizeService.screenTypeSubject.subscribe(
        (newScreenType: ScreenType) => (this.screenType = newScreenType)
      )
    );
    this.subscriptions.push(
      this.deviceService.isMobile.subscribe(
        newValue => (this.isMobile = newValue)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  clickOnSticky() {
    this.toggleMenuService.toggle();
  }
}
