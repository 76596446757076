import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { Subscription } from 'rxjs';
import { ClickOnScreenService } from '../../services/click-on-screen.service';
import { isSameDay } from 'date-fns';

@Component({
  selector: 'app-calendar-date',
  templateUrl: './calendar-date.component.html',
  styleUrls: ['./calendar-date.component.scss']
})
export class CalendarDateComponent implements OnInit, OnDestroy {
  @Input() selectedDate: Date;
  @Input() endDate: Date | null;
  @Output() filterChange = new EventEmitter<Date | null>();

  flatpickrInstance: Instance;
  isSelectOpen = false;
  subscriptions: Subscription[] = [];

  constructor(private clickOnScreenService: ClickOnScreenService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.clickOnScreenService.clickOnScreenEvent.subscribe(() => {
        if (this.isSelectOpen) {
          this.isSelectOpen = false;
        }
      })
    );
    this.initDateSelect();

    const selectedDate = this.selectedDate;

    this.flatpickrInstance.setDate(selectedDate);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private initDateSelect() {
    const myInput = document.querySelector('.calendar-select-container');
    this.flatpickrInstance = flatpickr(myInput, {
      static: true,
      onClose: this.onFlatpickrClose.bind(this)
    });
  }
  onFlatpickrClose() {
    // useful to avoid making a request on each date change.
    const [selectedDate] = this.flatpickrInstance.selectedDates;
    if (!isSameDay(this.selectedDate, selectedDate)) {
      this.selectedDate = selectedDate;
      this.filterChange.emit(this.selectedDate);
    }
  }
}
