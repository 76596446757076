<div class="container" *ngIf="indicatorsData">
  <app-kpi-indicator
    *ngFor="let item of indicatorsData"
    [text]="item.text"
    [count]="item.count"
    [uprCount]="item.uprCount"
    [total]="item.total"
    [color]="item.color"
  ></app-kpi-indicator>
</div>
<app-machine-timers-indicator-manager>
  
</app-machine-timers-indicator-manager>