import { Data } from '@angular/router';
import { Injectable } from '@angular/core';

import { Functionality } from '../enums/functionality.enum';
import { User } from '../models/user';
import { BypassUserManagementService } from './bypass-user-management.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor(private bypassService: BypassUserManagementService) {}

  private uiAllowlist: Map<string, Functionality[]> = this.getUiAllowlist();

  canActivate(user: User, data: Data): boolean {
    // If no "functionalities" data is defined on the route then the route is accessible to everybody
    return data && data.functionalities
      ? this.isAllowed(user, data.functionalities)
      : true;
  }

  isVisible(user: User, uiId: string): boolean {
    const allowlist = this.uiAllowlist.get(uiId) || [];
    return this.isAllowed(user, allowlist);
  }

  private isAllowed(user: User, allowlist: Functionality[]): boolean {
    if (this.bypassService.bypass.getValue()) {
      return true;
    } else {
      if (!allowlist || !Array.isArray(allowlist)) {
        return false;
      }

      for (const functionality of allowlist) {
        if (user.functionalities.includes(functionality)) {
          return true;
        }
      }
      return false;
    }
  }

  private getUiAllowlist(): Map<string, Functionality[]> {
    return new Map([
      ['report.btn', [Functionality.TEST] as Functionality[]],
      ['report.section.btn', [Functionality.TEST] as Functionality[]],
      [
        'sidemenu.station.tab',
        [Functionality['Unit - Work instructions - Set']] as Functionality[]
      ],
      [
        'checkparts.panel',
        [Functionality['Unit - Check parts - Set']] as Functionality[]
      ],
      [
        'checkparts.topsection',
        [Functionality['Unit - Check parts - Set']] as Functionality[]
      ],
      ['menu.report', [Functionality.Report] as Functionality[]],
      [
        'menu.workinstructions',
        [Functionality['Work instructions management']] as Functionality[]
      ],
      [
        'unit.workinstructions.stepconformity',
        [Functionality['Unit - Work instructions - Set']] as Functionality[]
      ],
      [
        'productiondashboard.tab',
        [Functionality.Planification] as Functionality[]
      ],
      [
        'home.project.section',
        [Functionality.Planification] as Functionality[]
      ],
      ['smr.manager', [Functionality['SMR Manager']] as Functionality[]],
      [
        'fabrication',
        [Functionality['Work instructions management']] as Functionality[]
      ],
      [
        'productionUnits.installationDatesUpdater',
        [Functionality['SMR Manager']] as Functionality[]
      ],
      [
        'machiningEntry.admin',
        [Functionality['Machining Entry Admin']] as Functionality[]
      ],
      [
        'machiningEntry.access',
        [Functionality['Machining Entry Access']] as Functionality[]
      ]
    ]);
  }
}
