export enum StorageKey {
  SESSION = 'SESSION',
  BOM = 'BOM',
  ACTUALCOMP = 'ACTUAL_COMP',
  CHECKPARTLIST = 'CHECK_PART_LIST',
  CHECKPARTOLD = 'CHECK_PART_OLD',
  LASTCOMPONENTLIST = 'LAST_COMPONENT_LIST',
  FULLSCREEN = 'FULL_SCREEN',
  PDFURL = 'PDF_URL',
  SUBMENU = 'SUB_MENU',
  TOKEN = 'TOKEN',
  USER = 'USER',
  STORAGEVERSION = 'STORAGE_VERSION',
  IFRAME_TOKEN = 'iframe_token',
  IFRAME_PROJECTS_ID = 'iframe_projectsId',
  USERLANGUAGE = 'userLanguage',
  USERSTATION = 'userStation',
  USERLINE = 'userLine',
  REACHED_GOALS = 'reachedGoals',
  VIBRATIONMODE = 'vibration_mode',
  LASTVIEWEDUNITS_OFFLINE = 'LAST_VIEWED_UNITS_OFFLINE',
  OFFLINEUNITSFORPROJECT = 'OFFLINE_UNITS_FOR_PROJECT',
  UNITSPENDINGSYNC = 'UNITS_PENDING_SYNC'
}
