<app-round-button
  data-cy="open-calendar"
  sizepx="42"
  fontSize="21px"
  [icon]="'far fa-calendar-alt'"
  class="picker"
></app-round-button>
<app-multi-radio-button
  [buttonsInnerText]="getButtonsInnerText()"
  [(selectButton)]="buttonSelected"
  (selectButtonChange)="updateRangeFromButtons($event)"
></app-multi-radio-button>
