import { Inject, Injectable } from '@angular/core';
import { InputTypeEnum } from '../enums/input-type.enum';
import { QrValidator } from '../interfaces/qr-validator.interface';
import { QR_VALIDATOR_TOKEN } from '../tokens/qr-validator.token';


@Injectable({
  providedIn: 'root'
})
export class InputTypeService {
  inputTypes: InputType[] = [
    {
      inputType: InputTypeEnum.RFIDLABEL,
      isValid: str => this.rfidRegex.test(str)
    },
    {
      inputType: InputTypeEnum.FRAMESERIALNUMBER,
      isValid: str => this.snRegex.test(str)
    },
    {
      inputType: InputTypeEnum.QRCODE,
      isValid: str => this.qrValidator.isValidLabel(str)
    },
    {
      inputType: InputTypeEnum.STRING,
      isValid: str => true
    }
  ];

  rfidRegex = new RegExp('^[0-9a-zA-Z]{24}$');
  snRegex = new RegExp('^[0-9]+$');
  constructor(@Inject(QR_VALIDATOR_TOKEN) private qrValidator: QrValidator) {}

  getInputType(str: string): InputTypeEnum {
    for (const it of this.inputTypes) {
      if (it.isValid(str)) {
        return it.inputType;
      }
    }
    return InputTypeEnum.UNKNOWN;
  }
}

interface InputType {
  inputType: InputTypeEnum;
  isValid: (arg0: string) => boolean;
}
