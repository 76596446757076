import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeviceService } from '../../../../shared/services/device.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { HeaderTitleService } from '../../services/header-title.service';
import { StationsBackendService } from '../work-instructions-stations/services/checklist-station-backend.service';
import { FrontedLineDto } from './interfaces/line.interface';
import { LinesBackendService } from './services/lines-backend.service';

@Component({
  selector: 'app-work-instructions-lines',
  templateUrl: './work-instructions-lines.component.html',
  styleUrls: ['./work-instructions-lines.component.scss']
})
export class WorkInstructionsLinesComponent implements OnInit, OnDestroy {
  lines: FrontedLineDto[] = [];

  selectedLineId = 'new-template';
  loading = true;
  lineName = '';
  showNewLineModal = false;
  showDeleteModal = false;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private toastrService: ToastService,
    private linesManagerService: LinesBackendService,
    private stationsService: StationsBackendService,
    public deviceService: DeviceService,
    private headerService: HeaderTitleService
  ) {
    headerService.setHeader('CHECKLIST MANAGER / LINE CONFIGURATIONS');
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.linesManagerService.lines.subscribe(data => {
        if (data) {
          this.lines = Array(data.length);
          let count = 0;
          this.subscriptions.push(
            this.stationsService
              .getStationsWithConfigurations()
              .subscribe(stationsConfig => {
                data.forEach((line, index) => {
                  this.subscriptions.push(
                    this.linesManagerService
                      .getLineById(line.id, line.version)
                      .subscribe(lineDetail => {
                        const statConf = [];
                        lineDetail.stationsWithUsers.forEach(station => {
                          stationsConfig.forEach(s => {
                            s.configurations.forEach(c => {
                              if (c.id === station.stationConfiguration) {
                                statConf.push(`${s.name} / ${c.name}`);
                              }
                            });
                          });
                        });
                        count++;
                        this.lines[index] = {
                          id: line.id,
                          name: line.name,
                          version: line.version,
                          createdBy: line.createdBy,
                          creationDate: line.creationDate,
                          stationsWithUsers: line.stationsWithUsers,
                          stationConfigurations: statConf,
                          expanded: false
                        };
                        this.loading = count !== data.length;
                      })
                  );
                });
              })
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleExpand(id: string) {
    this.lines.forEach(l => {
      if (id === l.id) {
        l.expanded = !l.expanded;
      } else {
        l.expanded = false;
      }
    });
  }

  setDelete(val: boolean, id: string = '') {
    this.showDeleteModal = val;
    this.selectedLineId = id;
  }

  getContent(): string {
    const name = this.lines.find(x => x.id === this.selectedLineId).name;
    return `Do you really want to delete the line "${name}"?`;
  }

  setCurrentLine(lineId: string, version: number) {
    this.router.navigateByUrl(
      `/app/work-instructions/lines/${lineId}?version=${version}`
    );
  }

  toggleNewLineModal() {
    this.showNewLineModal = !this.showNewLineModal;
  }

  onNameChange(e: any) {
    this.lineName = e.target.value;
  }

  addLine() {
    this.toggleNewLineModal();
    this.loading = true;
    this.subscriptions.push(
      this.linesManagerService
        .createNewLine(this.lineName)
        .subscribe(newLine => {
          if (newLine) {
            this.lines.push(newLine);
            this.linesManagerService.lines.next(this.lines);
            this.toastrService.success('Line created successfully');
          }
        })
    );
  }

  copyLine(lineId: string) {
    this.loading = true;
    this.subscriptions.push(
      this.linesManagerService.copyLine(lineId).subscribe(newLine => {
        this.lines.push(newLine);
        this.linesManagerService.lines.next(this.lines);
        this.toastrService.success('Line copied successfully');
        this.loading = false;
      })
    );
  }

  deleteLine(id: string) {
    this.showDeleteModal = false;
    this.loading = true;
    this.subscriptions.push(
      this.linesManagerService.deleteAnExistingLine(id).subscribe(success => {
        if (success) {
          this.toastrService.success('Line deleted successfully');
          const ind = this.lines.findIndex(x => x.id === this.selectedLineId);
          this.lines.splice(ind, 1);
          this.linesManagerService.lines.next(this.lines);
        }
      })
    );
  }
}
