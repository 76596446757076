import { Functionality } from '../enums/functionality.enum';

export class User {
  name: string;
  functionalities: Functionality[];

  get initials(): string {
    const index = Math.min(this.name.length, 2);
    return this.name.slice(0, index);
  }
  constructor({ name, functionalities }: { name: string; functionalities: string[] }) {
    this.name = name.toLowerCase();
    this.functionalities = functionalities.map(f => Functionality[f]);
  }
}
