import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { WorkInstructionsManagerRoutingModule } from './work-instructions-manager-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkInstructionsManagerComponent } from './work-instructions-manager.component';
import { WorkInstructionsLinksComponent } from './components/work-instructions-links/work-instructions-links.component';
import { WorkInstructionsStationsComponent } from './components/work-instructions-stations/work-instructions-stations.component';
import { WorkInstructionsStationComponent } from './components/work-instructions-stations/components/work-instructions-station/work-instructions-station.component';
import { WorkInstructionsLinesComponent } from './components/work-instructions-lines/work-instructions-lines.component';
import { LineConfigurationBodyComponent } from './components/work-instructions-lines/components/line-configuration-body/line-configuration-body.component';
import { LinkerComponent } from './components/work-instructions-links/components/linker/linker.component';
import { WorkInstructionsStationConfigurationComponent } from './components/work-instructions-station-configuration/work-instructions-station-configuration.component';
import { StationConfigurationBodyComponent } from './components/work-instructions-station-configuration/components/station-configuration-body/station-configuration-body.component';
// tslint:disable-next-line
import { LineConfigurationComponent } from './components/work-instructions-lines/components/line-configuration/line-configuration.component';
import { UserTagComponent } from './components/work-instructions-lines/components/user-tag/user-tag.component';
import { WorkInstructionsFrameImportComponent } from './components/work-instructions-frame-import/work-instructions-frame-import.component';
import { UploadButtonComponent } from './components/work-instructions-frame-import/components/upload-button/upload-button.component';
import { DragAndDropFilesDirective } from './components/work-instructions-frame-import/directives/drag-and-drop-files.directive';
import { KpiTargetComponent } from './components/kpi-target/kpi-target.component';
import { DraggableChartComponent } from './components/kpi-target/components/draggable-chart/draggable-chart.component';
import { KpiIndicatorsManagerComponent } from './components/kpi-target/components/kpi-indicators-manager/kpi-indicators-manager.component';
import { KpiIndicatorComponent } from './components/kpi-target/components/kpi-indicator/kpi-indicator.component';

@NgModule({
  declarations: [
    WorkInstructionsManagerComponent,
    WorkInstructionsLinksComponent,
    WorkInstructionsStationsComponent,
    WorkInstructionsStationComponent,
    WorkInstructionsLinesComponent,
    LineConfigurationBodyComponent,
    LinkerComponent,
    WorkInstructionsStationConfigurationComponent,
    StationConfigurationBodyComponent,
    LineConfigurationComponent,
    UserTagComponent,
    WorkInstructionsFrameImportComponent,
    UploadButtonComponent,
    DragAndDropFilesDirective,
    KpiTargetComponent,
    DraggableChartComponent,
    KpiIndicatorsManagerComponent,
    KpiIndicatorComponent
  ],
  imports: [
    AngularSvgIconModule,
    DragDropModule,
    CommonModule,
    SharedModule,
    WorkInstructionsManagerRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [KpiIndicatorsManagerComponent]
})
export class WorkInstructionsManagerModule {}
