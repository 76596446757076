import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SmrSelectionService } from '../../services/smr-selection.service';

@Component({
  selector: 'app-smr-selector',
  templateUrl: './smr-selector.component.html',
  styleUrls: ['./smr-selector.component.scss']
})
export class SmrSelectorComponent implements OnInit, OnDestroy {
  @Input() smrId: number;
  isSelected: boolean;
  sub: Subscription;
  constructor(private readonly smrSelectionService: SmrSelectionService) {
    this.isSelected = false;
  }

  ngOnInit(): void {
    this.updateSelectedStatusFromService();
    this.sub = this.smrSelectionService.selectionListSubject.subscribe(() => {
      this.updateSelectedStatusFromService();
    });
  }

  updateSelectedStatusFromService() {
    this.isSelected = this.smrSelectionService.isSmrSelected(this.smrId);
  }

  toggle(e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    if (this.smrId) {
      this.smrSelectionService.selectionToggle(this.smrId);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
