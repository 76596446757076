import { Component, OnDestroy, OnInit } from '@angular/core';

import { WorkInstructionsStationDto } from './interfaces/station.interface';
import { EditionEnablingService } from './services/edition-enabling.service';
import { Subscription } from 'rxjs';
import { ToastService } from '../../../../shared/services/toast.service';
import { StationsBackendService } from './services/checklist-station-backend.service';
import { HeaderTitleService } from '../../services/header-title.service';
import { StationSelectorService } from '../../../../shared/components/work-instructions/services/station-selector.service';

@Component({
  selector: 'app-work-instructions-stations',
  templateUrl: './work-instructions-stations.component.html',
  styleUrls: ['./work-instructions-stations.component.scss']
})
export class WorkInstructionsStationsComponent implements OnInit, OnDestroy {
  stations: WorkInstructionsStationDto[] = [];
  newStationName = '';
  editing = false;
  activeAddBtn = false;
  loading = true;

  newStationModal = false;

  subscriptions: Subscription[] = [];

  constructor(
    private editionEnablingService: EditionEnablingService,
    private stationsService: StationsBackendService,
    private toastrService: ToastService,
    private headerService: HeaderTitleService
  ) {
    headerService.setHeader('CHECKLIST MANAGER / STATIONS');
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.editionEnablingService.editing.subscribe(editing => {
        this.editing = editing;
        this.newStationName = '';
      })
    );

    this.loading = true;
    this.subscriptions.push(
      this.stationsService.stations.subscribe(data => {
        if (data) {
          this.stations = data;
          this.loading = false;
        }
      })
    );
  }

  setAddBtn(val: boolean) {
    this.activeAddBtn = val;
  }

  toggleShowModal() {
    this.newStationModal = !this.newStationModal;
  }

  updateStation(station: WorkInstructionsStationDto) {
    this.subscriptions.push(
      this.stationsService.updateStation(station).subscribe(success => {
        if (success) {
          const index = this.stations.findIndex(x => x.id === station.id);
          this.stations[index] = station;
          this.stationsService.stations.next(this.stations);
          this.toastrService.success('Station updated successfully');
        }
      })
    );
  }

  deleteStation(station: WorkInstructionsStationDto) {
    this.loading = true;
    this.subscriptions.push(
      this.stationsService
        .deleteAnExistingStation(station)
        .subscribe(success => {
          if (success) {
            const index = this.stations.findIndex(x => x.id === station.id);
            this.stations.splice(index, 1);
            this.stationsService.stations.next(this.stations);
            this.toastrService.success('Station deleted successfully');
            this.loading = false;
          }
        })
    );
  }

  onNewStationNameChange(e: any) {
    this.newStationName = e.target.value;
  }

  addStation() {
    this.newStationModal = false;
    if (this.newStationName) {
      this.loading = true;
      this.subscriptions.push(
        this.stationsService
          .createStation(this.newStationName)
          .subscribe(data => {
            if (data) {
              const tempList = this.stations;
              this.stations = tempList.filter(s => !s.isFinalQC);
              this.stations.push(data);
              this.stations.push(tempList.filter(s => s.isFinalQC)[0]);

              this.stationsService.stations.next(this.stations);
              this.newStationName = '';
              this.loading = false;
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
