import { Injectable } from '@angular/core';

import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { Observable, BehaviorSubject, from, of } from 'rxjs';

import SmrProgressDataMock from '../mocks/smr-progress-data.mock.json';
import SmrProgressDataPartialMock from '../mocks/smr-progress-data.partial.mock.json';

import { BackendService } from '../../../../shared/services/backend.service';
import { SmrProgressData, SmrProgressInProjectLot } from '../interfaces/smr-progress-data.interface';
import { SmrProgressDataApi } from '../interfaces/smr-progress-data.api.interface';

@Injectable()
export class SmrProgressBackendService {
  calendarRect: DOMRect;
  dateHover = new BehaviorSubject<Date>(null);
  displayRange: number;
  startDate: Date;

  smrDataArraySubject: BehaviorSubject<SmrProgressInProjectLot[]> = new BehaviorSubject<SmrProgressInProjectLot[]>([]);

  constructor(private readonly backend: BackendService) {}

  getDataForMultipleProjects(projectsId: Array<number>): Observable<SmrProgressInProjectLot[]> {
    return from(projectsId).pipe(concatMap(projectId => this.getDataForOneProject(projectId)));
  }

  getDataForOneProject(projectId: number): Observable<SmrProgressInProjectLot[]> {
    return from(['smr-planification', 'smr-progress']).pipe(
      mergeMap((path: string, index: number) => this.backend.get(`${path}?project=${projectId}`)),
      map((data: SmrProgressDataApi) => {
        const dataInProjectsLots = data.smrProgressDataPerLot.map(smrProgressData => {
          const dataInLot: SmrProgressInProjectLot = {
            projectLotsTuple: {
              projectId,
              lots: smrProgressData.lots.map(lotApi => lotApi.displays),
              referenceFile: smrProgressData.fileName
            },
            data: new SmrProgressData(smrProgressData)
          };
          return dataInLot;
        });
        return dataInProjectsLots;
      }),
      catchError(() => {
        const dataInProjectsLots: SmrProgressInProjectLot[] = [
          {
            projectLotsTuple: { projectId, lots: [], referenceFile: null },
            data: null
          }
        ];
        return of(dataInProjectsLots);
      })
    );
  }
}
