<ng-container *ngIf="title && links.children.length > 0">
  <app-edition-header
    [title]="title"
    [backRoute]="'app/work-instructions/links'"
    (saved)="onSave()"
  >
  </app-edition-header>

  <div *ngIf="(deviceService.isMobile | async) === false" class="header">
    <p>Lots (WBS)</p>
    <p style="margin-left: 200px;">Line Configuration Description</p>
  </div>

  <div *ngIf="deviceService.isMobile | async" class="header">
    <p>Lots (WBS)/Line Configuration Description</p>
  </div>

  <ng-container>
    <div *ngFor="let item of links.children">
      <app-recursive-linker
        *ngIf="links && !loadingLinks"
        [recursiveItem]="item"
        [templates]="possibleLines"
        [allTemplates]="allPossibleLines"
        [depth]="1"
        (itemChanged)="detectChanges()"
      ></app-recursive-linker>
    </div>
  </ng-container>

  <div *ngIf="links.children.length <= 0">NOTHING TO DISPLAY</div>
</ng-container>

<div class="loading-container" *ngIf="!title">
  <app-loading-cog></app-loading-cog>
</div>

<div *ngIf="links?.children.length === 0" class="no-content">
  <p>NO DATA TO DISPLAY</p>
  <app-button
    routerLink="../"
    [title]="'Go Back'"
    [icon]="'fa fa-arrow-left'"
    [iconSize]="'26px'"
    [size]="'22px'"
  ></app-button>
</div>
