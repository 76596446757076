import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { apiFormatter } from '../utils/api.utils';
import { map } from 'rxjs/operators';

@Injectable()
export class BackendService {
  private backendURI: string;
  constructor(private http: HttpClient) {
    this.backendURI = this.initBackendURI();
  }

  get(
    uri: string,
    options?: { headers?: HttpHeaders; responseType?, params?: HttpParams },
    format = true
  ): Observable<any> {
    const url = `${this.backendURI}/${uri}`;
    if (format) {
      return this.http.get(url, options).pipe(map(data => apiFormatter(data)));
    }
    return this.http.get(url, options);
  }

  post(
    uri: string,
    body: any,
    options?: { headers?: HttpHeaders; responseType? },
    format = true
  ): Observable<any> {
    const url = `${this.backendURI}/${uri}`;
    if (format) {
      return this.http
        .post(url, body, options)
        .pipe(map(data => apiFormatter(data)));
    }
    return this.http.post(url, body, options);
  }

  put(
    uri: string,
    id: string,
    body: any,
    options?: { headers: HttpHeaders }
  ): Observable<any> {
    const url = `${this.backendURI}/${uri}/${id}`;
    return this.http
      .put(url, body, options)
      .pipe(map(data => apiFormatter(data)));
  }

  delete(
    uri: string,
    id?: string,
    options?: { headers: HttpHeaders }
  ): Observable<any> {
    let url = `${this.backendURI}/${uri}`;
    if (id) {
      url += `/${id}`;
    }
    return this.http.delete(url, options).pipe(map(data => apiFormatter(data)));
  }

  private initBackendURI() {
    if (
      !environment.configuration ||
      !environment.configuration.backend_scheme ||
      !environment.configuration.backend_url
    ) {
      throw new Error('Backend configuration info missing');
    }

    let uri = `${environment.configuration.backend_scheme}${environment.configuration.backend_url}`;
    if (environment.configuration.backend_port) {
      uri += `:${environment.configuration.backend_port}`;
    }
    if (environment.configuration.backend_version) {
      uri += `/${environment.configuration.backend_version}`;
    }
    return `${uri}/api`;
  }
}
