import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SelectValue } from '../../../../../../shared/components/modal-select/interfaces/select-value';
import { ModalLockService } from '../../../../../../shared/services/modal-lock.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { EditionService } from '../../../../services/edition.service';
import { HeaderTitleService } from '../../../../services/header-title.service';
import { StationsBackendService } from '../../../work-instructions-stations/services/checklist-station-backend.service';
import { LineStationUserService } from '../../services/line-station-user.service';
import { LinesBackendService } from '../../services/lines-backend.service';

@Component({
  selector: 'app-line-configuration',
  templateUrl: './line-configuration.component.html',
  styleUrls: ['./line-configuration.component.scss']
})
export class LineConfigurationComponent implements OnInit, OnDestroy {
  unsavedChanges = false;

  editing = false;
  loading = true;
  stationSelectModal = false;
  newName = '';
  currentName = '';
  id = '';

  subscriptions: Subscription[] = [];

  stationsSelected: any[] = [];
  qcStation = null;
  possibleStations: SelectValue[] = [];

  stations = [];

  configurationsMap = new Map();

  configurations: any[] = [];
  possibleConfigurations: SelectValue[] = [];

  constructor(
    private linesService: LinesBackendService,
    private stationsService: StationsBackendService,
    private usersService: LineStationUserService,
    private toastrService: ToastService,
    private activeRoute: ActivatedRoute,
    private headerService: HeaderTitleService,
    public editionService: EditionService,
    public modalLockService: ModalLockService
  ) {}

  ngOnDestroy() {
    this.editionService.showEditionButton.next(false);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.loading = true;
    this.id = this.activeRoute.snapshot.params.id;
    const version = this.activeRoute.snapshot.queryParams.version;

    this.subscriptions.push(
      this.linesService.getLineById(this.id, version).subscribe(lineData => {
        if (lineData) {
          this.headerService.setHeaderAndSubheader(
            'LINE CONFIGURATION$' + lineData.name
          );
          this.editionService.showEditionButton.next(true);
          this.newName = lineData.name;
          this.currentName = lineData.name;
          this.stations = lineData.stationsWithUsers;
          this.subscriptions.push(
            this.stationsService
              .getStationsWithConfigurations()
              .subscribe(stationsData => {
                if (stationsData) {
                  this.stations.forEach(station => {
                    const currentStation = stationsData.find(s =>
                      s.configurations.some(
                        c => c.id === station.stationConfiguration
                      )
                    );
                    if (!currentStation.isFinalQC) {
                      this.stationsSelected.push({
                        id: currentStation.id,
                        name: currentStation.name,
                        isFinalQC: currentStation.isFinalQC,
                        config: currentStation.configurations.find(
                          c => c.id === station.stationConfiguration
                        ),
                        possibleConfigurations: currentStation.configurations,
                        users: station.users,
                        groups: station.groups
                      });
                    } else {
                      this.qcStation = {
                        id: currentStation.id,
                        name: currentStation.name,
                        isFinalQC: currentStation.isFinalQC,
                        config: currentStation.configurations.find(
                          c => c.id === station.stationConfiguration
                        ),
                        possibleConfigurations: currentStation.configurations,
                        users: station.users,
                        groups: station.groups
                      };
                    }
                  });
                  this.loading =
                    this.stationsSelected.length + 1 !==
                    lineData.stationsWithUsers.length;
                  stationsData.forEach(s => {
                    this.configurationsMap.set(s.id, s.configurations);
                    if (!s.isFinalQC) {
                      this.possibleStations.push({ id: s.id, label: s.name });
                    }
                  });
                }
              })
          );
        }
      })
    );
  }

  setEditing(val: boolean) {
    this.editing = val;
  }

  changeName(e: any) {
    this.newName = e.target.value;
  }

  setStationSelectModal(val: boolean) {
    this.stationSelectModal = val;
  }

  closeEditionModal() {
    this.newName = this.currentName;
    this.editionService.showEditionModal.next(false);
  }

  renameLine() {
    this.detectChanges();
    this.currentName = this.newName;
    this.headerService.setHeaderAndSubheader(
      'LINE CONFIGURATION$' + this.currentName
    );
    this.editionService.showEditionModal.next(false);
  }

  detectChanges() {
    this.unsavedChanges = true;
  }

  addStation(selectedValues: SelectValue[]) {
    this.detectChanges();
    const selected = selectedValues[0];
    this.stationsSelected.push({
      id: selected.id,
      name: selected.label,
      config: this.configurationsMap.get(selected.id)[0],
      possibleConfigurations: this.configurationsMap.get(selected.id),
      users: [],
      groups: []
    });
  }

  removeStation(removedStation: string) {
    this.detectChanges();
    const index = this.stationsSelected.findIndex(s => s.id === removedStation);
    this.stationsSelected.splice(index, 1);
  }

  onSave() {
    this.unsavedChanges = false;
    const formattedStations = this.stationsSelected.map(station => {
      return {
        StationConfiguration: station.config.id,
        Users: station.users.map(u => u.id),
        Groups: station.groups.map(g => g.id)
      };
    });
    const body = {
      Name: this.currentName,
      StationsWithUsers: formattedStations
    };
    body.StationsWithUsers.push({
      StationConfiguration: this.qcStation.config.id,
      Users: this.qcStation.users.map(u => u.id),
      Groups: this.qcStation.groups.map(g => g.id)
    });
    this.subscriptions.push(
      this.linesService.updateLine(this.id, body).subscribe(success => {
        if (success) {
          this.toastrService.success('Line edited successfully');
          this.linesService.getAll();
        }
      })
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    this.detectChanges();
    moveItemInArray(
      this.stationsSelected,
      event.previousIndex,
      event.currentIndex
    );
  }
}
