import { Component, Input, OnChanges } from '@angular/core';
import { getYear, addDays } from 'date-fns';

@Component({
  selector: 'app-smr-calendar-header',
  templateUrl: './smr-calendar-header.component.html',
  styleUrls: ['./smr-calendar-header.component.scss']
})
export class SmrCalendarHeaderComponent implements OnChanges {
  @Input() startDate: Date;
  @Input() displayRange: number;

  year = '-';

  ngOnChanges() {
    if (this.startDate && this.displayRange) {
      const yearStart = getYear(this.startDate);
      const yearEnd = getYear(addDays(this.startDate, this.displayRange));
      this.year = yearStart === yearEnd ? `${yearStart}` : `${yearStart} ${yearEnd}`;
    }
  }
}
