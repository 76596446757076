<div class="calendar-select-container">
  <span>
    {{ initialDate | date }}{{ endDate ? ' | ' : '' }}{{ endDate | date }}
  </span>
  <div class="picker-container">
    <app-round-button
      sizepx="36"
      fontSize="21px"
      shadow="none"
      icon="far fa-calendar-alt"
      class="picker"
    ></app-round-button>
  </div>
</div>
