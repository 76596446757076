import { Component, OnInit, Input } from '@angular/core';

import { ScreenSizeService } from '../../../../../shared/services/screen-size.service';
import { ScreenType } from '../.../../../../../../shared/enums/screen-type.enum';

@Component({
  selector: 'app-bom-list-dropdown-section',
  templateUrl: './bom-list-dropdown-section.component.html',
  styleUrls: ['./bom-list-dropdown-section.component.scss']
})
export class BomListDropdownSectionComponent implements OnInit {
  @Input() typeData: any;

  extensionIsOpen: boolean;
  dataList: any;
  screenType: ScreenType;
  ScreenTypeEnum = ScreenType;

  constructor(private screenSizeService: ScreenSizeService) {}

  ngOnInit() {
    this.extensionIsOpen = false;

    this.screenSizeService.screenTypeSubject.subscribe(newScreenSize => {
      this.screenType = newScreenSize;
    });
  }

  toggleExpansion() {
    this.extensionIsOpen = !this.extensionIsOpen;
  }

  expandedClass(): string {
    return this.extensionIsOpen ? 'extended' : 'non-extended';
  }

  getIconColor(): string {
    return !this.extensionIsOpen ? 'var(--menuItem-background2)' : 'var(--fabbrica)';
  }
}
