<div id="dochead">
  <div id="menu-btn" *ngIf="isMobile" (click)="clickOnSticky()">
    <i class="fa fa-chevron-right"></i>
  </div>
  <div
    class="title"
    [class.title-s]="screenType === ScreenTypeEnum.SMALL"
    [class.small]="size === 'small'"
  >
    <p id="name">{{ name }}</p>
    <p id="description">
      {{ description }}
    </p>
  </div>
  <div id="right-button-container">
    <ng-container [ngTemplateOutlet]="rightButtonTemplateRef"> </ng-container>
  </div>
</div>
