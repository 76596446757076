import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { debug } from 'console';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-modal-camera',
  templateUrl: './modal-camera.component.html',
  styleUrls: ['./modal-camera.component.scss']
})
export class ModalCameraComponent implements OnInit {
  @Output() cancelPressed = new EventEmitter();
  @Output() pictureTaken = new EventEmitter<any>();

  nextWebcam: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  trigger: Subject<void> = new Subject<void>();
  stream: MediaStream;

  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 1024 },
    height: { ideal: 1280 }
  };

  public multipleWebcamsAvailable = false;

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }

  onCancel() {
    this.cancelPressed.emit();
  }

  switchCamera() {
    this.nextWebcam.next(true);
  }

  triggerObservable() {
    return this.trigger.asObservable();
  }

  handleImage(webcamImage: WebcamImage): void {
    this.pictureTaken.emit(webcamImage);
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }
}
