import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { isSameDay } from 'date-fns';
import { Subscription } from 'rxjs';
import { ClickOnScreenService } from 'src/app/shared/services/click-on-screen.service';

@Component({
  selector: 'app-date-range-filter2',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})
export class DateRangeFilter2Component implements OnInit, OnDestroy {
  @Input() initialDate: Date;
  @Input() endDate: Date | null;
  @Input() enablePresets: boolean = true;

  @Output() filterChange = new EventEmitter<Array<Date | null>>();

  flatpickrInstance: Instance;
  isSelectOpen = false;
  subscriptions: Subscription[] = [];

  constructor(private clickOnScreenService: ClickOnScreenService) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.clickOnScreenService.clickOnScreenEvent.subscribe(() => {
        if (this.isSelectOpen) {
          this.isSelectOpen = false;
        }
      })
    );
    this.initDateSelect();

    let initialDate = this.initialDate;
    let endDate = this.endDate;

    this.flatpickrInstance.setDate([initialDate, endDate]);
    this.filterChange.emit([initialDate, endDate]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private initDateSelect() {
    const myInput = document.querySelector('.calendar-select-container');
    this.flatpickrInstance = flatpickr(myInput, {
      mode: 'range',
      onClose: this.onFlatpickrClose.bind(this),
      dateFormat: 'Y-m-d',
      enableTime: false
    });
  }

  onFlatpickrClose() {
    const [initialDate, endDate] = this.flatpickrInstance.selectedDates;

    if (
      !isSameDay(this.initialDate, initialDate) ||
      !isSameDay(this.endDate, endDate)
    ) {
      this.filterChange.emit([initialDate, endDate]);
    }
  }

  toggleSelect() {
    this.isSelectOpen = !this.isSelectOpen;
  }
}
