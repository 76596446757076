import { Injectable, HostListener, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OutsideClickService {
  outsideClickEvent: EventEmitter<boolean> = new EventEmitter();

  emitClic() {
    this.outsideClickEvent.emit(true);
  }
}
