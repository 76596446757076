import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { subDays } from 'date-fns';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { SmrDateRange } from './classes/smr-date-range';
import { SmrRangeInputPossibilities } from './enum/smr-range-input-possibilities.enum';

@Component({
  selector: 'app-smr-range-selector',
  templateUrl: './smr-range-selector.component.html',
  styleUrls: ['./smr-range-selector.component.scss']
})
export class SmrRangeSelectorComponent implements OnInit, OnChanges {
  @Input() currentRange: SmrDateRange;
  @Output() currentRangeChange = new EventEmitter<SmrDateRange>();
  rangeButtons: Array<ISmrRangeButton>;
  buttonSelected: number;
  flatpickrInstance: Instance;

  constructor() {
    this.rangeButtons = [
      { dayRange: 91, innerText: '3M' },
      { dayRange: 182, innerText: '6M' },
      { dayRange: 365, innerText: '1Y' }
    ];
    this.buttonSelected = 0;
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.currentRange = this.getRangeFromSpecificButton(this.buttonSelected);
      this.currentRangeChange.emit(this.currentRange);

      const myInput = document.querySelector('.picker');
      this.flatpickrInstance = flatpickr(myInput, {
        mode: 'range',
        onChange: this.updateRangeFromFlatPickr.bind(this)
      });
      this.updateFlatpickr(this.currentRange);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentRange) {
      this.updateFlatpickr(this.currentRange);
    }
    if (!changes.currentRange.firstChange && this.currentRange.lastChangedBy !== SmrRangeInputPossibilities.Buttons) {
      this.buttonSelected = -1;
    }
  }

  updateFlatpickr(newRange: SmrDateRange) {
    this.flatpickrInstance.setDate([newRange.startDate, newRange.endDate]);
  }

  getButtonsInnerText(): Array<string> {
    return this.rangeButtons.map(b => b.innerText);
  }

  updateRangeFromButtons(buttonIndex: number) {
    const newRange = this.getRangeFromSpecificButton(buttonIndex);
    this.emitNewRange(newRange);
  }
  updateRangeFromFlatPickr(selectedDates: Array<Date>, dateStr: string, instance) {
    if (selectedDates && selectedDates.length === 2) {
      const newRange = new SmrDateRange(
        selectedDates[0],
        this.getDaysDifferences(selectedDates[0], selectedDates[1]),
        SmrRangeInputPossibilities.FlatPickr
      );
      this.emitNewRange(newRange);
    }
  }

  private getRangeFromSpecificButton(buttonIndex: number): SmrDateRange {
    const tempDateRange = new SmrDateRange(new Date(), this.rangeButtons[buttonIndex].dayRange);
    const specificStartDate = subDays(new Date(), tempDateRange.step);
    return new SmrDateRange(
      specificStartDate,
      this.getDaysDifferences(specificStartDate, tempDateRange.endDate),
      SmrRangeInputPossibilities.Buttons
    );
  }

  private emitNewRange(newRange: SmrDateRange): void {
    if (this.needUpdate(this.currentRange, newRange)) {
      this.currentRange = newRange;
      this.currentRangeChange.emit(newRange);
    }
  }

  private needUpdate(oldRange: SmrDateRange, newRange: SmrDateRange): boolean {
    if (!oldRange) {
      return true;
    }
    const date1 = new Date(oldRange.startDate).setHours(0, 0, 0, 0);
    const date2 = new Date(newRange.startDate).setHours(0, 0, 0, 0);
    return date1 !== date2 || newRange.dayRange !== oldRange.dayRange;
  }

  private getDaysDifferences(date1: Date, date2: Date): number {
    const milliSecondDelta = date2.getTime() - date1.getTime();
    const msPerSecond = 1000;
    const msPerMinute = msPerSecond * 60;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    return Math.round(milliSecondDelta / msPerDay);
  }
}

interface ISmrRangeButton {
  dayRange: number;
  innerText: string;
}
