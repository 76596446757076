import { MachineTimerColors } from '../../enums/machine-timer-colors.enum';
import { MachineTimerStatuses } from '../../enums/machine-timer-statuses.enum';
import { MachineTimerWebsocketResponse } from './machine-timer-websocket-response';

export class MachineTimerData {
    name: string;
    time: string;
    color: string;
    requiresUpdate: boolean;
    machineTimerResponse: MachineTimerWebsocketResponse;

    constructor(machineStatus: MachineTimerWebsocketResponse) {
        this.machineTimerResponse = machineStatus
        this.setName()
        this.setTimerColor()
        this.setRequiresUpdate()
        this.setTime()
    }

    setName()
    {
        this.name = this.machineTimerResponse.name
    }
    
    setTimerColor() {   
        switch (this.machineTimerResponse.status) {
          case MachineTimerStatuses.NOT_RUNNING:
            this.color = MachineTimerColors.GRAY ;
            break;
          case  MachineTimerStatuses.SETUP:
            this.color = MachineTimerColors.LIGHT_BLUE;
            break;
          case  MachineTimerStatuses.MACHINING:
            this.color = MachineTimerColors.GREEN;
            break;
          case  MachineTimerStatuses.QC:
            this.color = MachineTimerColors.YELLOW;
            break;
          case  MachineTimerStatuses.STOPPAGE:
            this.color = MachineTimerColors.RED;
            break;
          default:
            this.color = MachineTimerColors.NONE;
        }
    }

    setRequiresUpdate()
    {
        this.requiresUpdate = !(this.machineTimerResponse.status === 'notRunning')
    }

    setTime()
    {
        this.time = this.CalculateElapsedTime();
    }

    CalculateElapsedTime(): string {
        const syncDate = new Date(this.machineTimerResponse.syncTime);
        const currentDate = new Date();

        const elapsedTime = Math.floor((currentDate.getTime() - syncDate.getTime()) / 1000);

        const timeToDisplay = !(this.machineTimerResponse.status === 'notRunning') ? this.machineTimerResponse.totalTimeAtSyncTime + elapsedTime : this.machineTimerResponse.totalTimeAtSyncTime;

        if (timeToDisplay === 0) {
            return '0min';
        }

        const hours = Math.floor(timeToDisplay / 3600);
        const minutes = Math.floor((timeToDisplay % 3600) / 60);

        if (hours === 0) {
            return `${minutes.toString().padStart(1, '0')}min`;
        } else {
            return `${hours}h${minutes.toString().padStart(1, '0')}min`;
        }
    }

    public UpdateElapsedTime()
    {
        this.setTime()  
    }
}
