<div class="container">
  <div class="text">
    UPR/DEGLAZE
  </div>
  <app-slider
    [disabled]="disabled"
    [value]="toggled"
    (valueChange)="onSliderValueChange($event)"
  ></app-slider>
</div>

<app-modal
  *ngIf="showModal"
  header="UPR/DEGLAZE"
  [confirmButtonDisabled]="
    selectedReasonIndex === -1 || innerSerialNumber === ''
  "
  (cancelEmitter)="onCancel()"
  (confirmEmitter)="onConfirm()"
>
  <div class="modal-body">
    Code:
    <input
      id="serial-number-input"
      type="text"
      placeholder="type code number"
      (change)="handleValueChange($event)"
      [ngStyle]="{ 'background-color': toggled ? 'transparent' : 'white' }"
    />

    Reason:
    <div class="reasons-container">
      <div
        *ngFor="let reason of reasons; let i = index"
        class="reason-container"
        (click)="reasonSelected(i)"
      >
        <app-custom-checkbox
          [selected]="i === selectedReasonIndex"
        ></app-custom-checkbox>
        <div
          class="reason-text"
          [ngStyle]="{ fontWeight: i === selectedReasonIndex ? 'bold' : '' }"
        >
          {{ reason }}
        </div>
      </div>
    </div>
  </div>
</app-modal>
