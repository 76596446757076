import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppLayoutComponent } from './core/components/app-layout/app-layout.component';
import { AppRoutingModule } from './app-routing.module';
import { FabbricaErrorHandler } from './shared/services/fabbrica-error-handler';
import { FabbricaHttpInterceptor } from './shared/services/fabbrica-http-interceptor';
import { HeaderTabComponent } from './modules/menu/components/header-tab/header-tab.component';
import { IFrameHttpInterceptor } from './modules/iframe/services/iframe-http-interceptor';
import { MenuComponent } from './modules/menu/menu.component';
import { MenuTabComponent } from './modules/menu/components/menu-tab/menu-tab.component';
import { SharedModule } from './shared/shared.module';
import { UserTabComponent } from './modules/menu/components/user-tab/user-tab.component';
import { StationTabComponent } from './modules/menu/components/station-tab/station-tab.component';
import { WorkInstructionsManagerModule } from './modules/work-instructions-manager/work-instructions-manager.module';

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    MenuComponent,
    MenuTabComponent,
    HeaderTabComponent,
    UserTabComponent,
    StationTabComponent
  ],
  imports: [
    SharedModule.forRoot(),
    AngularSvgIconModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HammerModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    WorkInstructionsManagerModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: FabbricaErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FabbricaHttpInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: IFrameHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
