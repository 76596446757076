import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'squareFootage' })
export class SquareFootagePipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return Math.round(value).toLocaleString();
    }
  }
}
