import { Injectable } from '@angular/core';

import { StorageService } from '../../../services/storage.service';
import { StorageKey } from '../../../enums/storage-key.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectorService {
  currentLanguage: BehaviorSubject<string> = new BehaviorSubject('En');

  constructor(private storageService: StorageService) {}

  set(state: string) {
    state === 'En'
      ? this.storageService.set(StorageKey.USERLANGUAGE, 'En')
      : this.storageService.set(StorageKey.USERLANGUAGE, 'Es');
  }

  get(): string {
    const lang = this.storageService.get(StorageKey.USERLANGUAGE);
    this.currentLanguage.next(lang);
    return lang || 'En';
  }

  remove() {
    this.storageService.clear(StorageKey.USERLANGUAGE);
  }
}
