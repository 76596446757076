import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmrSelectionService {
  selectionListSubject: BehaviorSubject<Array<number>>;
  private selectionList: Array<number>;
  constructor() {
    this.selectionList = [];
    this.selectionListSubject = new BehaviorSubject(this.selectionList);
  }

  selectionToggle(smrId: number) {
    if (this.isSmrSelected(smrId)) {
      this.removeFromList(smrId);
    } else {
      this.addToList(smrId);
    }
  }

  isSmrSelected(smrId: number): boolean {
    return this.selectionList.includes(smrId);
  }

  private addToList(smrId: number) {
    this.selectionList.push(smrId);
    this.selectionList.sort();
    this.emitNewSelectionList();
  }

  private removeFromList(smrId: number) {
    const index = this.selectionList.indexOf(smrId);
    if (index > -1) {
      this.selectionList.splice(index, 1);
      this.emitNewSelectionList();
    }
  }

  private emitNewSelectionList() {
    this.selectionListSubject.next(this.selectionList);
  }
}
