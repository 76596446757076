<div class="container" *ngIf="lot.show">
  <div
    *ngIf="!lot.expanded && lot.depth !== 3"
    class="icon"
    (click)="toggleShowChildren()"
  >
    <i class="fa fa-chevron-right"></i>
  </div>
  <div
    *ngIf="lot.expanded && lot.depth !== 3"
    class="icon"
    (click)="toggleShowChildren()"
  >
    <i class="fa fa-chevron-down"></i>
  </div>
  <div *ngIf="lot.depth === 3" class="spacer"></div>
  <div class="content" (click)="selectLot()">
    <div class="name-description">
      <div class="name">{{ lot.formattedName }}</div>
      <div class="description">{{ lot.description }}</div>
    </div>
    <app-lines-bar
      [stats]="lot.stats"
      [styles]="{ margin: '4px', padding: '4px' }"
    >
    </app-lines-bar>
    <div class="bar"></div>
  </div>
</div>

<div *ngIf="lot.expanded && lot.show">
  <div
    class="children-container"
    *ngIf="lot.children && lot.children.length > 0"
  >
    <app-lot-selectable-item
      *ngFor="let child of lot.children"
      [lot]="child"
    ></app-lot-selectable-item>
  </div>
</div>
