import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent {
  @Output() uploadEvent = new EventEmitter<any>();

  constructor() {}

  onFileSelected(e: any) {
    this.uploadEvent.emit(e);
  }
}
