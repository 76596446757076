<div *ngIf="dataList" id="container" (click)="toggleExpansion()" [ngClass]="{ 'container-open': isExpanded }">
  <div id="data">
    <div id="general-data">
      <div
        *ngIf="!(screenType === ScreenTypeEnum.SMALL)"
        id="list-container-l"
        [ngStyle]="{ 'grid-template-columns': gridTemplate }"
      >
        <div *ngIf="qty" class="data-value-l">{{ qty.toString() }}</div>
        <div class="data-container" *ngFor="let item of mainData">
          <p class="data-value-l" [ngClass]="nameClass(item)">{{ item.value }}</p>
        </div>
      </div>
      <div *ngIf="screenType === ScreenTypeEnum.SMALL" id="list-container-s">
        <div *ngIf="qty">{{ qty.toString() }}</div>
        <div id="without-qty-s">
          <div class="data-container" *ngFor="let item of mainData">
            <p class="data-value-s" [ngClass]="nameClass(item)">{{ item.value }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="small-data" *ngIf="isExpanded" [ngStyle]="{ 'grid-template-columns': gridTemplateSmall }">
      <div id="small-data-in-grid">
        <app-data-container-optionnal *ngFor="let smallData of otherData" [dataList]="smallData">
        </app-data-container-optionnal>
      </div>
    </div>
  </div>

  <div class="button-container" id="no-hover">
    <app-round-button
      *ngIf="!isExpanded"
      class="expand-btn"
      backgroundColor="transparent"
      iconColor="var(--menuItem-text1)"
      icon="fas fa-chevron-down"
      shadow="none"
      [sizepx]="32"
      fontSize="17px"
    ></app-round-button>
    <app-round-button
      *ngIf="isExpanded"
      class="expand-btn"
      backgroundColor="transparent"
      iconColor="var(--menuItem-text1)"
      icon="fas fa-chevron-up"
      shadow="none"
      [sizepx]="32"
      fontSize="17px"
    ></app-round-button>
  </div>

  <div class="button-container" id="hover">
    <app-round-button
      *ngIf="!isExpanded"
      class="expand-btn"
      backgroundColor="transparent"
      iconColor="var(--menuItem-text1)"
      icon="fas fa-chevron-down"
      shadow="none"
      [sizepx]="32"
      fontSize="17px"
    ></app-round-button>
    <app-round-button
      *ngIf="isExpanded"
      class="expand-btn"
      backgroundColor="transparent"
      iconColor="var(--menuItem-text1)"
      icon="fas fa-chevron-up"
      shadow="none"
      [sizepx]="32"
      fontSize="17px"
    ></app-round-button>
  </div>
</div>
