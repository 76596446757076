import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BackendService } from './backend.service';
import { BomPart } from '../models/bom-data.model';
import { StorageService } from './storage.service';
import { StorageKey } from '../enums/storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class BomService {
  private DOCUMENT_URL = ':kind/:ref?checkParts=:checkPartOption&serial=:serialNumber';
  bomSubject: BehaviorSubject<any>;
  bomForCheckParts: BehaviorSubject<any>;

  constructor(
    private storageService: StorageService,
    private backendService: BackendService
  ) {
    this.bomSubject = new BehaviorSubject<any>(this.getEmptyBoms());
    this.bomForCheckParts = new BehaviorSubject<any>(this.getEmptyBoms());
  }

  setBom(boms) {
    this.storageService.setDeprecated(StorageKey.BOM, JSON.stringify(boms));
    this.bomSubject.next(boms);
  }

  getBomForBom(id: number): Observable<any> {
    return this.backendService.get(this.buildBomUrl(id, false)).pipe(
      map(bomData => {
        const partList: Array<BomPart> = bomData.parts;
        const boms = {
          raw: partList,
          tree: this._buildTree(partList),
          list: this._buildList(partList)
        };
        this.setBom(boms);
      })
    );
  }

  getBomForCheckParts(id: number, serialNumber?: string): Observable<any> {
    return this.backendService.get(this.buildBomUrl(id, true, serialNumber)).pipe(
      map(bomData => {
        const partList: Array<BomPart> = bomData.parts;
        const boms = {
          list: this._buildList(partList)
        };
        this.bomForCheckParts.next(boms);
      })
    );
  }

  private buildBomUrl(ref: number, checkPartOption: boolean, serialNumber?: string ): string {
    const checkPartValue = checkPartOption ? 1 : 0;
    const serial = serialNumber ? serialNumber : '';
    return this.DOCUMENT_URL.replace(':kind', 'bom')
      .replace(':ref', `${ref}`)
      .replace(':checkPartOption', `${checkPartValue}`)
      .replace(':serialNumber', `${serial}`);
  }

  removeBom(): void {
    this.setBom(this.getEmptyBoms());
    this.storageService.removeDeprecated(StorageKey.BOM);
  }

  private getEmptyBoms(): any {
    return { raw: [], list: [], tree: [] };
  }

  // =======================================================
  // Create the bom as to be displayed with a List mode
  _buildList(parts): Array<any> {
    const list = new Map();
    parts
      .sort((a, b) => a.fileName.localeCompare(b.fileName))
      .forEach(part => {
        const type = part.type;
        const copy = Object.assign({}, part);
        // copy._excluded = !!copy.QTYExcludeList;
        copy._excluded = 0;
        copy._qty = +copy.qtyList.toFixed(8);
        if (copy._qty < 0) {
          copy._qty = 'undefined';
        }
        // copy._qtyExclude = +copy.QTYExcludeList.toFixed(8);
        copy._qtyExclude = +'0.00000000';
        if (copy._qtyExclude < 0) {
          copy._qtyExclude = 'undefined';
        }
        if (!list.has(type)) {
          list.set(type, { name: type, parts: [copy] });
        } else {
          const existingType = list.get(type);
          const existingCopy = existingType.parts.find(
            p => p.documentID === copy.documentID
          );
          if (existingCopy) {
            if (
              typeof existingCopy._qty === 'number' &&
              typeof copy._qty === 'number'
            ) {
              existingCopy._qty = +(existingCopy._qty + copy._qty).toFixed(8);
            }
            if (
              typeof existingCopy._qtyExclude === 'number' &&
              typeof copy._qtyExclude === 'number'
            ) {
              existingCopy._qtyExclude = +(
                existingCopy._qtyExclude + copy._qtyExclude
              ).toFixed(8);
            }
          } else {
            existingType.parts.push(copy);
          }
        }
      });
    const sortByName = (a, b) => {
      if (!a.name) {
        return 1;
      }
      if (!b.name) {
        return -1;
      }
      return a.name.localeCompare(b.name);
    };
    const sortedList = [...list.values()].sort(sortByName);

    let level = 1;
    for (const type of sortedList) {
      for (const part of type.parts) {
        part._level = level++;
      }
    }

    return sortedList;
  }
  // =======================================================
  // =======================================================
  // Create the bom as to be displayed with a Tree mode
  _buildTree(parts) {
    let tree = [];
    for (let depth = 0; depth <= 8; depth++) {
      parts
        .filter(p => p.treeDepth === depth)
        .sort((a, b) => a.fileName.localeCompare(b.fileName))
        .forEach((part, index, arr) => {
          // Custom calculations
          // part._excluded = !!part.QTYExcludeTree;
          part._excluded = 0;
          part._qty = +part.qtyTree.toFixed(8);
          if (part._qty < 0) {
            part._qty = 'undefined';
          }
          // part._qtyExclude = +part.QTYExcludeTree.toFixed(8);
          part._qtyExclude = +'0.00000000';
          if (part._qtyExclude < 0) {
            part._qtyExclude = 'undefined';
          }
          // Extract parent Tree
          let parentTree = part.tree.split(',');
          const parentID = parentTree.splice(-2, 1)[0];
          parentTree = parentTree.join(',');
          // Find parent
          const parentPart = parts.find(
            a => a.documentID.toString() === parentID && a.tree === parentTree
          );
          // Add part to parent
          if (parentPart) {
            parentPart._children = parentPart._children || [];
            parentPart._children.push(part);
            part._level = `${parentPart._level}.${parentPart._children.length}`;
          } else {
            part._level = `${index + 1}`;
            tree.push(part);
          }
        });
    }

    // Compute after tree is build
    parts.forEach(part => (part._totalParts = this._computeTotalParts(part)));
    tree = this.addLastProperty(tree);

    return tree;
  }

  private _computeTotalParts(item) {
    if (!item._children || !item._children.length) {
      if (
        typeof item._qty !== 'number' ||
        typeof item._qtyExclude !== 'number'
      ) {
        return 'undefined';
      }
      return item._qty + item._qtyExclude;
    }

    let totalChildren = 0;
    for (const child of item._children) {
      const childTotal = this._computeTotalParts(child);
      if (typeof totalChildren === 'number' && typeof childTotal === 'number') {
        totalChildren += childTotal;
      } else if (typeof childTotal !== 'number') {
        return 'undefined';
      }
    }
    return totalChildren;
  }

  private addLastProperty(tree: any): Array<BomPart> {
    tree.forEach((treeItem, ind, arr) => {
      if (
        treeItem.hasOwnProperty('_children') &&
        treeItem._children.length > 0
      ) {
        this.addLastProperty(treeItem._children);
      }
      if (ind === arr.length - 1) {
        treeItem._isLast = true;
      } else {
        treeItem._isLast = false;
      }
    });
    return tree;
  }
  // =======================================================
}
