import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkInstructionsLanguagesEnum } from '../../enums/work-instructions-languages.enum';
import { WorkInstructionsCustomizableInput } from '../../interfaces/work-instructions-customizable-input.interface';
import { WorkInstructionsLanguageTextData } from '../../interfaces/work-instructions-multilingual-text-data.interface';

@Component({
  selector: 'app-multi-choice-editable-button',
  templateUrl: './multi-choice-editable-button.component.html',
  styleUrls: ['./multi-choice-editable-button.component.scss']
})
export class MultiChoiceEditableButtonComponent
  implements OnInit, WorkInstructionsCustomizableInput {
  @Input() elements: WorkInstructionsLanguageTextData[];
  @Input() currentLanguage: WorkInstructionsLanguagesEnum;
  @Input() editModeActivated: boolean;
  @Input() disabled: boolean;
  @Input() defaultValue: WorkInstructionsLanguageTextData;
  
  selectButton: number;
  
  @Output() buttonSelected = new EventEmitter<WorkInstructionsLanguageTextData>();
  @Output() textChange = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.defaultValue) {
      this.selectButton = this.elements.findIndex(x => x.en === this.defaultValue.en && x.es === this.defaultValue.es) 
    }
    else {
      this.selectButton = -1
    }
  }

  onButtonClick(buttonIndex: number) {
    if (!this.editModeActivated && !this.disabled) {
      this.selectButton = buttonIndex;
      this.buttonSelected.emit(this.elements[this.selectButton]);
    }
  }

  enTextChange(e: any, index: number, english: boolean) {
    english ? this.elements[index].en = e.target.value : this.elements[index].es = e.target.value; 
    this.textChange.emit();
  }
  
  onTextChange() {
    this.textChange.emit();
  }

  addInput() {
    this.elements.push({en: '', es: ''});
  }

  removeInput(ind: number) {
    this.elements.splice(ind, 1);
  }
}