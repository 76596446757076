import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MachineTimerData } from 'src/app/shared/components/machine-timers/machine-timer-data';
import { MachineTimerWebsocketResponse } from 'src/app/shared/components/machine-timers/machine-timer-websocket-response';
import { MachineTimersWebsocketService } from 'src/app/shared/services/machine-timers-websocket.service';

@Injectable({
  providedIn: 'root'
})
export class MachineTimersManagerService {
  
  constructor(
    private socket: MachineTimersWebsocketService,
  ) {}

  getMachineTimers(): Observable<MachineTimerData[]> {
    return this.socket.fromEvent<MachineTimerWebsocketResponse[]>('machiningTimes').pipe(
      map((data: MachineTimerWebsocketResponse[]) => data.map(item => {
        return new MachineTimerData(item);
      }))
    );
  }
}
