import { Component, OnDestroy, OnInit } from '@angular/core';
import { MachineTimerData } from '../machine-timer-data';
import { Subscription, interval } from 'rxjs';
import { MachineTimersManagerService } from 'src/app/shared/services/machine-timers-manager.service';

@Component({
  selector: 'app-machine-timers-indicator-manager',
  templateUrl: './machine-timers-indicator-manager.component.html',
  styleUrls: ['./machine-timers-indicator-manager.component.scss']
})
export class MachineTimersIndicatorManagerComponent implements OnInit, OnDestroy {
  machineTimersData: MachineTimerData[];

  subscriptions: Subscription[] = [];

  private TIME = 1 * 60 * 1000; // milliseconds

  constructor(private machineTimersManagerService: MachineTimersManagerService
  ) {
    this.machineTimersData = [
    ];
   }

  ngOnInit(): void {
        
    // Subscribe to machine timers
    this.subscriptions.push(
      this.machineTimersManagerService.getMachineTimers().subscribe(
        data => {
          if(data){
            this.machineTimersData = data
          }
        })
    );

    // Update in-progress timers
    this.subscriptions.push(
      interval(this.TIME).subscribe(() => {
        this.updateInProgressTimers();
      })
    );
  }

  private updateInProgressTimers() {
    this.machineTimersData.forEach(timer => {
      if (timer.requiresUpdate) {
        timer.UpdateElapsedTime()
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
