import { Pipe, PipeTransform } from '@angular/core';
import { SmrProgressSmrData } from '../interfaces/smr-progress-data.interface';

@Pipe({
  name: 'smrLot'
})
export class SmrLotPipe implements PipeTransform {
  transform(smr: SmrProgressSmrData): string {
    let lot = smr?.lot || '';
    if (smr?.subLot) {
      lot += `-${smr.subLot}`;
    }
    if (smr?.subSubLot) {
      lot += `-${smr.subSubLot}`;
    }
    return lot;
  }
}
