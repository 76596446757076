import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceService } from '../../services/device.service';
@Component({
  selector: 'app-amplified-picture',
  templateUrl: './amplified-picture.component.html',
  styleUrls: ['./amplified-picture.component.scss']
})
export class AmplifiedPictureComponent {
  @Input() pictures: any[];
  @Input() pictureSelectedIndex: number;
  @Output() closeView = new EventEmitter<any>();
  base64Prefix = 'data:image/jpeg;base64,';

  constructor(public deviceService: DeviceService) {}

  close() {
    this.closeView.emit();
  }

  download() {
    this.downloadPicture(this.pictures[this.pictureSelectedIndex].data);
  }

  slidePicture(direction: string) {
    if (direction === 'left') {
      this.pictureSelectedIndex = Math.max(0, this.pictureSelectedIndex - 1);
    } else if (direction === 'right') {
      this.pictureSelectedIndex = Math.min(
        this.pictures.length - 1,
        this.pictureSelectedIndex + 1
      );
    }
  }

  downloadPicture(
    base64Data: string,
    contentType: string = 'image/jpeg',
    sliceSize = 512
  ) {
    const byteCharacters = atob(base64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blobData = new Blob(byteArrays, { type: contentType });
    const filename = this.pictures[this.pictureSelectedIndex].name;

    const blob = new Blob([blobData], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }
}
