import { Pipe, PipeTransform } from '@angular/core';
import { SmrProgressSmrData } from '../interfaces/smr-progress-data.interface';

@Pipe({
  name: 'smrPlant'
})
export class SmrPlantPipe implements PipeTransform {
  defaultValue = '-';
  transform(smrData: SmrProgressSmrData): string {
    return smrData.plant ? smrData.plant : this.defaultValue;
  }
}
