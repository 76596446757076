<div class="add-btn-container">
  <app-button
    [title]="data[0].text"
    [icon]="data[0].iconClass"
    [backgroundColor]="
      selectedButton === 0 ? color : 'var(--menuItem-background1)'
    "
    [fontColor]="
      editMode
        ? 'rgba(200, 200, 200, 1)'
        : selectedButton === 0
        ? 'var(--menuItem-background1)'
        : 'var(--fabbrica)'
    "
    [size]="'18px'"
    [iconSize]="'20px'"
    [iconBackgroundColor]="'transparent'"
    [shadow]="'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'"
    [border]="
      editMode
        ? '1px solid rgba(200, 200, 200, 1)'
        : '1px solid var(--fabbrica)'
    "
    [disable]="disabled"
    (click)="onButtonClick(0)"
  >
  </app-button>

  <app-button
    [title]="data[1].text"
    [icon]="data[1].iconClass"
    [backgroundColor]="
      selectedButton === 1 ? color : 'var(--menuItem-background1)'
    "
    [fontColor]="
      editMode
        ? 'rgba(200, 200, 200, 1)'
        : selectedButton === 1
        ? 'var(--menuItem-background1)'
        : 'var(--red)'
    "
    [size]="'18px'"
    [iconSize]="'20px'"
    [iconBackgroundColor]="'transparent'"
    [shadow]="'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'"
    [border]="
      editMode ? '1px solid rgba(200, 200, 200, 1)' : '1px solid var(--red)'
    "
    (click)="onButtonClick(1)"
    [disable]="disabled"
  >
  </app-button>

  <app-button
    [title]="data[2].text"
    [icon]="data[2].iconClass"
    [backgroundColor]="
      selectedButton === 2 ? color : 'var(--menuItem-background1)'
    "
    [fontColor]="
      editMode
        ? 'rgba(200, 200, 200, 1)'
        : selectedButton === 2
        ? 'var(--menuItem-background1)'
        : 'gray'
    "
    [size]="'18px'"
    [iconSize]="'20px'"
    [iconBackgroundColor]="'transparent'"
    [shadow]="'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'"
    [border]="editMode ? '1px solid rgba(200, 200, 200, 1)' : '1px solid gray'"
    (click)="onButtonClick(2)"
    [disable]="disabled"
  >
  </app-button>
</div>
