const startString = '{'; // String added to the beginning of any scan with the barcodeScanner
const endString = '}@'; // String added to the end of any scan with the barcodeScanner

export class BarcodeReader {
  public entireScan: string;
  public cleanedScan: string;

  constructor(newFullString: string) {
    this.entireScan = newFullString;
    const regex = new RegExp(`^${startString}[\\w\\W]+${endString}$`, 'm');
    const matches = this.entireScan.match(regex);
    if (matches && Array.isArray(matches) && matches.length) {
      this.cleanedScan = matches[0].replace(startString, '').replace(endString, '');
    } else {
      this.cleanedScan = '';
    }
  }

  isFromBarcodeScannerAndComplete(): boolean {
    return this.cleanedScan !== '';
  }
  isFromBarcodeScanner(): boolean {
    const startRegex = new RegExp(`^${startString}`);
    return startRegex.test(this.entireScan);
  }
}
