import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smrId'
})
export class SmrIdPipe implements PipeTransform {
  transform(fileName: string): string {
    return fileName ? fileName.split('.')[0] : '-';
  }
}
