import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { DeviceService } from '../../shared/services/device.service';
import { DeviceType } from '../../shared/enums/device-type.enum';
import { SubmenuService } from '../../shared/services/submenu.service';
import { MenuTab } from '../../shared/models/menu-tab.model';
import { MenuState } from './enums/menu-state.enum';
import { OutsideClickService } from '../../shared/services/outside-click.service';
import { ToggleMenuServiceService } from '../../shared/services/toggle-menu-service.service';
import { UserService } from '../../shared/services/user.service';
import { PermissionsService } from '../../shared/services/permissions.service';
import { User } from '../../shared/models/user';
import { BypassUserManagementService } from '../../shared/services/bypass-user-management.service';
import { MenuOpenedService } from './services/menu-opened.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  menuState: MenuState;
  subscriptions: Subscription[] = [];
  midTabs: Array<MenuTab>;
  subMenuTabs: Array<MenuTab>;
  user: User;

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private submenuService: SubmenuService,
    private outsideClickService: OutsideClickService,
    private userService: UserService,
    public permissionsService: PermissionsService,
    public bypassService: BypassUserManagementService,
    private toggleMenuService: ToggleMenuServiceService,
    private menuOpenedService: MenuOpenedService
  ) {
    this.subscriptions.push(
      this.submenuService.subMenuListSubject.subscribe(
        (newSubMenuTabs: Array<any>) => {
          this.subMenuTabs = [];
          if (newSubMenuTabs) {
            this.subMenuTabs = newSubMenuTabs;
            if (this.subMenuTabs.length > 0) {
              this.subMenuTabs.forEach(tab => {
                tab.isFirst = false;
                tab.isLast = false;
              });
              this.subMenuTabs[0].fontWeight = 'bold';
              this.subMenuTabs[0].isFirst = true;
              this.subMenuTabs[this.subMenuTabs.length - 1].isLast = true;
            }
          }
        }
      )
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          if (this.deviceService.isMobile.getValue()) {
            // Do not init the menu at each router navigation when we are
            // on a computer as we want to save the state of the menu
            this.initMenu(this.deviceService.actualDevice());
          }
        }
      })
    );
    this.user = this.userService.getUser();

    this.outsideClickService.outsideClickEvent.subscribe(() => {
      if (this.menuIsOpen() && this.menuState !== MenuState.COMPUTEROPEN) {
        this.toggleMenu();
      }
    });

    this.initMenu(this.deviceService.actualDevice());
    this.subscriptions.push(
      this.bypassService.bypass.subscribe(() => {
        this.initTab();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  closeMenu() {
    if (this.toggleMenuService.isOpen) {
      this.toggleMenuService.toggle();
    }
  }

  initTab() {
    const menuTabs = [
      {
        title: 'Home',
        link: '/app/home',
        icon: 'fas fa-home',
        type: 'icon',
        fontWeight: 'bold',
        fontSize: '26px',
        isFirst: true,
        isLast: false
      }
    ];
    if (
      this.permissionsService.isVisible(this.user, 'productiondashboard.tab')
    ) {
      menuTabs.push({
        title: 'Dashboard',
        link: '/app/production-dashboard',
        icon: 'assets/icon/dashboard-icon.svg',
        type: 'icon',
        fontWeight: 'bold',
        fontSize: '26px',
        isFirst: false,
        isLast: false
      });
    }

    if (
      this.permissionsService.isVisible(this.user, 'machiningEntry.access')
    ) {

      menuTabs.push({
        title: 'Machining',
        link: '/app/machining',
        icon: 'assets/icon/settings_motion_mode.svg',
        type: 'icon',
        fontWeight: 'bold',
        fontSize: '26px',
        isFirst: false,
        isLast: true
      });
    }
    this.midTabs = menuTabs;
  }

  toggleMenu() {
    const device = this.deviceService.actualDevice();
    switch (device) {
      case DeviceType.COMPUTER:
        this.manageComputerMenu();
        break;
      case DeviceType.TABLET:
        this.manageTabletMenu();
        break;
      case DeviceType.MOBILE:
        this.manageMobileMenu();
        break;
      case DeviceType.UNKNOWN:
        this.manageTabletMenu();
        break;
    }
  }

  private menuIsOpen(): boolean {
    const isOpen =
      this.menuState === MenuState.COMPUTEROPEN ||
      this.menuState === MenuState.TABLETOPEN;
    // || this.menuState === MenuState.MOBILEOPEN
    this.menuOpenedService.isOpen.next(isOpen);
    return isOpen;
  }

  initMenu(device: DeviceType) {
    this.initTab();
    switch (device) {
      case DeviceType.COMPUTER:
        this.menuOpenedService.isOpen.next(true);
        this.menuState = MenuState.COMPUTEROPEN;
        break;
      case DeviceType.TABLET:
        this.menuOpenedService.isOpen.next(false);
        this.menuState = MenuState.TABLETCLOSE;
        break;
      case DeviceType.MOBILE:
        this.menuOpenedService.isOpen.next(false);
        this.menuState = MenuState.MOBILECLOSE;
        break;
      case DeviceType.UNKNOWN:
        this.menuOpenedService.isOpen.next(false);
        this.menuState = MenuState.TABLETCLOSE;
        break;
    }
  }

  manageComputerMenu() {
    if (this.menuState !== MenuState.COMPUTEROPEN) {
      this.menuOpenedService.isOpen.next(true);
      this.menuState = MenuState.COMPUTEROPEN;
    } else {
      this.menuOpenedService.isOpen.next(false);
      this.menuState = MenuState.COMPUTERCLOSE;
    }
  }

  manageTabletMenu() {
    if (this.menuState !== MenuState.TABLETCLOSE) {
      this.menuOpenedService.isOpen.next(false);
      this.menuState = MenuState.TABLETCLOSE;
    } else {
      this.menuOpenedService.isOpen.next(true);
      this.menuState = MenuState.TABLETOPEN;
    }
  }

  manageMobileMenu() {
    this.toggleMenuService.toggle();
  }
}
