<app-modal
  [confirmDelete]="confirmDelete"
  header="{{ header }}"
  (cancelEmitter)="cancel()"
  (confirmEmitter)="confirm()"
  [cancelButtonText]="actionBtnText.cancelButtonText"
  [confirmButtonText]="actionBtnText.confirmButtonText"
  [invertButtons]="actionBtnText.invertButtons"
>
  <div class="modal-content">
    {{ content }}
  </div>
</app-modal>
