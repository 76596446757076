import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectData } from 'src/app/shared/components/project-list/interfaces/project-data';
import { ProjectService } from 'src/app/shared/components/project-list/services/project.service';

@Component({
  selector: 'app-project-picture',
  templateUrl: './project-picture.component.html',
  styleUrls: ['./project-picture.component.scss']
})
export class ProjectPictureComponent implements OnInit, OnDestroy {
  @Input() project: ProjectData;
  @Input() width: string;
  @Input() height: string;

  projectId = '';
  photoURL: string | ArrayBuffer | null = null;
  placeholderImage = '../../../../../../assets/images/placeholder-image.jpg';
  subscriptions: Subscription[] = [];

  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {
    this.projectId = this.project.name.split(' - ')[0];

    this.subscriptions.push(
      this.projectService.getPhoto(this.projectId).subscribe(data => {
        if (data) {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => (this.photoURL = reader.result);
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
