import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  initialPath = 'assets/audio/';

  playSound(audioFileName: string) {
    const audio = new Audio();
    audio.src = this.initialPath + audioFileName;
    audio.load();
    audio.play();
  }

  playScan() {
    this.playSound('scan.mp3');
  }
  playWrong() {
    this.playSound('Error.mp3');
  }
  playWarning() {
    this.playSound('Warning.mp3');
  }
  playSuccess() {
    this.playSound('Success.mp3');
  }
}
