import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { LastComponent } from '../models/last-component.model';
import { StorageKey } from '../enums/storage-key.enum';
import { ComponentService } from './component.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentListService {
  maxElementCount: number;
  constructor(
    private storageService: StorageService,
    private componentService: ComponentService
  ) {
    // Change this variable to modify the max element capacity:
    this.maxElementCount = 15;

    this.componentService.componentSub.subscribe(
      (lastComponent: LastComponent) => {
        if (lastComponent) {
          this.pushOneComponent(lastComponent);
        }
      }
    );
  }

  pushOneComponent(newComponent: LastComponent) {
    let componentList: Array<LastComponent> = this.getComponentList();
    if (componentList === null) {
      componentList = [];
    }

    const ind = componentList.findIndex(
      el => el.qrCode.sn === newComponent.qrCode.sn
    );
    // Remove if already exists in the array
    if (ind !== -1) {
      componentList.splice(ind, 1);
    }
    // ... and insert it again at the 1st place
    componentList.unshift(newComponent);
    //  Limit the size of the componentList
    componentList.splice(this.maxElementCount);
    this.setComponentList(componentList);
  }

  getComponentList(): Array<LastComponent> {
    const listJson: string = this.storageService.getDeprecated(
      StorageKey.LASTCOMPONENTLIST
    );
    const finalList: Array<LastComponent> = JSON.parse(listJson) || [];
    return finalList;
  }

  deleteComponentList() {
    this.storageService.removeDeprecated(StorageKey.LASTCOMPONENTLIST);
  }

  setComponentList(componentList: Array<LastComponent>): void {
    const listJson: string = JSON.stringify(componentList);
    this.storageService.setDeprecated(StorageKey.LASTCOMPONENTLIST, listJson);
  }

  updateLastComponent(lastComponent: LastComponent) {
    const contData = this.getComponentList();
    contData[0] = lastComponent;
    this.setComponentList(contData);
  }
}
