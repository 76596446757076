import { Injectable } from '@angular/core';

import { FileExtension } from '../enums/file-extension.enum';

@Injectable({
  providedIn: 'root'
})
export class FilenameManipulationService {
  private extensionList: Array<any>;

  constructor() {
    this.extensionList = [
      { ext: FileExtension.DWG, regexList: ['.DWG', '.dwg'] },
      { ext: FileExtension.PDF, regexList: ['.PDF', '.pdf'] },
      { ext: FileExtension.PDF3D, regexList: ['.PDF3D', '.pdf3d'] },
      { ext: FileExtension.SLDASM, regexList: ['.SLDASM', '.sldasm'] },
      { ext: FileExtension.SLDDWG, regexList: ['.SLDDRW', '.slddrw'] },
      { ext: FileExtension.SLDPRT, regexList: ['.SLDPRT', '.sldprt'] }
    ];
  }

  getFilenameWithoutExtension(fileName: string): string {
    const reg = /[.][a-zA-Z]+$/;
    return fileName.split(reg)[0];
  }

  getExtension(fileName: string): FileExtension {
    const reg3dPdf = /_3D.pdf$/i;
    if (reg3dPdf.test(fileName)) {
      return FileExtension.PDF3D;
    }

    const reg = /[.][a-zA-Z]+$/i;
    const ind = fileName.search(reg);
    const ext = fileName.slice(ind);
    return this.getEnum(ext.toUpperCase());
  }

  private getEnum(extString: string): FileExtension {
    for (const extTest of this.extensionList) {
      for (const regTest of extTest.regexList) {
        if (regTest === extString) {
          return extTest.ext;
        }
      }
    }
    return FileExtension.OTHER;
  }
}
