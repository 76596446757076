import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackendService } from '../../../../../shared/services/backend.service';
import {
  StationConfigurationFrontendDto,
  WorkInstructionsStationDto
} from '../interfaces/station.interface';
import { Station } from '../../../../../shared/interfaces/station.interface';

@Injectable({
  providedIn: 'root'
})
export class StationsBackendService {
  stationURI = 'birth-certificate/stations';
  configURI = 'birth-certificate/station-configuration';
  userStationsURI = 'birth-certificate/assembly-line-users';

  public stations = new BehaviorSubject<WorkInstructionsStationDto[]>(null);
  private innerStations: WorkInstructionsStationDto[] = [];

  public configurations = new BehaviorSubject<
    StationConfigurationFrontendDto[]
  >(null);

  private emitNewValues() {
    this.stations.next(this.innerStations);
  }

  constructor(private backend: BackendService) {
    this.getAll();
  }

  getAll() {
    this.backend.get(this.stationURI).subscribe(data => {
      if (data) {
        // this.innerStations = [...data];
        ///////////////////////////////////////////////////////
        this.innerStations = data.filter(s => !s.isFinalQC);
        this.innerStations.push(data.find(s => s.isFinalQC));
        ///////////////////////////////////////////////////////
        this.emitNewValues();
      }
    });
  }

  createStation(stationName: string): Observable<WorkInstructionsStationDto> {
    return this.backend.post(this.stationURI, { Name: stationName });
  }

  updateStation(station: WorkInstructionsStationDto): Observable<boolean> {
    return this.backend
      .put(
        this.stationURI,
        station.id.toString(),
        this.convertFrontendToBackendStation(station)
      )
      .pipe(map(data => data.success));
  }

  deleteAnExistingStation(
    station: WorkInstructionsStationDto
  ): Observable<boolean> {
    return this.backend
      .delete(this.stationURI, station.id.toString())
      .pipe(map(data => data.success));
  }

  getStationsWithConfigurations(): Observable<any> {
    return this.backend.get(`${this.stationURI}?configs=1`).pipe(
      map(data => {
        if (data) {
          return data.map(station => {
            return {
              id: station.id,
              name: station.name,
              isFinalQC: station.isFinalQC,
              configurations: station.configurations.map(config => {
                return {
                  id: config.id,
                  name: config.name
                };
              })
            };
          });
        }
      })
    );
  }

  getConfigurations(id: string): Observable<any> {
    return this.backend.get(`${this.stationURI}/${id}`);
  }

  getConfiguration(id: string, version: number): Observable<any> {
    return this.backend.get(`${this.configURI}/${id}?version=${version}`);
  }

  createNewConfiguration(
    id: string,
    configurationName: string
  ): Observable<any> {
    return this.backend.post(`${this.configURI}?station=${id}`, {
      Name: configurationName
    });
  }

  createConfigurationCopy(id: string): Observable<any> {
    return this.backend.post(`${this.configURI}/${id}`, {});
  }

  updateConfiguration(id: string, body: any): Observable<boolean> {
    return this.backend
      .put(this.configURI, id, body)
      .pipe(map(data => data.success));
  }

  deleteConfiguration(id: string): Observable<boolean> {
    return this.backend
      .delete(this.configURI, id)
      .pipe(map(data => data.success));
  }

  private convertFrontendToBackendStation(
    frontEndStation: WorkInstructionsStationDto
  ): BackendStationDto {
    return {
      Id: frontEndStation.id,
      Name: frontEndStation.name
    };
  }

  getUserStations(userId: string): Observable<Station[]> {
    return this.backend.get(`${this.userStationsURI}/${userId}`).pipe(
      map(data => {
        if (data) {
          return data.map(station => {
            return {
              id: station.id,
              label: station.name,
              isFinalQC: station.isFinalQC
            };
          });
        }
      })
    );
  }
}

interface BackendStationDto {
  Id: string;
  Name: string;
}
