import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class CheckForUpdateService {
  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const every1Min$ = interval(60 * 1000);
    const every1MinOnceAppIsStable$ = concat(appIsStable$, every1Min$);

    every1MinOnceAppIsStable$.subscribe(() => {
      this.updates.checkForUpdate();
    });
  }
}
