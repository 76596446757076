import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  constructor(private updates: SwUpdate) {}

  checkForUpdate() {
    this.updates.checkForUpdate();
  }

  updateApp(): Observable<any> {
    return from(this.updates.activateUpdate()).pipe(tap(() => document.location.reload()));
  }
}
