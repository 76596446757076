<div class="container">
  <app-editable-text
    id="enInput"
    name="enInput"
    [hidden]="
      !editModeActivated &&
      currentLanguage !== birthCertificateLanguagesEnum.ENGLISH
    "
    [whiteText]="whiteText"
    [languageLabel]="'En'"
    [editModeActivated]="editModeActivated"
    [(defaultValue)]="defaultValue.en"
    (defaultValueChange)="onTextChange()"
  >
  </app-editable-text>
  <app-editable-text
    id="spInput"
    name="spInput"
    [whiteText]="whiteText"
    [hidden]="
      !editModeActivated &&
      currentLanguage !== birthCertificateLanguagesEnum.SPANISH
    "
    [languageLabel]="'Es'"
    [editModeActivated]="editModeActivated"
    [(defaultValue)]="defaultValue.es"
    (defaultValueChange)="onTextChange()"
  >
  </app-editable-text>
</div>
