import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { addDays, getMonth, getWeek, getDate, format, isMonday, addMilliseconds, endOfDay, startOfDay } from 'date-fns';

import { Month } from '../interfaces/smr-progress-month.interface';
import { Week } from '../interfaces/smr-progress-week.interface';

@Injectable()
export class SmrProgressService {
  calendarRect: DOMRect;
  dateHover = new BehaviorSubject<Date>(null);
  displayRange: number;
  startDate: Date;

  private dateMemo = {};
  private datePositionMemo = {};
  private monthsMemo = {};
  private weeksMemo = {};

  computeMonths(): Month[] {
    const memoId = `${this.startDate}-${this.displayRange}`;
    if (!this.monthsMemo[memoId]) {
      const months: Month[] = [];
      for (let i = 0; i < this.displayRange; i++) {
        const day = addDays(this.startDate, i);
        const num = getMonth(day);
        const lastMonth = months.length && months[months.length - 1];
        if (lastMonth && lastMonth.num === num) {
          lastMonth.size++;
        } else {
          const label = `${format(day, 'MMM')}.`;
          months.push({ num, label, size: 1 });
        }
      }
      this.monthsMemo[memoId] = months;
    }
    return this.monthsMemo[memoId];
  }

  computeWeeks(): Week[] {
    const memoId = `${this.startDate}-${this.displayRange}`;
    if (!this.weeksMemo[memoId]) {
      const weeks: Week[] = [];
      for (let i = 0; i < this.displayRange; i++) {
        const day = addDays(this.startDate, i);
        const num = getWeek(day, { weekStartsOn: 1 });
        const lastWeek = weeks.length && weeks[weeks.length - 1];
        if (lastWeek && lastWeek.num === num) {
          lastWeek.size++;
        } else {
          const label = isMonday(day) ? `${getDate(day)}` : '';
          weeks.push({ num, size: 1, label });
        }
      }
      this.weeksMemo[memoId] = weeks;
    }
    return this.weeksMemo[memoId];
  }

  computeDatePosition({ min, date, max }: { min: Date; date: Date; max: Date }): string {
    if (min && date && max) {
      const memoId = `${min}-${date}-${max}`;
      if (!this.datePositionMemo[memoId]) {
        const range = max.valueOf() - min.valueOf();
        const position = date.valueOf() - min.valueOf();
        this.datePositionMemo[memoId] = `${(position / range) * 100}%`;
      }
      return this.datePositionMemo[memoId];
    }
  }

  updateHoveredDate(mouseClientX: number) {
    if (this.calendarRect) {
      const percentage = ((mouseClientX - this.calendarRect.left) / this.calendarRect.width) * 100;
      const date = this.computeDateFromPosition({
        min: startOfDay(this.startDate),
        percentage,
        max: endOfDay(addDays(this.startDate, this.displayRange - 1))
      });
      this.dateHover.next(date);
    }
  }

  private computeDateFromPosition({ min, percentage, max }: { min: Date; percentage: number; max: Date }): Date {
    if (min && percentage && max) {
      const memoId = `${min}-${percentage}-${max}`;
      if (!this.dateMemo[memoId]) {
        const range = max.valueOf() - min.valueOf();
        const diff = (percentage / 100) * range;
        const date = addMilliseconds(min, diff);
        this.dateMemo[memoId] = date;
      }
      return this.dateMemo[memoId];
    }
  }
}
