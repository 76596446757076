import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() confirmDelete = false;
  @Input() onlyConfirm = false;
  @Input() confirmButtonDisabled = false;
  @Input() confirmButtonText = 'Confirm';
  @Input() cancelButtonText = 'Cancel';
  @Input() invertButtons = false;

  @Input() header: string;
  @Input() headerIcon: string;
  @Input() color: string;
  @Output() cancelEmitter = new EventEmitter<any>();
  @Output() confirmEmitter = new EventEmitter<any>();

  cancel() {
    this.cancelEmitter.emit();
  }

  confirm() {
    this.confirmEmitter.emit();
  }
}
