import { Component, OnInit } from '@angular/core';
import { WorkInstructionsConformityToggleButtonData } from '../../interfaces/work-instructions-conformity-toggle-button-data.interface';
import { LanguageSelectorService } from '../../services/language-selector.service';

@Component({
  selector: 'app-language-toggle-button',
  templateUrl: './language-toggle-button.component.html',
  styleUrls: ['./language-toggle-button.component.scss']
})
export class LanguageToggleButtonComponent implements OnInit {
  selectedButton: number;
  data: WorkInstructionsConformityToggleButtonData[];

  constructor(private languageSelectorService: LanguageSelectorService) {}

  ngOnInit(): void {
    this.data = [
      {
        text: 'En',
        iconClass: 'fas fa-flag-usa'
      },
      {
        text: 'Es',
        iconClass: 'fas fa-flag'
      }
    ];
    this.selectedButton = this.languageSelectorService.get() === 'En' ? 0 : 1;
    this.languageSelectorService.set(this.data[this.selectedButton].text);
  }

  onButtonClick(index: number) {
    if (index !== this.selectedButton) {
      this.selectedButton = index;
      this.languageSelectorService.set(this.data[this.selectedButton].text);
    }
  }
}
