import { Injectable } from '@angular/core';
import { FabComponent } from '../models/component-data.model';
import { parse } from 'path';
import { BomPart } from '../models/bom-data.model';

@Injectable({
  providedIn: 'root'
})
export class PdmLinkService {
 
  constructor() { }

  public GetDrawingPdmLink(fabComponent: FabComponent)
  {
    return this.ComputePdmLinkFromFabComponent(fabComponent, ".SLDDRW")
  }

  public GetAssemblyPdmLink(fabComponent: FabComponent)
  {
     return this.ComputePdmLinkFromFabComponent(fabComponent, ".SLDASM")
  }

  public GetPartPdmLink(fileName: string, path: string)
  {
    return this.ComputPdmLink(path, fileName, ".SLDPRT")
  }

  public GetPdmLink(fileNameWithExtension: string, path: string)
  {
    if (!fileNameWithExtension.includes('.') || fileNameWithExtension.startsWith('.') || fileNameWithExtension.endsWith('.')) {
      throw new Error("Invalid filename format.");
    }
    
    const fileNameWithoutExtension = fileNameWithExtension.substring(0, fileNameWithExtension.lastIndexOf('.'));
    const extension = fileNameWithExtension.substring(fileNameWithExtension.lastIndexOf('.'));

    return this.ComputPdmLink(path, fileNameWithoutExtension, extension)
  }

  private ComputePdmLinkFromFabComponent(fabComponent: FabComponent, extension: string)
  {
    return this.ComputPdmLink(fabComponent.mainDocument.path, parse(fabComponent.mainDocument.fileName).name, extension)
  }

  private ComputPdmLink(path: string, name: string, extension: string)
  {
      const baseUrl="https://pdm2.fabbricausa.com/SOLIDWORKSPDM/FabbProjects";
      const inProjectPath= path;
      const fileNameWithoutExtension = name;
      const config ="%40";
      const fullLink=`${baseUrl}${inProjectPath}?view=preview&file=${fileNameWithoutExtension}${extension}&conf=${config}`;
      return fullLink;
  }
}
