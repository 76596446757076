import { Injectable } from '@angular/core';
import { LotStats, Stat } from '../interfaces/lot-stat';
import { FrameStatus } from '../../../enums/frame-status';
import { DesignStatus } from '../../../enums/design-status';
import { ProductionStatus } from '../../../enums/production-status';

@Injectable({
  providedIn: 'root'
})
export class StatFormatterService {
  formatOverallLot(lot: any): LotStats {
    const {
      toRelease,
      manufactured,
      installed,
      onHold,
      released,
      fpr
    } = this.transformProjectStats(lot.stat);

    const countDividerForPercent = lot.stat.count === 0 ? 1 : lot.stat.count;
    const releasedPercent = Math.round(
      (released * 100) / countDividerForPercent
    );
    const fprPercent = Math.round((fpr * 100) / countDividerForPercent);
    const manufacturedPercent = Math.round(
      (manufactured * 100) / countDividerForPercent
    );
    const onHoldPercent = Math.round((onHold * 100) / countDividerForPercent);
    const installedPercent = Math.round(
      (installed * 100) / countDividerForPercent
    );

    const stats: Stat[] = [];
    stats.push({ count: toRelease, color: 'transparent' } as Stat);
    stats.push({
      id: FrameStatus.FPR,
      name: 'FPR Sent To Production',
      count: fpr,
      percent: fprPercent,
      class: 'fpr',
      color: 'rgba(128, 0, 128, 1)'
    } as Stat);
    stats.push({
      id: FrameStatus.RELEASED,
      name: 'Released (FAR)',
      count: released,
      percent: releasedPercent,
      class: 'released',
      color: 'rgba(0, 137, 255, 1)'
    } as Stat);
    stats.push({
      id: FrameStatus.MANUFACTURED,
      name: 'Manufactured',
      count: manufactured,
      percent: manufacturedPercent,
      class: 'manufactured',
      color: 'rgba(255, 165, 0, 1)'
    } as Stat);
    stats.push({
      id: FrameStatus.INSTALLED,
      name: 'Installed',
      count: installed,
      percent: installedPercent,
      class: 'installed',
      color: 'rgba(37, 185, 0, 1)'
    } as Stat);
    stats.push({
      id: FrameStatus.ON_HOLD,
      name: 'On Hold',
      count: onHold,
      percent: onHoldPercent,
      class: 'on-hold',
      color: 'rgba(255, 21, 204, 1)'
    } as Stat);

    const formattedLot = {
      name: lot.name,
      count: lot.stat.count,
      stats,
      expanded: false,
      show: true,
      description: lot.description
    } as LotStats;
    return formattedLot;
  }

  formatDesignLot(lot: any): LotStats {
    const notStarted = lot.stat[DesignStatus.NOT_STARTED] || 0;
    const finished = lot.stat[DesignStatus.FINISHED] || 0;
    const inProgress = lot.stat[DesignStatus.IN_PROGRESS] || 0;
    const revision = lot.stat[DesignStatus.REVISION] || 0;

    const countDividerForPercent = lot.stat.count === 0 ? 1 : lot.stat.count;
    const inProgressPercent = Math.round(
      (inProgress * 100) / countDividerForPercent
    );
    const finishedPercent = Math.round(
      (finished * 100) / countDividerForPercent
    );
    const revisionPercent = Math.round(
      (revision * 100) / countDividerForPercent
    );

    const stats: Stat[] = [];
    stats.push({ count: notStarted, color: 'transparent' } as Stat);
    stats.push({
      id: DesignStatus.IN_PROGRESS,
      name: 'In Progress',
      count: inProgress,
      percent: inProgressPercent,
      class: 'in-progress',
      color: 'rgba(255, 255, 0, 1)'
    } as Stat);
    stats.push({
      id: DesignStatus.REVISION,
      name: 'Revision',
      count: revision,
      percent: revisionPercent,
      class: 'revision',
      color: 'rgba(237, 32, 4, 1)'
    } as Stat);
    stats.push({
      id: DesignStatus.FINISHED,
      name: 'Designed',
      count: finished,
      percent: finishedPercent,
      class: 'finished',
      color: 'rgba(109, 187, 255, 1)'
    } as Stat);

    const formattedLot = {
      id: lot.documentID,
      name: lot.name,
      count: lot.stat.count,
      stats,
      expanded: false,
      show: true
    } as LotStats;

    return formattedLot;
  }

  formatProductionStats(stats: any, productionStats: any) {
    const totalManufactured = stats?.manufactured ?? 0;
    const totalOnHold = stats?.onHold ?? 0;
    const totalUpr = stats?.upr ?? 0;

    const manufactured = productionStats?.manufactured ?? 0;
    const onHold = productionStats?.onHold ?? 0;
    const upr = productionStats?.upr ?? 0;

    const previouslyManufactured = totalManufactured - manufactured;
    const previouslyOnHold = totalOnHold - onHold;
    const previouslyUpr = totalUpr - upr;

    const totalUnits = stats?.count ?? 0;

    const denominator = totalUnits ? totalUnits : 1;

    const manufacturedPercent =
      Math.ceil(((manufactured * 100) / denominator) * 10) / 10;
    const previouslyManufacturedPercent =
      Math.ceil(((previouslyManufactured * 100) / denominator) * 10) / 10;

    const onHoldPercent = Math.ceil(((onHold * 100) / denominator) * 10) / 10;
    const previouslyOnHoldPercent =
      Math.ceil(((previouslyOnHold * 100) / denominator) * 10) / 10;

    const uprPercent = Math.ceil(((upr * 100) / denominator) * 10) / 10;
    const previouslyUprPercent =
      Math.ceil(((previouslyUpr * 100) / denominator) * 10) / 10;

    const rest =
      totalUnits -
      (manufactured +
        onHold +
        upr +
        previouslyOnHold +
        previouslyManufactured +
        previouslyUpr);

    const restPercent = Math.floor(((rest * 100) / denominator) * 10) / 10;

    return [
      {
        id: ProductionStatus.PREVIOUSLY_ON_HOLD,
        name: 'Previously On Hold',
        count: previouslyOnHold,
        percent: previouslyOnHoldPercent,
        class: 'previously-on-hold',
        color: '#FFBDF0'
      },
      {
        id: ProductionStatus.PRODUCTION_ON_HOLD,
        name: 'On Hold',
        count: onHold,
        percent: onHoldPercent,
        class: 'on-hold',
        color: '#FF15CC'
      },
      {
        id: ProductionStatus.PREVIOUSLY_MANUFACTURED,
        name: 'Previously Manufactured',
        count: previouslyManufactured,
        percent: previouslyManufacturedPercent,
        class: 'previously-manufactured',
        color: '#FEDC9E'
      },
      {
        id: ProductionStatus.PRODUCTION_MANUFACTURED,
        name: 'Manufactured',
        count: manufactured,
        percent: manufacturedPercent,
        class: 'manufactured',
        color: '#FFA500'
      },
      {
        id: ProductionStatus.PREVIOUSLY_UPR,
        name: 'Previously Upr',
        count: previouslyUpr,
        percent: previouslyUprPercent,
        class: 'previously-upr',
        color: '#FFB1A0'
      },
      {
        id: ProductionStatus.PRODUCTION_UPR,
        name: 'Upr',
        count: upr,
        percent: uprPercent,
        class: 'upr',
        color: '#c94848'
      },
      {
        id: ProductionStatus.REST,
        name: 'Rest',
        count: rest,
        percent: restPercent,
        class: 'rest',
        color: '#EBEBEB'
      }
    ] as Stat[];
  }

  formatProjectStats(stats: any) {
    const {
      toRelease,
      manufactured,
      installed,
      onHold,
      released,
      fpr
    } = this.transformProjectStats(stats);

    const countDividerForPercent = stats.count === 0 ? 1 : stats.count;
    const toReleasePercent = Math.round(
      (toRelease * 100) / countDividerForPercent
    );
    const releasedPercent = Math.round(
      (released * 100) / countDividerForPercent
    );
    const fprPercent = Math.round((fpr * 100) / countDividerForPercent);
    const manufacturedPercent = Math.round(
      (manufactured * 100) / countDividerForPercent
    );
    const installedPercent = Math.round(
      (installed * 100) / countDividerForPercent
    );
    const onHoldPercent = Math.round((onHold * 100) / countDividerForPercent);

    const formattedStats: Stat[] = [];
    formattedStats.push({
      id: FrameStatus.FPR,
      name: 'FPR Sent To Production',
      count: fpr,
      percent: fprPercent,
      class: 'fpr',
      color: 'rgba(128, 0, 128, 1)'
    } as Stat);
    formattedStats.push({
      id: FrameStatus.TO_RELEASE,
      name: 'To Release',
      count: toRelease,
      percent: toReleasePercent,
      class: 'to-release',
      color: 'rgba(117, 113, 113, 1)'
    } as Stat);
    formattedStats.push({
      id: FrameStatus.RELEASED,
      name: 'Released (FAR)',
      count: released,
      percent: releasedPercent,
      class: 'released',
      color: 'rgba(0, 137, 255, 1)'
    } as Stat);
    formattedStats.push({
      id: FrameStatus.MANUFACTURED,
      name: 'Manufactured',
      count: manufactured,
      percent: manufacturedPercent,
      class: 'manufactured',
      color: 'rgba(255, 165, 0, 1)'
    } as Stat);
    formattedStats.push({
      id: FrameStatus.INSTALLED,
      name: 'Installed',
      count: installed,
      percent: installedPercent,
      class: 'installed',
      color: 'rgba(37, 185, 0, 1)'
    } as Stat);
    formattedStats.push({
      id: FrameStatus.ON_HOLD,
      name: 'On Hold',
      count: onHold,
      percent: onHoldPercent,
      class: 'on-hold',
      color: 'rgba(255, 21, 204, 1)'
    } as Stat);

    return formattedStats;
  }

  private transformProjectStats(partitionedStats: any) {
    // transformedInstalled <- installed
    // transformedManufactured <- installed + manufactured
    // transformedFpr <-
    // transformedReleased <- transformedManufactured + onHold = installed + manufactured + onHold
    const toRelease = partitionedStats[FrameStatus.TO_RELEASE] || 0;
    let fpr = partitionedStats[FrameStatus.FPR] || 0;
    let manufactured = partitionedStats[FrameStatus.MANUFACTURED] || 0;
    const installed = partitionedStats[FrameStatus.INSTALLED] || 0;
    const onHold = partitionedStats[FrameStatus.ON_HOLD] || 0;
    let released = partitionedStats[FrameStatus.RELEASED] || 0;

    manufactured += installed;
    released += manufactured;
    fpr += released;

    return { toRelease, manufactured, installed, onHold, released, fpr };
  }

  getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
    const dates: Date[] = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  extractSpecialDates(dates: any[]) {
    const formattedDates: any[] = [];
    dates.forEach(d => {
      formattedDates.push({
        name: `${d.name} S`,
        date: this.formatDate(d.startDate)
      });
      formattedDates.push({
        name: `${d.name} E`,
        date: this.formatDate(d.endDate)
      });
    });

    return formattedDates;
  }

  extractDatasets(stats: any) {
    const frameCount: number =
      stats.statsOverall[stats.statsOverall.length - 1].stat.count;
    const mappedDatasets = new Map<
      string,
      {
        stats: number[];
        realStats: number[];
        color: string;
      }
    >();
    mappedDatasets.set('toRelease', {
      stats: [],
      realStats: [],
      color: 'transparent'
    });
    mappedDatasets.set('released', {
      stats: [],
      realStats: [],
      color: 'rgba(0, 137, 255, 1)' // blue
    });
    mappedDatasets.set('manufactured', {
      stats: [],
      realStats: [],
      color: 'rgba(255, 165, 0, 1)' // orange
    });
    mappedDatasets.set('onHold', {
      stats: [],
      realStats: [],
      color: 'rgba(255, 21, 204, 1)' // pink
    });
    mappedDatasets.set('installed', {
      stats: [],
      realStats: [],
      color: 'rgba(37, 185, 0, 1)' // green
    });
    mappedDatasets.set('fprSentToProd', {
      stats: [],
      realStats: [],
      color: 'rgba(128, 0, 128, 1)' // purple
    });

    const formattedSpecialDates =
      stats.specialDates && stats.specialDates.length > 0
        ? this.extractSpecialDates(stats.specialDates)
        : [];
    let maxDate = new Date(
      stats.statsOverall[stats.statsOverall.length - 1].to
    );

    let minDate = new Date(stats.statsOverall[1].to);

    formattedSpecialDates.forEach(specialDateObject => {
      const date = new Date(specialDateObject.date);
      if (date > maxDate) {
        maxDate = date;
      }
      if (date < minDate) {
        minDate = date;
      }
    });

    const dates = this.getDatesInRange(minDate, maxDate);
    dates.forEach(date => {
      const stat = stats.statsOverall.find(
        s => date >= new Date(s.from) && date <= new Date(s.to)
      );

      if (stat) {
        const tmpStat = {
          toRelease: stat.stat.toRelease ? stat.stat.toRelease : 0,
          released: stat.stat.released ? stat.stat.released : 0,
          onHold: stat.stat.onHold ? stat.stat.onHold : 0,
          manufactured: stat.stat.manufactured ? stat.stat.manufactured : 0,
          fprSentToProd: stat.stat.fPRSentToProd ? stat.stat.fPRSentToProd : 0,
          installed: stat.stat.installed ? stat.stat.installed : 0
        };

        const toRelease =
          tmpStat.toRelease +
          tmpStat.released +
          tmpStat.fprSentToProd +
          tmpStat.onHold +
          tmpStat.manufactured +
          tmpStat.installed;
        const fprSentToProd =
          tmpStat.fprSentToProd +
          tmpStat.released +
          tmpStat.onHold +
          tmpStat.manufactured +
          tmpStat.installed;
        const released =
          tmpStat.released +
          tmpStat.onHold +
          tmpStat.manufactured +
          tmpStat.installed;
        const onHold =
          tmpStat.onHold + tmpStat.manufactured + tmpStat.installed;
        const manufactured = tmpStat.manufactured + tmpStat.installed;
        const installed = tmpStat.installed;

        mappedDatasets.get('toRelease')?.stats.push(toRelease);
        mappedDatasets.get('toRelease')?.realStats.push(tmpStat.toRelease);
        mappedDatasets.get('released')?.stats.push(released);
        mappedDatasets.get('released')?.realStats.push(tmpStat.released);
        mappedDatasets.get('fprSentToProd')?.stats.push(fprSentToProd);
        mappedDatasets
          .get('fprSentToProd')
          ?.realStats.push(tmpStat.fprSentToProd);
        mappedDatasets.get('manufactured')?.stats.push(manufactured);
        mappedDatasets
          .get('manufactured')
          ?.realStats.push(tmpStat.manufactured);
        mappedDatasets.get('onHold')?.stats.push(onHold);
        mappedDatasets.get('onHold')?.realStats.push(tmpStat.onHold);
        mappedDatasets.get('installed')?.stats.push(installed);
        mappedDatasets.get('installed')?.realStats.push(tmpStat.installed);
      }
    });

    return {
      dates: dates.map(d => this.formatDate(d)),
      datasets: mappedDatasets,
      specialDates: formattedSpecialDates,
      frameCount: frameCount
    };
  }
}
