import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent {
  @Input() confirmDelete = false;
  header: string;
  content: string;
  result: boolean;
  actionBtnText = {
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    invertButtons: false
  };

  constructor(public bsModalRef: BsModalRef) {}

  confirm() {
    this.result = true;
    this.bsModalRef.hide();
  }

  cancel() {
    this.result = false;
    this.bsModalRef.hide();
  }
}
