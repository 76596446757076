import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ClickOnScreenService } from '../../../../../../shared/services/click-on-screen.service';
import { LineStationUserDto } from '../../interfaces/line-station-user.interface';
import { LineStationUserService } from '../../services/line-station-user.service';
import { SelectValue } from '../../../../../../shared/components/modal-select/interfaces/select-value';
import { Station } from '../../../../../../shared/interfaces/station.interface';
import { ModalLockService } from '../../../../../../shared/services/modal-lock.service';

@Component({
  selector: 'app-line-configuration-body',
  templateUrl: './line-configuration-body.component.html',
  styleUrls: ['./line-configuration-body.component.scss']
})
export class LineConfigurationBodyComponent implements OnInit, OnDestroy {
  @Input() station: any;
  @Output() deleteStation = new EventEmitter<string>();
  @Output() madeChanges = new EventEmitter<any>();

  possibleUsers: SelectValue[] = [];
  possibleGroups: SelectValue[] = [];
  possibleOptions: SelectValue[] = [];
  configurations: Station[] = [];

  allGroups: any[] = [];
  qcGroups: any[] = [];
  allUsers: SelectValue[] = [];
  qcUsers: SelectValue[] = [];

  showDeleteModal = false;
  showSelectUserModal = false;
  showSelectStationModal = false;
  loading = true;

  subscriptions: Subscription[] = [];

  constructor(
    private usersService: LineStationUserService,
    private clickOnScreenService: ClickOnScreenService,
    private modalLockService: ModalLockService
  ) {}

  ngOnInit(): void {
    if (this.station.isFinalQC) {
      this.subscriptions.push(
        this.usersService.qcGroups.subscribe(groups => {
          if (groups) {
            this.qcGroups = groups;
            groups.forEach(group => {
              const ind = this.station.groups.findIndex(g => g === group.id);
              if (ind >= 0) {
                this.station.groups[ind] = group;
              } else {
                if (!this.possibleGroups.some(g => g.id === group.id)) {
                  this.possibleGroups.push({
                    id: group.id,
                    label: group.name,
                    flag: true
                  });
                }
              }
            });
            this.possibleGroups.sort((a, b) =>
              a.label === b.label ? 0 : a.label < b.label ? -1 : 0
            );
          }
          this.subscriptions.push(
            this.usersService.qcUsers.subscribe(users => {
              if (users) {
                this.qcUsers = users;
                users.forEach(user => {
                  const ind = this.station.users.findIndex(u => u === user.id);
                  if (ind >= 0) {
                    this.station.users[ind] = { id: user.id, name: user.label };
                  } else {
                    if (
                      !this.possibleUsers.some(u => u.id === user.id) &&
                      !this.station.groups.some(g =>
                        g.users.some(u => u === user.id)
                      )
                    ) {
                      this.possibleUsers.push(user);
                    }
                  }
                });
                this.loading = false;
              }
            })
          );
        })
      );
    } else {
      this.subscriptions.push(
        this.usersService.groups.subscribe(groups => {
          if (groups) {
            this.allGroups = groups;
            groups.forEach(group => {
              const ind = this.station.groups.findIndex(g => g === group.id);
              if (ind >= 0) {
                this.station.groups[ind] = group;
              } else {
                if (!this.possibleGroups.some(g => g.id === group.id)) {
                  this.possibleGroups.push({
                    id: group.id,
                    label: group.name,
                    flag: true
                  });
                }
              }
            });
            this.possibleGroups.sort((a, b) =>
              a.label === b.label ? 0 : a.label < b.label ? -1 : 0
            );
          }
          this.subscriptions.push(
            this.usersService.users.subscribe(users => {
              if (users) {
                this.allUsers = users;
                users.forEach(user => {
                  const ind = this.station.users.findIndex(u => u === user.id);
                  if (ind >= 0) {
                    this.station.users[ind] = { id: user.id, name: user.label };
                  } else {
                    if (
                      !this.possibleUsers.some(u => u.id === user.id) &&
                      !this.station.groups.some(g =>
                        g.users.some(u => u === user.id)
                      )
                    ) {
                      this.possibleUsers.push(user);
                    }
                  }
                });
                this.loading = false;
              }
            })
          );
        })
      );
    }

    this.configurations = this.station.possibleConfigurations.map(c => ({
      id: c.id,
      label: c.name
    }));

    this.subscriptions.push(
      this.clickOnScreenService.clickOnScreenEvent.subscribe(() => {
        this.showDeleteModal = false;
      })
    );
  }

  getOptions(): SelectValue[] {
    return this.possibleGroups.concat(this.possibleUsers);
  }

  getTags() {
    return this.station.groups
      .map(g => ({ type: 'group', name: g.name, id: g.id }))
      .concat(
        this.station.users.map(u => ({ type: 'user', name: u.name, id: u.id }))
      );
  }

  configChanged(values: SelectValue[]) {
    const value = values[0];
    this.station.config = { id: value.id, name: value.label };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setShowSelectUserModal(val: boolean) {
    this.showSelectUserModal = val;
    this.modalLockService.lock(val);
  }

  emitClick() {
    this.clickOnScreenService.emitClick();
  }

  setShowSelectStationModal(val: boolean) {
    this.showSelectStationModal = val;
    this.modalLockService.lock(val);
  }

  getCurrentConfig(): Station {
    const id = this.station.config.id;
    const station = this.configurations.find(c => c.id === id);
    return { id, label: station.label, isFinalQC: station.isFinalQC };
  }

  setShowDeleteModal(val: boolean) {
    this.emitClick();
    this.showDeleteModal = val;
  }

  confirmStationDelete() {
    this.setShowDeleteModal(false);
    this.deleteStation.emit(this.station.id);
  }

  removeUser(userType: string, userId: number) {
    this.madeChanges.emit();
    if (userType === 'user') {
      const index = this.station.users.findIndex(x => x.id === userId);
      const user = this.station.users[index];
      this.station.users = [
        ...this.station.users.slice(0, index),
        ...this.station.users.slice(index + 1, this.station.users.length)
      ];
      this.possibleUsers.push(this.usersService.convertUserToStation(user));
      this.possibleUsers.sort((a, b) =>
        a.label === b.label ? 0 : a.label < b.label ? -1 : 0
      );
    } else if (userType === 'group') {
      const index = this.station.groups.findIndex(x => x.id === userId);
      const group = this.station.groups[index];
      this.station.groups = [
        ...this.station.groups.slice(0, index),
        ...this.station.groups.slice(index + 1, this.station.groups.length)
      ];
      this.possibleGroups.push({ id: group.id, label: group.name, flag: true });
      this.possibleGroups.sort((a, b) =>
        a.label === b.label ? 0 : a.label < b.label ? -1 : 0
      );
      group.users.forEach(u => {
        const userIndex = this.allUsers.findIndex(user => user.id === u);
        if (userIndex >= 0) {
          this.possibleUsers.push(this.allUsers[userIndex]);
        }
      });
      this.possibleUsers.sort((a, b) =>
        a.label === b.label ? 0 : a.label < b.label ? -1 : 0
      );
    }
  }

  addUser(selectedValues: SelectValue[]) {
    this.madeChanges.emit();
    const usersAddedByGroups = [];
    selectedValues.forEach(value => {
      if (!value.flag && !usersAddedByGroups.some(u => u === value.id)) {
        const user = value;
        const index = this.possibleUsers.findIndex(x => x.id === user.id);
        this.possibleUsers = [
          ...this.possibleUsers.slice(0, index),
          ...this.possibleUsers.slice(index + 1, this.possibleUsers.length)
        ];
        this.station.users.push({
          id: user.id,
          name: user.label
        } as LineStationUserDto);
      } else if (value.flag) {
        const group = !this.station.isFinalQC
          ? this.allGroups.find(g => g.id === value.id)
          : this.qcGroups.find(g => g.id === value.id);
        const index = this.possibleGroups.findIndex(x => x.id === group.id);
        this.possibleGroups = [
          ...this.possibleGroups.slice(0, index),
          ...this.possibleGroups.slice(index + 1, this.possibleGroups.length)
        ];
        group.users.forEach(u => {
          usersAddedByGroups.push(u);
          let userIndex = this.possibleUsers.findIndex(x => x.id === u);
          if (userIndex >= 0) {
            this.possibleUsers = [
              ...this.possibleUsers.slice(0, userIndex),
              ...this.possibleUsers.slice(
                userIndex + 1,
                this.possibleUsers.length
              )
            ];
          }
          userIndex = this.station.users.findIndex(x => x.id === u);
          if (userIndex >= 0) {
            this.station.users.splice(userIndex, 1);
          }
        });
        this.station.groups.push(group);
      }
    });
  }

  getContent(name: string) {
    return `Do you really want to delete the station "${name}" from the list?`;
  }
}
