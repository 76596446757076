<div
  *ngIf="!(screenType === ScreenTypeEnum.SMALL)"
  id="grid-container-l"
  [ngStyle]="{ 'grid-template-columns': gridTemplate }"
>
  <div id="qty-l">{{ qty }}</div>
  <div *ngFor="let title of titleList" [ngClass]="nameClass(title)">{{ title }}</div>
</div>
<div *ngIf="screenType === ScreenTypeEnum.SMALL" id="grid-container-s">
  <div id="qty-s">{{ qty }}</div>
  <div id="other-data-s">
    <div *ngFor="let title of titleList" [ngClass]="nameClass(title)">{{ title }}</div>
  </div>
</div>
