import { Pipe, PipeTransform } from '@angular/core';
import { SmrProgressProjectLotTuple } from '../interfaces/smr-progress-project-lot-tuple';

@Pipe({
  name: 'smrProjectLot'
})
export class SmrProjectLotPipe implements PipeTransform {
  transform(projectLotTuple: SmrProgressProjectLotTuple): string {
    const project = projectLotTuple.projectId;
    if (!project) {
      return '';
    }
    const lots = projectLotTuple.lots.filter(lot => !!lot);
    let fullLot = '';

    if (lots && lots.length > 0) {
      fullLot = '-';
      fullLot += lots.join('-');
    }
    return `${project}${fullLot}`;
  }
}
