<div id="wrapper">
  <div id="number">{{ floor?.id }}</div>
  <div id="smrs">
    <app-smr
      *ngFor="let smr of floor?.smrs"
      [smr]="smr"
      [startDate]="startDate"
      [displayRange]="displayRange"
    ></app-smr>
  </div>
</div>
