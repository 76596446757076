import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-round-button',
  templateUrl: './round-button.component.html',
  styleUrls: ['./round-button.component.scss']
})
export class RoundButtonComponent implements OnChanges {
  @Input() icon: string;
  @Input() sizepx: number;
  @Input() fontSize: string;
  @Input() backgroundColor: string;
  @Input() iconColor: string;
  @Input() border: string;
  @Input() shadow: string;
  @Input() text: string;
  @Input() isActive: boolean;

  refreshIcon: boolean;
  isSvg: boolean;

  size: string;

  ngOnChanges() {
    this.setSize(this.sizepx || 60);
    this.fontSize = this.fontSize || '30px';
    this.backgroundColor = this.backgroundColor || 'var(--fabbrica)';
    this.iconColor = this.iconColor || 'var(--white)';
    this.border = this.border || 'none';
    this.shadow = this.shadow || 'var(--shadow2)';
    if (typeof this.isActive === 'undefined') {
      this.isActive = true;
    }
    if (this.icon) {
      this.checkForSvg();
    }
  }

  checkForSvg() {
    this.isSvg = !!this.icon.match(/svg$/);
  }

  setSize(sizepx: number) {
    this.size = `${sizepx}px`;
  }
}
