import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BomButtonState } from '../models/bom-button-state.model';
import { BomMode } from '../../../enums/bom-mode.enum';

@Component({
  selector: 'app-bom-button-section',
  templateUrl: './bom-button-section.component.html',
  styleUrls: ['./bom-button-section.component.scss']
})
export class BomButtonSectionComponent implements OnInit {
  @Input() defaultState: BomButtonState;
  @Output() buttonStateUpdate = new EventEmitter();
  buttonState: BomButtonState;

  ngOnInit() {
    this.buttonState = this.defaultState;
  }

  manageMode(bomMode: BomMode) {
    this.buttonState.mode = bomMode;
    this.emitButtonState();
  }
  toggleFullScreen() {
    this.buttonState.expand = !this.buttonState.expand;
    this.emitButtonState();
  }

  emitButtonState() {
    this.buttonStateUpdate.emit(this.buttonState);
  }
}
