<div xmlns="http://www.w3.org/1999/html">
  <div *ngIf="editModeActivated" class="language-label align-self-end">
    {{ languageLabel }}
  </div>
  <textarea
    id="input"
    type="text"
    cdkTextareaAutosize
    [ngClass]="whiteText ? 'white-text' : ''"
    placeholder="Insert text here..."
    [disabled]="!editModeActivated"
    [value]="actualValue"
    (change)="changeName($event)"
  ></textarea>
</div>
