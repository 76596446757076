import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppLayoutComponent } from './core/components/app-layout/app-layout.component';
import { ConnectedGuard } from './core/guards/connected.guard';
import { DisconnectedGuard } from './core/guards/disconnected.guard';
import { PermissionsGuard } from './core/guards/permissions.guard';
import { StorageVersionGuard } from './core/guards/storage-version.guard';
import { Functionality } from './shared/enums/functionality.enum';

const routes: Routes = [
  {
    path: 'logout',
    loadChildren: () =>
      import('./modules/logout/logout.module').then(m => m.LogoutModule)
  },
  {
    path: 'login',
    canActivate: [DisconnectedGuard, StorageVersionGuard],
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'app',
    component: AppLayoutComponent,
    canActivate: [ConnectedGuard, StorageVersionGuard, PermissionsGuard],
    canActivateChild: [ConnectedGuard, StorageVersionGuard, PermissionsGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'projects/:id',
        data: { functionalities: [Functionality.Planification] },
        loadChildren: () =>
          import('./modules/projects/projects.module').then(
            m => m.ProjectsModule
          )
      },
      {
        path: 'production-dashboard',
        data: { functionalities: [Functionality.Planification] },
        loadChildren: () =>
          import(
            './modules/production-dashboard/production-dashboard.module'
          ).then(m => m.ProductionDashboardModule)
      },
      {
        path: 'machining',
        data: { functionalities: [Functionality['Machining Entry Access']] },
        loadChildren: () =>
          import(
            './modules/machining/machining.module'
          ).then(m => m.MachiningModule)
      },
      {
        path: 'report',
        data: { functionalities: [Functionality.Report] },
        loadChildren: () =>
          import('./modules/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'component/:qrScanString',
        loadChildren: () =>
          import('./modules/fab-component/fab-component.module').then(
            m => m.FabComponentModule
          )
      },
      {
        path: 'unit/:qrScanString',
        loadChildren: () =>
          import('./modules/fab-component/fab-component.module').then(
            m => m.FabComponentModule
          )
      },
      {
        path: 'user-preferences',
        loadChildren: () =>
          import('./modules/user-preferences/user-preferences.module').then(
            m => m.UserPreferencesModule
          )
      },
      {
        path: 'work-instructions',
        data: {
          functionalities: [Functionality['Work instructions management']]
        },
        canActivate: [ConnectedGuard, StorageVersionGuard, PermissionsGuard],
        canActivateChild: [
          ConnectedGuard,
          StorageVersionGuard,
          PermissionsGuard
        ],
        loadChildren: () =>
          import(
            './modules/work-instructions-manager/work-instructions-manager.module'
          ).then(m => m.WorkInstructionsManagerModule)
      },
      {
        path: 'tv-screen/:lineNumber',
        loadChildren: () =>
          import('./modules/tv-screen/tv-screen.module').then(
            m => m.TvScreenModule
          )
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'iframe',
    loadChildren: () =>
      import('./modules/iframe/iframe.module').then(m => m.IframeModule)
  },
  {
    path: 'offline',
    loadChildren: () =>
      import('./modules/offline-debug.module/offline-debug.module').then(
        m => m.OfflineDebugModule
      )
  },
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'app'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
