import { Injectable } from '@angular/core';
import { FilenameManipulationService } from './filename-manipulation.service';

@Injectable({
  providedIn: 'root'
})
export class BomLineDataService {
  constructor(private filenameManipulationService: FilenameManipulationService) {}

  getDataList(part: any, onlyOptions: boolean = false): Array<any> {
    const dataList = [
      [
        { id: '_qty', value: part._qty },
        { id: 'FileName', value: this.filenameManipulationService.getFilenameWithoutExtension(part.fileName) },
        { id: 'Description', value: part.description }
      ],
      [
        { id: 'Length', name: 'Length', value: part.length },
        { id: 'Height', name: 'Height', value: part.height },
        { id: 'Thickness', name: 'Thickness', value: part.thickness }
      ],
      [
        { id: 'ColorOrFinish', name: 'Color or Finish', value: part.colorOrFinish },
        { id: 'Material', name: 'Material', value: part.material }
      ],
      [
        { id: 'Revision', name: 'Rev', value: part.revision },
        { id: 'Status', name: 'Status', value: part.status }
      ],
      [
        { id: 'Cut', name: 'Cut', value: part.cut },
        { id: 'Phantom', name: 'Phantom', value: part.phantom }
      ]
    ];

    if (onlyOptions) {
      dataList.shift();
    }
    this.checkBlanks(dataList);
    return dataList;
  }

  checkBlanks(list: Array<any>) {
    list.forEach(item1 => {
      item1.forEach(item => {
        if (item.value === ' ' || item.value === null || item.value === undefined) {
          item.value = '---';
        }
      });
    });
  }
}
