<div
  class="btn"
  [class.disable]="disable"
  [class.inactive]="inactive"
  [ngStyle]="{
    'background-color': backgroundColor,
    color: fontColor,
    'font-size': iconSize,
    width: width?width: calculateWidth(title),
    height: height,
    'box-shadow': shadow,
    border: border,
    'justify-content': 'center'
  }"
>
  <div
    *ngIf="!noIcon && !svgIcon"
    class="icon-container"
    [ngStyle]="{
      'background-color': iconBackgroundColor,
      border: iconBorder,
      height: '35px',
      width: '35px',
      color: iconColor
    }"
  >
    <i [ngClass]="icon"></i>
  </div>

  <div
    *ngIf="svgIcon"
    class="icon-container"
    [ngStyle]="{
      'background-color': iconBackgroundColor,
      border: iconBorder,
      height: iconSize,
      width: iconSize,
      color: iconColor
    }"
  >
    <svg-icon
      [src]="icon"
      [svgStyle]="{
        'width': iconSize,
        'height': iconSize,
        fill: iconColor
      }"
    ></svg-icon>
  </div>

  <div
    [ngStyle]="{ 'font-size': size, 'font-weight': fontWeight }"
    [ngClass]="noIcon ? 'par no-padding' : 'par'"
    *ngIf="title"
  >
    {{ title }}
  </div>
</div>
