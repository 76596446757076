<app-loading-cog *ngIf="isWaiting()"></app-loading-cog>
<app-round-box *ngIf="isError()">No pdf found for this file</app-round-box>
<div id="page-nav" *ngIf="pageCount > 1">
  <div id="page-buttons">
    <app-round-button
      (click)="previousPage()"
      sizepx="30"
      fontSize="15"
      icon="fas fa-chevron-left"
    ></app-round-button>
    <app-round-button
      (click)="nextPage()"
      sizepx="30"
      fontSize="15"
      icon="fas fa-chevron-right"
    ></app-round-button>
  </div>
  <p>Page {{ page }}/{{ pageCount }}</p>
</div>
<app-round-button
  *ngIf="!getFullScreenState()"
  id="close-pdf"
  (click)="toggleFullScreen()"
  icon="fas fa-expand-alt"
></app-round-button>
<app-round-button
  *ngIf="getFullScreenState()"
  id="close-pdf"
  (click)="toggleFullScreen()"
  icon="fas fa-compress-alt"
></app-round-button>

<div id="zoom-buttons" *ngIf="pdfSrc && rangebarIsActive">
  <app-round-button
    (click)="multiplyZoom(0.66)"
    icon="fas fa-search-minus"
  ></app-round-button>
  <app-round-button
    (click)="multiplyZoom(1.33)"
    icon="fas fa-search-plus"
  ></app-round-button>  
</div>

<div id="fixed-pdf-view">
  <pdf-viewer
    [src]="pdfSrc"
    [original-size]="false"
    [zoom]="zoom"
    [show-all]="false"
    [page]="page"
    [rotation]="angle"
    [zoom-scale]="'page-fit'"
    (after-load-complete)="afterPdfLoad($event)"
  >
  </pdf-viewer>
</div>
