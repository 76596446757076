import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastService {
  constructor(private toastr: ToastrService) {
    this.toastr.toastrConfig.positionClass = 'toast-top-center';
    this.toastr.toastrConfig.enableHtml = true;
  }

  info(message: string, title: string = ''): void {
    this.toastr.info(message, title);
  }

  success(message: string, title: string = ''): void {
    this.toastr.success(message, title);
  }

  warning(message: string, title: string = ''): void {
    this.toastr.warning(message, title);
  }

  error(message: string, title: string = ''): void {
    this.toastr.error(message, title);
  }
}
