<div id="wrapper" [class.opened]="opened" (click)="toggle()" appDateHover>
  <div id="right-buttons">
    <div id="is-selected-icon" *ngIf="isSelected">
      <i class="fas fa-check"></i>
    </div>
    <app-round-button
      class="no-hover-button"
      *ngIf="!opened"
      border="solid 2px var(--menuItem-border1)"
      backgroundColor="white"
      iconColor="var(--menuItem-border1)"
      icon="fas fa-ellipsis-h"
      shadow="none"
      [sizepx]="20"
      fontSize="12px"
    ></app-round-button>
    <app-round-button
      class="hover-button"
      *ngIf="!opened"
      border="solid 2px var(--fabbrica)"
      backgroundColor="white"
      iconColor="var(--fabbrica)"
      icon="fas fa-ellipsis-h"
      shadow="none"
      [sizepx]="20"
      fontSize="12px"
    ></app-round-button>
    <app-round-button
      class="no-hover-button"
      *ngIf="opened"
      border="solid 2px var(--menuItem-border1)"
      backgroundColor="white"
      iconColor="var(--menuItem-border1)"
      icon="fas fa-caret-up"
      shadow="none"
      [sizepx]="20"
      fontSize="12px"
    ></app-round-button>
    <app-round-button
      class="hover-button"
      *ngIf="opened"
      border="solid 2px var(--fabbrica)"
      backgroundColor="white"
      iconColor="var(--fabbrica)"
      icon="fas fa-caret-up"
      shadow="none"
      [sizepx]="20"
      fontSize="12px"
    ></app-round-button>
  </div>

  <div id="number">{{ smr?.data?.fileName | smrNumber }}</div>
  <div id="states">
    <ng-container *ngIf="smr?.dates?.inProcess?.length">
      <div
        *ngFor="let date of smr.dates.inProcess"
        class="date in-progress"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.inModification?.length">
      <div
        *ngFor="let date of smr.dates.inModification"
        class="date in-modification"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.processed?.length">
      <div
        *ngFor="let date of smr.dates.processed"
        class="date processed"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.inRevision?.length">
      <div
        *ngFor="let date of smr.dates.inRevision"
        class="date in-revision"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.readyToShipOrPartiallyShipped?.length">
      <div
        *ngFor="let date of smr.dates.readyToShipOrPartiallyShipped"
        class="date ready-to-ship-or-partially-shipped"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.soonToBeLate?.length">
      <div
        *ngFor="let date of smr.dates.soonToBeLate"
        class="date soon-to-be-late"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.late?.length">
      <div
        *ngFor="let date of smr.dates.late"
        class="date late"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.shipped?.length">
      <div
        *ngFor="let date of smr.dates.shipped"
        class="date shipped"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.plannedOnSiteDate?.length">
      <div
        *ngFor="let date of smr.dates.plannedOnSiteDate"
        class="date planned-on-site-date"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
    <ng-container *ngIf="smr?.dates?.plannedProcessedDate?.length">
      <div
        *ngFor="let date of smr.dates.plannedProcessedDate"
        class="date planned-processed-date"
        appDateRangePosition
        [minDate]="startDate"
        [displayRange]="displayRange"
        [startDate]="date?.from"
        [endDate]="date?.to"
      ></div>
    </ng-container>
  </div>
  <div id="info" [class.opened]="opened" title="">
    <div class="title">
      <strong>{{ smr?.data?.fileName | smrId }}</strong> - {{ smr?.data?.description }}
    </div>
    <div id="view-button-container" *ngIf="isInIframe">
      <app-round-button
        sizepx="42"
        fontSize="21px"
        (click)="viewSmr($event, smr?.data?.documentID)"
        [icon]="'fas fa-eye'"
      ></app-round-button>
      <app-smr-selector [smrId]="smr?.data?.documentID"></app-smr-selector>
    </div>
    <div class="smr-info">
      <div>
        <span class="label">Lot</span>
        <span>{{ smr?.data | smrLot }}</span>
      </div>
      <div>
        <span class="label">Floor</span>
        <span>{{ smr?.data?.floor }}</span>
      </div>
      <div>
        <app-loading-dots [loading]="!hasCount">
          <span>{{ counts | smrAssembliesCountsPipe }}, </span>
          <span>{{ counts | smrMachinedPartsCountsPipe }}, </span>
          <span>{{ counts | smrExtrusionsCountsPipe }}</span>
        </app-loading-dots>
      </div>
      <div>
        <span class="label">Plant:</span>
        <span>{{ smr?.data | smrPlant }}</span>
      </div>
      <div>
        <ng-container *ngIf="smr?.dates?.plannedOnSiteDate?.length">
          <span class="label">Ship to site:</span>
          <ng-container *ngFor="let date of smr.dates.plannedOnSiteDate">
            {{ date.from | date }}
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
