<div [ngClass]="station.isFinalQC ? 'qcStation' : 'hover'" *ngIf="!loading">
  <div class="header-row ">
    <div class="left-container">
      <app-flat-icon
        *ngIf="!station.isFinalQC"
        [icon]="'fa fa-wrench'"
        size="59px"
        iconSize="30px"
        color="var(--menuItem-text1)"
      >
      </app-flat-icon>
      <svg-icon
        *ngIf="station.isFinalQC"
        id="ribbon-svg"
        [src]="'assets/icon/ribbon.svg'"
        [stretch]="true"
        [svgStyle]="{ 'width.px': 30, 'height.px': 35 }"
      >
      </svg-icon>

      <p>{{ station.name + ' / ' + station.config.name }}</p>

      <div *ngIf="station.isFinalQC" class="final-inspection-container">
        <p id="p-tag">Final inspection</p>
      </div>
    </div>

    <div class="right-container">
      <app-round-button
        *ngIf="!showDeleteModal && !station.isFinalQC"
        [icon]="'fas fa-trash'"
        class="delete-btn"
        [sizepx]="48"
        fontSize="26px"
        backgroundColor="var(--red)"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
        (click)="setShowDeleteModal(true); $event.stopPropagation()"
      ></app-round-button>

      <div
        class="confirm-delete"
        *ngIf="showDeleteModal && !station.isFinalQC"
        style="margin-right: 5px;"
        (click)="confirmStationDelete(); $event.stopPropagation()"
      >
        <p>Confirm</p>
        <div
          class="icon"
          (click)="setShowDeleteModal(false); $event.stopPropagation()"
        >
          <i class="fa fa-trash"></i>
        </div>
      </div>

      <app-round-button
        class="mr5px vertical-padding config-btn"
        [icon]="'fa fa-wrench'"
        [sizepx]="48"
        fontSize="26px"
        backgroundColor="#4CABDE"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
        (click)="setShowSelectStationModal(true)"
      ></app-round-button>
    </div>
  </div>

  <div *ngIf="station.users.length > 0" class="header">
    <p>Users</p>
  </div>

  <div class="users-container">
    <app-user-tag
      *ngFor="let tag of getTags()"
      [user]="tag"
      (removeEvent)="removeUser(tag.type, tag.id)"
    ></app-user-tag>

    <div class="add-user-btn" (click)="setShowSelectUserModal(true)">
      <app-flat-icon
        icon="fa fa-plus-circle"
        size="48px"
        iconSize="32px"
        color="var(--fabbrica-xdark)"
      >
      </app-flat-icon>
      <div>Add new user</div>
    </div>
  </div>

  <div style="border-bottom: 1px solid var(--menuItem-border1)"></div>
</div>

<app-modal-select
  *ngIf="showSelectUserModal"
  [header]="'Select a user'"
  [headerIcon]="'fa fa-user'"
  [optionIcon]="'fa fa-user'"
  [alternativeIcon]="'fa fa-users'"
  [multipleSelection]="true"
  [elements]="getOptions()"
  [selectedElement]="getOptions()[0]"
  (cancelEmitter)="setShowSelectUserModal(false)"
  (confirmEmitter)="addUser($event)"
></app-modal-select>

<app-modal-select
  *ngIf="showSelectStationModal"
  [header]="'Select a station'"
  [headerIcon]="'fa fa-wrench'"
  [optionIcon]="'fa fa-wrench'"
  [elements]="configurations"
  [selectedElement]="getCurrentConfig()"
  (cancelEmitter)="setShowSelectStationModal(false)"
  (confirmEmitter)="configChanged($event)"
></app-modal-select>

<div *ngIf="loading" style="margin-top: 25%;">
  <app-loading-cog></app-loading-cog>
</div>
