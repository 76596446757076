import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { LinksBackendService } from './services/links-backend.service';
import { HeaderTitleService } from '../../services/header-title.service';
import { DeviceService } from '../../../../shared/services/device.service';
import { LinesBackendService } from '../work-instructions-lines/services/lines-backend.service';
import { SelectValue } from '../../../../shared/components/modal-select/interfaces/select-value';

@Component({
  selector: 'app-work-instructions-links',
  templateUrl: './work-instructions-links.component.html',
  styleUrls: ['./work-instructions-links.component.scss']
})
export class WorkInstructionsLinksComponent implements OnInit, OnDestroy {
  loadingProjects = true;
  subscriptions: Subscription[] = [];
  possibleProjects: SelectValue[] = [];

  constructor(
    private linksService: LinksBackendService,
    private linesService: LinesBackendService,
    private router: Router,
    private headerService: HeaderTitleService,
    public deviceService: DeviceService
  ) {
    headerService.setHeader('CHECKLIST MANAGER / PROJECTS LINKS');
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.linksService.projects.subscribe(data => {
        if (data) {
          this.possibleProjects = data;
          this.loadingProjects = false;
        }
      })
    );

    this.linesService.getAll();
    this.linesService.getSoftDeleted();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  editionRequired(id: string) {
    this.router.navigateByUrl(`app/work-instructions/links/${id}`);
  }
}
