import { Injectable } from '@angular/core';

import { BackendService } from './backend.service';

@Injectable()
export class DocumentService {
  private DOCUMENT_URL = ':kind/:ref';

  constructor(private backendService: BackendService) {}

  getData(id: number) {
    return this.backendService.get(this.buildDocUrl(id));
  }

  private buildDocUrl(ref: number): string {
    return this.DOCUMENT_URL.replace(':kind', 'document').replace(':ref', `${ref}`);
  }
}
