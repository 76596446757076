import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kpi-indicator',
  templateUrl: './kpi-indicator.component.html',
  styleUrls: ['./kpi-indicator.component.scss']
})
export class KpiIndicatorComponent implements OnInit {
  @Input() text: string;
  @Input() count: number;
  @Input() uprCount: number;
  @Input() total: number;
  @Input() color: string;

  coloredWidth: number;
  label: string;

  updateAdvanceWidth() {
    this.coloredWidth = Math.min(this.count / this.total, 1) * 100;
  }

  ngOnInit(): void {
    this.updateAdvanceWidth();

    const totalLabel = this.total !== 0 ? this.total : 'NOE';
    this.label = this.text
      ? `${this.text} - ${this.count}/${totalLabel} [${this.uprCount}]`
      : `${this.count}/${totalLabel} [${this.uprCount}]`;
  }
}
