import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

import 'hammerjs';

import { DeviceService } from '../../services/device.service';
import { DeviceType } from '../../enums/device-type.enum';
import { FullScreenService } from '../../services/full-screen.service';
import { LoadingStatus } from '../../../shared/enums/loading-status.enum';
import { PdfService } from '../../services/pdf.service';

@Component({
  selector: 'app-pdf-container',
  templateUrl: './pdf-container.component.html',
  styleUrls: ['./pdf-container.component.scss']
})
export class PdfContainerComponent implements OnInit {
  @Input() pdfSrc: string;
  @Output() fullScreenStateEvent = new EventEmitter<boolean>();

  private viewOn: boolean;
  private firstInit: boolean;

  zoom: number;
  page: number;
  angle: number;
  private lastZoom: number;
  private lastMoveType = 4;
  rangebarIsActive: boolean;
  pageCount: number;
  private pdfLoading: LoadingStatus;

  private hammertime;

  constructor(
    private deviceService: DeviceService,
    private pdfService: PdfService,
    private fullScreenService: FullScreenService
  ) {
    this.zoom = 1;
    this.lastZoom = 1;
    this.page = 1;
    this.angle = 0;
  }

  ngOnInit() {
    this.pdfLoading = LoadingStatus.WAITING;
    this.firstViewInit();
  }

  getFullScreenState(): boolean {
    return this.fullScreenService.get();
  }

  firstViewInit() {
    // Only if it's the first init time and that the view is on
    this.rangeBarInit();
    setTimeout(() => {
      const myElement = document.getElementById('fixed-pdf-view').parentElement;
      this.hammertime = new Hammer.Manager(myElement, {
        recognizers: [[Hammer.Pinch, { enable: this.deviceService.actualDevice() !== DeviceType.COMPUTER }]]
      });
      this.pinchInit();
    }, 100);
  }

  rangeBarInit() {
    this.rangebarIsActive = this.deviceService.actualDevice() === DeviceType.COMPUTER;
  }

  pinchInit() {
    this.hammertime.on('pinch', ev => {
      this.setZoom(this.lastZoom * ev.scale);
      if (ev.eventType === this.lastMoveType) {
        // if this is the last pinch event of the sequence
        this.lastZoom = this.lastZoom * ev.scale; // we record the actual zoom
      }
    });
  }

  toggleFullScreen() {
    this.fullScreenService.toggle();
  }

  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  multiplyZoom(amount: number) {
    this.zoom *= amount;
  }

  setZoom(e: any) {
    if (e.detail) {
      this.zoom = e.detail.value;
    } else if (typeof e === 'number') {
      this.zoom = e;
    }
  }

  nextPage() {
    this.page = this.page >= this.pageCount ? this.pageCount : this.page + 1;
  }

  previousPage() {
    this.page = this.page <= 1 ? 1 : this.page - 1;
  }

  rotateRight() {
    this.angle = this.angle >= 270 ? 0 : this.angle + 90;
  }

  rotateLeft() {
    this.angle = this.angle < 90 ? 270 : this.angle - 90;
  }

  isWaiting(): boolean {
    return (
      (this.pdfService.pdfStatus === LoadingStatus.WAITING || this.pdfLoading === LoadingStatus.WAITING) &&
      !this.isError()
    );
  }
  isError(): boolean {
    return this.pdfService.pdfStatus === LoadingStatus.ERROR;
  }

  afterPdfLoad(event: any) {
    this.pdfLoading = LoadingStatus.COMPLETE;
    this.pageCount = event._pdfInfo.numPages;
  }
}
