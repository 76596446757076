<div
  class="head-container container justify-content-space-around"
  [class.disabled]="disabled"
  (click)="setExpanded(!expanded)"
>
  <div class="step-container">
    <div *ngIf="!editMode" class="circle-container can-disable">
      <div *ngIf="!editMode" [ngClass]="'circle ' + conformityColor">
        <strong>{{ displayConformity() }}</strong>
      </div>
    </div>

    <div
      *ngIf="(deviceService.isMobile | async) === false"
      class="icon-warning-container"
    >
      <div
        *ngIf="critical"
        [ngClass]="!editMode ? 'icon-warning' : 'icon-warning warning-hover'"
        [ngStyle]="{
          background: editMode ? '' : 'transparent',
          'box-shadow': editMode ? '' : 'none'
        }"
        (click)="setCritical(); $event.stopPropagation()"
      >
        <i class="fa fa-exclamation-triangle"></i>
      </div>
      <div
        *ngIf="!critical"
        [ngClass]="
          !editMode
            ? 'icon-warning gray4'
            : 'icon-warning gray4 warning-gray-hover'
        "
        [ngStyle]="{
          background: editMode ? '' : 'transparent',
          'box-shadow': editMode ? '' : 'none',
          color: editMode ? '' : 'transparent'
        }"
        (click)="setCritical(); $event.stopPropagation()"
      >
        <i class="fa fa-exclamation-triangle"></i>
      </div>
    </div>

    <div class="icon-warning-container can-disable">
      <div class="index">
        <p>{{ displayIndex() }}</p>
      </div>
    </div>

    <ng-container
      *ngIf="!editMode && (deviceService.isMobile | async) === false"
    >
      <p class="description-p can-disable" *ngIf="currentLanguage === 'En'">
        {{ description.en }}
      </p>
      <p class="description-p can-disable" *ngIf="currentLanguage === 'Es'">
        {{ description.es }}
      </p>
    </ng-container>

    <app-bilingual-editable-text
      class="editable-description can-disable"
      *ngIf="editMode && (deviceService.isMobile | async) === false"
      [editModeActivated]="true"
      [defaultValue]="description"
      [currentLanguage]="currentLanguage"
      (textChange)="onTextChange()"
      (click)="$event.stopPropagation()"
    >
    </app-bilingual-editable-text>
    <div class="tag-container">
      <div *ngIf="isFinalQC" id="final-inspection-step">Final Inspection</div>
      <div *ngIf="qcBypass" class="bypass-sign">
        Bypass available
      </div>
    </div>
  </div>

  <div class="icon-container can-disable">
    <app-round-button
      *ngIf="!expanded"
      [ngStyle]="{ 'margin-right': isFinalQC && editMode ? '64px' : '' }"
      class="expand-btn"
      [icon]="'fas fa-chevron-down'"
      [sizepx]="32"
      fontSize="17px"
      backgroundColor="transparent"
      iconColor="var(--menuItem-text1)"
      shadow="none"
    ></app-round-button>
    <app-round-button
      *ngIf="expanded"
      [ngStyle]="{ 'margin-right': isFinalQC && editMode ? '64px' : '' }"
      class="expand-btn"
      [icon]="'fas fa-chevron-up'"
      [sizepx]="32"
      fontSize="17px"
      backgroundColor="transparent"
      iconColor="var(--menuItem-text1)"
      shadow="none"
    ></app-round-button>

    <app-round-button
      *ngIf="editMode && !deleteExpanded && !isFinalQC"
      [icon]="'fas fa-trash'"
      class="remove-step-btn  can-disable"
      [sizepx]="48"
      fontSize="26px"
      backgroundColor="var(--red)"
      iconColor="var(--menuItem-background1)"
      shadow="var(--shadow1)"
      (click)="setDeleteExpanded(true); $event.stopPropagation()"
    ></app-round-button>

    <div
      class="confirm-delete remove-step-btn can-disable"
      *ngIf="editMode && deleteExpanded && !isFinalQC"
      (click)="emitRemove(); $event.stopPropagation()"
    >
      <div
        class="icon"
        (click)="setDeleteExpanded(false); $event.stopPropagation()"
      >
        <i class="fa fa-trash"></i>
      </div>
      <p>Confirm</p>
    </div>
  </div>
</div>

<div
  class="row-two"
  [class.disabled]="disabled"
  *ngIf="deviceService.isMobile | async"
>
  <div class="warning-container can-disable">
    <div
      *ngIf="critical"
      [ngClass]="!editMode ? 'icon-warning' : 'icon-warning warning-hover'"
      [ngStyle]="{
        background: editMode ? '' : 'transparent',
        'box-shadow': editMode ? '' : 'none'
      }"
      (click)="setCritical(); $event.stopPropagation()"
    >
      <i class="fa fa-exclamation-triangle"></i>
    </div>
    <div
      *ngIf="!critical"
      [ngClass]="
        !editMode
          ? 'icon-warning gray4'
          : 'icon-warning gray4 warning-gray-hover'
      "
      [ngStyle]="{
        background: editMode ? '' : 'transparent',
        'box-shadow': editMode ? '' : 'none',
        color: editMode ? '' : 'transparent'
      }"
      (click)="setCritical(); $event.stopPropagation()"
    >
      <i class="fa fa-exclamation-triangle"></i>
    </div>
  </div>

  <ng-container
    class="can-disable"
    *ngIf="!editMode && (deviceService.isMobile | async)"
  >
    <p class="description-p" *ngIf="currentLanguage === 'En'">
      {{ description.en }}
    </p>
    <p class="description-p" *ngIf="currentLanguage === 'Es'">
      {{ description.es }}
    </p>
  </ng-container>

  <app-bilingual-editable-text
    class="editable-description"
    *ngIf="editMode && (deviceService.isMobile | async)"
    [editModeActivated]="true"
    [defaultValue]="description"
    [fontSize]="'14px'"
    [currentLanguage]="currentLanguage"
    (textChange)="onTextChange()"
    (click)="$event.stopPropagation()"
  >
  </app-bilingual-editable-text>
</div>

<div
  class="main-content"
  [class.disabled]="disabled"
  [hidden]="!expanded"
  (click)="setExpanded(!expanded)"
>
  <div
    *ngIf="expanded"
    class="inputs container column-layout can-disable"
    [ngStyle]="{
      'margin-left': editMode ? '' : '60px',
      width: editMode ? '100%' : 'calc(100% - 60px)'
    }"
  >
    <app-standard-input
      *ngFor="let input of inputs; let i = index"
      [input]="input"
      [index]="i"
      [disabled]="disabled"
      [editModeActivated]="editMode"
      [currentLanguage]="currentLanguage"
      [comment]="comment"
      [pictures]="pictures"
      (resultEmitter)="emitResult($event, i)"
      (removedInput)="removeInput($event)"
      (textChanged)="onTextChange()"
      (pictureAdd)="handleNewPicture($event)"
      (pictureDelete)="handlePictureDeletion($event)"
      (picturesSaved)="picturesSaved.emit($event)"
      (commentSaved)="commentSaved.emit($event)"
      (click)="$event.stopPropagation()"
    ></app-standard-input>
  </div>
  <div class="input-dp-container can-disable" style="margin-top: 10px;">
    <app-new-input-dropdown
      *ngIf="editMode"
      (click)="$event.stopPropagation()"
      (buttonSelected)="addInput($event)"
    >
    </app-new-input-dropdown>
  </div>

  <div class="container toggle-container column-layout align-items-end">
    <app-slider
      *ngIf="editBypass"
      class="bypass-toggle"
      label="Bypass step"
      [value]="qcBypass"
      (valueChange)="onBypassToggleChange()"
    ></app-slider>
    <app-conformity-toggle-button
      class="toggle can-disable"
      [stepIndex]="index"
      [selectedDefault]="conformitySelected"
      [editMode]="editMode"
      [disabled]="disabled"
      (buttonToggled)="setConformity($event)"
      (click)="$event.stopPropagation()"
    >
    </app-conformity-toggle-button>
  </div>

  <ng-container *ngIf="isFinalQC && !editMode">
    <app-photo-input
      [pictures]="pictures"
      [comment]="comment"
      [editMode]="editMode"
      [disabled]="disabled"
      (pictureAdd)="handleNewPicture($event)"
      (pictureDelete)="handlePictureDeletion($event)"
      (picturesSaved)="picturesSaved.emit($event)"
      (commentSaved)="commentSaved.emit($event)"
    >
    </app-photo-input>
  </ng-container>
</div>
