import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checklist-info',
  templateUrl: './checklist-info.component.html',
  styleUrls: ['./checklist-info.component.scss']
})
export class ChecklistInfoComponent {
  @Input() id: any;
  @Input() name: string;
  @Input() createdAt: Date;
  @Input() updatedAt: Date;
  @Input() createdBy: string;
  
  @Output() editPressed = new EventEmitter<any>();
  @Output() clonePressed = new EventEmitter<any>();
  @Output() removePressed = new EventEmitter<any>();
  @Output() titleChange = new EventEmitter<string>();
  
  expanded = false;

  setExpanded() {
    this.expanded = !this.expanded;
  }

  editId(e: Event) {
    e.stopPropagation();
    this.editPressed.emit(this.id);
  }

  cloneId(e: Event) {
    e.stopPropagation();
    this.clonePressed.emit(this.id);
  }

  remove(e: Event) {
    e.stopPropagation();
    this.removePressed.emit(this.id);
  }

  titleChanged(e: any) {
    this.titleChange.emit(e);
  }
}
