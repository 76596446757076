import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, UrlTree, Router } from '@angular/router';

import { ClearStorageService } from '../../shared/services/clear-storage.service';
import { StorageVersionService } from '../../shared/services/storage-version.service';
import { ToastService } from '../../shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class StorageVersionGuard implements CanActivate, CanActivateChild {
  constructor(
    private storageVersion: StorageVersionService,
    private clearStorageService: ClearStorageService,
    private router: Router,
    private toastService: ToastService
  ) {}

  canActivate(): boolean | UrlTree {
    return this.checkStorageVersion();
  }

  canActivateChild(): boolean | UrlTree {
    return this.checkStorageVersion();
  }

  private checkStorageVersion(): boolean | UrlTree {
    if (this.storageVersion.isValid()) {
      return true;
    }
    this.clearStorageService.clearOnUpdate();
    this.storageVersion.set();
    this.toastService.warning('Due to an application update, you have been logged out.');
    return this.router.parseUrl('/logout');
  }
}
