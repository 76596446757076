import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { RecursiveItem } from '../../../../../../shared/components/recursive-linker/interfaces/recursive-item';
import { LinksBackendService } from '../../services/links-backend.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { LinesBackendService } from '../../../work-instructions-lines/services/lines-backend.service';
import { HeaderTitleService } from '../../../../services/header-title.service';
import { DeviceService } from '../../../../../../shared/services/device.service';
import { SelectValue } from '../../../../../../shared/components/modal-select/interfaces/select-value';

@Component({
  selector: 'app-linker',
  templateUrl: './linker.component.html',
  styleUrls: ['./linker.component.scss']
})
export class LinkerComponent implements OnInit, OnDestroy {
  unsavedChanges = false;

  links: RecursiveItem;
  modifiedLinks: any[] = [];
  subscriptions: Subscription[] = [];
  loadingLinks = true;
  possibleLines: SelectValue[] = [];
  allPossibleLines: SelectValue[] = [];
  lines: any[] = [];
  title = '';

  constructor(
    private activeRoute: ActivatedRoute,
    private linksService: LinksBackendService,
    private linesService: LinesBackendService,
    private toastrService: ToastService,
    private headerService: HeaderTitleService,
    public deviceService: DeviceService
  ) {}

  detectChanges() {
    this.unsavedChanges = true;
  }

  ngOnInit(): void {
    const id = this.activeRoute.snapshot.params.id;
    this.subscriptions.push(
      this.linksService
        .getLinks(id)
        .pipe(map(data => this.linksService.transformLinks(data)))
        .subscribe(data => {
          this.links = data;
          this.headerService.setHeader(
            'LINKS IN PROJECT ' + this.links.name.slice(0, 5)
          );
          this.title = `Linking Project "${this.links.name}"`;
          this.loadingLinks = false;
        })
    );

    this.subscriptions.push(
      this.linesService.lines
        .pipe(
          map(data => {
            if (data) {
              return data.map(line => {
                return { id: line.id, label: line.name };
              });
            }
          })
        )
        .subscribe(data => {
          if (data) {
            this.possibleLines = [
              { id: 'inherited', label: 'Inherited' },
              ...data,
              { id: 'no-template', label: 'No Template' }
            ];
          }
        })
    );

    this.subscriptions.push(
      this.linesService.allLines
        .pipe(
          map(data => {
            if (data) {
              return data.map(line => {
                this.lines.push(line);
                return { id: line.id, label: line.name };
              });
            }
          })
        )
        .subscribe(data => {
          if (data) {
            this.allPossibleLines = [
              { id: 'inherited', label: 'Inherited' },
              ...data,
              { id: 'no-template', label: 'No Template' }
            ];
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSave() {
    const validLinks = [];
    this.linksService.getValidLinks(this.links, validLinks);
    const resp = validLinks.map(link => {
      return {
        Project: link.Project,
        Lot: link.Lot,
        SubLot: link.SubLot,
        SubSubLot: link.SubSubLot,
        Frame: link.Frame,
        LineConfiguration: link.LineConfiguration,
        LineConfigurationVersion: link.LineConfiguration
          ? this.lines.find(line => line.id === link.LineConfiguration).version
          : -1
      };
    });

    this.subscriptions.push(
      this.linksService
        .linkProject(this.links.longId, resp)
        .subscribe(success => {
          if (success) {
            this.unsavedChanges = false;
            this.toastrService.success('Project successfully linked');
          } else {
            this.toastrService.error('Error linking project');
          }
        })
    );
  }
}
