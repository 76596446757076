<div class="container">
  <app-round-button
    [icon]="'fa fa-times'"
    class="close-picture-button"
    [sizepx]="65"
    fontSize="48px"
    backgroundColor="var(--red)"
    iconColor="var(--menuItem-background1)"
    shadow="var(--shadow1)"
    (click)="close()"
  ></app-round-button>

  <div class="picture-container">
    <div class="gallery">
      <app-round-button
        *ngIf="(deviceService.isMobile | async) === false"
        [ngStyle]="{ visibility: pictureSelectedIndex === 0 ? 'hidden' : '' }"
        [icon]="'fa fa-arrow-left'"
        class="arrow-button"
        [sizepx]="65"
        fontSize="38px"
        backgroundColor="var(--menuItem-text1)"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
        (click)="slidePicture('left')"
      ></app-round-button>
      <img
        [src]="base64Prefix + pictures[pictureSelectedIndex].data"
        class="picture"
      />
      <app-round-button
        *ngIf="(deviceService.isMobile | async) === false"
        [ngStyle]="{
          visibility:
            pictureSelectedIndex === pictures.length - 1 ? 'hidden' : ''
        }"
        [icon]="'fa fa-arrow-right'"
        class="arrow-button"
        [sizepx]="65"
        fontSize="38px"
        backgroundColor="var(--menuItem-text1)"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
        (click)="slidePicture('right')"
      ></app-round-button>
    </div>
    <p class="picture-name">{{ pictures[pictureSelectedIndex].name }}</p>
    <div class="buttons-container">
      <app-round-button
        *ngIf="deviceService.isMobile | async"
        [ngStyle]="{ visibility: pictureSelectedIndex === 0 ? 'hidden' : '' }"
        [icon]="'fa fa-arrow-left'"
        class="arrow-button"
        [sizepx]="65"
        fontSize="38px"
        backgroundColor="var(--menuItem-text1)"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
        (click)="slidePicture('left')"
      ></app-round-button>
      <app-round-button
        [icon]="'fa fa-download'"
        class="download-picture-button"
        [sizepx]="65"
        fontSize="38px"
        backgroundColor="var(--fabbrica)"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
        (click)="download()"
      ></app-round-button>
      <app-round-button
        *ngIf="deviceService.isMobile | async"
        [ngStyle]="{
          visibility:
            pictureSelectedIndex === pictures.length - 1 ? 'hidden' : ''
        }"
        [icon]="'fa fa-arrow-right'"
        class="arrow-button"
        [sizepx]="65"
        fontSize="38px"
        backgroundColor="var(--menuItem-text1)"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
        (click)="slidePicture('right')"
      ></app-round-button>
    </div>
  </div>
</div>
