<div id="wrapper" [ngClass]="{ open: isOpen, close: !isOpen }">
  <div id="label">
    <span id="project">{{ project }}</span>
    <span *ngIf="lots">-</span>
    <span id="lots">{{ lots }}</span>
  </div>
  <div id="dates" appDateHover>
    <ng-container *ngFor="let floor of floors">
      <span
        *ngIf="floor?.startDate"
        class="start date"
        [attr.data-id]="floor?.id"
        appDatePosition
        [date]="floor.startDate"
        [minDate]="startDate"
        [displayRange]="displayRange"
      >
      </span>
    </ng-container>
  </div>
</div>
