import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BackendService } from './backend.service';
import { LoadingStatus } from '../enums/loading-status.enum';
import { StorageService } from './storage.service';
import { StorageKey } from '../enums/storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private PDF_URL = 'pdmFiles/idNumber?rev=revNumber';
  public pdfStatus: LoadingStatus;
  constructor(private backendService: BackendService, private storageService: StorageService) {
    this.pdfStatus = LoadingStatus.WAITING;
  }

  public getPdfFromDatabase(id: number, rev: number): Observable<Blob> {
    if (id && rev) {
      this.pdfStatus = LoadingStatus.WAITING;
      return this.backendService.get(this.buildUrl(id, rev), { responseType: 'blob' as 'json' }, false);
    } else {
      this.pdfStatus = LoadingStatus.ERROR;
    }
  }

  private buildUrl(id: number, rev: number): string {
    return this.PDF_URL.replace('idNumber', `${id.toString()}`).replace('revNumber', `${rev.toString()}`);
  }

  public setPdfBlobUrl(url: string) {
    url ? (this.pdfStatus = LoadingStatus.COMPLETE) : (this.pdfStatus = LoadingStatus.ERROR);
    this.storageService.setDeprecated(StorageKey.PDFURL, url);
  }
  public getPdfBlobUrl(): string {
    return this.storageService.getDeprecated(StorageKey.PDFURL);
  }
  public removePdfUrl() {
    this.pdfStatus = LoadingStatus.WAITING;
    this.storageService.removeDeprecated(StorageKey.PDFURL);
  }
}
