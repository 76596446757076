<div id="full-container" (click)="toggleExpand()">
  <app-round-button
    data-cy="plus-button"
    *ngIf="!isExpanded"
    [sizepx]="iconSize"
    [ngStyle]="{ left: getOffsetDistance('circle') }"
    [backgroundColor]="iconBackgroundColor"
    icon="fas fa-plus"
    [iconColor]="iconColor"
    [border]="buttonBorder"
    [shadow]="'none'"
  ></app-round-button>
  <app-round-button
    data-cy="minus-button"
    *ngIf="isExpanded"
    [sizepx]="iconSize"
    [ngStyle]="{ left: getOffsetDistance('circle') }"
    [backgroundColor]="iconBackgroundColor"
    [iconColor]="iconColor"
    [border]="buttonBorder"
    icon="fas fa-minus"
    [shadow]="'none'"
  ></app-round-button>
  <div id="bar-container">
    <div id="bar-level" *ngFor="let depth of treeDepths">
      <div
        id="top-bar"
        *ngIf="barsStatus[depth].topBar"
        class="bar"
        [ngStyle]="{ left: getOffsetDistance('bar', depth), width: getBarWidth() }"
      ></div>
      <div
        id="bottom-bar"
        *ngIf="barsStatus[depth].bottomBar"
        class="bar"
        [ngStyle]="{ left: getOffsetDistance('bar', depth), width: getBarWidth() }"
      ></div>
    </div>
  </div>
</div>
