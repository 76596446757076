import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { BomIndenterState } from '../models/bom-indenter-state.model';

@Component({
  selector: 'app-bom-tree-indenter',
  templateUrl: './bom-tree-indenter.component.html',
  styleUrls: ['./bom-tree-indenter.component.scss']
})
export class BomTreeIndenterComponent implements OnInit, OnChanges {
  @Input() indenterState: BomIndenterState;
  @Output() isExpandedChanged = new EventEmitter();
  iconColor: string;
  iconSize: number;
  private barWidth: number;
  isExpanded: boolean;
  iconBackgroundColor: string;
  private unitOffsetDistance: number;
  treeDepths: Array<number>;
  barsStatus: Array<any>;
  buttonBorder: string;

  constructor() {
    this.iconSize = 45;
    this.barWidth = 10;
    this.unitOffsetDistance = 17;
  }

  ngOnInit() {
    this.isExpanded = false;
    if (this.indenterState) {
      this.treeDepths = [...Array(this.indenterState.treeDepth).keys()];
    }
    this.manageBars();
  }
  ngOnChanges() {
    if (this.indenterState) {
      this.manageIconSrc();
    }
  }

  manageIconSrc() {
    if (this.indenterState.hasChildren) {
      this.iconBackgroundColor = 'var(--white)';
      this.iconColor = 'var(--fabbrica)';
      this.buttonBorder = 'solid 2px var(--fabbrica)';
    } else {
      this.iconColor = 'var(--menuItem-border1)';
      this.iconBackgroundColor = 'var(--menuItem-border1)';
      this.buttonBorder = 'none';
    }
  }

  getOffsetDistance(item: string, depth: number = 0): string {
    let offset = 0;
    if (item === 'circle' && this.indenterState) {
      offset = (this.iconSize / 2 - this.barWidth / 2) * (this.indenterState.treeDepth - 1);
    } else if (item === 'bar') {
      offset = (this.iconSize / 2 - this.barWidth / 2) * (depth + 1);
    }
    return `${offset.toString()}px`;
  }

  getIconSize(): string {
    return `${this.iconSize.toString()}px`;
  }
  getBarWidth(): string {
    return `${this.barWidth.toString()}px`;
  }

  toggleExpand() {
    if (this.indenterState.hasChildren) {
      this.isExpanded = !this.isExpanded;
      this.manageIconSrc();
      this.manageBars();
      this.isExpandedChanged.emit(this.isExpanded);
    }
  }

  manageBars() {
    this.barsStatus = [];
    // initiate the bars of all the levels
    if (this.treeDepths) {
      this.treeDepths.forEach((depth, index, arr) => {
        if (depth < arr.length - 1) {
          this.barsStatus.push({ topBar: true, bottomBar: true });
        } else {
          this.barsStatus.push({ topBar: false, bottomBar: false });
        }
      });
    }

    if (this.barsStatus.length > 0) {
      if (this.isExpanded) {
        // manage the bottom bar at the level of the component
        this.barsStatus[this.barsStatus.length - 1].bottomBar = true;
      } else {
        this.barsStatus[this.barsStatus.length - 1].bottomBar = false;
      }
    }

    if (this.indenterState && this.barsStatus.length > 1) {
      if (this.indenterState.isLast && this.indenterState.treeDepth > 1) {
        // manage the bottom bar at the level of the component
        this.barsStatus[this.barsStatus.length - 2].bottomBar = false;
      }
    }
  }
}
