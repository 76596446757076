import { apiFormatter, fromStringToDate } from 'src/app/shared/utils/api.utils';
import { SmrProgressPerLotDataApi } from './smr-progress-data.api.interface';
import { SmrProgressProjectLotTuple } from './smr-progress-project-lot-tuple';

export interface SmrProgressInProjectLot {
  projectLotsTuple: SmrProgressProjectLotTuple;
  data: SmrProgressData;
}

export class SmrProgressData {
  kind: string;
  floors: SmrProgressFloorShared[];
  categories: SmrProgressCategory[];

  constructor(data: SmrProgressPerLotDataApi) {
    Object.assign(this, fromStringToDate(apiFormatter(data)));
    this.categories?.forEach(category => {
      category?.floors.forEach(floor => {
        const sharedFloorInfo = this.floors.find(f => f.id === floor.id);
        if (sharedFloorInfo) {
          Object.setPrototypeOf(floor, sharedFloorInfo);
        }
      });
    });
  }
}

export interface SmrProgressFloorShared {
  id: string;
  startDate: Date;
}

export interface SmrProgressCategory {
  id: string;
  name: string;
  floors: SmrProgressFloor[];
}

export interface SmrProgressFloor extends SmrProgressFloorShared {
  id: string;
  plannedProcessedDate: Date;
  plannedOnSiteDate: Date;
  smrs?: SmrProgressSmr[];
}

export interface SmrProgressSmr {
  dates: SmrProgressSmrDates;
  data: SmrProgressSmrData;
}

export interface SmrProgressSmrDates {
  inProcess: DateRange[];
  inModification: DateRange[];
  processed: DateRange[];
  inRevision: DateRange[];
  readyToShipOrPartiallyShipped: DateRange[];
  soonToBeLate: DateRange[];
  late: DateRange[];
  shipped: DateRange[];
  plannedOnSiteDate: DateRange[];
  plannedProcessedDate: DateRange[];
}

export interface SmrProgressSmrData {
  assembliesCount: number;
  description: string;
  documentID: number;
  fileName: string;
  floor: number;
  lot: string;
  machinedExtrusionsCount: { buy: number; make: number };
  subLot: string;
  subSubLot: string;
  plant: string;
}

interface DateRange {
  from: Date;
  to: Date;
}
