import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

import { BarcodeScannerService } from 'src/app/shared/services/barcode-scanner.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { DeviceType } from 'src/app/shared/enums/device-type.enum';
import { environment } from 'src/environments/environment';
import { OutsideClickService } from 'src/app/shared/services/outside-click.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { ToggleMenuServiceService } from '../../../shared/services/toggle-menu-service.service';
import { ClickOnScreenService } from '../../../shared/services/click-on-screen.service';
import { RfidKeyboardScannerService } from 'src/app/shared/services/rfid-keyboard-scanner.service';

declare var gtag;

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
  classGrid: string;
  classMenu: string;
  showMenu = false;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.barcodeScannerService.newKeyboardInput(event);
    this.rfidKeyboardScannerService.newKeyboardInput(event);
  }

  constructor(
    private router: Router,
    public deviceService: DeviceService,
    private outsideClickService: OutsideClickService,
    private sessionService: SessionService,
    private barcodeScannerService: BarcodeScannerService,
    private rfidKeyboardScannerService: RfidKeyboardScannerService,
    private clickOnScreenService: ClickOnScreenService,
    public toggleMenuService: ToggleMenuServiceService
  ) {
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      this.sessionService.updateActiveSession(event.urlAfterRedirects); // Save activeSession

      if (environment.production) {
        // Google analytics at each router navigation
        gtag('config', 'UA-155615080-1', {
          page_path: event.urlAfterRedirects
        });
      }
    });

    this.deviceService.isMobile.subscribe(() => this.initClasses());

    this.toggleMenuService.showMenu.subscribe(data => {
      this.showMenu = data ? true : false;
    });
  }

  clickOnSticky() {
    this.toggleMenuService.toggle();
  }

  ngOnInit() {
    this.initClasses();
  }

  public initClasses() {
    this.classGrid =
      this.deviceService.actualDevice() === DeviceType.COMPUTER ||
      this.deviceService.isMobile.getValue()
        ? 'grid-parralel'
        : 'grid-over';
    this.classMenu =
      this.deviceService.actualDevice() === DeviceType.COMPUTER
        ? 'menu-parralel'
        : 'menu-over';
  }

  clickOutOfTheMenu() {
    this.outsideClickService.emitClic();
  }

  clickOnScreen() {
    this.clickOnScreenService.emitClick();
  }
}
