import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edition-header',
  templateUrl: './edition-header.component.html',
  styleUrls: ['./edition-header.component.scss']
})
export class EditionHeaderComponent {
  @Input() backRoute: string;
  @Input() title: string;
  @Output() saved = new EventEmitter<any>();

  constructor(private router: Router) {}

  onSave() {
    this.saved.emit();
  }

  goTo() {
    this.router.navigateByUrl(this.backRoute);
  }
}
