import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService } from '../../../shared/services/toast.service';

export interface UprModalResponse {
  newSerialNumber: string;
  reasonSelected: number;
}

@Component({
  selector: 'app-upr-toggle',
  templateUrl: './upr-toggle.component.html',
  styleUrls: ['./upr-toggle.component.scss']
})
export class UprToggleComponent {
  @Input() toggled: boolean = false;
  @Output() confirmSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() uprOff: EventEmitter<any> = new EventEmitter<any>();

  disabled: boolean;
  showModal: boolean = false;
  selectedReasonIndex = -1;
  innerSerialNumber: string;
  reasons: string[] = [
    'Missing/Rejected Glass',
    'Missing/Rejected Alumn Panel',
    'Missing/Rejected Accessories',
    'Missing/Rejected Decoration',
    'Missing/Rejected Profile',
    'Deglaze/Reglaze Test',
    'Missing/Rejected Other',
    'Will be fixed on the site (PM Call)',
    'Unit is on Hold'
  ];

  constructor(private toastr: ToastService) {}

  reasonSelected(index: number) {
    if (index === this.selectedReasonIndex) index = -1;
    this.selectedReasonIndex = index;
  }

  handleValueChange(e: any) {
    this.innerSerialNumber = e.target.value;
  }

  onConfirm() {
    if (this.selectedReasonIndex === -1 || this.innerSerialNumber === '')
      return;
    this.showModal = false;
    const resp: UprModalResponse = {
      newSerialNumber: this.innerSerialNumber,
      reasonSelected: this.selectedReasonIndex + 1
    };
    if (this.selectedReasonIndex + 1 === 8) {
      this.toggled = !this.toggled;
      this.toastr.success('UPR Resolved as On Site Fix');
    }
    this.confirmSelected.emit(resp);
  }

  onCancel() {
    this.showModal = false;
    this.toggled = !this.toggled;
  }

  onSliderValueChange(val: boolean) {
    const currVal = !val;
    this.toggled = currVal;
    if (this.toggled) {
      this.selectedReasonIndex = -1;
      this.innerSerialNumber = '';
      this.showModal = true;
    } else {
      this.uprOff.emit();
    }
  }
}
