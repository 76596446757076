import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceType } from '../../shared/enums/device-type.enum';
import { User } from '../../shared/models/user';
import { DeviceService } from '../../shared/services/device.service';
import { PermissionsService } from '../../shared/services/permissions.service';
import { SubmenuService } from '../../shared/services/submenu.service';
import { ToggleMenuServiceService } from '../../shared/services/toggle-menu-service.service';
import { UserService } from '../../shared/services/user.service';
import { EditionService } from './services/edition.service';
import { HeaderTitleService } from './services/header-title.service';

@Component({
  selector: 'app-work-instructions-manager',
  templateUrl: './work-instructions-manager.component.html',
  styleUrls: ['./work-instructions-manager.component.scss']
})
export class WorkInstructionsManagerComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  user: User;

  constructor(
    private menuService: SubmenuService,
    private toggleMenuService: ToggleMenuServiceService,
    public deviceService: DeviceService,
    public headerService: HeaderTitleService,
    public editionService: EditionService,
    private permissionsService: PermissionsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.manageMenuItems();
  }

  ngOnDestroy() {
    this.menuService.removeMenu();
  }

  clickOnSticky() {
    this.toggleMenuService.toggle();
  }

  editionButtonClick() {
    this.editionService.showEditionModal.next(true);
  }

  manageMenuItems(): void {
    this.menuService.removeMenu();
    this.menuService.addMenuItem({
      title: 'Stations',
      icon: 'assets/icon/stations.svg',
      fontSize: '22px',
      link: '/app/work-instructions/stations',
      rank: 0,
      fontWeight: 'bold'
    });
    this.menuService.addMenuItem({
      title: 'Lines',
      icon: 'assets/icon/lines.svg',
      fontSize: '22px',
      link: '/app/work-instructions/lines',
      rank: 1,
      fontWeight: 'bold'
    });
    this.menuService.addMenuItem({
      title: 'Links',
      icon: 'assets/icon/links.svg',
      fontSize: '22px',
      link: '/app/work-instructions/links',
      rank: 2,
      fontWeight: 'bold'
    });
    this.menuService.addMenuItem({
      title: 'KPI Target',
      icon: 'fa fa-bullseye',
      fontSize: '22px',
      link: '/app/work-instructions/kpi',
      rank: 3,
      fontWeight: 'bold'
    });
    if (this.permissionsService.isVisible(this.user, 'smr.manager')) {
      this.menuService.addMenuItem({
        title: 'SAP Frame Import',
        icon: 'assets/icon/upload-file.svg',
        fontSize: '22px',
        link: '/app/work-instructions/frame-upload',
        rank: 4,
        fontWeight: 'bold'
      });
    }
  }
}
