import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval, pipe, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { StorageService } from '../../../../../../shared/services/storage.service';
import { KpiManagerService } from '../../services/kpi-manager.service';
import { StorageKey } from '../../../../../../shared/enums/storage-key.enum';
import { ConfettiService } from '../../../../../../shared/services/confetti.service';

@Component({
  selector: 'app-kpi-indicators-manager',
  templateUrl: './kpi-indicators-manager.component.html',
  styleUrls: ['./kpi-indicators-manager.component.scss']
})
export class KpiIndicatorsManagerComponent implements OnInit, OnDestroy {
  indicatorsData: any[];

  subscriptions: Subscription[] = [];

  private TIME = 1 * 60 * 1000; // milliseconds
  private rawData = [];

  constructor(
    private kpiManagerService: KpiManagerService,
    private storageService: StorageService,
    private confettiService: ConfettiService
  ) {
    this.indicatorsData = [
      {
        text: 'Waiting data...',
        total: 0,
        count: 0,
        color: '#FFFFFF',
        uprCount: 0
      }
    ];
  }

  private initIndicatorsData(data: any[]) {
    let kpiGoalsReached = this.storageService.get(StorageKey.REACHED_GOALS);
    if (!kpiGoalsReached) {
      kpiGoalsReached = new Array(data.length).fill(false);
      this.storageService.set(StorageKey.REACHED_GOALS, kpiGoalsReached);
    }

    this.indicatorsData = [];
    data.forEach(line => {
      this.indicatorsData.push({
        text: `L${line.LineNumber}`,
        total: line.GoalValue,
        count: line.Produced,
        uprCount: line.Upr,
        color: this.kpiManagerService.getKpiColor(
          line.StartWorkingHour,
          line.EndWorkingHour,
          line.Produced,
          line.GoalValue
        )
      });

      if (
        line.Produced === line.GoalValue &&
        !kpiGoalsReached[line.LineNumber - 1] &&
        line.GoalValue !== 0
      ) {
        kpiGoalsReached[line.LineNumber - 1] = true;
        this.storageService.set(StorageKey.REACHED_GOALS, kpiGoalsReached);
        this.confettiService.throwConfetti();
      } else if (
        line.Produced < line.GoalValue &&
        kpiGoalsReached[line.LineNumber - 1]
      ) {
        kpiGoalsReached[line.LineNumber - 1] = false;
        this.storageService.set(StorageKey.REACHED_GOALS, kpiGoalsReached);
      }
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.kpiManagerService.syncGoals().subscribe(data => {
        if (data) {
          this.updateFromRawData(data);
        }
      })
    );

    this.subscriptions.push(
      of()
        .pipe(
          mergeMap(() =>
            interval(this.TIME).pipe(
              map(() => this.initIndicatorsData(this.rawData))
            )
          )
        )
        .subscribe()
    );

  }

  updateFromRawData(rawData): void {
    this.rawData = rawData;
    this.initIndicatorsData(this.rawData);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
