import { Component, Input } from '@angular/core';

import { BomPart } from '../../../models/bom-data.model';

@Component({
  selector: 'app-bom-tree-section',
  templateUrl: './bom-tree-section.component.html',
  styleUrls: ['./bom-tree-section.component.scss']
})
export class BomTreeSectionComponent {
  @Input() documents: Array<BomPart>;
}
