import { Injectable } from '@angular/core';

import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { BackendService } from './backend.service';
import { FabComponent } from '../models/component-data.model';
import { LastComponent } from '../models/last-component.model';
import { StorageKey } from '../enums/storage-key.enum';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {
  private DOCUMENT_URL = 'components/searchString';
  componentSub: BehaviorSubject<LastComponent>;

  constructor(private backendService: BackendService, private storageService: StorageService) {
    this.componentSub = new BehaviorSubject<LastComponent>(null);
  }

  // Get the component from the webserv and return it without any savings
  getComponentFromDatabase(compName: string): Observable<FabComponent> {
    this.removeActiveComponent();
    const compObs = this.backendService.get(this.buildUrl(compName));
    return compObs;
  }

  setActualComponent(lastComp: LastComponent) {
    this.componentSub.next(lastComp); // Emit
  }

  removeActiveComponent() {
    this.componentSub.next(null);
    this.storageService.removeDeprecated(StorageKey.ACTUALCOMP);
  }

  private buildUrl(searchString: string): string {
    return this.DOCUMENT_URL.replace('searchString', `${searchString}`);
  }

  updateWithNewBom(boms: any) {
    const oldComp: LastComponent = this.componentSub.value;
    oldComp.bom = boms;
    this.setActualComponent(oldComp);
  }
}
