<div id="type-dropdown-l" (click)="toggleExpansion()" *ngIf="!(screenType === ScreenTypeEnum.SMALL)">
  <app-round-button
    data-cy="dropdown-button"
    icon="fas fa-chevron-right"
    sizepx="50"
    [backgroundColor]="getIconColor()"
    [ngClass]="expandedClass()"
  ></app-round-button>
  <p>{{ typeData?.name.toUpperCase() }}</p>
</div>

<div id="type-dropdown-s" (click)="toggleExpansion()" *ngIf="screenType === ScreenTypeEnum.SMALL">
  <app-round-button
    data-cy="dropdown-button"
    icon="fas fa-chevron-right"
    sizepx="36"
    fontSize="22px"
    [backgroundColor]="getIconColor()"
    [ngClass]="expandedClass()"
  ></app-round-button>
  <p>{{ typeData?.name.toUpperCase() }}</p>
</div>

<div id="table-container" *ngIf="extensionIsOpen">
  <div id="title-line-container">
    <app-table-title-line
      [gridTemplate]="'10% 40% 1fr'"
      [titleList]="['Qty', 'Name', 'Description']"
    ></app-table-title-line>
  </div>

  <div id="table-data" *ngFor="let part of typeData?.parts">
    <app-bom-list-part-item [part]="part"></app-bom-list-part-item>
  </div>
</div>
