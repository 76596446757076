import { Component, OnInit, Input } from '@angular/core';

import { MenuTab } from '../../../../shared/models/menu-tab.model';
import { DeviceService } from '../../../../shared/services/device.service';
import { MenuOpenedService } from '../../services/menu-opened.service';

@Component({
  selector: 'app-menu-tab',
  templateUrl: './menu-tab.component.html',
  styleUrls: ['./menu-tab.component.scss']
})
export class MenuTabComponent implements OnInit {
  @Input() menuTab: MenuTab;
  customSvg: boolean;
  isSolo: boolean;

  constructor(
    public menuOpenedService: MenuOpenedService,
    public deviceService: DeviceService
  ) {}

  ngOnInit() {
    if (this.menuTab) {
      this.isSolo = this.menuTab.isLast && this.menuTab.isFirst;
      this.customSvg = !!this.menuTab.icon.match(/svg$/);

      if (!this.menuTab.icon) {
        this.menuTab.icon = 'fas fa-question';
      }
      if (!this.menuTab.backgroundColor) {
        this.menuTab.backgroundColor = '#CCCCCC';
      }
      if (!this.menuTab.iconColor) {
        this.menuTab.iconColor = '#515151';
      }
      if (!this.menuTab.fontWeight) {
        this.menuTab.fontWeight = 'normal';
      }
      if (!this.menuTab.fontSize) {
        this.menuTab.fontSize = '24px';
      }
      if (!this.menuTab.type) {
        this.menuTab.type = 'icon';
      }
      if (!this.menuTab.rotateIcon) {
        this.menuTab.rotateIcon = false;
      }
      if (!this.menuTab.borderRadius) {
        this.menuTab.borderRadius = '0px';
      }
    }
  }

  circleColor(): string {
    return this.isSolo ? 'transparent' : 'colored';
  }
}
