<div id="placeholder">
  <input
    [disabled]="disabled"
    id="input-field"
    type="text"
    name="fname"
    placeholder="Search for a component"
    (keyup.enter)="onSearchButtonClick()"
  />
  <div class="floating">
    <app-round-button
      data-cy="search-button"
      id="button"
      (click)="onSearchButtonClick()"
      [icon]="'fas fa-search'"
      [sizepx]="64"
    ></app-round-button>
  </div>
</div>
