import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { ScreenSizeService } from '../../../shared/services/screen-size.service';
import { ScreenType } from '../../../shared/enums/screen-type.enum';

@Component({
  selector: 'app-data-container-optionnal',
  templateUrl: './data-container-optionnal.component.html',
  styleUrls: ['./data-container-optionnal.component.scss']
})
export class DataContainerOptionnalComponent implements OnInit, OnChanges {
  @Input() dataList: any;

  screenType: ScreenType;
  ScreenTypeEnum = ScreenType;

  constructor(private screenSizeService: ScreenSizeService) {}

  ngOnInit() {
    this.screenSizeService.screenTypeSubject.subscribe(newScreenSize => {
      this.screenType = newScreenSize;
    });
  }

  ngOnChanges() {
    this.dataList.forEach(item => {
      if (item.value === ' ') {
        item.value = '---';
      }
    });
  }
}
