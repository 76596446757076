import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from '../../../../../shared/services/backend.service';
import { KpiWebsocketService } from '../../../../../shared/services/kpi-websocket.service';

@Injectable({
  providedIn: 'root'
})
export class KpiManagerService {
  constructor(
    private backendService: BackendService,
    private socket: KpiWebsocketService
  ) {}
  private readonly LINES_URL = 'birth-certificate/lines';
  private RED = '#F1A0A0';
  private YELLOW = '#E8E258';
  private GREEN = '#6EEA89';
  private GRAY = '#EAEAEA';

  getAllLines(
    withGoals: boolean = false,
    initDate?: String,
    endDate?: String
  ): Observable<any> {
    const url = withGoals
      ? `${this.LINES_URL}?goals=1&initDate=${initDate}&endDate=${endDate}`
      : `${this.LINES_URL}?goals=0`;
    return this.backendService.get(url);
  }

  createGoal(lineNumber: number, value: number, date: string): Observable<any> {
    const url = `${this.LINES_URL}/${lineNumber}/goals`;
    return this.backendService.post(url, { GoalValue: value, Date: date });
  }

  syncGoals(): Observable<any> {
    return this.socket.fromEvent('linesStatistics');
  }

  getKpiColor(
    startHour: number,
    endHour: number,
    processedUnits: number,
    goal: number,
    moment: Date = new Date()
  ): string {
    if (goal === 0) {
      return this.GRAY;
    }
    const totalWorkHours = endHour - startHour;
    let elapsedTime = moment.getHours() + moment.getMinutes() / 60 - startHour;
    if (elapsedTime < 0) {
      elapsedTime = 0;
    } else if (elapsedTime > totalWorkHours) {
      elapsedTime = totalWorkHours;
    }
    const expectedUnits = (elapsedTime * goal) / totalWorkHours;

    const lowerThreshold = expectedUnits * (1 - 0.15);
    const higherThreshold = expectedUnits * (1 + 0);

    if (processedUnits >= higherThreshold) {
      return this.GREEN;
    } else if (
      processedUnits < higherThreshold &&
      processedUnits >= lowerThreshold
    ) {
      return this.YELLOW;
    }
    return this.RED;
  }
}
