import { Component, Input } from '@angular/core';
import { LotStats } from '../../interfaces/lot-stat';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-lot-selectable-item',
  templateUrl: './lot-selectable-item.component.html',
  styleUrls: ['./lot-selectable-item.component.scss']
})
export class LotSelectableItemComponent {
  @Input() lot: LotStats;

  constructor(private dashboardService: DashboardService) {}

  toggleShowChildren() {
    this.lot.expanded = !this.lot.expanded;
  }

  selectLot() {
    this.dashboardService.selectedLotFormattedName.next(
      this.lot.formattedName as string
    );
    this.dashboardService.selectedLot.next(this.lot.id);
    this.dashboardService.showSearchBar.next(false);
  }
}
