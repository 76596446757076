import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { BomButtonState } from './models/bom-button-state.model';
import { BomMode } from '../../enums/bom-mode.enum';
import { FullScreenService } from '../../../shared/services/full-screen.service';
import { BomService } from '../../services/bom.service';

@Component({
  selector: 'app-bom-section',
  templateUrl: './bom-section.component.html',
  styleUrls: ['./bom-section.component.css']
})
export class BomSectionComponent implements OnInit, OnDestroy {
  boms: any;
  isLoaded: boolean;
  buttonState: BomButtonState;
  defaultState: BomButtonState;
  bomModeEnum = BomMode;
  sub: Subscription;

  constructor(
    private fullScreenService: FullScreenService,
    private bomService: BomService
  ) {
    this.isLoaded = false;
  }

  ngOnInit() {
    this.defaultState = {
      mode: BomMode.TREE,
      expand: false,
      export: false,
      select: false,
      advancedUser: false
    };
    this.buttonState = this.defaultState;

    this.sub = this.bomService.bomSubject.subscribe(newBoms => {
      this.boms = newBoms;
      this.checkIfLoaded();
    });
  }

  checkIfLoaded() {
    this.isLoaded =
      this.boms && this.boms.list && this.boms.tree ? true : false;
  }

  manageButtonsActions(newButtonState: BomButtonState) {
    this.buttonState = newButtonState;
    this.updateFullScreenMode();
  }

  updateFullScreenMode() {
    this.fullScreenService.set(this.buttonState.expand);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
