import { Component, OnInit } from '@angular/core';

import { User } from '../../../../shared/models/user';
import { DeviceService } from '../../../../shared/services/device.service';
import { UserService } from '../../../../shared/services/user.service';
import { MenuOpenedService } from '../../services/menu-opened.service';

@Component({
  selector: 'app-user-tab',
  templateUrl: './user-tab.component.html',
  styleUrls: ['./user-tab.component.scss']
})
export class UserTabComponent implements OnInit {
  user: User;

  constructor(private userService: UserService, public menuOpenedService: MenuOpenedService, public deviceService: DeviceService) {}

  ngOnInit() {
    this.userService.user.subscribe(u => (this.user = u));
  }
}
