<div class="container">
  <div
    class="input-container"
    *ngIf="
      input.inputType === workInstructionsInputTypesEnum.MULTIPLEOPTIONSBUTTON
    "
  >
    <app-multi-choice-editable-button
      [elements]="input.inputData"
      [editModeActivated]="editModeActivated"
      [disabled]="disabled"
      [currentLanguage]="currentLanguage"
      [defaultValue]="input.result"
      (buttonSelected)="
        handleOutput(
          $event,
          workInstructionsInputTypesEnum.MULTIPLEOPTIONSBUTTON
        )
      "
      (textChange)="onTextChange()"
    >
    </app-multi-choice-editable-button>

    <app-round-button
      *ngIf="editModeActivated"
      (click)="removeInput(index); $event.stopPropagation()"
      class="input-icon"
      data-cy="toggle"
      [icon]="'fa fa-times'"
      [sizepx]="35"
      fontSize="20px"
      backgroundColor="var(--red)"
      iconColor="var(--menuItem-background1)"
      shadow="none"
    ></app-round-button>
  </div>

  <div
    class="input-container"
    *ngIf="input.inputType === workInstructionsInputTypesEnum.NUMERICALINPUT"
  >
    <app-generic-type-input
      [disabled]="editModeActivated || disabled"
      [value]="input.result"
      [type]="'number'"
      (valueEmitter)="
        handleOutput($event, workInstructionsInputTypesEnum.NUMERICALINPUT)
      "
    >
    </app-generic-type-input>

    <app-round-button
      *ngIf="editModeActivated"
      (click)="removeInput(index); $event.stopPropagation()"
      class="input-icon"
      data-cy="toggle"
      [icon]="'fa fa-times'"
      [sizepx]="35"
      fontSize="20px"
      backgroundColor="var(--red)"
      iconColor="var(--menuItem-background1)"
      shadow="none"
    ></app-round-button>
  </div>

  <div
    class="input-container"
    *ngIf="input.inputType === workInstructionsInputTypesEnum.TEXTINPUT"
  >
    <app-generic-type-input
      [disabled]="editModeActivated || disabled"
      [value]="input.result"
      [type]="'text'"
      (valueEmitter)="
        handleOutput($event, workInstructionsInputTypesEnum.TEXTINPUT)
      "
    >
    </app-generic-type-input>

    <app-round-button
      *ngIf="editModeActivated"
      (click)="removeInput(index); $event.stopPropagation()"
      class="input-icon"
      data-cy="toggle"
      [icon]="'fa fa-times'"
      [sizepx]="35"
      fontSize="20px"
      backgroundColor="var(--red)"
      iconColor="var(--menuItem-background1)"
      shadow="none"
    ></app-round-button>
  </div>

  <div
    class="input-container"
    *ngIf="input.inputType === workInstructionsInputTypesEnum.WEIGHTINPUT"
  >
    <app-generic-type-input
      [disabled]="editModeActivated || disabled"
      [value]="input.result"
      [type]="'weight'"
      (valueEmitter)="
        handleOutput($event, workInstructionsInputTypesEnum.WEIGHTINPUT)
      "
    >
    </app-generic-type-input>

    <app-round-button
      *ngIf="editModeActivated"
      (click)="removeInput(index); $event.stopPropagation()"
      class="input-icon"
      data-cy="toggle"
      [icon]="'fa fa-times'"
      [sizepx]="35"
      fontSize="20px"
      backgroundColor="var(--red)"
      iconColor="var(--menuItem-background1)"
      shadow="none"
    ></app-round-button>
  </div>

  <div
    class="input-container"
    *ngIf="input.inputType === workInstructionsInputTypesEnum.RFIDINPUT"
  >
    <app-rfid-input
      [value]="input.result"
      [disabled]="editModeActivated || disabled"
      (rfidScanned)="
        handleOutput($event, workInstructionsInputTypesEnum.RFIDINPUT)
      "
    ></app-rfid-input>

    <app-round-button
      *ngIf="editModeActivated"
      (click)="removeInput(index); $event.stopPropagation()"
      class="input-icon"
      data-cy="toggle"
      [icon]="'fa fa-times'"
      [sizepx]="35"
      fontSize="20px"
      backgroundColor="var(--red)"
      iconColor="var(--menuItem-background1)"
      shadow="none"
    ></app-round-button>
  </div>

  <div
    class="input-container"
    *ngIf="input.inputType === workInstructionsInputTypesEnum.PHOTOINPUT"
  >
    <app-photo-input
      [pictures]="input.result"
      [comment]="comment"
      [editMode]="editModeActivated"
      [disabled]="disabled"
      [pictures]="pictures"
      (pictureAdd)="pictureAdd.emit($event)"
      (pictureDelete)="pictureDelete.emit($event)"
      (picturesSaved)="picturesSaved.emit($event)"
      (commentSaved)="commentSaved.emit($event)"
    >
    </app-photo-input>

    <app-round-button
      *ngIf="editModeActivated"
      (click)="removeInput(index); $event.stopPropagation()"
      class="input-icon"
      data-cy="toggle"
      [icon]="'fa fa-times'"
      [sizepx]="35"
      fontSize="20px"
      backgroundColor="var(--red)"
      iconColor="var(--menuItem-background1)"
      shadow="none"
    ></app-round-button>
  </div>
</div>
