import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-photo-input',
  templateUrl: './photo-input.component.html',
  styleUrls: ['./photo-input.component.scss']
})
export class PhotoInputComponent {
  @Input() pictures: any[] = [];
  @Input() comment: string;
  @Input() editMode: boolean;
  @Input() disabled: boolean;
  @Output() pictureAdd = new EventEmitter<string>();
  @Output() pictureDelete = new EventEmitter<string>();
  @Output() picturesSaved = new EventEmitter<any>();
  @Output() commentSaved = new EventEmitter<string>();

  handleNewPicture(base64Data: string) {
    this.pictureAdd.emit(base64Data);
  }

  handlePictureDeletion(pictureName: string) {
    this.pictureDelete.emit(pictureName);
  }
}
