<div class="container">
  <textarea
    #commentBox
    id="comment-box"
    class="comment-box"
    rows="4"
    [disabled]="disabled"
    placeholder="Add a comment..."
    (change)="onChange()"
  >
        {{ comment }}
    </textarea
  >
</div>
