<div *ngIf="projects" class="container">
  <div class="header">
    <div class="title main">PROJECTS</div>

    <div class="flex-container">
      <div class="switch-container">
        <div class="title secondary">PROJECTS</div>
        <app-slider
          label="Show completed projects"
          (change)="filterFromSwitch()"
        ></app-slider>
      </div>

      <div class="search-bar">
        <app-generic-search-bar
          placeholder="Search projects..."
          [autocomplete]="true"
          (valueChangeEmitter)="filterProjects($event)"
        ></app-generic-search-bar>
      </div>
    </div>
  </div>

  <div class="sub-header">
    <div class="sub-title">Name</div>
  </div>

  <div class="content">
    <app-project-item
      *ngFor="let project of projects"
      [project]="project"
      (selectProject)="seeProjectDetails($event)"
    ></app-project-item>
  </div>
</div>
