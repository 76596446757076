import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checklist-manager',
  templateUrl: './checklist-manager.component.html',
  styleUrls: ['./checklist-manager.component.scss']
})
export class ChecklistManagerComponent {
  @Input() checklists: any[];
  @Output() editionRequired = new EventEmitter<any>();
  @Output() createChecklist = new EventEmitter<any>();
  @Output() removeChecklist = new EventEmitter<any>();
  @Output() cloneChecklist = new EventEmitter<any>();
  constructor() {}

  addChecklist() {
    this.createChecklist.emit();
  }

  editPressed(item: any) {
    this.editionRequired.emit(item);
  }

  clonePressed(id: any) {
    this.cloneChecklist.emit(id);
  }

  removePressed(id: any) {
    this.removeChecklist.emit(id);
  }

  titleChange(newTitle: string, index: number) {
    this.checklists[index].name = newTitle;
  }
}
