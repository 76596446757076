<div
  class="checkbox-container"
  (click)="handleClick()"
  [ngStyle]="{
    border: '1px solid ' + getColor('black')
  }"
>
  <div
    class="inner-content"
    [ngStyle]="{
      'background-color': getColor('transparent')
    }"
  >
    &nbsp;
  </div>
</div>
