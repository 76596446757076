<button
    *ngFor="let item of data; let i = index; first as isFirst; last as isLast"
    [class.first]="isFirst"
    [class.last]="isLast"
    [class.activated]="selectedButton === i"
    (click)="onButtonClick(i)"
  >
    <div>
        {{item.text}}
        <i class={{item.iconClass}}></i>
    </div>
</button>