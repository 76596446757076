import { Component, Input, OnInit } from '@angular/core';

import { SmrProgressCategory, SmrProgressFloor } from '../../interfaces/smr-progress-data.interface';

@Component({
  selector: 'app-smr-category',
  templateUrl: './smr-category.component.html',
  styleUrls: ['./smr-category.component.scss']
})
export class SmrCategoryComponent implements OnInit {
  @Input() category: SmrProgressCategory;
  @Input() displayRange: number;
  @Input() startDate: Date;
  @Input() opened = false;

  hasSmrs = false;

  plannedProcessedDates = new Map();
  plannedOnSiteDates = new Map();

  isLoading = false;
  totalNumberOfSmrs = 0;
  smrLineHeightInPx = 22;

  ngOnInit() {
    const floors = Array.isArray(this.category?.floors) ? this.category.floors : [];
    this.hasSmrs = this.isThereAnySmrs(floors);
    floors.forEach(floor => {
      this.totalNumberOfSmrs += floor.smrs ? floor.smrs.length : 0;
      if (floor.plannedProcessedDate) {
        const date = floor.plannedProcessedDate;
        const key = date.toUTCString();
        const data = this.plannedProcessedDates.get(key);
        const ids = data?.ids ? `${data?.ids},${floor.id}` : floor.id;
        this.plannedProcessedDates.set(key, { date, ids });
      }
      if (floor.plannedOnSiteDate) {
        const date = floor.plannedOnSiteDate;
        const key = date.toUTCString();
        const data = this.plannedOnSiteDates.get(key);
        const ids = data?.ids ? `${data?.ids},${floor.id}` : floor.id;
        this.plannedOnSiteDates.set(key, { date, ids });
      }
    });
  }

  toggle() {
    if (!this.hasSmrs) {
      return;
    }
    this.isLoading = true;
    setTimeout(() => {
      this.opened = !this.opened;
      this.isLoading = false;
    }, 30);
  }

  isThereAnySmrs(floors: SmrProgressFloor[]): boolean {
    return floors.filter(floor => floor.smrs && floor.smrs.length > 0).length > 0;
  }
}
