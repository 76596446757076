import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ModalConfirmComponent } from '../components/modal-confirm/modal-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  bsModalRef: BsModalRef;

  constructor(private bsModalService: BsModalService) { }

  confirm(
    header = '! Confirmation',
    content = 'All changes made will be lost. Do you want to continue?', 
    actionBtnText = {
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      invertButtons: false
    }): Observable<boolean> {
    const config = {
      initialState: {
        header,
        content,
        actionBtnText
      }
    }
    this.bsModalRef = this.bsModalService.show(ModalConfirmComponent, config);
    return new Observable<boolean>(this.getResult());
  }

  private getResult() {
    return (observer) => {
      const subscription = this.bsModalRef.onHidden.subscribe(() => {
        observer.next(this.bsModalRef.content.result);
        observer.complete();
      })
      return {
        unsubscribe() {
          subscription.unsubscribe();
        }
      }
    }
  }
}
