import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProjectData } from '../../interfaces/project-data';

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss']
})
export class ProjectItemComponent implements OnInit, OnDestroy {
  @Input() project: ProjectData;
  @Output() selectProject = new EventEmitter<string>();
  expanded = false;
  projectId = '';
  projectFrameCount: number = 0;
  hasData = true;

  subscriptions: Subscription[] = [];

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.projectId = this.project.name.split(' - ')[0];
    this.hasData =
      this.project.stats[0]?.count > 0 ||
      this.project.stats[1]?.count > 0 ||
      this.project.stats[2]?.count > 0 ||
      this.project.stats[3]?.count > 0 ||
      this.project.stats[4]?.count > 0 ||
      this.project.stats[5]?.count > 0;

    this.project.stats
      .filter(s => s.id === 'toRelease' || s.id === 'released')
      .forEach(s => {
        this.projectFrameCount += s.count;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  onSelectProject() {
    if (this.hasData) {
      this.selectProject.emit(this.projectId);
    } else {
      this.toastService.warning(
        `The project ${this.projectId} does not have any statistical data yet.`
      );
    }
  }
}
