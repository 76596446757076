<div class="container">
  <div class="header-text">File upload</div>
  <div class="subheader-text">You can upload the SAP Frame data from here.</div>
  <div class="box" appDragAndDropFiles (filesDropped)="fileUploaded($event)">
    <div class="content">
      <ng-container *ngIf="uploadedFiles.length === 0">
        <svg-icon
          id="custom-svg"
          src="/assets/icon/upload-file2.svg"
          [svgStyle]="{ 'width.px': 65, 'height.px': 65 }"
        ></svg-icon>

        <div class="subheader-text">Select a file or drag here</div>

        <app-upload-button (uploadEvent)="fileUploaded($event.target.files)">
          <app-button
            title="Select a file"
            width="200px"
            [noIcon]="true"
          ></app-button>
        </app-upload-button>
      </ng-container>

      <ng-container *ngIf="uploadedFiles.length > 0">
        <div class="files-container">
          <div
            *ngFor="let file of uploadedFiles; let i = index"
            class="file-item"
          >
            {{ file.name }}
            <app-round-button
              [sizepx]="20"
              fontSize="12px"
              backgroundColor="var(--red)"
              icon="fas fa-times"
              shadow="none"
              (click)="removeUploadedFile(i)"
            ></app-round-button>
          </div>
        </div>

        <div class="button-container">
          <app-upload-button (uploadEvent)="fileUploaded($event.target.files)">
            <app-button
              title="Select another file"
              width="200px"
              backgroundColor="white"
              border="2px solid var(--fabbrica)"
              fontColor="var(--fabbrica)"
              [noIcon]="true"
            ></app-button>
          </app-upload-button>
          <app-button
            class="upload-button"
            title="Upload frame"
            width="200px"
            [svgIcon]="true"
            icon="/assets/icon/upload-file.svg"
            iconColor="var(--fabbrica)"
            iconBackgroundColor="white"
            iconBorder="white"
            (click)="upload()"
          ></app-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="uploading" *ngIf="uploading"></div>
