import { Pipe, PipeTransform } from '@angular/core';

import { GroupCounts } from 'src/app/shared/interfaces/group-counts.interface';
import { transform } from 'src/app/shared/components/smr-progress/pipes/smr-counts.transform';

@Pipe({
  name: 'smrMachinedPartsCountsPipe'
})
export class SmrMachinedPartsCountPipe implements PipeTransform {
  transform(groupCounts: GroupCounts): string {
    return transform(groupCounts?.machinedPartsCount, 'machined parts');
  }
}
