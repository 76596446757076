import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smrNumber'
})
export class SmrNumberPipe implements PipeTransform {
  transform(fileName: string): string {
    const match = fileName && fileName.match(/SMR([A-Z0-9-]*)\./);
    return match && match.length >= 1 ? match[1] : '-';
  }
}
