<app-smr-calendar
  (click)="toggleOpening()"
  [startDate]="currentRange?.startDate"
  [displayRange]="currentRange?.dayRange"
  [data]="weeks"
  id="sticky-wrapper-start-floor"
  #stickyWrapper
>
  <div id="absolute-container">
    <app-smr-floors-header
      [projectAndLot]="dataInProject?.projectLotsTuple | smrProjectLot"
      [startDate]="currentRange?.startDate"
      [displayRange]="currentRange?.dayRange"
      [floors]="dataInProject?.data?.floors"
      [isOpen]="isOpen"
    >
    </app-smr-floors-header>
    <div id="arrow" [class.opened]="isOpen">
      <app-round-button
        id="toggle-Button"
        sizepx="42"
        fontSize="21px"
        icon="fas fa-chevron-right"
        [backgroundColor]="'#2ca0df'"
      ></app-round-button>
    </div>
    <div id="no-file-message" *ngIf="!dataInProject.data">
      <span>No planification file found for this project</span>
    </div>
  </div>
</app-smr-calendar>

<app-smr-calendar
  *ngIf="isOpen"
  id="expandable-section"
  [startDate]="currentRange?.startDate"
  [displayRange]="currentRange?.dayRange"
  [data]="weeks"
>
  <ng-container *ngFor="let category of dataInProject?.data.categories">
    <app-smr-category
      [category]="category"
      [startDate]="currentRange?.startDate"
      [displayRange]="currentRange?.dayRange"
    >
    </app-smr-category>
  </ng-container>
</app-smr-calendar>
