<div
  id="box"
  [routerLink]="'/app/user-preferences'"
  routerLinkActive="active"
  [ngStyle]="{
    'flex-direction':
      (deviceService.isMobile | async)
        ? 'row-reverse'
        : (menuOpenedService.isOpen | async)
        ? 'row-reverse'
        : 'row'
  }"
>
  <div id="content">
    <div class="par">{{ user?.name }}</div>
  </div>
  <div class="icon">
    <i class="fa fa-user"></i>
  </div>
</div>
