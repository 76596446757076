import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-conditional-input',
  templateUrl: './conditional-input.component.html',
  styleUrls: ['./conditional-input.component.scss']
})
export class ConditionalInputComponent {
  @Input() reportForm: FormGroup;
  @Input() lotEnabled: boolean;
  @Input() error: boolean;
  @Input() readOnly: boolean;
  
  public sublotEnabled: boolean;
  public subSublotEnabled: boolean;

  constructor() {}

  public enableSublot(event): void {
    this.sublotEnabled = event.target.value !== '';
    if (!this.sublotEnabled) {
      this.subSublotEnabled = false;
    }

    let value = this.parseValue(event.target.value);
    value = isNaN(parseInt(value, 10)) ? '0' : value;
    event.target.value = value;
    this.reportForm.get('lot').setValue(value);
  }

  public enableSubsublot(event): void {
    this.subSublotEnabled = event.target.value !== '';

    let value = this.parseValue(event.target.value);

    value = isNaN(parseInt(value, 10)) ? '0' : value;
    event.target.value = value;
    this.reportForm.get('sublot').setValue(value);
  }

  public setSubSublotValue(event): void {
    let value: string = this.parseValue(event.target.value);

    value = isNaN(parseInt(value, 10)) ? '0' : value;
    event.target.value = value;
    this.reportForm.get('subSublot').setValue(value);
  }

  private parseValue(value: string): string {
    value = String(parseInt(value, 10)).padStart(2, '0');

    if (value.length > 2) {
      value = value.substring(value.length - 2);
    }

    return value;
  }
}
