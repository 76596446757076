<div id="placeholder" (click)="onFocus()">
  <input
    #search
    [(ngModel)]="textValue"
    (focus)="onFocus()"
    (keyup)="onChange()"
    [size]="search.value.length === 0 ? 60 : search.value.length"
    id="input-field"
    type="text"
    name="fname"
    placeholder="Search Lots Overall Progress"
    autocomplete="off"
  />
  <div>{{ description }}</div>
  <div class="floating">
    <app-round-button
      data-cy="search-button"
      id="button"
      [icon]="'fas fa-chevron-down'"
      [sizepx]="36"
      [fontSize]="24"
      [shadow]="'none'"
    ></app-round-button>
  </div>
</div>
