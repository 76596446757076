<ng-container *ngIf="!loading">
  <div class="header">
    <div class="p">Line Name</div>
  </div>

  <div
    (click)="toggleExpand(line.id)"
    class="header-row hover"
    *ngFor="let line of lines"
  >
    <div class="main-line-content">
      <div class="left-container">
        <div class="p">{{ line.name }}</div>
      </div>

      <div class="right-container">
        <app-round-button
          *ngIf="!line.expanded"
          class="mr10px expand-btn"
          data-cy="toggle"
          [icon]="'fas fa-chevron-down'"
          [sizepx]="32"
          fontSize="17px"
          backgroundColor="transparent"
          iconColor="var(--menuItem-text1)"
          shadow="none"
        ></app-round-button>
        <app-round-button
          *ngIf="line.expanded"
          class="mr10px expand-btn"
          data-cy="toggle"
          [icon]="'fas fa-chevron-up'"
          [sizepx]="32"
          fontSize="17px"
          backgroundColor="transparent"
          iconColor="var(--menuItem-text1)"
          shadow="none"
        ></app-round-button>
        <app-round-button
          (click)="
            setCurrentLine(line.id, line.version); $event.stopPropagation()
          "
          class="mr5px"
          [icon]="'fa fa-edit'"
          [sizepx]="48"
          fontSize="26px"
          backgroundColor="var(--fabbrica)"
          iconColor="var(--menuItem-background1)"
          shadow="var(--shadow1)"
        ></app-round-button>
      </div>
    </div>

    <div class="station-configurations" *ngIf="line.expanded">
      <div class="config-content">
        <div class="p" *ngFor="let config of line.stationConfigurations">
          {{ config }}
        </div>
      </div>

      <div class="add-btn-container">
        <app-button
          class="btn-margin"
          [title]="'Delete line configuration'"
          [icon]="'fa fa-trash'"
          [backgroundColor]="'var(--red)'"
          [fontColor]="'white'"
          [iconBackgroundColor]="'var(--red-dark)'"
          (click)="setDelete(true, line.id); $event.stopPropagation()"
        >
        </app-button>
        <app-button
          class="btn-margin"
          [title]="'Copy line configuration'"
          [icon]="'fa fa-copy'"
          [backgroundColor]="'var(--fab-unit)'"
          [fontColor]="'white'"
          [iconBackgroundColor]="'var(--fab-unit-dark)'"
          (click)="copyLine(line.id); $event.stopPropagation()"
        >
        </app-button>
      </div>
    </div>
  </div>
  <div>
    <div class="add-btn-container">
      <app-button
        [title]="'Add a new line configuration'"
        [icon]="'fa fa-plus'"
        [backgroundColor]="'var(--fabbrica)'"
        [fontColor]="'white'"
        [iconBackgroundColor]="'var(--fabbrica-xdark)'"
        (click)="toggleNewLineModal()"
      >
      </app-button>
    </div>
  </div>
</ng-container>

<div class="container" *ngIf="loading">
  <app-loading-cog></app-loading-cog>
</div>

<app-modal
  *ngIf="showNewLineModal"
  header="Name the line configuration"
  headerIcon="fas fa-list"
  (cancelEmitter)="toggleNewLineModal()"
  (confirmEmitter)="addLine()"
>
  <input
    class="input-modal"
    type="text"
    placeholder="Enter a name"
    [value]="lineName"
    (change)="onNameChange($event)"
  />
</app-modal>

<app-modal
  *ngIf="showDeleteModal"
  [confirmDelete]="true"
  header="Delete the line configuration"
  headerIcon="fas fa-exclamation"
  (cancelEmitter)="setDelete(false)"
  (confirmEmitter)="deleteLine(selectedLineId)"
>
  <div class="p" class="modal-content">
    {{ getContent() }}
  </div>
</app-modal>
