import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy
} from '@angular/core';

import { BarcodeScannerService } from '../../services/barcode-scanner.service';
import { SearchResult } from '../../models/search-result.model';
import { Subscription } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { InputMethodEnum } from '../../enums/input-method.enum';
import { InputTypeEnum } from '../../enums/input-type.enum';
import { InputTypeService } from '../../services/input-type.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Output() searchEvent = new EventEmitter<SearchResult>();
  @Input() minChar: number;
  @Input() disabled = false;

  private subs: Subscription[] = [];
  private HtmlElementId: string;

  constructor(
    private toastService: ToastService,
    private barcodeScannerService: BarcodeScannerService,
    private inputTypeService: InputTypeService
  ) {
    this.subs = [
      this.barcodeScannerService.scannedString.subscribe(newSearch => {
        this.onSearch(newSearch);
      })
    ];

    this.minChar = this.minChar || 1;
  }

  ngOnInit() {
    this.HtmlElementId = 'input-field';
  }

  onSearchButtonClick(): void {
    const fullString = this.getFullString();
    this.onSearch({
      fullString,
      inputMethod: InputMethodEnum.KEYBOARD,
      inputType: this.inputTypeService.getInputType(fullString)
    });
  }

  onSearch(search: SearchResult): void {
    // If we have a custom string we
    if (this.disabled) {
      return;
    }
    if (search.fullString.length >= this.minChar) {
      this.resetSearchBarValue();
      this.searchEvent.emit(search);
    } else {
      this.toastService.info(
        `At least ${this.minChar} characters are needed for a component search`
      );
    }
  }

  resetSearchBarValue(): void {
    const textField: any = document.getElementById(this.HtmlElementId);
    textField.value = '';
  }

  getFullString(): string {
    const textField: any = document.getElementById(this.HtmlElementId);
    return textField.value;
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
