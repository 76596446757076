import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from '../../../../../shared/services/backend.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  url = 'sap-frame-import';

  constructor(private backend: BackendService) {}

  upload(file: File): Observable<any> {
    return this.backend.post(this.url, file);
  }
}
