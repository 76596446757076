<app-bom-tree-item-section
  (isExpandedEvent)="isExpandedEvent($event)"
  [document]="document"
></app-bom-tree-item-section>
<div *ngIf="isExpanded">
  <app-bom-tree-item-container
    *ngFor="let childItem of document._children"
    [document]="childItem"
  ></app-bom-tree-item-container>
</div>
