import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-smr-calendar-controls',
  templateUrl: './smr-calendar-controls.component.html',
  styleUrls: ['./smr-calendar-controls.component.scss']
})
export class SmrCalendarControlsComponent {
  @Output() stepBackward = new EventEmitter();
  @Output() stepForward = new EventEmitter();

  moveBackward() {
    this.stepBackward.emit();
  }

  moveForward() {
    this.stepForward.emit();
  }
}
