import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClickOnScreenService } from '../../services/click-on-screen.service';
import { OutsideClickService } from '../../services/outside-click.service';

@Component({
  selector: 'app-collapsable-mini-menu',
  templateUrl: './collapsable-mini-menu.component.html',
  styleUrls: ['./collapsable-mini-menu.component.scss']
})
export class CollapsableMiniMenuComponent implements OnInit, OnDestroy {
  expanded = false;
  subscriptions: Subscription[] = [];

  constructor(private clickOnScreenService: ClickOnScreenService) {}

  ngOnInit() {
    this.subscriptions.push(this.clickOnScreenService.clickOnScreenEvent.subscribe(() => {
      this.expanded = false;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  emitClick() {
    this.clickOnScreenService.emitClick();
  }

  toggle() {
    const wasExpanded = this.expanded;
    this.emitClick();
    this.expanded = !this.expanded;
    if (wasExpanded) {
      this.expanded = false;
    }
  }

}
