import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BackendService } from 'src/app/shared/services/backend.service';
import { SmrExcelExportConfig } from '../interfaces/smr-progress-excel-config';

@Injectable()
export class SmrExcelExportService {
  constructor(private readonly backend: BackendService) {}

  askForExcelAndDownload(exportConfig: SmrExcelExportConfig): Observable<any> {
    const finalPath = this.buildPathFromExportConfig(exportConfig);
    const options = this.getOptions();
    return this.backend.get(finalPath, options, false);
  }

  private buildPathFromExportConfig(exportConfig: SmrExcelExportConfig): string {
    const projects = exportConfig.projectsNumber.join(',');
    const startDate = this.properlyFormattedDate(exportConfig.startDate);
    const endDate = this.properlyFormattedDate(exportConfig.endDate);
    return encodeURI(`smr-excel-report?projects=[${projects}]&start-date=${startDate}&end-date=${endDate}`);
  }

  properlyFormattedDate(dateToFormat: Date): string {
    return `${dateToFormat.getFullYear()}-${dateToFormat.getMonth() + 1}-${dateToFormat.getDate()}`;
  }

  private getOptions() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return { headers, responseType: 'blob' };
  }
}
