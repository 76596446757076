<ng-container
*ngFor="let item of elements; let i = index; first as isFirst; last as isLast">
  <div class="container" [ngStyle]="{'margin-left': (elements.length > 1 && editModeActivated) ? '-45px' : '0px'}">
    <app-round-button
      *ngIf="elements.length > 1 && editModeActivated"
      (click)='removeInput(i)'
      class="btn"
      data-cy="toggle"
      [icon]="'fa fa-minus'"
      [sizepx]="35"
      fontSize="20px"
      backgroundColor="var(--menuItem-background1)"
      iconColor="var(--red)"
      border="1px solid var(--red)"
      shadow="none"
    ></app-round-button>

    <button
      [class.activated]="i === selectButton && !editModeActivated"
      [ngClass]="editModeActivated ? 'edit' : 'assembly'"
      (click)="onButtonClick(i)"
    >
  <!-- Edition Mode -->
    <div class="input-container" *ngIf="editModeActivated">
      <div class="input-width" style="display: flex; align-items: center; border-bottom: 1px solid var(--fabbrica-xdark);">
        <input
        type="text"
        placeholder='Insert text here...'
        [value]="item.en"
        (change)='enTextChange($event, i, true)'
      />
        <p style="color: var(--fabbrica);">En</p>
      </div>
      <div class="input-width" style="display: flex; align-items: center;">
        <input
        type="text"
        placeholder='Insert text here...'
        [value]="item.es"
        (change)='enTextChange($event, i, false)'
      />
        <p style="color: var(--fabbrica);">Es</p>
      </div>
    </div>
      
  <!-- Assembly Mode -->
    <p *ngIf="!editModeActivated">{{ currentLanguage === 'En' ? item.en : item.es }}</p>
  </button>
  </div>
  
</ng-container>


<app-round-button
  *ngIf="editModeActivated"
  (click)='addInput()'
  class="btn"
  data-cy="toggle"
  [icon]="'fa fa-plus'"
  [sizepx]="35"
  fontSize="20px"
  backgroundColor="var(--menuItem-background1)"
  iconColor="var(--fabbrica)"
  border='1px solid var(--fabbrica)'
  shadow="none"
></app-round-button>
