<div (click)="toggle($event)">
  <app-round-button
    id="not-selected-button"
    *ngIf="!isSelected"
    border="solid 2px transparent"
    backgroundColor="var(--menuItem-border1)"
    iconColor="var(--white)"
    icon="far fa-square"
    sizepx="42"
    fontSize="24px"
  ></app-round-button>
  <app-round-button
    id="selected-button"
    *ngIf="isSelected"
    border="solid 2px transparent"
    backgroundColor="var(--fabbrica)"
    iconColor="var(--white)"
    icon="far fa-check-square"
    sizepx="42"
    fontSize="24px"
  ></app-round-button>
</div>
