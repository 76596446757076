import { Component, Input } from '@angular/core';
import { SmrProgressFloor } from '../../interfaces/smr-progress-data.interface';

@Component({
  selector: 'app-smr-floor',
  templateUrl: './smr-floor.component.html',
  styleUrls: ['./smr-floor.component.scss']
})
export class SmrFloorComponent {
  @Input() startDate: Date;
  @Input() displayRange: number;
  @Input() floor: SmrProgressFloor;
}
