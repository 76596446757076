import { Injectable } from '@angular/core';

import { ComponentListService } from '../services/component-list.service';
import { Session } from '../models/session';
import { StorageKey } from '../enums/storage-key.enum';
import { StorageService } from '../services/storage.service';
import { UserService } from './user.service';
import { LanguageSelectorService } from '../../shared/components/work-instructions/services/language-selector.service';
import { StationSelectorService } from '../../shared/components/work-instructions/services/station-selector.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private maxSessionNo: number;

  constructor(
    private storageService: StorageService,
    private componentListService: ComponentListService,
    private userService: UserService,
    private languageService: LanguageSelectorService,
    private stationService: StationSelectorService
  ) {
    this.maxSessionNo = 5;
  }

  restoreSession() {
    const session: Session = this.getActiveSession();
    this.componentListService.setComponentList(session.lastviewed);
    this.languageService.set(session.userLanguage);
    this.stationService.set(session.userStation);
  }

  getActiveSession(): Session {
    const username = this.userService.user.value.name;
    return this.getOneSession(username);
  }

  updateActiveSession(lastUrl: string) {
    const oldSessions: Array<Session> = this.getAllSessions();
    const newSessions: Array<Session> = [];
    const newSession: Session = {
      userName: this.userService.user.value.name,
      lastviewed: this.componentListService.getComponentList(),
      lastnavigation: lastUrl,
      userLanguage: this.languageService.get(),
      userStation: this.stationService.subject.value
    };

    if (oldSessions.length === 0) {
      // If no session recorded, we push the new one:
      newSessions.push(newSession);
    } else if (oldSessions.length > 0) {
      // Else we only update the newSession, and check for its existence before:
      let sessionFound = false;
      oldSessions.forEach(oldSession => {
        if (oldSession.userName === newSession.userName) {
          newSessions.push(newSession);
          sessionFound = true;
        } else {
          newSessions.push(oldSession);
        }
      });
      if (!sessionFound) {
        // If no session were found with the same username, add the new session:
        newSessions.push(newSession);
      }
    }
    this.setAllSessions(newSessions);
  }

  private setAllSessions(newSessions: Array<Session>) {
    if (newSessions.length > this.maxSessionNo) {
      newSessions = newSessions.slice(1, newSessions.length - 1);
    }
    this.storageService.setDeprecated(
      StorageKey.SESSION,
      JSON.stringify(newSessions)
    );
  }

  private getOneSession(userName: string): Session {
    const sessions: Array<Session> = this.getAllSessions();
    const userSession: Session = sessions.find(
      session => session.userName === userName
    );
    if (userSession) {
      return userSession;
    } else {
      return this.emptySession(this.userService.user.value.name);
    }
  }

  private getAllSessions(): Array<Session> {
    const allSession: Array<Session> = JSON.parse(
      this.storageService.getDeprecated(StorageKey.SESSION)
    );
    if (allSession) {
      return allSession;
    } else {
      return [];
    }
  }

  private emptySession(user: string): Session {
    return {
      userName: user,
      lastviewed: [],
      lastnavigation: '',
      userStation: null,
      userLanguage: ''
    };
  }
}
