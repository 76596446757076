<div class="add-btn-container">
  <app-button
    [size]="(deviceService.isMobile | async) ? '14px' : '18px'"
    [title]="'Add new input'"
    [icon]="'fa fa-plus'"
    [backgroundColor]="'white'"
    [iconColor]="'white'"
    [fontColor]="'var(--fabbrica-xdark)'"
    [iconBackgroundColor]="'var(--fabbrica-xdark)'"
    [border]="'1px solid var(--fabbrica-xdark)'"
    (click)="toggleExpand()"
  >
  </app-button>
</div>

<ng-container *ngIf="expanded">
  <button
    *ngFor="
      let item of options;
      let i = index;
      first as isFirst;
      last as isLast
    "
    class="container expanded"
    [class.first]="isFirst"
    [class.last]="isLast"
    (click)="onButtonSelect(i)"
  >
    {{ item }}
  </button>
</ng-container>
