<div id="range-presets-select" >
  <div
    class="select"
    [ngClass]="enable ? '' : 'disable'"
    [class.error]="error"
    (click)="toggleSelect(); $event.stopPropagation()"
  >
    <ng-container *ngIf="selectedPreset < 0; else value">
      {{ placeholder }}
    </ng-container>
    <ng-template #value> {{ presets[selectedPreset].label }}</ng-template>
  </div>
  <span id="arrow-button-container">
    <app-round-button
      *ngIf="isSelectOpen"
      class="button"
      icon="fas fa-chevron-up"
      iconColor="white"
      shadow="none"
      [sizepx]="36"
      fontSize="18px"
      (click)="toggleSelect()"
    ></app-round-button>
    <app-round-button
      *ngIf="!isSelectOpen"
      [isActive]="enable"
      class="button"
      icon="fas fa-chevron-down"
      iconColor="white"
      shadow="none"
      [sizepx]="36"
      fontSize="18px"
      (click)="toggleSelect(); $event.stopPropagation()"
    ></app-round-button>
  </span>
  <div class="select-options" *ngIf="isSelectOpen">
    <ng-container *ngFor="let option of presets; let i = index; last as isLast">
      <div
        class="option"
        [ngClass]="isLast ? 'last-option' : ''"
        (click)="selectPreset(i)"
      >
        {{ option.label }}
      </div>
    </ng-container>
  </div>
</div>
