import { Injectable } from '@angular/core';
import { DeviceType } from '../enums/device-type.enum';
import { DeviceDetectionService } from './device-detection.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  isMobile = new BehaviorSubject<boolean>(window.innerWidth <= 750);

  constructor(private deviceDetectorservice: DeviceDetectionService) {}

  actualDevice(): DeviceType {
    let device = DeviceType.UNKNOWN;

    if (this.isType(DeviceType.COMPUTER)) {
      device = DeviceType.COMPUTER;
    } else if (this.isType(DeviceType.TABLET)) {
      device = DeviceType.TABLET;
    } else if (this.isType(DeviceType.MOBILE)) {
      device = DeviceType.MOBILE;
    }

    if (this.isType(DeviceType.UNKNOWN)) {
      device = DeviceType.COMPUTER;
    }

    if (environment.configuration.bypassDevice) {
      device = environment.configuration.bypassDevicetype;
    }
    return device;
  }

  private isType(deviceType: DeviceType): boolean {
    switch (deviceType) {
      case DeviceType.COMPUTER:
        return this.deviceDetectorservice.isDesktop();
      case DeviceType.TABLET:
        return this.deviceDetectorservice.isTablet();
      case DeviceType.MOBILE:
        return this.deviceDetectorservice.isMobile();
    }
  }
}
