import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IntranetSmrLinkService {
  private devIntranetOrigin = 'https://fabbrica.ingeniusolutions.com';
  private prodIntranetOrigin = 'https://intranet.fabbricausa.com';

  constructor() {}

  getUrlForOneSmr(smrId: number): string {
    const origin = this.getOriginLocation();
    return `${origin}/document/?ref=${smrId.toString()}`;
  }
  getUrlForMultipleSmrs(smrsId: Array<number>): string {
    const origin = this.getOriginLocation();
    const formattedIds = smrsId.join(',');
    return `${origin}/documents/?refs=${formattedIds}`;
  }

  viewSmrOnIntranet(smrId: number) {
    const url = this.getUrlForOneSmr(smrId);
    this.openNewWindowToLink(url);
  }

  viewMultipleSmrsOnIntranet(smrsId: Array<number>) {
    if (smrsId.length === 1) {
      this.viewSmrOnIntranet(smrsId[0]);
    } else if (smrsId.length > 1) {
      const url = this.getUrlForMultipleSmrs(smrsId);
      this.openNewWindowToLink(url);
    }
  }

  private openNewWindowToLink(url: string) {
    window.open(url);
  }

  private getOriginLocation() {
    return environment.production ? this.prodIntranetOrigin : this.devIntranetOrigin;
  }
}
