import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, timeout } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AuthService } from './auth.service';
import { FabbricaHttpTimeoutService } from './fabbrica-http-timeout.service';
import { TokenStorageService } from './token-storage.service';

@Injectable()
export class FabbricaHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private tokenStorageService: TokenStorageService,
    private readonly fabbricaHttpTimeout: FabbricaHttpTimeoutService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('assets')) {
      return next.handle(req);
    }

    const { url } = this.router.routerState.snapshot;
    if (url.startsWith('/iframe')) {
      return next.handle(req);
    }

    const token = this.tokenStorageService.get();
    const headers = token ? { 'x-access-token': encodeURI(token) } : {};
    req = req.clone({ setHeaders: headers });

    return next.handle(req).pipe(
      timeout(this.fabbricaHttpTimeout.getTimeout(req.url)),
      catchError(err => {
        if (err.status === 401) {
          this.authService.logout();
          this.router.navigateByUrl('login');
        } else if (err.name === 'TimeoutError') {
          err = new HttpErrorResponse({ status: 408 });
        }
        return throwError(err);
      })
    );
  }
}
