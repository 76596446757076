import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.scss']
})
export class CommentSectionComponent implements AfterViewInit {
  @Input() disabled: boolean;
  @Input() comment: string;
  @Output() commentChange = new EventEmitter<string>();
  @ViewChild('commentBox') commentBox: ElementRef;

  ngAfterViewInit(): void {
    this.commentBox.nativeElement.value = '';
    if (this.comment) {
      this.commentBox.nativeElement.value += this.comment;
    }
  }

  onChange() {
    this.comment = this.commentBox.nativeElement.value;
    this.commentChange.emit(this.comment);
  }
}
