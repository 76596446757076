import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { ScreenSizeService } from '../../../shared/services/screen-size.service';
import { ScreenType } from '../../../shared/enums/screen-type.enum';

@Component({
  selector: 'app-data-container',
  templateUrl: './data-container.component.html',
  styleUrls: ['./data-container.component.scss']
})
export class DataContainerComponent implements OnInit, OnChanges {
  @Input() dataList: Array<any>;
  @Input() initialState: boolean;
  @Input() gridTemplate: string;
  gridTemplateSmall: string;
  isExpanded: boolean;
  mainData: Array<any>;
  otherData: Array<Array<any>>;
  screenType: ScreenType;
  ScreenTypeEnum = ScreenType;
  qty: string;

  constructor(private screenSizeService: ScreenSizeService) {}

  ngOnInit() {
    this.screenSizeService.screenTypeSubject.subscribe(newScreenSize => {
      this.screenType = newScreenSize;
    });

    if (this.initialState) {
      this.isExpanded = this.initialState;
    } else {
      this.isExpanded = false;
    }
    if (this.gridTemplate) {
      this.gridTemplateSmall = `${this.gridTemplate.match(/^[\S]+ /)} 1fr`;
    }
  }

  ngOnChanges() {
    if (this.dataList) {
      this.mainData = Array.isArray(this.dataList[0]) ? this.dataList[0] : this.dataList;
      this.otherData = Array.isArray(this.dataList[0]) ? this.dataList.slice(1) : [[]];

      let index: number;
      const qtyItem = this.mainData.find((data, ind) => {
        index = ind;
        return data.id === '_qty';
      });

      if (qtyItem) {
        this.qty = qtyItem.value;
        this.mainData.splice(index, 1);
      }
    }
  }

  toggleExpansion() {
    this.isExpanded = !this.isExpanded;
  }

  nameClass(item) {
    if (item.id === 'FileName') {
      return 'name';
    }
  }
}
