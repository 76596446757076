<div class="container">
  <div class="button-container">
    <app-button
      [disable]="disabled"
      title="Take photo"
      width="200px"
      height="50px"
      icon="fa fa-camera"
      iconSize="25px"
      [backgroundColor]="disabled ? 'white' : 'var(--fabbrica)'"
      [fontColor]="disabled ? '#CCCCCC' : 'white'"
      [border]="disabled ? '2px solid #CCCCCC' : ''"
      (click)="toggleShowCamera()"
    ></app-button>
  </div>
  <div [ngClass]="pictures.length > 0 ? 'gallery' : ''">
    <ng-container *ngFor="let pic of pictures; let i = index">
      <img
        *ngIf="pictureCorrectType(pic)"
        class="thumbnail"
        [src]="base64Prefix + pic.data"
        (click)="amplifyPicture(i)"
      />
      <app-round-button
        *ngIf="pictureCorrectType(pic)"
        [icon]="'fa fa-trash'"
        class="delete-picture-button"
        [sizepx]="24"
        fontSize="12px"
        backgroundColor="var(--red)"
        iconColor="var(--menuItem-background1)"
        shadow="var(--shadow1)"
        (click)="deletePicture(i)"
      ></app-round-button>
    </ng-container>
  </div>
</div>

<app-modal-camera
  *ngIf="showCamera"
  (cancelPressed)="toggleShowCamera()"
  (pictureTaken)="addNewPicture($event)"
></app-modal-camera>

<app-amplified-picture
  *ngIf="showAmplifiedPicture"
  [pictures]="pictures"
  [pictureSelectedIndex]="pictureSelectedIndex"
  (closeView)="closeAmplifiedPicture()"
></app-amplified-picture>
