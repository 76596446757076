import { Directive, Input, ElementRef, OnChanges } from '@angular/core';

import { endOfDay, addDays, startOfDay } from 'date-fns';

import { SmrProgressService } from '../services/smr-progress.service';

@Directive({
  selector: '[appDatePosition]'
})
export class DatePositionDirective implements OnChanges {
  @Input() date: Date;
  @Input() displayRange: number;
  @Input() minDate: Date;
  private maxDate: Date;

  constructor(private el: ElementRef, private readonly smrProgressService: SmrProgressService) {}

  ngOnChanges() {
    if (this.minDate && this.displayRange) {
      this.minDate = startOfDay(this.minDate);
      this.maxDate = endOfDay(addDays(this.minDate, this.displayRange - 1));
    }
    if (this.minDate && this.date && this.maxDate) {
      const position = this.smrProgressService.computeDatePosition({
        min: startOfDay(this.minDate),
        date: this.date,
        max: this.maxDate
      });
      this.el.nativeElement.style.left = position;
    }
  }
}
