import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkInstructionsManagerComponent } from './work-instructions-manager.component';
import { WorkInstructionsLinksComponent } from './components/work-instructions-links/work-instructions-links.component';
import { WorkInstructionsStationsComponent } from './components/work-instructions-stations/work-instructions-stations.component';
import { WorkInstructionsLinesComponent } from './components/work-instructions-lines/work-instructions-lines.component';
import { WorkInstructionsFrameImportComponent } from './components/work-instructions-frame-import/work-instructions-frame-import.component';
import { KpiTargetComponent } from './components/kpi-target/kpi-target.component';
// tslint:disable-next-line
import { LineConfigurationComponent } from './components/work-instructions-lines/components/line-configuration/line-configuration.component';
import { LinkerComponent } from './components/work-instructions-links/components/linker/linker.component';
import { StationConfigurationBodyComponent } from './components/work-instructions-station-configuration/components/station-configuration-body/station-configuration-body.component';
import { UnsavedChangesGuard } from '../../core/guards/unsaved-changes.guard';
import { PermissionsGuard } from '../../core/guards/permissions.guard';
import { Functionality } from '../../shared/enums/functionality.enum';

const routes: Routes = [
  {
    path: '',
    component: WorkInstructionsManagerComponent,
    children: [
      { path: 'stations', component: WorkInstructionsStationsComponent },
      { path: 'links', component: WorkInstructionsLinksComponent },
      {
        path: 'links/:id',
        component: LinkerComponent,
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: 'stations/station-configuration/:id',
        component: StationConfigurationBodyComponent,
        canDeactivate: [UnsavedChangesGuard]
      },
      { path: 'lines', component: WorkInstructionsLinesComponent },
      {
        path: 'lines/:id',
        component: LineConfigurationComponent,
        canDeactivate: [UnsavedChangesGuard]
      },
      { path: 'kpi', component: KpiTargetComponent },
      {
        path: 'frame-upload',
        component: WorkInstructionsFrameImportComponent,
        canActivate: [PermissionsGuard],
        data: { functionalities: [Functionality['SMR Manager']] }
      },
      { path: '', redirectTo: 'stations', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkInstructionsManagerRoutingModule {}
