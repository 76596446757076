import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { PermissionsService } from '../../../../services/permissions.service';
import { ToastService } from '../../../../services/toast.service';
import { UserService } from '../../../../services/user.service';

import { WorkInstructionsConformityToggleButtonData } from '../../interfaces/work-instructions-conformity-toggle-button-data.interface';
import { StepInputValidatedService } from '../../services/step-input-validated.service';

@Component({
  selector: 'app-conformity-toggle-button',
  templateUrl: './conformity-toggle-button.component.html',
  styleUrls: ['./conformity-toggle-button.component.scss']
})
export class ConformityToggleButtonComponent implements OnInit, OnChanges {
  @Input() stepIndex: number;
  @Input() editMode: boolean;
  @Input() disabled: boolean;
  @Input() selectedDefault = -1;
  @Output() buttonToggled = new EventEmitter<number>();

  selectedButton: number;
  data: WorkInstructionsConformityToggleButtonData[];
  color = 'var(--menuItem-background2)';

  constructor(
    private userService: UserService,
    private permissionsService: PermissionsService,
    private toastrService: ToastService,
    private validatedStepInputsService: StepInputValidatedService
  ) {}

  private init() {
    this.selectedButton = this.selectedDefault;
    switch (this.selectedButton) {
      case -1:
        this.color = 'var(--menuItem-background2)';
        break;
      case 0:
        this.color = 'var(--fabbrica)';
        break;
      case 1:
        this.color = 'var(--red)';
        break;
      case 2:
        this.color = 'gray';
        break;
    }

    this.data = [
      {
        text: 'C',
        iconClass: 'fa fa-check'
      },
      {
        text: 'NC',
        iconClass: 'fa fa-times'
      },
      {
        text: 'NA',
        iconClass: 'fa fa-question'
      }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  ngOnInit(): void {
    this.init();
  }

  onButtonClick(index: number) {
    const validInputs = this.validatedStepInputsService.getValue(
      this.stepIndex
    );

    if (this.editMode || this.disabled) {
      return;
    }
    if (
      this.permissionsService.isVisible(
        this.userService.getUser(),
        'unit.workinstructions.stepconformity'
      )
    ) {
      if (index !== 0) {
        this.selectedButton = this.selectedButton === index ? -1 : index;
        switch (this.selectedButton) {
          case -1:
            this.color = 'var(--menuItem-border1)';
            break;
          case 0:
            this.color = 'var(--fabbrica)';
            break;
          case 1:
            this.color = 'var(--red)';
            break;
          case 2:
            this.color = 'gray';
            break;
        }
        this.buttonToggled.emit(this.selectedButton);
      } else {
        if (validInputs || this.selectedButton === 0) {
          this.selectedButton = this.selectedButton === index ? -1 : index;
          switch (this.selectedButton) {
            case -1:
              this.color = 'var(--menuItem-border1)';
              break;
            case 0:
              this.color = 'var(--fabbrica)';
              break;
            case 1:
              this.color = 'var(--red)';
              break;
            case 2:
              this.color = 'gray';
              break;
          }
          this.buttonToggled.emit(this.selectedButton);
        } else {
          this.toastrService.warning(
            'The step inputs must be filled to conform it!'
          );
        }
      }
    }
  }
}
