<div
  class="link-line hover"
  (click)="setExpanded(!recursiveItem.isExpanded); $event.stopPropagation()"
>
  <div class="left-supercontainer">
    <div *ngIf="deviceService.isMobile | async" class="icon-container">
      <div class="depth" *ngFor="let depth of depthArray()"></div>
    </div>

    <div class="left-container">
      <div class="container">
        <div
          *ngIf="(deviceService.isMobile | async) === false"
          class="icon-container"
        >
          <div class="depth" *ngFor="let depth of depthArray()"></div>
        </div>

        <div class="description">{{ formatName() }}</div>
      </div>

      <div class="config">
        <div *ngIf="iconColor === 'green'" class="link-status-icon green">
          <i class="fa fa-check"></i>
        </div>
        <div *ngIf="iconColor === 'red'" class="link-status-icon red">
          <i class="fa fa-question"></i>
        </div>
        <div *ngIf="iconColor === 'orange'" class="link-status-icon orange">
          <i class="fa fa-minus"></i>
        </div>
        <div *ngIf="iconColor === 'gray'" class="link-status-icon gray">
          <i class="fa fa-link"></i>
        </div>

        <div [ngStyle]="boldTemplate ? { 'font-weight': 'bold' } : {}">
          {{ getTemplateName(recursiveItem.lineConfig) }}
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="add-btn-container">
      <app-round-button
        *ngIf="hasChildren() && !recursiveItem.isExpanded"
        class="mr10px expand-btn"
        data-cy="toggle"
        [icon]="'fas fa-chevron-down'"
        [sizepx]="32"
        fontSize="17px"
        backgroundColor="transparent"
        [iconColor]="'var(--menuItem-text1)'"
        shadow="none"
      ></app-round-button>
      <app-round-button
        *ngIf="hasChildren() && recursiveItem.isExpanded"
        class="mr10px expand-btn"
        data-cy="toggle"
        [icon]="'fas fa-chevron-up'"
        [sizepx]="32"
        fontSize="17px"
        backgroundColor="transparent"
        [iconColor]="'var(--menuItem-text1)'"
        shadow="none"
      ></app-round-button>
    </div>
    <app-round-button
      [icon]="'fa fa-edit'"
      [sizepx]="48"
      fontSize="24px"
      backgroundColor="var(--fabbrica)"
      iconColor="var(--menuItem-background1)"
      shadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      (click)="setShowSelector(true); $event.stopPropagation()"
    ></app-round-button>
  </div>
</div>
<div class="children-container" *ngIf="hasChildren()">
  <div [hidden]="!recursiveItem.isExpanded" class="roww">
    <app-recursive-linker
      *ngFor="let child of recursiveItem.children"
      [recursiveItem]="child"
      [depth]="depth + 1"
      [templates]="templates"
      [allTemplates]="allTemplates"
      (itemChanged)="emitChange($event)"
    ></app-recursive-linker>
  </div>
</div>

<app-modal-select
  *ngIf="showTemplatesSelector"
  [header]="'Select a line configuration'"
  [headerIcon]="'fa fa-list'"
  [elements]="templates"
  [selectedElement]="recursiveItem.lineConfig"
  (cancelEmitter)="setShowSelector(false)"
  (confirmEmitter)="onTemplateChanged($event)"
></app-modal-select>
