import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { StorageKey } from '../enums/storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class FullScreenService {
  constructor(private storageService: StorageService) {}

  set(state: boolean): void {
    if (state) {
      this.storageService.setDeprecated(StorageKey.FULLSCREEN, 'true');
    } else {
      this.storageService.setDeprecated(StorageKey.FULLSCREEN, 'false');
    }
  }

  get(): boolean {
    const stateStr = this.storageService.getDeprecated(StorageKey.FULLSCREEN);
    if (stateStr === 'true') {
      return true;
    } else {
      return false;
    }
  }

  remove(): void {
    this.storageService.removeDeprecated(StorageKey.FULLSCREEN);
  }

  toggle() {
    this.set(!this.get());
  }
}
