<div id="lot-input" [class.error]="error">
  <input
    id="lot1"
    type="text"
    name="lot"
    [disabled]="!lotEnabled || readOnly"
    (keyup)="enableSublot($event)"
    [value]="reportForm.get('lot').value"
  />
  <span class="lot-separator">-</span>
  <input
    id="lot2"
    type="text"
    name="sublot"
    [disabled]="!sublotEnabled || !lotEnabled || readOnly"
    (keyup)="enableSubsublot($event)"
    [value]="reportForm.get('sublot').value"
  />
  <span class="lot-separator">-</span>
  <input
    id="lot3"
    type="text"
    name="subsublot"
    [disabled]="!subSublotEnabled || !sublotEnabled || !lotEnabled || readOnly"
    (keyup)="setSubSublotValue($event)"
    [value]="reportForm.get('subSublot').value"
  />
</div>
