import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

import { TokenStorageService } from 'src/app/shared/services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DisconnectedGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private tokenStorageService: TokenStorageService) {}

  canActivate(): boolean | UrlTree {
    return this.checkToken();
  }

  canActivateChild(): boolean | UrlTree {
    return this.checkToken();
  }

  private checkToken(): boolean | UrlTree {
    const token = this.tokenStorageService.get();
    return token ? this.router.parseUrl('/app') : true;
  }
}
