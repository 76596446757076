<div (click)='emitClick()'>
    <div class="row">
        <ng-content *ngIf="expanded"></ng-content>
        <app-round-button
          [icon]="'fa fa-cog'"
          [sizepx]="59"
          fontSize="30px"
          backgroundColor="var(--fabbrica)"
          iconColor="var(--menuItem-background1)"
          shadow="var(--shadow1)"
          (click)='toggle(); $event.stopPropagation()'
        ></app-round-button>
    </div>
</div>
