<div id="full-container">
  <app-doc-head
    [name]="headerService.headerTitle | async"
    [description]="headerService.subheaderTitle | async"
    size="small"
  >
    <ng-template #rightButtonTemplateRef>
      <app-button
        *ngIf="
          (deviceService.isMobile | async) === false &&
            editionService.showEditionButton | async
        "
        [title]="'Edit name'"
        [icon]="'fa fa-edit'"
        [backgroundColor]="'transparent'"
        [fontColor]="'white'"
        [iconBackgroundColor]="'transparent'"
        [shadow]="'none'"
        [border]="'1px solid white'"
        (click)="editionButtonClick()"
      >
      </app-button>

      <app-round-button
        *ngIf="
          (deviceService.isMobile | async) &&
          (editionService.showEditionButton | async)
        "
        [icon]="'fa fa-edit'"
        [sizepx]="48"
        fontSize="22px"
        backgroundColor="transparent"
        iconColor="white"
        border="1px solid white"
        shadow="none"
        (click)="editionButtonClick()"
      ></app-round-button>
    </ng-template>
  </app-doc-head>

  <div id="main-content">
    <router-outlet></router-outlet>
  </div>
</div>
