import { Component, Input, OnInit } from '@angular/core';
import { DeviceType } from '../../../../shared/enums/device-type.enum';
import { DeviceService } from '../../../../shared/services/device.service';

import { MenuState } from '../../enums/menu-state.enum';

@Component({
  selector: 'app-header-tab',
  templateUrl: './header-tab.component.html',
  styleUrls: ['./header-tab.component.scss']
})
export class HeaderTabComponent implements OnInit {
  @Input() state: MenuState;
  deviceType: DeviceType;

  constructor(private deviceService: DeviceService) {}
  ngOnInit(): void {
    this.deviceType = this.deviceService.actualDevice();
  }

  rotationActive() {
    if (this.isClose(this.state)) {
      return 'rotation-active';
    } else {
      return '';
    }
  }

  isClose(state: MenuState): boolean {
    return !(
      state === MenuState.COMPUTEROPEN ||
      state === MenuState.MOBILEOPEN ||
      state === MenuState.TABLETOPEN
    );
  }
}
