<app-round-button
  [icon]="'fa fa-times'"
  class="close-btn"
  [sizepx]="36"
  fontSize="22px"
  backgroundColor="var(--red)"
  iconColor="var(--menuItem-background1)"
  shadow="var(--shadow1)"
  (click)="removeEvent.emit()"
></app-round-button>

<div class="container">
  <app-flat-icon
    [icon]="user.type === 'user' ? 'fa fa-user' : 'fa fa-users'"
    size="48px"
    iconSize="26px"
    color="var(--fabbrica-xdark)"
  >
  </app-flat-icon>
  <div class="padd-right-16">{{ user.name }}</div>
</div>
