import { Injectable } from '@angular/core';

import { StorageKey } from '../enums/storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  getDeprecated(key: StorageKey): string {
    return localStorage.getItem(key.valueOf());
  }

  setDeprecated(key: StorageKey, jsonValue: string): string {
    localStorage.setItem(key.valueOf(), jsonValue);
    return jsonValue;
  }

  removeDeprecated(key: StorageKey) {
    localStorage.removeItem(key.valueOf());
  }

  set(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch {}
    return null;
  }

  clear(key: string): void {
    localStorage.removeItem(key);
  }
}
