import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import {
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks
} from 'date-fns';
import { Subscription } from 'rxjs';
import { ClickOnScreenService } from '../../services/click-on-screen.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  @Input() initialDate: Date;
  @Input() endDate: Date | null;
  @Output() filterChange = new EventEmitter<Array<Date | null>>();

  flatpickrInstance: Instance;
  isSelectOpen = false;
  subscriptions: Subscription[] = [];

  constructor(private clickOnScreenService: ClickOnScreenService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.clickOnScreenService.clickOnScreenEvent.subscribe(() => {
        if (this.isSelectOpen) {
          this.isSelectOpen = false;
        }
      })
    );
    this.initDateSelect();

    const initialDate = this.initialDate;
    const endDate = this.endDate;

    this.flatpickrInstance.setDate([initialDate, endDate]);
    this.filterChange.emit([initialDate, endDate]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private initDateSelect() {
    const myInput = document.querySelector('.calendar-select-container');
    this.flatpickrInstance = flatpickr(myInput, {
      mode: 'range',
      onClose: this.onFlatpickrClose.bind(this),
      static: true
    });
  }

  onFlatpickrClose() {
    // useful to avoid making a request on each date change.
    const [initialDate, endDate] = this.flatpickrInstance.selectedDates;
    if (
      !isSameDay(this.initialDate, initialDate) ||
      !isSameDay(this.endDate, endDate)
    ) {
      this.initialDate = initialDate;
      this.endDate = endDate;
      this.filterChange.emit(this.flatpickrInstance.selectedDates);
    }
  }

  toggleSelect() {
    this.isSelectOpen = !this.isSelectOpen;
  }
}
