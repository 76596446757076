import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  isDesktop() {
    const width = window.innerWidth;
    return width >= 1024;
  }
  isTablet() {
    const width = window.innerWidth;
    return 1024 > width && width > 750;
  }
  isMobile() {
    const width = window.innerWidth;
    return width <= 750;
  }
}
