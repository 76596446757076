import { Component, OnInit } from '@angular/core';
import { SmrSelectionService } from '../../services/smr-selection.service';
import { IntranetSmrLinkService } from '../smr/services/intranet-smr-link.service';

@Component({
  selector: 'app-smr-intranet-viewer-button',
  templateUrl: './smr-intranet-viewer-button.component.html',
  styleUrls: ['./smr-intranet-viewer-button.component.scss']
})
export class SmrIntranetViewerButtonComponent implements OnInit {
  isEnable: boolean;
  buttonBackgroundColor: string;
  selectedSmrs: Array<number>;
  constructor(
    private readonly smrSelectionService: SmrSelectionService,
    private readonly intranetSmrLinkService: IntranetSmrLinkService
  ) {
    this.selectedSmrs = [];
    this.isEnable = false;
    this.buttonBackgroundColor = 'var(--menuItem-border1)';
  }

  ngOnInit(): void {
    this.smrSelectionService.selectionListSubject.subscribe(newSelectedSmrs => {
      this.selectedSmrs = newSelectedSmrs;
      if (this.selectedSmrs && this.selectedSmrs.length > 0) {
        this.isEnable = true;
        this.buttonBackgroundColor = 'var(--fabbrica)';
      } else {
        this.isEnable = false;
        this.buttonBackgroundColor = 'var(--menuItem-border1)';
      }
    });
  }

  viewSmrs() {
    if (this.isEnable) {
      this.intranetSmrLinkService.viewMultipleSmrsOnIntranet(this.selectedSmrs);
    }
  }
}
