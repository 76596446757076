import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from '../../services/toast.service';
@Component({
  selector: 'app-frame-pictures',
  templateUrl: './frame-pictures.component.html',
  styleUrls: ['./frame-pictures.component.scss']
})
export class FramePicturesComponent {
  @Input() disabled: boolean;
  @Input() pictures: any[];
  @Output() pictureTaken = new EventEmitter<any>();
  @Output() pictureDeleted = new EventEmitter<string>();

  showCamera = false;
  base64Prefix = 'data:image/jpeg;base64,';

  showAmplifiedPicture = false;
  pictureSelectedIndex = -1;

  constructor(private toastrService: ToastService) {}

  pictureCorrectType(picture: any) {
    return typeof picture !== 'string';
  }

  toggleShowCamera() {
    this.showCamera = !this.showCamera;
  }

  addNewPicture(picture: any) {
    this.pictureTaken.emit(picture.imageAsBase64);
    this.toastrService.success('Picture successfully taken');
  }

  deletePicture(i) {
    const deletedPicture = this.pictures.splice(i, 1)[0];
    if (!deletedPicture.new) {
      this.pictureDeleted.emit(deletedPicture.name);
    }
  }

  amplifyPicture(index: number) {
    this.showAmplifiedPicture = true;
    this.pictureSelectedIndex = index;
  }

  closeAmplifiedPicture() {
    this.showAmplifiedPicture = false;
    this.pictureSelectedIndex = -1;
  }
}
