import { Component, Input } from '@angular/core';

import { BomPart } from '../../../models/bom-data.model';

@Component({
  selector: 'app-bom-list-section',
  templateUrl: './bom-list-section.component.html',
  styleUrls: ['./bom-list-section.component.scss']
})
export class BomListSectionComponent {
  @Input() documents: Array<BomPart>;
}
