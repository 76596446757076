import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecursiveItem } from './interfaces/recursive-item';
import { RecursiveLinkerService } from './services/recursive-linker.service';
import { DeviceService } from '../../services/device.service';
import { SelectValue } from '../modal-select/interfaces/select-value';

@Component({
  selector: 'app-recursive-linker',
  templateUrl: './recursive-linker.component.html',
  styleUrls: ['./recursive-linker.component.scss']
})
export class RecursiveLinkerComponent implements OnInit {
  @Input() recursiveItem: RecursiveItem;
  @Input() templates: SelectValue[];
  @Input() allTemplates: SelectValue[];
  @Input() depth: number;
  @Output() itemChanged = new EventEmitter<RecursiveItem>();

  showTemplatesSelector = false;
  boldTemplate = false;
  iconClass = '';
  iconColor = '';
  name = '';

  constructor(
    private recursiveLinkService: RecursiveLinkerService,
    public deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.depth = this.depth || 0;
    this.getTemplateName(this.recursiveItem.lineConfig);
  }

  hasChildren(): boolean {
    return !!this.recursiveItem?.children?.length;
  }

  hasLink(): boolean {
    return this.recursiveItem.lineConfig !== 'inherited';
  }

  depthArray(): number[] {
    return Array.from({ length: this.depth }, (v, i) => i);
  }

  onTemplateChanged(values: any[]): void {
    const val = values[0];
    this.recursiveItem.lineConfig = val.id;
    this.recursiveItem.manuallyAssignedValue =
      this.recursiveItem.lineConfig === 'inherited' ? false : true;
    this.recursiveLinkService.itemChanged(this.recursiveItem);
    this.itemChanged.emit(this.recursiveItem);
  }

  emitChange(item: RecursiveItem) {
    this.itemChanged.emit(item);
  }

  setExpanded(val: boolean) {
    this.recursiveItem.isExpanded = val;
    const action = this.recursiveItem.isExpanded
      ? 'collapseBrothers'
      : 'collapseChildren';
    this.recursiveLinkService[action](this.recursiveItem);
  }

  setShowSelector(val: boolean) {
    this.formatName();
    this.showTemplatesSelector = val;
  }

  formatName() {
    const splittedLongId = this.recursiveItem.longId.split(',');
    let namePart: String;
    if (this.recursiveItem.nodeType === 'Frame') {
      namePart = splittedLongId[4];
    } else {
      let formattedName = splittedLongId[1];
      for (let i = 2; i < splittedLongId.length; i++) {
        formattedName += '.' + splittedLongId[i];
      }
      namePart = formattedName;
    }

    if (this.recursiveItem.description) {
      namePart = `${namePart} - ${this.recursiveItem.description}`;
    }
    return namePart;
  }

  getTemplateName(id: string): string {
    const name = this.allTemplates[
      this.allTemplates.findIndex(elem => elem.id === id)
    ]?.label;
    if (this.recursiveItem.manuallyAssignedValue) {
      this.boldTemplate = true;
      if (name === 'No Template') {
        this.iconClass = 'fa fa exclamation';
        this.iconColor = 'orange';
      } else {
        this.iconClass = 'fa fa check';
        this.iconColor = 'green';
      }
      return name;
    }
    this.boldTemplate = false;
    if (name !== 'Inherited') {
      this.iconColor = 'gray';
    } else {
      this.iconColor = 'red';
    }
    return name !== 'Inherited' ? `${name}` : 'Not set';
  }
}
