import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bom-tree-item-container',
  templateUrl: './bom-tree-item-container.component.html',
  styleUrls: ['./bom-tree-item-container.component.scss']
})
export class BomTreeItemContainerComponent {
  @Input() document: any;

  isExpanded: boolean;
  constructor() {
    this.isExpanded = false;
  }

  isExpandedEvent(newExpandedState: boolean) {
    this.isExpanded = newExpandedState;
  }
}
