import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { SmrProgressService } from '../services/smr-progress.service';

@Directive({
  selector: '[appDateHoveredArea]'
})
export class DateHoveredAreaDirective implements AfterViewInit, OnDestroy {
  updateCalendarRectFn;

  constructor(
    private el: ElementRef,
    private readonly smrProgressService: SmrProgressService,
    private readonly window: Window
  ) {}

  ngAfterViewInit() {
    this.updateCalendarRectFn = () =>
      (this.smrProgressService.calendarRect = this.el.nativeElement.getBoundingClientRect());
    this.window.addEventListener('resize', this.updateCalendarRectFn.bind(this));
    this.updateCalendarRectFn();
  }

  ngOnDestroy() {
    this.window.removeEventListener('resize', this.updateCalendarRectFn.bind(this));
  }
}
