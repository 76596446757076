import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackendService } from '../../../../../../app/shared/services/backend.service';
import {
  FrontedLineDto,
  FrontedLineStationDto
} from '../interfaces/line.interface';

@Injectable({
  providedIn: 'root'
})
export class LinesBackendService {
  private linesURI = 'birth-certificate/line-configurations';

  lines: BehaviorSubject<any[]> = new BehaviorSubject<FrontedLineDto[]>(null);
  allLines: BehaviorSubject<any[]> = new BehaviorSubject<FrontedLineDto[]>(null);
  innerLines: FrontedLineDto[] = [];

  constructor(private backend: BackendService) {
    this.getAll();
    this.getSoftDeleted();
  }

  getAll() {
    this.backend.get(this.linesURI).subscribe(data => {
      this.innerLines = [...data];
      this.lines.next(this.innerLines);
    });
  }

  getSoftDeleted() {
    this.backend.get(`${this.linesURI}?deleted`).subscribe(data => {
      this.allLines.next(data);
    });
  }

  getLineById(id: string, version: number): Observable<any> {
    return this.backend
      .get(`${this.linesURI}/${id}?version=${version}`)
  }

  createNewLine(name: string): Observable<any> {
    return this.backend
      .post(`${this.linesURI}`, { Name: name })
  }

  updateLine(id: string, body: any): Observable<boolean> {
    return this.backend
      .put(this.linesURI, id, body)
      .pipe(map(data => data.success));
  }

  copyLine(id: string): Observable<any> {
    return this.backend
      .post(`${this.linesURI}/copy/${id}`, {});
  }

  deleteAnExistingLine(id: string): Observable<boolean> {
    return this.backend
      .delete(this.linesURI, id)
      .pipe(map(data => data.success));
  }
}

