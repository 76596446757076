import { Injectable } from '@angular/core';
import JSConfetti from 'js-confetti';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {
  private confetti = new JSConfetti();

  throwConfetti(): void {
    this.confetti.addConfetti({
      emojis: ['⚙️'],
      emojiSize: 35,
      confettiNumber: 75
    });
    this.confetti.addConfetti({
      confettiRadius: 10,
      confettiColors: ['#52852e', '#6db23d'],
      confettiNumber: 500
    });
  }
}
