import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../../../models/user';
import { PermissionsService } from '../../../../services/permissions.service';
import { UserService } from '../../../../services/user.service';
import { WorkInstructionsInputTypesEnum } from '../../enums/work-instructions-input-types.enum';
import { WorkInstructionsStepConformity } from '../../enums/work-instructions-step-conformity.enum';
import { WorkInstructionsLanguageTextData } from '../../interfaces/work-instructions-multilingual-text-data.interface';
import { StepCollapseService } from '../../services/step-collapse.service';
import { DeviceService } from '../../../../services/device.service';
import { ClickOnScreenService } from '../../../../services/click-on-screen.service';
import { StepInputValidatedService } from '../../services/step-input-validated.service';
import { BarcodeScannerService } from '../../../../../shared/services/barcode-scanner.service';
import { InputValue } from '../standard-input/standard-input.component';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {
  @Input() defaultExpanded: boolean;
  @Input() disabled: boolean;
  @Input() isFinalQC: boolean;
  @Input() qcBypass: boolean;
  @Input() index: number;
  @Input() editMode: boolean;
  @Input() editBypass: boolean;
  @Input() currentLanguage: string;
  @Input() critical = false;
  @Input() conformity: WorkInstructionsStepConformity =
    WorkInstructionsStepConformity.UNKNOWN;
  @Input() description: WorkInstructionsLanguageTextData = { en: '', es: '' };
  @Input() inputs: any[] = [];
  @Input() comment: string;
  @Input() pictures: any[];
  @Input() unit: string;

  @Output() inputControlsChanged = new EventEmitter<any[]>();
  @Output() criticalityChanged = new EventEmitter<boolean>();
  @Output() stepRemoved = new EventEmitter<number>();
  @Output() inputValueChanged = new EventEmitter<any>();
  @Output() conformityChanged = new EventEmitter<
    WorkInstructionsStepConformity
  >();
  @Output() qcBypassChanged = new EventEmitter<boolean>();
  @Output() commentChange = new EventEmitter<string>();
  @Output() picturesChange = new EventEmitter<any[]>();
  @Output() pictureDeleted = new EventEmitter<string>();
  @Output() picturesSaved = new EventEmitter<any>();
  @Output() commentSaved = new EventEmitter<string>();

  subscriptions: Subscription[] = [];
  conformitySelected = -1;
  conformityColor = 'gray';
  expanded = false;
  deleteExpanded = false;
  user: User;
  rfid: string = '';

  conformityMap = new Map([
    [WorkInstructionsStepConformity.UNKNOWN, -1],
    [WorkInstructionsStepConformity.CONFORM, 0],
    [WorkInstructionsStepConformity.NONCONFORM, 1],
    [WorkInstructionsStepConformity.NOTAPPLICABLE, 2]
  ]);

  conformityColorMap = new Map([
    [WorkInstructionsStepConformity.UNKNOWN, 'empty'],
    [WorkInstructionsStepConformity.CONFORM, 'fabbrica'],
    [WorkInstructionsStepConformity.NONCONFORM, 'red'],
    [WorkInstructionsStepConformity.NOTAPPLICABLE, 'gray']
  ]);

  constructor(
    private stepCollapseService: StepCollapseService,
    private userService: UserService,
    private permissionsService: PermissionsService,
    private clickOnScreenService: ClickOnScreenService,
    private validatedStepInputsService: StepInputValidatedService,
    private scannerService: BarcodeScannerService,
    public deviceService: DeviceService
  ) {}

  private validateStep() {
    let valid = true;
    if (this.critical) {
      if (this.inputs.length > 0) {
        valid = !this.inputs.some(
          inp =>
            inp.inputType !== 'textInput' &&
            (inp.result === null ||
              inp.result === undefined ||
              Number.isNaN(inp.result))
        );
      }
    }
    return valid;
  }

  ngOnInit(): void {
    if (this.inputs.some(inp => inp.inputType === 'rfidInput')) {
      this.subscriptions.push(
        this.scannerService.scannedString.subscribe(
          data => (this.rfid = data.fullString)
        )
      );
    }

    this.user = this.userService.getUser();
    this.conformitySelected = this.conformityMap.get(this.conformity) as number;
    this.conformityColor = this.conformityColorMap.get(
      this.conformity
    ) as string;

    this.subscriptions.push(
      this.stepCollapseService.expandedIndex.subscribe(index => {
        this.expanded = index === this.index;
      })
    );
    this.expanded = this.defaultExpanded;
    this.defaultExpanded = false;

    this.inputs = this.inputs.map((input, i) => {
      return {
        index: i,
        inputType: input.inputType,
        inputData: input.inputData,
        result: input.result
      };
    });

    this.subscriptions.push(
      this.clickOnScreenService.clickOnScreenEvent.subscribe(() => {
        this.deleteExpanded = false;
      })
    );
  }

  emitClick() {
    this.clickOnScreenService.emitClick();
  }

  displayIndex(): string {
    return `Step ${this.index + 1}`;
  }

  emitRemove() {
    this.stepRemoved.emit(this.index);
  }

  emitResult(e: InputValue, index: number) {
    this.inputs[index].result = e.value;
    this.inputControlsChanged.emit(this.inputs);
    const validInputs = this.validateStep();
    this.validatedStepInputsService.setValue(this.index, validInputs);
    if (e.type === WorkInstructionsInputTypesEnum.RFIDINPUT) {
      this.setConformity(-1);
    }
  }

  onTextChange() {
    this.inputControlsChanged.emit(this.inputs);
  }

  displayConformity() {
    if (this.conformity === WorkInstructionsStepConformity.UNKNOWN) {
      return '';
    }
    return this.conformity;
  }

  addInput(e: any) {
    switch (e) {
      case 0:
        this.inputs.push({
          index: this.inputs.length,
          inputType: WorkInstructionsInputTypesEnum.MULTIPLEOPTIONSBUTTON,
          inputData: [{ en: '', es: '' }]
        });
        break;
      case 1:
        this.inputs.push({
          index: this.inputs.length,
          inputType: WorkInstructionsInputTypesEnum.NUMERICALINPUT
        });
        break;
      case 2:
        this.inputs.push({
          index: this.inputs.length,
          inputType: WorkInstructionsInputTypesEnum.TEXTINPUT
        });
        break;
      case 3:
        this.inputs.push({
          index: this.inputs.length,
          inputType: WorkInstructionsInputTypesEnum.WEIGHTINPUT
        });
        break;
      case 4:
        this.inputs.push({
          index: this.inputs.length,
          inputType: WorkInstructionsInputTypesEnum.RFIDINPUT
        });
        break;
      case 5:
        this.inputs.push({
          index: this.inputs.length,
          inputType: WorkInstructionsInputTypesEnum.PHOTOINPUT
        });
      default:
        break;
    }
    this.inputControlsChanged.emit(this.inputs);
  }

  removeInput(index: any) {
    this.inputs.splice(index, 1);
    this.inputs.forEach((input, i) => {
      input.index = i;
    });
    this.inputControlsChanged.emit(this.inputs);
  }

  setConformity(index: number) {
    if (
      !this.editMode &&
      this.permissionsService.isVisible(
        this.user,
        'unit.workinstructions.stepconformity'
      )
    ) {
      this.conformitySelected = index;

      const result = {} as any;
      result.description = this.description;
      result.isCritical = this.critical;
      result.inputs = this.inputs;

      this.conformityMap.forEach((value, key) => {
        if (this.conformitySelected === value) {
          result.conformity = key;
        }
      });

      this.conformityColor = this.conformityColorMap.get(
        result.conformity
      ) as string;
      this.commentChange.emit(this.comment);
      this.picturesChange.emit(this.pictures);
      this.conformityChanged.emit(result.conformity);
    }
  }

  setExpanded(val: boolean) {
    this.stepCollapseService.setExpandedIndex(this.index);
    this.expanded = val;
  }

  setCritical() {
    if (this.editMode) {
      this.critical = !this.critical;
      this.criticalityChanged.emit(this.critical);
    }
  }

  setDeleteExpanded(val: boolean) {
    this.emitClick();
    this.deleteExpanded = val;
  }

  onBypassToggleChange() {
    this.qcBypassChanged.emit(!this.qcBypass);
  }

  handleNewPicture(base64Data: string) {
    const splittedUnit = this.unit.split('$');
    const name = splittedUnit[0].split(':')[1];
    const serial = splittedUnit[splittedUnit.length - 1].split(':')[1];
    const date = new Date().toISOString().replace(/:/g, '_');
    const pictureName = `${name}_${serial}_${date}.jpeg`;
    const picture = { name: pictureName, data: base64Data, new: true };
    this.pictures.push(picture);
  }

  handlePictureDeletion(pictureName: string) {
    this.pictureDeleted.emit(pictureName);
  }
}
