<div (click)="viewSmrs()" id="fixed-container">
  <app-round-button
    sizepx="42"
    fontSize="21px"
    [icon]="'fas fa-eye'"
    [backgroundColor]="buttonBackgroundColor"
    [isActive]="isEnable"
  ></app-round-button>
  <div *ngIf="!isEnable" id="check" class="not-enable round-container">
    <i class="fas fa-check"></i>
  </div>
  <div *ngIf="isEnable" id="number" class="enable round-container">{{ selectedSmrs?.length }}</div>
</div>
