import { Injectable } from '@angular/core';
import { QrCode } from '../models/qrcode';
import { QrValidator } from '../interfaces/qr-validator.interface';


@Injectable({
  providedIn: 'root'
})
export class QrCodeService implements QrValidator {
  private delim = '$';
  fields: QrFieldDefinition[] = [
    { scan: 'NO:', field: 'no' },
    { scan: 'CR:', field: 'cr' },
    { scan: 'POS:', field: 'pos' },
    { scan: 'LOT:', field: 'lot' },
    { scan: 'SN:', field: 'sn' },
    { scan: 'DD:', field: 'expirationDate' },
    { scan: 'PN:', field: 'pailNo' }
  ];


  isValidLabel(label: string): boolean {
    const qrCode = this.getQrCodeFromString(label);
    return qrCode.isValidLabel;
  }

  getQrCodeFromString(newFullString: string = ''): QrCode {
    const cleanString = this.cleanString(newFullString);

    const qrSplitResults: QrSplitResult[] = this.fields
      .map(f => {
        const foundAt = cleanString.indexOf(f.scan);
        const from = foundAt !== -1 ? foundAt + f.scan.length : -1;
        return {
          fieldDef: f,
          foundAt,
          from,
          to: null
        };
      })
      .sort((a, b) => a.from - b.from);

    qrSplitResults.forEach((sr, index, arr) => {
      if (sr.from === -1) {
        sr.to = -1;
      } else if (index < arr.length - 1) {
        sr.to = arr[index + 1].foundAt;
      } else {
        sr.to = cleanString.length;
      }
    });

    const rawQrCode = {};
    qrSplitResults.forEach(sr => {
      rawQrCode[sr.fieldDef.field] = cleanString.slice(sr.from, sr.to);
    });
    const qrCode = rawQrCode as QrCode;

    qrCode.isValidLabel = qrCode.no !== '';

    if (qrCode.sn) {
      if (+qrCode.sn) {
        // this is a frame SN, we need to remove all the heading 0
        qrCode.sn = parseInt(qrCode.sn, 10).toString();
      } else {
        // keep the string as is, this is a checkpart SN
      }
    } else {
      qrCode.sn = '';
    }

    if (qrCode.no && qrCode.no.length > 0) {
      qrSplitResults
        .filter(sr => sr.foundAt !== -1)
        .map(sr => {
          qrCode.link = qrCode.link
            ? `${qrCode.link}${this.delim}${sr.fieldDef.scan}${
                qrCode[sr.fieldDef.field]
              }`
            : `${sr.fieldDef.scan}${qrCode[sr.fieldDef.field]}`;
        });
    } else {
      qrCode.link = newFullString;
    }
    qrCode.floor = this.getFloorFromCr(qrCode.cr);

    if (qrCode.isValidLabel) {
      qrCode.absoluteLink = `/app/unit/${qrCode.link}`;
    } else {
      qrCode.absoluteLink = `/app/component/${qrCode.link}`;
    }

    return qrCode;
  }

  cleanString(rawString: string): string {
    return rawString
      .replace(/\$/g, '')
      .replace(/ /g, '')
      .replace(/\r/g, '')
      .replace(/\n/g, '')
      .toUpperCase();
  }

  hasSameSerialNumberThan(qr1: QrCode, qr2: QrCode): boolean {
    const thisSnWithoutLeadingZeros = parseInt(qr1.sn, 10);
    const otherSnWithoutLeadingZeros = parseInt(qr2.sn, 10);
    return thisSnWithoutLeadingZeros === otherSnWithoutLeadingZeros;
  }

  getFloorFromCr(crateString: string): string {
    if (!crateString || crateString === '') {
      return '';
    }
    const CrRegex = /-[0-9]{5}/;
    const result = crateString.match(CrRegex);
    return (
      (result &&
        Array.isArray(result) &&
        result.length &&
        result[0].slice(1, 3)) ||
      ''
    );
  }
}

interface QrFieldDefinition {
  scan: string;
  field: string;
}

interface QrSplitResult {
  fieldDef: QrFieldDefinition;
  foundAt: number;
  from: number;
  to: number;
}
