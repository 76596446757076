<div *ngIf="title">
  <app-edition-header
    [backRoute]="'app/work-instructions/stations'"
    (saved)="onSave()"
  >
  </app-edition-header>

  <div class="header" *ngIf="(deviceService.isMobile | async) === false">
    <p>Steps</p>
    <p style="margin-left: 70px;">Steps Description</p>
  </div>
  <div class="header" *ngIf="deviceService.isMobile | async">
    <p>Steps/Description</p>
  </div>

  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div
      *ngFor="let step of steps"
      class="step-row"
      cdkDrag
      [cdkDragDisabled]="modalLockService.lockScreen | async"
    >
      <div class="drag" cdkDragHandle>
        <div class="drag-line"></div>
      </div>

      <app-step
        class="mobile-margin-left"
        [editMode]="true"
        [currentLanguage]="'En'"
        [index]="step.index"
        [description]="step.description"
        [critical]="step.isCritical"
        [isFinalQC]="false"
        [inputs]="step.inputs"
        [conformity]="step.conformity"
        [isFinalQC]="step.isFinalQC"
        [(comment)]="step.comment"
        (criticalityChanged)="setCriticality($event, step)"
        (inputControlsChanged)="setInputs($event, step)"
        (stepRemoved)="removeStep(step)"
      >
      </app-step>
    </div>
  </div>

  <div *ngIf="qcStep">
    <div class="step-row">
      <div class="drag">
        <div class="no-drag">
          <svg-icon
            [src]="'assets/icon/ribbon.svg'"
            [stretch]="true"
            [svgStyle]="{ 'width.px': 30, 'height.px': 35, fill: '#C4C4C4' }"
          >
          </svg-icon>
        </div>
      </div>

      <app-step
        class="mobile-margin-left"
        [editMode]="true"
        [currentLanguage]="'En'"
        [index]="steps.length"
        [isFinalQC]="true"
        [description]="qcStep.description"
        [critical]="qcStep.isCritical"
        [inputs]="qcStep.inputs"
        [conformity]="qcStep.conformity"
        [isFinalQC]="qcStep.isFinalQC"
        [(comment)]="qcStep.comment"
        (criticalityChanged)="setCriticality($event, qcStep)"
        (inputControlsChanged)="setInputs($event, qcStep)"
      >
      </app-step>
    </div>
  </div>

  <div class="add-btn-container">
    <app-button
      [title]="'Add new step'"
      [icon]="'fa fa-plus'"
      [backgroundColor]="'var(--fabbrica)'"
      [fontColor]="'white'"
      [iconBackgroundColor]="'var(--fabbrica-xdark)'"
      (click)="addStep()"
    >
    </app-button>
  </div>

  <div style="margin-top: 25%;" *ngIf="!title">
    <app-loading-cog></app-loading-cog>
  </div>

  <app-modal
    *ngIf="editionService.showEditionModal | async"
    header="Name the station configuration"
    headerIcon="fas fa-clipboard-check"
    (cancelEmitter)="closeEditionModal()"
    (confirmEmitter)="renameConfiguration()"
  >
    <input
      class="input-modal"
      type="text"
      placeholder="Enter a new name"
      [value]="newName"
      (change)="changeName($event)"
    />
  </app-modal>
</div>
