import { Directive, Input, ElementRef, OnChanges } from '@angular/core';
import { SmrProgressService } from '../services/smr-progress.service';
import { endOfDay, addDays, startOfDay } from 'date-fns';

@Directive({
  selector: '[appDateRangePosition]'
})
export class DateRangePositionDirective implements OnChanges {
  @Input() minDate: Date;
  @Input() displayRange: number;
  @Input() startDate: Date = new Date();
  @Input() endDate: Date = new Date();

  private maxDate: Date;

  constructor(private el: ElementRef, private readonly smrProgressService: SmrProgressService) {}

  ngOnChanges() {
    if (this.minDate && this.displayRange) {
      this.minDate = startOfDay(this.minDate);
      this.maxDate = endOfDay(addDays(this.minDate, this.displayRange - 1));
    }
    if (this.minDate && this.maxDate && this.startDate && this.endDate) {
      const startDateLeft = this.smrProgressService.computeDatePosition({
        min: this.minDate,
        date: this.startDate,
        max: this.maxDate
      });
      const endDateLeft = this.smrProgressService.computeDatePosition({
        min: this.minDate,
        date: this.endDate,
        max: this.maxDate
      });
      const endDateRight = `${100 - +endDateLeft.slice(0, -1)}%`;

      this.el.nativeElement.style.left = startDateLeft;
      this.el.nativeElement.style.right = endDateRight;
    }
  }
}
