import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ScreenType } from '../enums/screen-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private screenSizes: Array<any>;
  screenTypeSubject: BehaviorSubject<ScreenType>;
  screenWidth: BehaviorSubject<number>;
  constructor() {
    // Defines the different types of screens available within the App:
    this.screenSizes = [
      {
        type: ScreenType.SMALL,
        minWidth: 0,
        maxWidth: 800
      },
      {
        type: ScreenType.MEDIUM,
        minWidth: 801,
        maxWidth: 1200
      },
      {
        type: ScreenType.LARGE,
        minWidth: 1201,
        maxWidth: 999999
      }
    ];
    this.screenWidth = new BehaviorSubject<number>(this.getWidth());
    this.screenTypeSubject = new BehaviorSubject<ScreenType>(this.getSizeMode());
    window.addEventListener('resize', this.resizeUpdate.bind(this));
  }

  private getSizeMode(): ScreenType {
    const windowWidth = this.getWidth();
    const screenType = this.screenSizes.find(screenSize => {
      if (screenSize.minWidth <= windowWidth && screenSize.maxWidth >= windowWidth) {
        return true;
      } else {
        return false;
      }
    });
    if (screenType) {
      return screenType.type;
    } else {
      return ScreenType.UNKNOWN;
    }
  }

  resizeUpdate() {
    this.screenTypeSubject.next(this.getSizeMode());
    this.screenWidth.next(this.getWidth());
  }

  private getWidth(): number {
    return window.innerWidth;
  }
}
