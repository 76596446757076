import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmService } from 'src/app/shared/services/confirm.service';
import { LineConfigurationComponent } from '../../modules/work-instructions-manager/components/work-instructions-lines/components/line-configuration/line-configuration.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<unknown> {
  constructor(private confirmService: ConfirmService) { }

  canDeactivate(component: LineConfigurationComponent): Observable<boolean> | boolean {
    if (component.unsavedChanges) {
      return this.confirmService.confirm()
    }
    return true;
  }
  
}
