import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalLockService {
  lockScreen = new BehaviorSubject<boolean>(false);

  public lock(val: boolean) {
    this.lockScreen.next(val)
  }
}
