import { Component, HostListener } from '@angular/core';
import { DeviceService } from './shared/services/device.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private deviceService: DeviceService) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.deviceService.isMobile.next(event.target.innerWidth <= 750);
  }
}
