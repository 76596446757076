import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { WorkInstructionsEditableItem } from '../../interfaces/work-instructions-editable-item.interface';

@Component({
  selector: 'app-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss']
})
export class EditableTextComponent
  implements OnInit, WorkInstructionsEditableItem {
  @Input() languageLabel: string;
  @Input() defaultValue: string;
  @Input() editModeActivated = false;
  @Input() whiteText = false;
  @Output() defaultValueChange: EventEmitter<string> = new EventEmitter<
    string
  >();

  actualValue: string;

  ngOnInit(): void {
    this.actualValue = this.defaultValue ? this.defaultValue : '';
  }

  changeName(e: any) {
    this.actualValue = e.target.value;
    this.defaultValueChange.emit(this.actualValue);
  }
}
