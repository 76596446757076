<div id="full-box" class="shadow">
  <div
    id="top-section"
    class="shadow"
    [ngStyle]="{ 'background-color': color }"
  >
    <i class="{{ headerIcon }} icon"></i>
    <h2>{{ header }}</h2>
  </div>

  <div id="text-section">
    <ng-content></ng-content>
  </div>

  <div id="bottom-section">
    <div id="button-section" [class.invert-order]="invertButtons">
      <app-button
        *ngIf="!onlyConfirm"
        style="z-index: 2;"
        [title]="cancelButtonText"
        [icon]="'fa fa-times'"
        [backgroundColor]="confirmDelete ? '#828282' : 'var(--red)'"
        [fontColor]="'white'"
        [iconBackgroundColor]="
          confirmDelete ? 'var(--menuItem-text1)' : 'var(--red-dark)'
        "
        (click)="cancel()"
      ></app-button>
      <app-button
        style="z-index: 2;"
        [title]="confirmButtonText"
        [icon]="'fa fa-check'"
        [backgroundColor]="
          !confirmButtonDisabled
            ? confirmDelete
              ? 'var(--red)'
              : 'var(--fabbrica)'
            : '#9BB688'
        "
        [fontColor]="'white'"
        [iconBackgroundColor]="
          !confirmButtonDisabled
            ? confirmDelete
              ? 'var(--red-dark)'
              : 'var(--fabbrica-xdark)'
            : '#9BB688'
        "
        (click)="confirm()"
      ></app-button>
    </div>
    <div *ngIf="false" id="rectangle-section"></div>
  </div>
</div>
