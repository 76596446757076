import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-multi-radio-button',
  templateUrl: './multi-radio-button.component.html',
  styleUrls: ['./multi-radio-button.component.scss']
})
export class MultiRadioButtonComponent {
  @Input() buttonsInnerText: Array<string>;
  @Input() selectButton: number;
  @Output() selectButtonChange = new EventEmitter<number>();

  @Output() newSelection = new EventEmitter<number>();

  onButtonClick(buttonIndex: number) {
    this.selectButton = buttonIndex;
    this.selectButtonChange.emit(this.selectButton);
  }
}
